import { useLetterBundlesByStatus, useModifyAndDownloadState } from '../../lib/queries/queries';
import ManageLettersColumn from './ManageLettersColumn';
import '../../scss/dashboard/_manage-letters.scss';
import LetterFilter from './LetterFilter';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';

ManageLetters.propTypes = {
  canAdoptSocial: PropTypes.bool.isRequired,
  letterFilter: PropTypes.string,
  updateFilter: PropTypes.func,
};

function ManageLetters({ letterFilter, updateFilter, canAdoptSocial }) {
  const modifyAndDownloadState = useModifyAndDownloadState();
  const { isSuccess: isLetterBundleSuccess, isLoading: isLetterBundleLoading, data: letterBundlesByStatus } = useLetterBundlesByStatus();

  if (isLetterBundleLoading) {
    return (<Loading />);
  }
  if (!isLetterBundleSuccess || !letterBundlesByStatus) {
    return null;
  }

  const filterByVoterName = (letters) => letters.filter((l) => `${ l.first_name.toLowerCase() } ${ l.last_name.toLowerCase() }`.includes(letterFilter));

  const filteredBundles = (bundlesByStatus) => {
    if (!letterFilter || letterFilter === '' || letterFilter.length < 2 || !bundlesByStatus) {
      return bundlesByStatus;
    }

    const bundles = [];
    let letter_count = 0;
    const letter_hashes = [];

    bundlesByStatus.bundles.forEach((b) => {
      if (b.hashid.toLowerCase().includes(letterFilter) || b.district_id.toLowerCase().includes(letterFilter) || b.short_description.toLowerCase().includes(letterFilter)) {
        // if letter bundle's hash district id, or short description contains filter, add to the filtered results
        bundles.push(b);
        letter_hashes.push(b.hashid);
        letter_count += b.letter_count;
      } else {
        //check to see if the bundle contains a voter name that contains the filter
        const filteredVoters = filterByVoterName(b.letters);
        if (filteredVoters.length > 0) {
          //if the bundle contains a voter name that contains the filter
          // filter letters by name and update letter counts
          const updatedBundle = { ...b, letters: filteredVoters, letter_count: filteredVoters.length };
          bundles.push(updatedBundle);
          letter_hashes.push(b.hashid);
          letter_count += updatedBundle.letter_count;
        }
      }
    });
    if (!bundles || bundles.length === 0) {
      return { bundle_status: bundlesByStatus.bundle_status, letter_count: 0, bundle_count: 0 };
    }
    return { ...bundlesByStatus, bundles, bundle_count: bundles.length, letter_count, bundle_hashes: letter_hashes };
  };

  const letterFilterPresent = !!letterFilter && (letterFilter !== '');
  const subtitle = `Track the voters you've adopted${ canAdoptSocial ? ' individually or with your party' : '' }, along with your letter-writing process, using the bundles listed below.`;

  return (
    <div className='px-2 px-sm-5 pb-5 user-letters' data-testid='manage-letters'>
      <div className='row mb-4'>
        <div className='col-lg-12 col-xl-6 pb-4' >
          <div className='row mb-2'>
            <div className='col'>
              <span className='pt-2 h2'>
                My Letters
              </span>
              <span className='fs-4 fw-bold'>
                { ` (${ letterBundlesByStatus.total_letter_count } letters in ${ letterBundlesByStatus.distinct_bundle_count } bundles)` }
              </span>
            </div>
          </div>
          <div className='row'>
            <span className='fs-6'>
              { subtitle }
            </span>
          </div>
        </div>
        <div className='col-lg-12 col-xl-6'>
          <LetterFilter className='p-2 mr-3 rounded-pill letter-filter' letterFilter={ letterFilter } updateFilter={ updateFilter } />
        </div>
      </div>
      <div className='row'>
        <ManageLettersColumn
          bundleData={ filteredBundles(letterBundlesByStatus.unprepped) || { bundle_status: 'UNPREPPED', letter_count: 0, bundle_count: 0 } }
          canAdoptSocial={ canAdoptSocial }
          letterFilterPresent={ letterFilterPresent }
          modifyAndDownloadState={ modifyAndDownloadState }
        />
        <ManageLettersColumn
          bundleData={ filteredBundles(letterBundlesByStatus.prepped) || { bundle_status: 'PREPPED', letter_count: 0, bundle_count: 0 } }
          canAdoptSocial={ canAdoptSocial }
          letterFilterPresent={ letterFilterPresent }
          modifyAndDownloadState={ modifyAndDownloadState }
        />
        <ManageLettersColumn
          bundleData={ filteredBundles(letterBundlesByStatus.sent) || { bundle_status: 'SENT', letter_count: 0, bundle_count: 0 } }
          canAdoptSocial={ canAdoptSocial }
          letterFilterPresent={ letterFilterPresent }
          modifyAndDownloadState={ modifyAndDownloadState }
        />
      </div>
    </div>
  );
}

export default ManageLetters;
