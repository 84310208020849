import PropTypes from 'prop-types';

const letterKitType = 'LETTERKIT';

const letterKitPropType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  'districts.displayName': PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
});

export { letterKitPropType, letterKitType };
