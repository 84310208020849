/**
 * @param {Object} params
 * @param {string[]} params.grants - list of grants
 * @param {string} params.resource - resource (e.g. "*:*" or "partner:dailykos" or "ANY" to return true if the user has the specified permissionto for any resource)
 * @param {string} params.permission - permission (e.g. "user.read" or "partner.read")
 *
 * Accepts list of grants for a user, a resource and permission to check, and then true if grants
 * give access to the resource/permission.
 */
function checkPermission({ grants, resource, permission }) {
  const partnerType = resource.split(':')[0];
  const matchingGrants = grants.filter((grant) => grant.permission === permission && (
    resource === 'ANY' || grant.resource === resource || grant.resource === '*:*' || grant.resource === `${ partnerType }:*`
  ));
  return matchingGrants.length > 0;
}

function getGrants() {
  const grants = localStorage.getItem('grants');
  if (!grants || grants === '') {
    return [];
  } else {
    return JSON.parse(grants);
  }
}

function getUserId() {
  return localStorage.getItem('user_id');
}

function getUserGroups() {
  const groups = localStorage.getItem('groups');
  if (!groups || groups === '') {
    return [];
  } else {
    return JSON.parse(groups);
  }
}

function getUserPartners() {
  const partners = localStorage.getItem('partners');
  if (!partners || partners === '') {
    return [];
  } else {
    return JSON.parse(partners);
  }
}

function isAdmin(user) {
  return user.is_admin;
}

function isAuthenticated() {
  // Check whether the current time is past the
  // Access Token's expiry time
  try {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  } catch {
    return false;
  }
}

function canUserAdoptSocialBatches() {
  return getUserGroups().includes('socialbatchadopters');
}

function isUserQualified(user) {
  return user.qual_state === 'qualified'
    || user.qual_state === 'super_qualified';
}

function nullifySession(queryClient) {
  localStorage.removeItem('access_token');
  localStorage.removeItem('email');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('grants');
  localStorage.removeItem('groups');
  localStorage.removeItem('id_token');
  localStorage.removeItem('is_admin');
  localStorage.removeItem('letter_adoption_override');
  localStorage.removeItem('partners');
  localStorage.removeItem('picture_url');
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_qualified_state');
  localStorage.removeItem('next_partner_to_sign');
  sessionStorage.removeItem('emailToken');
  if (queryClient) {
    queryClient.removeQueries('campaigns');
    queryClient.removeQueries('letterKits');
    queryClient.removeQueries('users');
    queryClient.clear();
    localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
  }
}

export {
  canUserAdoptSocialBatches,
  checkPermission,
  getGrants,
  getUserId,
  getUserPartners,
  isAdmin,
  isAuthenticated,
  isUserQualified,
  nullifySession,
};
