import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

function LeerEnEspañol({ className, Element }) {
  const { i18n, t } = useTranslation('common');
  const handleSwitchTranslation = useCallback(
    async (event) => {
      event.preventDefault();
      await i18n.changeLanguage(t('leer-en-espanol.lng'));
    },
    [i18n, t],
  );

  return (
    <Element className={ classNames('font-italic', className) }>
      (
      <a
        className='switch-translation'
        href={ t('leer-en-espanol.href') }
        onClick={ handleSwitchTranslation }
      >
        { t('leer-en-espanol.text') }
      </a>
      )
    </Element>
  );
}

LeerEnEspañol.defaultProps = {
  Element: 'p',
};

LeerEnEspañol.propTypes = {
  Element: PropTypes.string,
  className: PropTypes.string,
};

export default LeerEnEspañol;
