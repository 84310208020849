import axios from 'axios';
import moment from 'moment';
import deprecatedConfig from './lib/deprecatedConfig';

function comparePosts(a, b, field, direction = 'asc') {
  if (a[field] === b[field]) {
    return 0;
  }
  let firstField = a[field];
  let secondField = b[field];
  if (direction.toLowerCase() === 'desc') {
    firstField = b[field];
    secondField = a[field];
  }
  if (field.match(/_(?:at|date)$/)) {
    return (moment(firstField).isBefore(moment(secondField))) ? -1 : 1;
  } else if (isNaN(firstField)) {
    return firstField.localeCompare(secondField);
  } else {
    return (firstField > secondField) ? 1 : -1;
  }
}

export function getBranchOverride() {
  const branchOverride = localStorage.getItem('cmsBranchName');
  return branchOverride || undefined;
}
export function setBranchOverride(value) {
  localStorage.setItem('cmsBranchName', value);
}

async function getPostHTML(post) {
  if (post) {
    const url = await deprecatedConfig.get('cmsApiUrl');
    const res = await axios({
      method: 'GET',
      url: `${ url }/${ post.location }`,
    });
    return res && res.data ? res.data : undefined;
  }
}

export function sortPosts({ posts, sortBy }) {
  const [sortByField, direction] = sortBy.split(':');
  return [...posts].sort((b, a) => comparePosts(b, a, sortByField || 'posted_at', direction));
}

export function filterPosts({ posts, slug, tag }) {
  return posts.filter((p) => {
    if (tag && p.tags.indexOf(tag) < 0) {
      return false;
    }
    return !(slug && p.slug !== slug);
  });
}
export async function getPosts({ sortBy, tag, slug = undefined }) {
  let posts;
  const [url, branchName] = await Promise.all([deprecatedConfig.get('cmsApiUrl'), deprecatedConfig.get('defaultCmsBranchName')]);
  const res = await axios({
    method: 'GET',
    url: `${ url }/branches/${ getBranchOverride() || branchName }/manifest.json?nocache=${ (new Date()).getTime() }`,
  });
  posts = res && res.data ? res.data : [];
  posts = filterPosts({ posts, slug, tag });
  let html;
  if (posts.length === 1) {
    html = await getPostHTML(posts[0]);
  } else {
    return sortPosts({ posts, sortBy });
  }
  if (html) {
    posts[0].html = html;
  }
  return posts;
}
