import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  Filter,
  TextInput,
  BooleanInput,
  DateInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  BulkExportButton,
  FunctionField,
  Create,
  SimpleForm,
  required,
  regex,
  maxLength,
  minLength,
  Toolbar,
  SaveButton,
  Edit,
  EditButton,
  useNotify,
} from 'react-admin';
import { createTimezoneDate } from '../lib/dates';

const urlRegex = /^https?:\/\/.*$/;
const urlMessage = "This should be a valid URL, starting with 'https://'";

const errorHandler = (error, notify) => {
  if (error.status === 'Conflict') {
    notify(`Error: District ID must be Unique`);
  } else {
    notify(`Error: ${ error.body || error.message }`);
  }
};

const tzFormat = (dateToFormat) => (dateToFormat ? createTimezoneDate(dateToFormat).toISOString().split('T')[0] : dateToFormat);

const SharedInputs = () => (
  <Fragment>
    <DateInput format={ (v) => tzFormat(v) } source='electionDate' validate={ [required()] } /> <br />
    <TextInput label='State name' source='state' validate={ [required()] } /> <br />
    <TextInput label='State Abbreviation' source='stateAbbr' validate={ [minLength(2)] } /> <br />
    <TextInput source='districtNum' validate={ [minLength(2)] } /> <br />

    <SelectInput
      choices={ [
        { id: 'EVENT', name: 'EVENT' },
        { id: 'ROLLING', name: 'ROLLING' },
        { id: 'ROLLING_ASAP', name: 'ROLLING_ASAP' },
      ] }
      source='mailDateType'
      validate={ [required()] }
    /> <br />
    <DateInput format={ (v) => tzFormat(v) } source='mailDateFrom' validate={ [required()] } /> <br />
    <DateInput format={ (v) => tzFormat(v) } source='mailDate' validate={ [required()] } /> <br />
    <BooleanInput source='isNew' />
    <SelectInput
      choices={ [
        { id: 'social', name: 'social' },
        { id: 'political', name: 'political' },
        { id: 'voter-engagement', name: 'Voter Registration' },
      ] }
      source='taxType'
      validate={ [required()] }
    /> <br />
    <ReferenceInput
      filterToQuery={ (name) => ({ 'name:contains': name }) }
      fullWidth
      label='Template'
      reference='DistrictTemplates'
      sortBy='id'
      source='template'
      validate={ [required()] }
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <SelectInput
      choices={ [
        { id: 'gotv', name: 'gotv' },
        { id: 'fpca-form', name: 'fpca-form' },
        { id: 'vrbm', name: 'vrbm' },
        { id: 'vrbm-form', name: 'vrbm-form' },
        { id: 'vrbm-n09', name: 'vrbm-n09' },
        { id: 'vrbm-form-labs', name: 'vrbm-form-labs' },
      ] }
      source='type'
    /> <br />

    <TextInput fullWidth source='displayName' /> <br />
    <TextInput fullWidth multiline source='description' /> <br />
    <TextInput fullWidth multiline source='returnAddress' /> <br />
    <TextInput label='Return Address City' source='raCity' /> <br />
    <TextInput label='Return Address State' source='raState' /> <br />
    <TextInput label='Return Address ZIP' source='raZip' /> <br />
    <TextInput fullWidth multiline source='whyThisDistrict' /> <br />
    <TextInput fullWidth source='urlElectionInfo' validate={ [regex(urlRegex, urlMessage)] } /> <br />
    <TextInput fullWidth source='urlWikipedia' validate={ [regex(urlRegex, urlMessage)] } /> <br />
    <TextInput fullWidth source='urlBallotpedia' validate={ [regex(urlRegex, urlMessage)] } /> <br />
    <TextInput fullWidth source='urlSwingleft' validate={ [regex(urlRegex, urlMessage)] } /> <br />
    <TextInput fullWidth source='trainingUrl' /> <br />
    <BooleanInput source='isLabs' />
    <TextInput fullWidth multiline source='labsDescription' /> <br />
    <BooleanInput helperText='Pause adoption of this district' source='pauseAdoption' />
    <BooleanInput helperText='Restrict adoption to volunteers within the same state' label='State-only mode' source='stateOnlyMode' />
    <BooleanInput helperText='Hide district except to affiliated partners' label='Partner-specific mode' source='partnerSpecificMode' />
    <BooleanInput helperText='Hide district except to affiliated groups' label='Group-specific mode' source='groupSpecificMode' />
    <BooleanInput helperText='Allow volunteers to request letter kits for this campaign' label='Letter-kit mode' source='letterKitMode' />
    <BooleanInput helperText='Star this campaign as priority' source='priority' />

  </Fragment>
);


export const DistrictEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };

  return (
    <Edit onFailure={ onFailure } undoable={ false } { ...props }>
      <SimpleForm redirect='edit'>
        <TextInput disabled source='id' />
        <TextInput disabled source='districtId' />
        <NumberInput disabled source='availableVoterCount' />
        <SharedInputs />
      </SimpleForm>
    </Edit>
  );
};

const DistrictCreateToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton
      label='create'
    />
  </Toolbar>
);

export const DistrictCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };
  return (
    <Create onFailure={ onFailure } { ...props }>
      <SimpleForm toolbar={ <DistrictCreateToolbar /> }>
        <TextInput
          helperText='This cannot be changed or deleted.'
          label='District ID'
          source='districtId'
          validate={ [
            required(),
            regex(/^[A-Z][A-Z\d_]*$/, 'ID must be alphanumeric with uppercase letters'),
            regex(/[A-Z]/, "ID can't be all numbers"),
            minLength(3),
            maxLength(24),
          ] }
        />
        <SharedInputs />
      </SimpleForm>
    </Create>
  );
};

const DistrictListBulkActionButtons = (props) => (
  <BulkExportButton { ...props } />
);

const DistrictFilter = (props) => (
  <Filter { ...props }>
    <DateInput label='Election Date Before' source='electionDate:lessThanOrEqualTo' />
    <DateInput alwaysOn label='Election Date After' source='electionDate:greaterThanOrEqualTo' />
    <TextInput alwaysOn label='District ID' source='districtId:contains' />
    <BooleanInput defaultValue={ false } source='pauseAdoption' />
  </Filter>
);

const DistrictList = (props) => (
  <List
    { ...props }
    bulkActionButtons={ <DistrictListBulkActionButtons /> }
    filterDefaultValues={{ 'electionDate:greaterThanOrEqualTo': (new Date()).toISOString().split('T')[0] }}
    filters={ <DistrictFilter /> }
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source='id' />
      <TextField source='districtId' />
      <NumberField label='Avail Voters' source='availableVoterCount' />
      <TextField source='type' />
      <TextField source='taxType' />
      <FunctionField label='Election Date' render={ (record) => tzFormat(record.electionDate) } sortBy='electionDate' />
      <FunctionField label='Mail Date' render={ (record) => tzFormat(record.mailDate) } sortBy='mailDate' />
      <BooleanField label='paused' source='pauseAdoption' />
      <EditButton { ...props } />
    </Datagrid>
  </List>
);

export default DistrictList;
