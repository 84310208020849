import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { letterKitPropType } from './letterKitTypes';
import LetterKitRequestSummary from './LetterKitRequestSummary';
import Loading from '../components/Loading';

function isKitApproved(kit) {
  return kit.status === 'APPROVED'
         || kit.status === 'SENT';
}

function isKitRejected(kit) {
  return kit.status === 'REJECTED';
}

function isKitShipped(kit) {
  return kit.status === 'SENT';
}

const applyAgainMessage = (
  <Fragment>
    We&rsquo;re sorry, but your request was not approved. Please feel free to apply again.
  </Fragment>
);
const requestApprovedMessage = (
  <Fragment>
    Your letter kit request has been approved! Please <Link to='/dashboard'>visit your dashboard</Link> to mark your
    letters sent.
  </Fragment>
);
const kitShippedMessage = (
  <Fragment>
    Your letter kit has shipped! Please <Link to='/dashboard'>visit your dashboard</Link> to mark your letters sent.
  </Fragment>
);

function LetterKitRequestConfirmation({ email, kits }) {
  let message = `Thank you for submitting your request. Check your email (${ email }) for updates.`;

  if (kits?.length >= 1) {
    const anyKitsApproved = kits.some(isKitApproved);
    const allKitsRejected = kits.every(isKitRejected);
    const allKitsShipped = kits.every(isKitShipped);

    if (anyKitsApproved) {
      message = requestApprovedMessage;
    }
    if (allKitsRejected) {
      message = applyAgainMessage;
    }
    if (allKitsShipped) {
      message = kitShippedMessage;
    }
  }

  return (
    <div className='row'>
      <div className='col'>
        <h3 className='fw-bold h5 pb-3 pt-2'>
          { message }
        </h3>

        {
          kits?.length >= 1
            ? <LetterKitRequestSummary kits={ kits } />
            : <Loading />
        }
      </div>
    </div>
  );
}

LetterKitRequestConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  kits: PropTypes.arrayOf(letterKitPropType),
};

export default LetterKitRequestConfirmation;
