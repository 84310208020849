/* eslint-disable react/no-unstable-nested-components */

import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import propTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { Component, Fragment } from 'react';

import AdminUserOverrides from './AdminUserOverrides';
import { createTimezoneDate } from '../lib/dates';
import downloadLetters from '../lib/downloadLetters';
import downloadLetterBundles from '../lib/downloadLetterBundles';
import EmailPreferencesForm from '../EmailPreferencesForm';
import Footer from '../page/Footer';
import Header from '../page/Header';
import logger from '../lib/logger';
import Modal from '../components/Modal';
import t from '../lib/deprecated_i18n';

import '../scss/admin/_admin.scss';

import MUIDataTable from 'mui-datatables';
import AddressColumn from './tables/AddressColumn';

function buttonClassNames(state, qualState) {
  return classNames(
    'w-25 btn btn-light small',
    { checked: state === qualState },
  );
}

class AdminUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voters: [],
      bundles: [],
      events: [],
      emailVerifications: [],
      user: '',
      locale: props.locale || 'en',
      auth0_id: this.props.match.params.id,
      city: '',
      state: '',
      relinquishingUnprepped: false,
      markingAllPreppedAndSent: false,
      markingAllUnsent: false,
      alert: null,
      showRelinquishVotersModal: null,
    };
  }

  static get propTypes() {
    return {
      showHeader: propTypes.bool,
      showFooter: propTypes.bool,
      auth: propTypes.object,
      match: propTypes.object,
      locale: propTypes.string,
    };
  }

  async getUser(auth0_id) {
    const accessToken = await this.props.auth.getAccessToken();
    const res = await axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      params: {
        includeGroups: true,
        includePartners: true,
      },
      url: `${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }`,
    });
    this.setState({ user: res.data });
    this.getAndSetLocation();
  }

  async getAndSetLocation() {
    const accessToken = await this.props.auth.getAccessToken();
    const res = await axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      url: `${ process.env.REACT_APP_API_URL }/lookup-zip-details`,
      params: { zip: this.state.user.zip },
    });
    this.setState({ city: res.data[0].city, state: res.data[0].state });
  }

  async getAdoptedVoters(auth0_id) {
    try {
      const accessToken = await this.props.auth.getAccessToken();
      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };
      const res = await axios
        .get(`${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }/letterStats`, {
          headers,
        });
      this.setState({ voterStats: res.data });
    } catch (err) {
      logger.error(err);
    }
  }

  async getUserEvents(auth0_id) {
    try {
      const accessToken = await this.props.auth.getAccessToken();
      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };
      const res = await axios
        .get(`${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }/events`, {
          headers,
          params: {
            auth0Id: auth0_id,
          },
        });
      this.setState({ events: res.data });
    } catch (err) {
      logger.error(err);
    }
  }

  async getEmailVerifications(auth0_id) {
    const accessToken = await this.props.auth.getAccessToken();
    const headers = {
      Authorization: 'Bearer '.concat(accessToken),
    };
    const res = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }/messages`, {
      headers,
      params: {
        auth0Ids: auth0_id,
        types: 'EMAIL_FORGOT-PASSWORD,EMAIL_REGISTER-USER,EMAIL_VERIFY-EMAIL',
      },
    });
    this.setState({ emailVerifications: res.data });
  }

  async refreshLetterKitsAndBundles(auth0_id) {
    try {
      const accessToken = await this.props.auth.getAccessToken();
      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };
      const [bundleResult, letterKitResult] = await Promise.all([
        axios.get(`${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }/campaigns/stats`, {
          headers,
          params: {
            groupBy: 'adopted_at',
          },
        }),
        axios.get(`${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }/letterBundles`, {
          headers,
          params: {
            filter: { type: 'LETTERKIT' },
          },
        }),
      ]);
      this.setState({ bundles: bundleResult.data, letterKits: letterKitResult.data });
    } catch (err) {
      logger.error(err);
    }
  }

  async downloadBundleForUser(userId) {
    try {
      await downloadLetters({ auth: this.props.auth, userId, excludePrepped: true });
    } catch (err) {
      logger.error(err);
    }
  }

  async confirmAllNotSentForUser(auth0_id) {
    const accessToken = await this.props.auth.getAccessToken();
    await axios
      .patch(`${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }/letters`, {
        filters: {
          confirmedSent: false,
          confirmedNotSent: false,
        },
        update: {
          confirmedNotSent: true,
        },
      }, {
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
      });
    this.refreshLetterKitsAndBundles(this.props.match.params.id);
    this.getAdoptedVoters(this.props.match.params.id);
    this.setState({ markingAllUnsent: false });
  }

  componentDidMount() {
    this.getUser(this.props.match.params.id);
    this.getAdoptedVoters(this.props.match.params.id);
    this.refreshLetterKitsAndBundles(this.props.match.params.id);
    this.getUserEvents(this.props.match.params.id);
    this.getEmailVerifications(this.props.match.params.id);
  }

  handleChangeStatus = async (event) => {
    event.preventDefault();
    const { newQualState } = event.currentTarget.dataset;
    if (this.state.user.qual_state === newQualState) {
      return;
    }
    try {
      const accessToken = await this.props.auth.getAccessToken();
      await axios({
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        url: `${ process.env.REACT_APP_API_URL }/v1/users/${ this.state.user.auth0_id }/admin`,
        data: {
          qualified_state: newQualState,
        },
      });
      await this.getUser(this.state.user.auth0_id);
    } catch (err) {
      if (err.response.status === 409) {
        this.setState({
          alert: {
            severity: 'error',
            message: t('admin.user.update.error.duplicateEmail'),
          },
        });
      }
      logger.error(err);
    }
  };

  handleSnackbarClose = () => {
    this.setState({ alert: null });
  };

  cancelRelinquishUnprepped() {
    this.setState({ relinquishingUnprepped: false });
  }

  cancelMarkingAllPreppedAndSent() {
    this.setState({ markingAllPreppedAndSent: false });
  }

  hideRelinquishVotersModal = () => {
    this.setState({ showRelinquishVotersModal: null });
  };

  rejectLetterKits = async ({ accessToken, adopter, hashId = null }) => {
    const filters = {
      status: ['REQUESTED', 'APPROVED'],
      type: 'LETTERKIT',
    };
    if (hashId) { filters.hashid = hashId; }

    await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/${ adopter }/letterBundles`, {
      filters,
      update: {
        status: 'REJECTED',
      },
    }, {
      Authorization: 'Bearer '.concat(accessToken),
    });
  };

  handleRelinquishUnprepped = async (event) => {
    try {
      const { adopter } = event.currentTarget.dataset;
      const accessToken = await this.props.auth.getAccessToken();

      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };
      await this.rejectLetterKits({ accessToken, adopter });
      await axios
        .patch(`${ process.env.REACT_APP_API_URL }/v1/users/${ adopter }/letters`, {
          filters: {
            confirmedPrepped: false,
            confirmedSent: false,
            confirmedNotSent: false,
          },
          update: {
            adopterId: '',
          },
        }, {
          headers,
        });

      await this.refreshLetterKitsAndBundles(this.props.match.params.id);
      await this.getAdoptedVoters(this.props.match.params.id);
      this.cancelRelinquishUnprepped();
    } catch (err) {
      logger.error(err);
    }
  };

  confirmAllPreppedAndSent = async (event) => {
    const { adopter } = event.currentTarget.dataset;
    const accessToken = await this.props.auth.getAccessToken();
    await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/${ adopter }/letters`, {
      filters: {
        confirmedSent: false,
        confirmedNotSent: false,
      },
      update: {
        confirmedPrepped: true,
        confirmedSent: true,
      },
    }, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
    });
    this.refreshLetterKitsAndBundles(this.props.match.params.id);
    this.getAdoptedVoters(this.props.match.params.id);
    this.cancelMarkingAllPreppedAndSent();
  };

  renderStatus() {
    const qualState = this.state.user.qual_state;
    return (
      <div className='btn-group btn-group-toggle w-100'>
        <label
          className={ buttonClassNames('banned', qualState) }
          data-new-qual-state='banned'
          htmlFor='banned'
          onClick={ this.handleChangeStatus }
        >
          <input
            autoComplete='off'
            className='btn-check'
            defaultChecked={ qualState === 'banned' }
            id='banned'
            name='status'
            type='radio'
          />
          B
        </label>
        <label
          className={ buttonClassNames('pre_qualified', qualState) }
          data-new-qual-state='pre_qualified'
          htmlFor='prequal'
          onClick={ this.handleChangeStatus }
        >
          <input
            autoComplete='off'
            className='btn-check'
            defaultChecked={ qualState === 'pre_qualified' }
            id='prequal'
            name='status'
            type='radio'
          />
          P
        </label>
        <label
          className={ buttonClassNames('qualified', qualState) }
          data-new-qual-state='qualified'
          htmlFor='qual'
          onClick={ this.handleChangeStatus }
        >
          <input
            autoComplete='off'
            className='btn-check'
            defaultChecked={ qualState === 'qualified' }
            id='qual'
            name='status'
            type='radio'
          />
          Q
        </label>
        <label
          className={ buttonClassNames('super_qualified', qualState) }
          data-new-qual-state='super_qualified'
          htmlFor='superqual'
          onClick={ this.handleChangeStatus }
        >
          <input
            autoComplete='off'
            className='btn-check'
            defaultChecked={ qualState === 'super_qualified' }
            id='superqual'
            name='status'
            type='radio'
          />
          S
        </label>
      </div>
    );
  }

  downloadBundle = async (event) => {
    const { adopter, hashid } = event.currentTarget.dataset;
    try {
      const filters = {
        adopterId: adopter,
      };
      if (hashid) {
        filters.letterHash = hashid;
      }
      await downloadLetterBundles({ filters, adopter, auth: this.props.auth });
    } catch (err) {
      logger.error(err);
    }
  };

  updateLettersInBundle = async (event) => {
    const { action, adopter, district } = event.currentTarget.dataset;
    const adoptedAtEpoch = Number(event.currentTarget.dataset.adoptedAtEpoch);
    const filters = {
      districtId: district,
      adoptedAtEpoch,
    };

    const update = {};

    if (action === 'markPrepped') {
      filters.confirmedPrepped = false;
      filters.confirmedSent = false;
      filters.confirmedNotSent = false;
      update.confirmedPrepped = true;
    }

    if (action === 'markUnprepped') {
      filters.confirmedPrepped = true;
      filters.confirmedSent = false;
      filters.confirmedNotSent = false;
      update.confirmedPrepped = false;
    }

    if (action === 'markSent') {
      filters.confirmedPrepped = true;
      filters.confirmedSent = false;
      filters.confirmedNotSent = false;
      update.confirmedSent = true;
    }

    if (action === 'markUnsent') {
      filters.confirmedPrepped = true;
      filters.confirmedSent = true;
      filters.confirmedNotSent = false;
      update.confirmedSent = false;
    }

    const accessToken = await this.props.auth.getAccessToken();
    const headers = {
      Authorization: 'Bearer '.concat(accessToken),
    };
    await axios.patch(
        `${ process.env.REACT_APP_API_URL }/v1/users/${ adopter }/letters`,
        { filters, update },
        { headers },
    );
    this.refreshLetterKitsAndBundles(this.props.match.params.id);
  };

  relinquish = async (event) => {
    const { adopter, district, hashid, bundleType } = event.currentTarget.dataset;
    const adoptedAtEpoch = Number(event.currentTarget.dataset.adoptedAtEpoch);
    try {
      const accessToken = await this.props.auth.getAccessToken();
      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };

      if (bundleType === 'LETTERKIT') { await this.rejectLetterKits({ accessToken, adopter, hashid }); }

      await axios
        .patch(`${ process.env.REACT_APP_API_URL }/v1/users/${ adopter }/letters`, {
          filters: {
            districtId: district,
            adoptedAtEpoch,
            confirmedPrepped: false,
            confirmedSent: false,
            confirmedNotSent: false,
          },
          update: {
            adopterId: '',
          },
        }, {
          headers,
        });

      await this.refreshLetterKitsAndBundles(this.props.match.params.id);
      this.setState({ showRelinquishVotersModal: null });
    } catch (err) { logger.error(err); }
  };

  handleShowRelinquishVotersModal = (event) => {
    const index = Number(event.currentTarget.dataset.index);
    this.setState({ showRelinquishVotersModal: index });
  };

  renderBundleActionButtons = (dataIndex) => {
    if (!this.state.bundles[dataIndex]) {
      return;
    }

    const {
      epoch,
      adopter_user_id,
      district_id,
      prepped_count,
      sent_count,
      unprepped_count,
      hashid,
      bundle_type,
    } = this.state.bundles[dataIndex];

    const adopted = parseInt(unprepped_count, 10);
    const prepped = parseInt(prepped_count, 10);
    const sent = parseInt(sent_count, 10);

    const buttons = [];
    buttons.push(
      <button
        className='btn btn-sm btn-warning ms-2'
        data-adopter={ adopter_user_id }
        data-hashid={ hashid }
        key='downloadBundle'
        onClick={ this.downloadBundle }
        title='Download Bundle'
      >
        Download
      </button>,
    );
    if (adopted > 0) {
      buttons.push(
        <span>
          <Modal
            hideModal={ this.hideRelinquishVotersModal }
            modalButtons={ (
              <div>
                <button
                  className='btn btn-info btn-small me-2 col-5'
                  data-adopted-at-epoch={ epoch }
                  data-adopter={ adopter_user_id }
                  data-bundle-type={ bundle_type }
                  data-district={ district_id }
                  data-hashid={ hashid }
                  onClick={ this.relinquish }
                  title='Confirm Relinquish'
                  type='cancel'
                >{ t('admin.user.relinquishVoters.yes') }
                </button>

                <button
                  className='btn btn-danger btn-small col-5'
                  onClick={ this.hideRelinquishVotersModal }
                  title='Cancel Relinquish'
                  type='submit'
                >{ t('admin.user.relinquishVoters.no') }
                </button>
              </div>
            ) }
            modalText={ `Are you sure you wish to relinquish this batch of ${ adopted } voters for campaign ${ district_id }? This cannot be undone.` }
            modalTitle='Confirm voter release'
            showModal={ this.state.showRelinquishVotersModal === dataIndex }
          />
          <button
            className='btn btn-sm btn-warning ms-2'
            data-index={ dataIndex }
            key='relinquish'
            onClick={ this.handleShowRelinquishVotersModal }
            title='Adopted to Relinquished'
          >
            Relinquish
          </button>
        </span>,
      );

      buttons.push(
        <button
          className='btn btn-sm btn-warning ms-2'
          data-action='markPrepped'
          data-adopted-at-epoch={ epoch }
          data-adopter={ adopter_user_id }
          data-district={ district_id }
          key='markPrepped'
          onClick={ this.updateLettersInBundle }
          title='Adopted to Prepped'
        >
          A -&gt; P
        </button>,
      );
    }

    if (prepped > 0) {
      buttons.push(
        <button
          className='btn btn-sm btn-warning ms-2'
          data-action='markUnprepped'
          data-adopted-at-epoch={ epoch }
          data-adopter={ adopter_user_id }
          data-district={ district_id }
          key='markUnprepped'
          onClick={ this.updateLettersInBundle }
          title='Prepped to Adopted'
        >
          A &lt;- P
        </button>,
      );
      buttons.push(
        <button
          className='btn btn-sm btn-warning ms-2'
          data-action='markSent'
          data-adopted-at-epoch={ epoch }
          data-adopter={ adopter_user_id }
          data-district={ district_id }
          key='markSent'
          onClick={ this.updateLettersInBundle }
          title='Prepped to Sent'
        >
          P -&gt; S
        </button>,
      );
    }

    if (sent > 0) {
      buttons.push(
        <button
          className='btn btn-sm btn-warning ms-2'
          data-action='markUnsent'
          data-adopted-at-epoch={ epoch }
          data-adopter={ adopter_user_id }
          data-district={ district_id }
          key='markUnsent'
          onClick={ this.updateLettersInBundle }
          title='Sent to Prepped'
        >
          P &lt;- S
        </button>,
      );
    }

    return (
      <div>
        { buttons }
      </div>
    );
  };

  renderLetterKitTable(letterKits) {
    const columns = [
      {
        name: 'hashid',
        label: 'Hashid',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'districtId',
        label: 'Campaign',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'kitType',
        label: 'Kit Type',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          customBodyRenderLite: (rowIndex) => (
            AddressColumn(letterKits[rowIndex])
          ),
          filter: true,
        },
      },
      {
        name: 'statement',
        label: 'Statement',
      },
      {
        name: 'createdAt',
        label: 'Created At',
        options: {
          filter: false,
          sort: true,
          sortDirection: 'desc',
          customBodyRender: (row) => (
            <span>
              { row && moment(row)
                .local()
                .format('MM/DD/YY, hh:mm:ss a') }
            </span>
          ),
        },
      },
      {
        name: 'approvedAt',
        label: 'Approved At',
        options: {
          filter: false,
          sort: true,
          sortDirection: 'desc',
          customBodyRender: (row) => (
            <span>
              { row && moment(row)
                .local()
                .format('MM/DD/YY, hh:mm:ss a') }
            </span>
          ),
        },
      },
      {
        name: 'sentAt',
        label: 'Sent At',
        options: {
          filter: false,
          sort: true,
          sortDirection: 'desc',
          customBodyRender: (row) => (
            <span>
              { row && moment(row)
                .local()
                .format('MM/DD/YY, hh:mm:ss a') }
            </span>
          ),
        },
      },
    ];
    return (
      <div>
        <MUIDataTable
          columns={ columns }
          data={ letterKits }
          options={{
            filterType: 'textField',
            fixedHeader: false,
            fixedSelectColumn: false,
            print: false,
            responsive: 'standard',
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 100],
            selectableRows: 'none',
          }}
          title='Letter Kits'
        />
      </div>
    );
  }

  renderBundleTable(bundles) {
    const columns = [
      {
        name: 'adopted_at',
        label: 'Adopted At',
        options: {
          filter: false,
          sort: true,
          sortDirection: 'desc',
          customBodyRender: (row) => (
            <span>
              { moment(row)
                .local()
                .format('MM/DD/YY, hh:mm:ss a') }
            </span>
          ),
        },
      },
      {
        name: 'district_id',
        label: 'Campaign',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'hashid',
        label: 'Hashid',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'bundle_type',
        label: 'Bundle type',
        options: {
          customBodyRenderLite: (rowIndex) => {
            const bundleType = bundles[rowIndex].bundle_type;
            if (bundleType === 'LETTERKIT') {
              return `${ bundleType } (${ bundles[rowIndex].kit_type })`;
            }
            return bundleType;
          },
          setCellHeaderProps: () => ({
            className: 'bundle-type',
          }),
          filter: true,
          sort: true,
        },
      },
      {
        name: 'election_date',
        label: 'Election Date',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (row) => (
            <span>
              { moment(createTimezoneDate(row))
                .format('MM/DD/YY') }
            </span>
          ),
        },
      },
      {
        name: 'unprepped_count',
        label: 'Adopted',
        options: {
          setCellProps: () => ({
            className: 'bundle-adopted-count',
          }),
          filter: false,
          sort: true,
        },
      },
      {
        name: 'prepped_count',
        label: 'Prepped',
        options: {
          setCellProps: () => ({
            className: 'bundle-prepped-count',
          }),
          filter: false,
          sort: true,
        },
      },
      {
        name: 'sent_count',
        label: 'Sent',
        options: {
          setCellProps: () => ({
            className: 'bundle-sent-count',
          }),
          filter: false,
          sort: true,
        },
      },
      {
        name: 'unsent_count',
        label: 'Not Sent',
        options: {
          setCellProps: () => ({
            className: 'bundle-unsent-count',
          }),
          filter: false,
          sort: true,
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        options: {
          download: false,
          filter: false,
          sort: true,
          customBodyRenderLite: this.renderBundleActionButtons,
        },
      },
    ];
    return (
      <div>
        <MUIDataTable
          columns={ columns }
          data={ bundles }
          options={{
            filterType: 'textField',
            fixedHeader: false,
            fixedSelectColumn: false,
            print: false,
            responsive: 'standard',
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 100],
            selectableRows: 'none',
          }}
          title='Letter Bundles'
        />
      </div>
    );
  }

  formatSubType = (dataIndex) => {
    const dataRow = this.state.events[dataIndex];
    if (!dataRow) {
      return ' ';
    }
    if (dataRow.audit_new_value) {
      return `${ dataRow.sub_type }  (${ dataRow.audit_new_value })`;
    } else {
      return dataRow.sub_type;
    }
  };

  renderEventTable(events) {
    const columns = [
      {
        name: 'updated_at',
        label: 'Timestamp',
        options: {
          customBodyRender: (value) => (
            <span>
              { value
                ? moment(value)
                  .local()
                  .format('MM/DD/YY, hh:mm a')
                : ' ' }
            </span>
          ),
          sort: true,
        },
      },
      {
        name: 'type',
        label: 'Type',
        options: {
          sort: true,
        },
      },
      {
        name: 'sub_type',
        label: 'Subtype',
        options: {
          customBodyRenderLite: this.formatSubType,
          sort: true,
        },
      },
      {
        name: 'created_by',
        label: 'Actor',
        options: {
          sort: true,
        },
      },
      {
        name: 'request_id',
        label: 'Request Id',
        options: {
          sort: true,
        },
      },
    ];

    return (
      <div>
        <MUIDataTable
          columns={ columns }
          data={ events }
          options={{
            filter: false,
            fixedHeader: false,
            fixedSelectColumn: false,
            print: false,
            selectableRows: 'none',
            sort: true,
            setTableProps: () => ({
              className: '-striped -highlight',
              style: { width: '90vw' },
            }),
          }}
          title='Event Log'
        />
      </div>
    );
  }

  renderEmailVerificationTable(emailVerifications) {
    const columns = [
      {
        name: 'send_date',
        label: 'Send Date',
        options: {
          customBodyRender: (value) => (
            <span>
              { value
                ? moment(value)
                  .local()
                  .format('MM/DD/YY, hh:mm a')
                : ' ' }
            </span>
          ),
          sort: true,
        },
      },
      {
        name: 'type',
        label: 'Type',
        options: {
          customBodyRender: (value) => (
            <span>
              { value ? value.replace(/^EMAIL_/, '') : ' ' }
            </span>
          ),
          sort: true,
        },
      },
      {
        name: 'sent_at',
        label: 'Sent At',
        options: {
          customBodyRender: (value) => (
            <span>
              { value
                ? moment(value)
                  .local()
                  .format('MM/DD, hh:mm a')
                : ' ' }
            </span>
          ),
          sort: true,
        },
      },
      {
        name: 'clicked_at',
        label: 'Clicked',
        options: {
          customBodyRender: (value) => (
            <span>
              { value
                ? moment(value)
                  .local()
                  .format('MM/DD, hh:mm a')
                : ' ' }
            </span>
          ),
          sort: true,
        },
      },
      {
        name: 'authenticated_clicked_at',
        label: 'Signed In',
        options: {
          customBodyRender: (value) => (
            <span>
              { value
                ? moment(value)
                  .local()
                  .format('MM/DD, hh:mm a')
                : ' ' }
            </span>
          ),
          sort: true,
        },
      },
    ];

    return (
      <div>
        <MUIDataTable
          columns={ columns }
          data={ emailVerifications }
          options={{
            filter: false,
            fixedHeader: false,
            fixedSelectColumn: false,
            print: false,
            selectableRows: 'none',
            sort: true,
            setTableProps: () => ({
              className: '-striped -highlight',
            }),
          }}
          title='Email Verifications'
        />
      </div>
    );
  }

  render() {
    const summaryStats = this.state.voterStats || {};

    const emailUrl = `mailto:${ this.state.user.email }`;
    const twitterUrl =
      `https://www.twitter.com/${ this.state.user.twitter_profile_url }`;
    const facebookUrl =
      `https://www.facebook.com/${ this.state.user.facebook_profile_url }`;
    const linkedinUrl =
      `https://www.linkedin.com/in/${ this.state.user.linkedin_profile_url }`;
    const statusButtons = this.renderStatus();
    const letterKitRows = this.renderLetterKitTable(this.state.letterKits);
    const bundleRows = this.renderBundleTable(this.state.bundles);
    const eventRows = this.renderEventTable(this.state.events);
    const emailVerificationRows = this.renderEmailVerificationTable(this.state.emailVerifications);

    const alert = this.state.alert;
    const alertSeverity = alert ? alert.severity : 'info';
    const alertMessage = alert ? alert.message : '';

    return (
      <Fragment>
        <Snackbar
          autoHideDuration={ 6000 }
          onClose={ this.handleSnackbarClose }
          open={ alert }
        >
          <Alert severity={ alertSeverity }>
            { alertMessage }
          </Alert>
        </Snackbar>
        { this.props.showHeader ? <Header /> : null }
        <div className='container-fluid mb-4 mt-4' style={{ width: 'unset' }}>
          <div className='mb-4'>
            <h4 className='mb-4'>Basics</h4>

            <div>
              <p>
                <span className='me-4'>Full name:</span>
                { this.state.user.full_name }
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>Preferred name:</span>
                { this.state.user.preferred_name }
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>Current campaign:</span>
                { this.state.user.current_district }
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>auth0 Id:</span>
                { this.state.user.auth0_id }
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>Created at:</span>
                <span>
                  { moment(this.state.user.created_at)
                    .local()
                    .format('MM/DD, hh:mm a') }
                </span>
                <span className='ms-2'>
                  ({ moment(this.state.user.created_at).fromNow() })
                </span>
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>Updated at:</span>
                <span>
                  { moment(this.state.user.updated_at)
                    .local()
                    .format('MM/DD, hh:mm a') }
                </span>
                <span className='ms-2'>
                  ({ moment(this.state.user.updated_at).fromNow() })
                </span>
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>Email address:</span>
                <a href={ emailUrl }>{ this.state.user.email }</a>
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>Email verified?</span>
                <span>
                  {
                    this.state.user.email_verified
                      ? (<i aria-hidden='true' className='fa fa-check' />)
                      : (<i aria-hidden='true' className='fa fa-times' />)
                  }
                </span>
              </p>
            </div>

            <div>
              <p>
                <span className='me-3'>Twitter profile link: </span>
                <a href={ twitterUrl } rel='noreferrer noopener' target='_blank'>
                  { this.state.user.twitter_profile_url }
                </a>
              </p>
            </div>

            <div>
              <p>
                <span className='me-3'>Facebook profile link:</span>
                <a href={ facebookUrl } rel='noreferrer noopener' target='_blank'>
                  { this.state.user.facebook_profile_url }
                </a>
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>LinkedIn profile:</span>
                <a href={ linkedinUrl } rel='noreferrer noopener' target='_blank'>
                  { this.state.user.linkedin_profile_url }
                </a>
              </p>
            </div>

            <div>
              <p>
                <span className='me-4'>Location:</span>
                { this.state.city && (
                  <span>
                    { this.state.city }, { this.state.state }{ ' ' }
                  </span>
                ) }
                { this.state.user.zip }
              </p>
            </div>
            <div>
              <p>
                <span className='me-4'>Letter Writing Sample:</span>
                { this.state.user.letter_writing_sample }
              </p>
            </div>
            <div>
              <p>
                <span className='me-4'>Why write letters:</span>
                { this.state.user.why_write_letters }
              </p>
            </div>
            <div>
              <p>
                <span className='me-4'>Partners:</span>
                { this.state.user.partners ? this.state.user.partners.join(',') : '' }
              </p>
            </div>
            <div>
              <p>
                <span className='me-4'>Groups:</span>
                { this.state.user.groups ? this.state.user.groups.join(', ') : '' }
              </p>
            </div>
          </div>

          <hr />
          <div className='mb-4'>
            { bundleRows }
          </div>

          <hr />
          <div className='mb-4'>
            { letterKitRows }
          </div>

          <div className='mb-4'>
            <h4 className='mb-4'>Voter Stuff</h4>

            <h5>Overview</h5>

            <p>
              This user has adopted{ ' ' }
              <strong>{ summaryStats.totalAdoptedCount }</strong> voters total.{ ' ' }
              { '  ' }
              Of those,{ ' ' }
              <strong>
                { summaryStats.sentCount + summaryStats.unsentCount }
              </strong>{ ' ' }
              have a final disposition —{ ' ' }
            </p>
            <p className='ms-4 ps-4'>
              Sent: <strong>{ summaryStats.sentCount }</strong>
            </p>
            <p className='ms-4 ps-4'>
              NOT sent: <strong>{ summaryStats.unsentCount }</strong>
            </p>

            <h5 className=''>Unresolved Voters</h5>

            <div>
              Adopted but not marked “prepared”: { summaryStats.unpreppedCount }
              <button
                // Disable unless there are some voters not yet marked prepped
                className='btn btn-small btn-success ms-2 mt-2'
                disabled={ summaryStats.unpreppedCount === 0 }
                onClick={ () => this.downloadBundleForUser(this.state.user.auth0_id) }
              >
                Generate PDF of unprepped letters
              </button>
              { !this.state.relinquishingUnprepped
                ? (
                  <button
                    className='btn btn-small btn-warning ms-2 mt-2'
                      // Disable if there aren’t any voters adopted who
                      // haven't been marked prepped to relinquish, or if all of the
                      // the volunteer's voters already have a final disposition
                    disabled={
                        !!(summaryStats.unpreppedCount === 0
                    || summaryStats.totalAdoptedCount
                      === summaryStats.sentCount + summaryStats.unsentCount)
                      }
                    onClick={ () => {
                      this.setState({ relinquishingUnprepped: true });
                    } }
                  >
                    Relinquish unprepped voters
                  </button>
                  )
                : (
                  <div className='alert alert-warning my-3 mx-5'>
                    <p className='me-4 ms-4 mt-3 mb-3'>
                      { t('admin.user.relinquishUnpreppredVoters.warning') }
                    </p>
                    <p className='small'>
                      { ' ' }
                      If the election has already happened, you should mark the
                      letters “not sent,” rather than relinquishing them, because
                      it’s too late for another volunteer to adopt them.
                    </p>
                    <button
                      className='btn btn-info btn-small me-2'
                      disabled={
                          !!(summaryStats.unpreppedCount === 0
                      || summaryStats.totalAdoptedCount
                        === summaryStats.sentCount + summaryStats.unsentCount)
                        }
                      onClick={ () => {
                        this.cancelRelinquishUnprepped();
                      } }
                      type='cancel'
                    >
                      Cancel
                    </button>

                    <button
                      className='btn btn-danger btn-small ms-2 mt-2'
                      data-adopter={ this.state.user.auth0_id }
                      onClick={ this.handleRelinquishUnprepped }
                      type='submit'
                    >
                      Yes, relinquish unprepped voters
                    </button>
                  </div>
                  ) }
            </div>

            <div>
              Not explicitly marked “sent“ or “not sent”:{ ' ' }
              { summaryStats.totalAdoptedCount
                - summaryStats.sentCount
                - summaryStats.unsentCount }
              { !this.state.markingAllPreppedAndSent
                ? (
                  <button
                    className='btn btn-small btn-warning ms-2 mt-2'
                      // Disable if there are no voters left who haven't yet been
                      // marked either explicitly sent or explicitly not sent — i.e.,
                      // if the number of adopted voters equals the sum of the voters
                      // with a final disposition — "sent" or "not sent."
                    disabled={
                        summaryStats.totalAdoptedCount
                    === summaryStats.sentCount + summaryStats.unsentCount
                      }
                    onClick={ () => {
                      this.setState({ markingAllPreppedAndSent: true });
                    } }
                  >
                    Mark prepped and sent
                  </button>
                  )
                : (
                  <div className='alert alert-warning my-3 mx-5'>
                    <p className='me-4 ms-4 mt-3 mb-3'>
                      Are you sure you want to mark all of the voters this user
                      adopted but hasn’t yet marked “prepared” or “sent” both
                      “prepared” and “sent”? This cannot be undone.
                    </p>
                    <button
                      className='btn btn-info btn-small me-2'
                      disabled={
                          summaryStats.totalAdoptedCount
                      === summaryStats.sentCount + summaryStats.unsentCount
                        }
                      onClick={ () => {
                        this.cancelMarkingAllPreppedAndSent();
                      } }
                      type='cancel'
                    >
                      Cancel
                    </button>

                    <button
                      className='btn btn-danger btn-small ms-2 mt-2'
                      data-adopter={ this.state.user.auth0_id }
                      onClick={ this.confirmAllPreppedAndSent }
                      type='submit'
                    >
                      Yes, mark prepped and sent
                    </button>
                  </div>
                  ) }
              { !this.state.markingAllUnsent
                ? (
                  <button
                    className='btn btn-small btn-warning ms-2 mt-2'
                      // Disable if there are no voters left who haven't yet been
                      // marked either explicitly sent or explicitly not sent — i.e.,
                      // if the number of adopted voters equals the sum of the voters
                      // with a final disposition — "sent" or "not sent."
                    disabled={
                        summaryStats.totalAdoptedCount
                    === summaryStats.sentCount + summaryStats.unsentCount
                      }
                    onClick={ () => {
                      this.setState({ markingAllUnsent: true });
                    } }
                  >
                    Mark <strong>NOT</strong> sent
                  </button>
                  )
                : (
                  <div className='alert alert-warning my-3 mx-5'>
                    <p className='me-4 ms-4 mt-3 mb-3'>
                      Are you sure you want to mark all of the voters this user
                      adopted but hasn’t yet marked “prepared” or “sent” NOT sent?
                      This cannot be undone.
                    </p>
                    <button
                      className='btn btn-info btn-small me-2'
                      disabled={
                          summaryStats.totalAdoptedCount
                      === summaryStats.sentCount + summaryStats.unsentCount
                        }
                      onClick={ () => {
                        this.setState({ markingAllUnsent: false });
                      } }
                      type='cancel'
                    >
                      Cancel
                    </button>

                    <button
                      className='btn btn-danger btn-small ms-2 mt-2'
                      onClick={ () => {
                        this.confirmAllNotSentForUser(this.state.user.auth0_id);
                      } }
                      type='submit'
                    >
                      Yes, mark <strong>NOT</strong> sent
                    </button>
                  </div>
                  ) }
            </div>
          </div>

          <div className='mb-4'>
            <h4 className='mb-4'>Qualification</h4>
            { statusButtons }
          </div>
          <div className='mb-4'>
            <h4 className='mb-4'>{ t('admin.user.overrides') }</h4>
            <AdminUserOverrides
              auth={ this.props.auth }
              locale={ this.state.locale }
              onUserUpdated={ (updatedUser) => { this.setState({ user: updatedUser }); } }
              user={ this.state.user }
            />
          </div>
          <div className='mb-4'>
            <h4 className='mb-4'>Email Preferences</h4>
            <EmailPreferencesForm
              auth={ this.props.auth }
              auth0_id={ this.state.auth0_id }
              locale={ this.state.locale }
            />
          </div>

          <div className='mb-4'>
            <hr />
            { eventRows }
          </div>

          <div className='mb-4'>
            <h4 className='mb-4'>Email Verifications</h4>
            { emailVerificationRows }
          </div>
        </div>
        { this.props.showFooter ? <Footer /> : null }
      </Fragment>
    );
  }
}

export default AdminUser;
