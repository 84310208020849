import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';

import logger from '../lib/logger';
import useLocalStorageState from '../lib/hooks/useLocalStorageState';

const defaultPictureUrl = 'https://i0.wp.com/cdn.auth0.com/avatars/vf.png';
const pictureUrlKey = 'picture_url';

const UserProfilePictureContext = createContext(null);

function UserProfilePictureProvider({ children }) {
  const [pictureUrl, setPictureUrl] = useLocalStorageState(pictureUrlKey, defaultPictureUrl);
  const value = useMemo(() => [pictureUrl, setPictureUrl], [pictureUrl, setPictureUrl]);

  return (
    <UserProfilePictureContext.Provider value={ value }>
      { children }
    </UserProfilePictureContext.Provider>
  );
}

UserProfilePictureProvider.propTypes = {
  children: PropTypes.element,
};

function useUserProfilePicture() {
  const context = useContext(UserProfilePictureContext);
  if (context === null) {
    logger.error('useUserProfilePicture() must be called within a UserProfilePictureProvider');
  }
  return context;
}

export { UserProfilePictureProvider, useUserProfilePicture };
