import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Route as PlainRoute } from 'react-router-dom';

import Tracker from '../Tracker';
import { useAuth } from '../../contexts/AuthContext';

function Route(props) {
  const auth = useAuth();

  const tracker = useMemo(() => new Tracker({ auth }), [auth]);

  tracker.logEvent('Route:navigate', {
    path: props.path,
  });

  tracker.trackSegment();

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
    });
  }

  return <PlainRoute { ...props } />;
}

Route.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Route;
