import { Link, useRouteMatch } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

import LeerEnEspañol from '../components/LeerEnEspanol';
import Loading from '../components/Loading';
import StyledErrorMessage from '../forms/StyledErrorMessage';
import { post } from '../lib/apiClient';

import '../scss/pages/_opt-out.scss';

function OptOut() {
  const { params: { code }} = useRouteMatch();
  const { t } = useTranslation('optOut');

  const {
    formState: { errors: formErrors, isSubmitted: isFormSubmitted },
    handleSubmit: validateForm,
    register,
    setError: setFormError,
  } = useForm({
    criteriaMode: 'all', // This is needed to render messages [https://react-hook-form.com/api/useformstate/errormessage]
    defaultValues: { code },
    shouldUseNativeValidation: false,
  });

  const {
    isLoading: isOptOutRequestLoading,
    isSuccess: isOptOutRequestSuccess,
    mutate: requestOptOut,
  } = useMutation(
    async (formData) => await post(`/voter/opt-out`, formData),
    {
      onError: (error) => {
        setFormError(
          'code',
          {
            type: 'manual',
            message: t([`error.${ error.response.status }`, 'error.general']),
          },
          { shouldFocus: true },
        );
      },
    },
  );

  const handleSubmit = useMemo(
    () => validateForm(async (data) => await requestOptOut(data)),
    [requestOptOut, validateForm],
  );

  function formInputClassNames(name) {
    let validationClassName;
    if (isFormSubmitted && formErrors) {
      validationClassName = formErrors[name] ? 'is-invalid' : 'is-valid';
    }
    return ['form-control', validationClassName].filter(Boolean).join(' ');
  }

  return (
    <main className='container mb-5 pt-md-3 pt-lg-4'>
      <section className='row'>
        <section className='col-12 col-sm-9 col-md-7 col-lg-5 mx-auto pt-1 pt-sm-3 px-xl-5'>
          <section className='card'>
            <section className='card-body'>
              <h4 className='card-title mb-1 mt-1 text-center'>
                { t('title') }
              </h4>
              <LeerEnEspañol className='mb-3 text-center' />

              <form className='border-top opt-out pt-2' noValidate onSubmit={ handleSubmit }>
                <p className='hyphens-auto lead pb-1'>
                  { t('message') }
                </p>

                <fieldset className='mb-3'>
                  <label>
                    { t('label') }
                  </label>
                  <input
                    className={ formInputClassNames('code') }
                    placeholder='A1B2C3D'
                    readOnly={ isOptOutRequestSuccess }
                    type='text'
                    {
                      ...register(
                        'code',
                        {
                          minLength: {
                            message: t('error.code.tooShort'),
                            value: 6,
                          },
                          required: t('error.code.required'),
                          setValueAs: (value) => value.trim(),
                        },
                      )
                    }
                  />
                  <StyledErrorMessage errors={ formErrors } name='code' />
                </fieldset>

                {
                  isOptOutRequestLoading
                    ? <Loading className='d-flex pb-2' />
                    : null
                }
                {
                  isOptOutRequestSuccess
                    ? (
                      <div className='lead pb-3 text-success'>
                        { t('success') }
                      </div>
                      )
                    : null
                }
                {
                  !isOptOutRequestLoading && !isOptOutRequestSuccess
                    ? (
                      <fieldset className='mb-3'>
                        <input
                          className='btn w-100 btn-primary'
                          disabled={ !!isOptOutRequestLoading }
                          type='submit'
                          value={ t('button') }
                        />
                      </fieldset>
                      )
                    : null
                }
              </form>
              <p>{ t('support-prefix') }<a href='mailto:help@votefwd.org'>help@votefwd.org</a>.</p>
            </section>
          </section>

          <section className='card mt-3'>
            <section className='card-body'>
              <p className='lead mb-1'>
                <Trans i18nKey='programOverview' t={ t }>
                  0
                  <Link className='program-overview' to='/program-overview'>
                    1
                  </Link>
                  2
                </Trans>
              </p>
            </section>
          </section>
        </section>
      </section>
    </main>
  );
}

export default OptOut;
