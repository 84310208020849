import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleShowLayout,
  DateField,
  NumberField,
  FunctionField,
  SelectInput,
  ShowButton,
  Show,
  TextInput,
  Filter,
} from 'react-admin';


export const VoterView = (props) => (
  <Show { ...props }>
    <SimpleShowLayout>
      <FunctionField label='First Name' render={ (record) => (record['userOptOuts.createdAt'] ? `${ record.firstName } (Opted Out)` : record.firstName) } />
      <TextField source='middleName' />
      <TextField source='lastName' />
      <TextField source='suffix' />
      <TextField source='registrationId' />
      <TextField source='districtId' />
      <TextField source='hashid' />
      <TextField source='adopterUserId' />
      <TextField label='Adopter Email' source='users.email' />
      <TextField source='address' />
      <TextField source='city' />
      <TextField source='county' />
      <TextField source='state' />
      <TextField source='zip' />
      <TextField source='gender' />
      <DateField source='dateOfBirth' />
      <NumberField source='age' />
      <DateField label='Opted Out At' showTime source='userOptOuts.createdAt' />
      <NumberField source='pledgeCount' />
      <DateField showTime='true' source='adoptedAt' />
      <DateField showTime='true' source='confirmedPreppedAt' />
      <DateField showTime='true' source='confirmedSentAt' />
      <DateField showTime='true' source='confirmedNotSentAt' />
    </SimpleShowLayout>
  </Show>
);

const VoterFilters = (props) => (
  <Filter { ...props }>
    <TextInput alwaysOn label='First Name' source='firstName:startsWith' />
    <TextInput alwaysOn label='Last Name' source='lastName:startsWith' />
    <TextInput alwaysOn label='District ID' source='districtId:startsWith' />
    <TextInput alwaysOn label='Zip' source='zip:startsWith' />
    <SelectInput
      choices={ [
        { id: null, name: 'All Rows' },
        { id: true, name: 'Opt Outs Only' },
      ] }
      label='Opted Out?'
      source='userOptOuts.createdAt:isNotNull'
    />
    <SelectInput
      choices={ [
        { id: 0, name: 'All Rows' },
        { id: 1, name: 'Pledges Only' },
      ] }
      label='Pledged?'
      source='pledgeCount:greaterThanOrEqualTo'
    />
    <TextInput alwaysOn source='state' />
    <TextInput alwaysOn label='Adopter Email' source='users.email:startsWith' />
    <TextInput alwaysOn source='hashid' />
  </Filter>
);

const Voters = (props) => (
  <List
    { ...props }
    filters={ <VoterFilters /> }
    sort={{ field: 'lastName', order: 'ASC' }}
  >
    <Datagrid>
      <TextField source='firstName' />
      <TextField source='lastName' />
      <TextField source='districtId' />
      <TextField source='hashid' />
      <TextField label='Adopter Email' source='users.email' />
      <TextField source='zip' />
      <TextField source='state' />
      <DateField source='adoptedAt' />
      <DateField source='confirmedPreppedAt' />
      <DateField source='confirmedSentAt' />
      <ShowButton { ...props } />
    </Datagrid>
  </List>
);

export default Voters;
