import PropTypes from 'prop-types';
import { Redirect, useRouteMatch } from 'react-router-dom';

import Loading from '../../components/Loading';
import Page from '../../page/Page';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../queries/queries';

function UserChecker({ component: Component }) {
  const auth = useAuth();
  const { data: user, isLoading } = useUser();
  const match = useRouteMatch();

  if (isLoading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  } else if (!user.auth0_id) {
    return <Redirect to='/' />;
  }

  return <Component auth={ auth } match={ match } user={ user } />;
}

UserChecker.propTypes = {
  component: PropTypes.func.isRequired,
};

export default UserChecker;
