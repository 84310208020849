import React, { Component } from 'react';
import axios from 'axios';
import logger from './lib/logger';
import Tracker from './lib/Tracker';
import Modal from './components/Modal';
import propTypes from 'prop-types';
import i18n from './locales/strings.json';

class EmailPreferencesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale || 'en',
      error: '',
      getSettingsCalled: false,
      showErrorModal: false,
      showUnsubscribeModal: false,
      showUnsentModal: false,
      preferences: {
        reminders: true,
        newsletter: false,
        labs: false,
        receive_fewer: false,
        unsubscribe: false,
      },
      composite_update_token: props.composite_update_token,
      auth0_id: props.auth0_id,
      isAuthenticated: this.props.auth.isAuthenticated(),
      hasUnsent: false,
      unpreppedCount: 0,
    };

    this.tracker = new Tracker({ auth: props.auth });
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
      auth0_id: propTypes.string,
      composite_update_token: propTypes.string,
    };
  }

  getPrefs = async () => {
    const accessToken = (!this.state.composite_update_token) && await this.props.auth.getAccessToken();
    try {
      const getRequest = {
        method: 'GET',
        url: `${ process.env.REACT_APP_API_URL }/v1/emails/preferences`,
        params: {
          composite_update_token: this.state.composite_update_token,
          auth0_id: this.state.auth0_id,
        },
      };
      if (accessToken) {
        getRequest.headers = {
          Authorization: 'Bearer '.concat(accessToken),
        };
      }

      const res = await axios(getRequest);
      if (res.data) {
        this.setState({
          hasUnsent: res.data.hasUnsent,
          unpreppedCount: res.data.unpreppedCount,
          preferences: res.data,
          email: res.data.email,
          composite_update_token: res.data.composite_update_token,
          getSettingsCalled: true,
        });
      }
    } catch (err) {
      if ([400, 404].indexOf(err.response.status) >= 0) {
        this.setState({
          error: 'notfound',
          getSettingsCalled: true,
        });
      } else {
        this.setState({
          error: 'generic',
          getSettingsCalled: true,
        });
      }
    }
  };

  componentDidMount() {
    if (this.state.composite_update_token !== '' || this.state.isAuthenticated) {
      this.getPrefs();
    }
  }

  hideErrorModal = () => {
    this.setState({ showErrorModal: false });
  };

  hideUnsentModal = () => {
    this.setState({ showUnsentModal: false });
  };

  hideUnsubscribeModal = () => {
    this.setState({ showUnsubscribeModal: false });
  };

  relinquishUnpreppedLetters = () => {
    const prefsToUpdate = { unsubscribe: true };
    this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
    this.submitEmailPreferencesForm(prefsToUpdate, true, false);
    this.hideUnsentModal();
  };

  relinquishUnsentLetters = () => {
    const prefsToUpdate = { unsubscribe: true };
    this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
    this.submitEmailPreferencesForm(prefsToUpdate, false, true);
    this.hideUnsentModal();
  };

  confirmUnsubscribe = () => {
    if (this.state.hasUnsent) {
      this.hideUnsubscribeModal();
      this.setState({ showUnsentModal: true });
    } else {
      this.hideUnsubscribeModal();
      const prefsToUpdate = { unsubscribe: true };
      this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
      //update email preferences and relinquish unprepped letters
      this.submitEmailPreferencesForm(prefsToUpdate, true, false);
    }
  };

  handleUnsubscribeChange = (event) => {
    const prefsToUpdate = { unsubscribe: event.target.checked };
    if (prefsToUpdate.unsubscribe) {
      this.setState({ showUnsubscribeModal: true });
    } else {
      this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
      this.submitEmailPreferencesForm(prefsToUpdate);
    }
  };

  handleReceiveFewerChange = (event) => {
    const prefsToUpdate = { receive_fewer: event.target.checked };
    this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
    this.submitEmailPreferencesForm(prefsToUpdate);
  };

  handleNewsletterChange = (event) => {
    const prefsToUpdate = { newsletter: event.target.checked };
    this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
    this.submitEmailPreferencesForm(prefsToUpdate);
  };

  handleRemindersChange = (event) => {
    const prefsToUpdate = { reminders: event.target.checked };
    this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
    this.submitEmailPreferencesForm(prefsToUpdate);
  };

  handleLabsChange = (event) => {
    const prefsToUpdate = { labs: event.target.checked };
    this.setState({ preferences: { ...this.state.preferences, ...prefsToUpdate }});
    this.submitEmailPreferencesForm(prefsToUpdate);
  };

  submitEmailPreferencesForm = async (prefsToUpdate, relinquishUnpreppedLetters = false, relinquishUnsentLetters = false) => {
    const payload = {
      composite_update_token: this.state.composite_update_token,
      auth0_id: this.state.auth0_id,
      relinquishUnpreppedLetters,
      relinquishUnsentLetters,
      preferences: prefsToUpdate,
    };

    try {
      const res = await axios.post(`${ process.env.REACT_APP_API_URL }/v1/emails/preferences`, payload);
      if (res.data && relinquishUnsentLetters && res.data.hasUnsent !== undefined) {
        this.setState({ hasUnsent: res.data.hasUnsent });
      }
      this.tracker.logEvent('EmailPreferences:function:updatePreferences', { status: 200 });
    } catch (err) {
      logger.error(err);
      // Undo the preference changes:
      const undoPrefs = {};
      for (const key of Object.keys(prefsToUpdate)) {
        undoPrefs[key] = !prefsToUpdate[key];
      }
      this.setState({ showModal: true, preferences: { ...this.state.preferences, ...undoPrefs }});
      this.tracker.logEvent('EmailPreferences:function:updatePreferences', { status: 500 });
    }
  };

  render() {
    let unsubscribeUnpreppedFooterWarning = '';
    if (this.state.unpreppedCount > 0) {
      unsubscribeUnpreppedFooterWarning =
        i18n[this.state.locale]['email.preferences.unsubscribe.warning.unprepped']
          .replace('{{unpreppedCount}}', this.state.unpreppedCount);
      if (this.state.unpreppedCount > 1) {
        // Plural for 'voters'
        unsubscribeUnpreppedFooterWarning = unsubscribeUnpreppedFooterWarning
          .replace('{{voters}}', i18n[this.state.locale]['email.preferences.unsubscribe.warning.unprepped.voters']);
      } else {
        // Singular for 'voter'
        unsubscribeUnpreppedFooterWarning = unsubscribeUnpreppedFooterWarning
          .replace('{{voters}}', i18n[this.state.locale]['email.preferences.unsubscribe.warning.unprepped.voter']);
      }
    }

    return (
      <div className={ this.state.getSettingsCalled ? '' : 'hidden' } key={ this.state.composite_update_token }>
        <Modal
          hideModal={ this.hideErrorModal }
          modalText={ i18n[this.state.locale]['email.preferences.save.error.description'] }
          modalTitle={ i18n[this.state.locale]['email.preferences.save.error.title'] }
          showModal={ this.state.showErrorModal }
        />
        <Modal
          hideModal={ this.hideUnsubscribeModal }
          modalButtons={ (
            <div className='row justify-content-center'>
              <button
                className='btn btn-info btn-small me-2 col-5'
                onClick={ this.hideUnsubscribeModal }
                type='cancel'
              >{ i18n[this.state.locale]['email.preferences.unsubscribe.warning.button.no'] }
              </button>
              <button
                className='btn btn-danger btn-small col-5'
                onClick={ this.confirmUnsubscribe }
                type='submit'
              >{ i18n[this.state.locale]['email.preferences.unsubscribe.warning.button.yes'] }
              </button>
            </div>
          ) }
          modalTextJsx={
            <div>
              <p>
                { i18n[this.state.locale]['email.preferences.unsubscribe.warning'] }
              </p>
              <p className='fw-bold'>
                { unsubscribeUnpreppedFooterWarning }
              </p>
            </div>
          }
          modalTitle={ i18n[this.state.locale]['email.preferences.unsubscribe'] }
          showModal={ this.state.showUnsubscribeModal }
        />
        <Modal
          hideModal={ this.hideUnsentModal }
          modalButtons={ (
            <div className='row justify-content-center'>
              <button
                className='btn btn-info btn-small me-2 col-5'
                onClick={ this.relinquishUnsentLetters }
                type='cancel'
              >{ i18n[this.state.locale]['email.preferences.unsubscribe.hasUnsent.button.no'] }
              </button>
              <button
                className='btn btn-danger btn-small col-5'
                onClick={ this.relinquishUnpreppedLetters }
                type='submit'
              >{ i18n[this.state.locale]['email.preferences.unsubscribe.hasUnsent.button.yes'] }
              </button>
            </div>
          ) }
          modalText={ i18n[this.state.locale]['email.preferences.unsubscribe.hasUnsent.description'] }
          modalTitle={ i18n[this.state.locale]['email.preferences.unsubscribe.hasUnsent.title'] }
          showModal={ this.state.showUnsentModal }
        />
        <div className={ [this.state.error === '' ? '' : 'hidden', 'mb-3'].join(' ') }>
          <div>
            <div className='row'>
              <div className='col-9'>
                <label>{ i18n[this.state.locale]['email.preferences.mailinglist'] }</label>
                <br /><span>{ i18n[this.state.locale]['email.preferences.mailinglist.description'] }</span>
              </div>
              <div className='col'>
                <label className='switch float-end'>
                  <input
                    checked={ this.state.preferences.newsletter }
                    onChange={ this.handleNewsletterChange }
                    type='checkbox'
                  />
                  <span className='slider round' />
                </label>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-9'>
                <label>{ i18n[this.state.locale]['email.preferences.labs'] }</label>
                <br /><span>{ i18n[this.state.locale]['email.preferences.labs.description'] }</span>
              </div>
              <div className='col'>
                <label className='switch float-end'>
                  <input checked={ this.state.preferences.labs } onChange={ this.handleLabsChange } type='checkbox' />
                  <span className='slider round' />
                </label>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-9'>
                <label>{ i18n[this.state.locale]['email.preferences.receive_fewer'] }</label>
                <br /><span>{ i18n[this.state.locale]['email.preferences.receive_fewer.description'] }</span>
              </div>
              <div className='col'>
                <label className='switch float-end'>
                  <input
                    checked={ this.state.preferences.receive_fewer }
                    onChange={ this.handleReceiveFewerChange }
                    type='checkbox'
                  />
                  <span className='slider round' />
                </label>
              </div>
            </div>
            <hr />
            <div className={ ['row', this.state.preferences.is_user ? '' : 'hidden'].join(' ') }>
              <div className='col-9'>
                <label>{ i18n[this.state.locale]['email.preferences.reminders'] }</label>
                <br /><span>{ i18n[this.state.locale]['email.preferences.reminders.description'] }</span>
              </div>
              <div className='col'>
                <label className='switch float-end'>
                  <input
                    checked={ this.state.preferences.reminders }
                    onChange={ this.handleRemindersChange }
                    type='checkbox'
                  />
                  <span className='slider round off-warning' />
                </label>
              </div>
            </div>
            <hr className={ ['row', this.state.preferences.is_user ? '' : 'hidden'].join(' ') } />
            <div className='row'>
              <div className='col-9'>
                <label>{ i18n[this.state.locale]['email.preferences.unsubscribe'] }</label>
                <br /><span>{ i18n[this.state.locale]['email.preferences.unsubscribe.description'] }</span>
              </div>
              <div className='col'>
                <label className='switch float-end'>
                  <input
                    checked={ this.state.preferences.unsubscribe }
                    id='unsubscribeToggle'
                    onChange={ this.handleUnsubscribeChange }
                    type='checkbox'
                  />
                  <span className='slider round on-warning' />
                </label>
              </div>
            </div>
          </div>
        </div>
        <small className={ this.state.error ? '' : 'hidden' }>
          { this.state.error === 'notfound'
            ? i18n[this.state.locale]['email.preferences.notfound.error']
            : i18n[this.state.locale]['email.preferences.generic.error'] }
        </small>
      </div>
    );
  }
}

export default EmailPreferencesForm;
