import axios from 'axios';
import logger from './logger';


/**
 * Calls necessary API to get a link to download a zip containing pdfs based on parameters.
 * Then "clicks" on the link to download the zip. This is a bit hacky, but heuristically
 * was the only way we could get it to work on various devices (ipad, phone, desktop)
 */
async function downloadLetterBundles({ filters, auth, userId, excludePrepped = false }) {
  const accessToken = await auth.getAccessToken();

  logger.debug(`In downloadLetterBundles, about to call letterBUndle-url with excludPrepped set to: ${ excludePrepped }`);
  const res = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/letterBundles/letterBundle-url`, {
    headers: {
      Authorization: 'Bearer '.concat(accessToken),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: { data: { userId, filters, excludePrepped }},
    responseType: 'json',
  });
  const link = document.createElement('a');
  link.href = res.data;
  document.body.appendChild(link);
  link.click();
}

export default downloadLetterBundles;
