import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import Header from './page/Header';
import Footer from './page/Footer';
import logger from './lib/logger';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Election extends Component {
  static get propTypes() {
    return {
      election: propTypes.object,
      year: propTypes.string,
      markPreppedAndSent: propTypes.func,
      markNotSent: propTypes.func,
    };
  }

  render() {
    const { election, year } = this.props;

    return (
      <div className='election-history-item'>
        <h5 className='header'>
          { year === '2020' ? `${ year }: The Big Send` : year }
        </h5>
        { Object.keys(election.states).map((key) => (
          <div className='row election-history-row' key={ key }>
            <div className='col'>
              { key }
            </div>
            <div className='col'>
              { election.states[key].sent_count }
            </div>
          </div>
        )) }
        <div className='row election-history-row-end'>
          <div className='col'>
            { year } Total Confirmed
          </div>
          <div className='col'>
            { election.annualSent }
          </div>
        </div>
        { election.annualUnConfirmed > 0 && (
          <div className='election-history-item-message'>
            <p className='lead'>
              You also have <strong>{ election.annualUnConfirmed } unconfirmed letters</strong> in { year }. <br className='d-none d-lg-block' />
              Did you send these?
            </p>
            <p>
              Either way is fine! We just need to know because it helps us measure impact.
            </p>
            <div className='mt-2 mb-2'>
              <button
                className='btn btn-danger btn-rounded px-5 me-2'
                data-year={ year }
                onClick={ this.props.markPreppedAndSent }
              >
                Yes, I sent these!
              </button>
              <button
                className='btn btn-primary btn-rounded px-5'
                data-year={ year }
                onClick={ this.props.markNotSent }
              >
                No, I didn’t send!
              </button>
            </div>
          </div>
        ) }
      </div>
    );
  }
}

// eslint-disable-next-line react/no-unsafe
class ElectionHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      history: {},
      grandTotal: 0,
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
    };
  }

  isLoggedIn = () => {
    if (localStorage.getItem('user_id')) {
      return true;
    } else {
      return false;
    }
  };

  // TODO: abstract this out
  getCurrentUser = async () => {
    const accessToken = await this.props.auth.getAccessToken();
    const res = await axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      url: `${ process.env.REACT_APP_API_URL }/v1/users/self`,
    });
    const user = res.data;
    this.setState({ user });
    if (!this.isQualified(user)) {
      window.location.replace('/verify');
    }
  };

  // TODO: abstract this out
  isQualified(user) {
    if (
      user.qual_state === 'qualified'
      || user.qual_state === 'super_qualified'
    ) {
      return true;
    } else {
      return false;
    }
  }

  async getElectionHistory() {
    const user_id = localStorage.getItem('user_id');
    if (user_id) {
      const accessToken = await this.props.auth.getAccessToken();
      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };
      const res = await axios
        .get(`${ process.env.REACT_APP_API_URL }/v1/users/${ user_id }/campaigns/stats`, {
          headers,
          params: {
            groupBy: 'year',
          },
        });
      this.processElectionHistory(res.data);
    }
  }

  processElectionHistory = (elections) => {
    const electionHistory = elections;
    const historyByYear = {};
    let total = 0;
    electionHistory.map((row) => {
      total += row.sent_count;
      if (!historyByYear[row.election_year]) {
        historyByYear[row.election_year] = {
          annualSent: row.sent_count,
          annualUnConfirmed: row.unconfirmed_count,
          states: {
            [row.state]: {
              sent_count: row.sent_count,
              unconfirmed_count: row.unconfirmed_count,
            },
          },
        };
      } else {
        historyByYear[row.election_year].annualSent += row.sent_count;
        historyByYear[row.election_year].annualUnConfirmed += row.unconfirmed_count;
        historyByYear[row.election_year].states[row.state] = {
          sent_count: row.sent_count,
          unconfirmed_count: row.unconfirmed_count,
        };
      }
      //map has to return something
      return true;
    });
    this.setState({ history: historyByYear, grandTotal: total });
  };

  markNotSent = async (event) => {
    const { year } = event.currentTarget.dataset;
    const yearStart = moment([year, '01', '01']);
    const yearEnd = moment([year, '12', '31']);
    const user_id = localStorage.getItem('user_id');
    const accessToken = await this.props.auth.getAccessToken();
    await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/${ user_id }/letters`, {
      filters: {
        campaignTimeframe: 'past',
        confirmedSent: false,
        confirmedNotSent: false,
        electionDateFrom: yearStart,
        electionDateTo: yearEnd,
      },
      update: {
        confirmedNotSent: true,
      },
    }, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
    });
    await this.getElectionHistory();
  };

  markPreppedAndSent = async (event) => {
    const { year } = event.currentTarget.dataset;
    const yearStart = `${ year }-01-01`;
    const yearEnd = `${ year }-12-31`;
    const user_id = localStorage.getItem('user_id');
    const accessToken = await this.props.auth.getAccessToken();
    await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/${ user_id }/letters`, {
      filters: {
        campaignTimeframe: 'past',
        confirmedSent: false,
        confirmedNotSent: false,
        electionDateFrom: yearStart,
        electionDateTo: yearEnd,
      },
      update: {
        confirmedPrepped: true,
        confirmedSent: true,
      },
    }, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
    });
    await this.getElectionHistory();
  };

  UNSAFE_componentWillMount() {
    if (!this.isLoggedIn()) {
      logger.error('ElectionHistory: not logged in, redirecting to /');
      window.location.replace('/');
    } else {
      this.getElectionHistory();
      this.getCurrentUser();
    }
  }

  render() {
    const { history, grandTotal } = this.state;

    return (
      <React.Fragment>
        <Header />
        <div className='container election-history pt-3 pb-5 mb-5'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-9'>
              <h2 className='mt-5'>History</h2>
              { grandTotal > 0 && (
                <p className='total-text'>
                  You’ve sent a grand total <br className='d-none d-lg-block' />of <strong>{ grandTotal } letters</strong>!
                </p>
              ) }
              <p>
                <strong>Please note that letters appear in this view only after election day, or for rolling campaigns, the mailing deadline. Until then, they’re on your <Link to='/dashboard'>Dashboard</Link>.</strong>
              </p>
              <hr className='mt-4 mb-5' />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-9'>
              { Object.keys(history).length > 0 && Object.keys(history).map((key) => (
                <Election
                  election={ history[key] }
                  key={ key }
                  markNotSent={ this.markNotSent }
                  markPreppedAndSent={ this.markPreppedAndSent }
                  year={ key }
                />
              )) }
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default ElectionHistory;
