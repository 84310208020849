import pluralize from 'pluralize';
import parse from 'html-react-parser';

import strings from '../locales/strings.json';

const locale = 'en';

/**
 * Render an i18n message.
 * @param {string} key
 * @param {Object} interpolations - Interpolate data within placeholders
 *   delimited {{double curly braces}}. Keys are placeholders; values will be
 *   stringified and inserted in their place. For example, with the message
 *   "something {{foo}}", pass the interpolation object `{ foo: 'special' }` and
 *   the output will be "something special".  Will also replace {{pluralize:Campaign}}
 *   with either "Campaign" or "Campaigns" depending on the count passed in.
 * @param {number} count - If count is 1, {{pluralize:word}} becomes "word" otherwise it's "words".
 * @return {string}
 */
function message(key, interpolations, count = 0) {
  let result = strings[locale][key];
  if (!result) {
    throw new Error(`Unable to find message with key "${ key }" in locale "${ locale }"`);
  }
  if (interpolations) {
    Object.keys(interpolations).forEach((placeholder) => {
      result = result.replace(
        new RegExp(`{{\\s*${ placeholder }\\s*}}`, 'g'),
        String(interpolations[placeholder]),
      );
    });
  }
  // Now replace any {{pluralize:word}} to "word" or "words" based on the passed in count.
  // eslint-disable-next-line prefer-regex-literals, regexp/no-super-linear-backtracking, regexp/strict
  const matches = result.match(new RegExp(`{{\\s*pluralize:([^}]+)\\s*}}`, 'g'));
  if (matches) {
    for (const match of matches) {
      const pluralizedWord = pluralize(match.split(':')[1].replace(/\}\}$/, ''), count);
      result = result.replace(match, pluralizedWord);
    }
  }
  return parse(result);
}

function t(key, ...messageArgs) {
  if (messageArgs.length >= 1) {
    return message(key, ...messageArgs);
  }
  if (strings[locale][key]) {
    return parse(strings[locale][key]);
  }
  return '';
}

export default t;
export { message };
