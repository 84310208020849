import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import Footer from './Footer';
import Header from './Header';
import MinimalHeader from './MinimalHeader';

function Page({ children, hasMinimalHeader }) {
  return (
    <Fragment>
      { hasMinimalHeader ? <MinimalHeader /> : <Header /> }
      { children }
      <Footer />
    </Fragment>
  );
}

Page.defaultProps = {
  hasMinimalHeader: false,
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  hasMinimalHeader: PropTypes.bool,
};

export default memo(Page);
