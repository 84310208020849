import axios from 'axios';
import propTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import logger from './lib/logger';
import ProgressIndicator from './ProgressIndicator';
import Recaptcha from './Recaptcha';
import { destroyBeacon, initAndOpenBeacon, navigateBeacon } from './Beacon';
import { isUserQualified } from './lib/user';
import { message } from './lib/deprecated_i18n';

class Qualify extends Component {
  componentWillUnmount() {
    destroyBeacon();
  }

  constructor(props) {
    super(props);
    this.state = {
      acceptSLContact: false,
      confirmedUnderstoodInstructions: false,
      confirmedUnderstoodInstructionsError: false,
      facebookProfileVal: '',
      gRecaptchaResponse: '',
      linkedInProfileVal: '',
      nameFormError: false,
      nameFormVal: '',
      prefNameFormVal: '',
      reasonError: false,
      reasonVal: '',
      twitterProfileVal: '',
      zipError: false,
      zipFormVal: '',
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      updateUser: propTypes.func,
      addSLPartner: propTypes.func,
      partners: propTypes.arrayOf(propTypes.object),
      user: propTypes.object,
    };
  }

  handleCaptcha = async (response) => {
    const accessToken = await this.props.auth.getAccessToken();
    const res = await axios({
      method: 'POST',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      url: `${ process.env.REACT_APP_API_URL }/recaptcha`,
      data: {
        recaptchaResponse: response,
      },
    });
    if (res.status === 200) {
      this.props.updateUser({ is_human_at: true });
    } else {
      logger.error('Something went wrong with validation of humanness.');
    }
  };

  handleNameChange = (event) => {
    this.setState({ nameFormVal: event.target.value });
  };

  handleNameSubmit = (event) => {
    event.preventDefault();

    const fullName = this.state.nameFormVal.trim();
    const prefName = this.state.prefNameFormVal.trim();

    if (fullName < 2 || prefName.length < 1) {
      this.setState({ nameFormError: true });
    } else {
      this.props.updateUser({
        full_name: fullName,
        preferred_name: prefName,
      });
    }
  };

  handlePreferredNameChange = (event) => {
    this.setState({ prefNameFormVal: event.target.value });
  };

  showSwingLeftOptIn = () => {
    const alreadySLPartner = this.props.partners.map((p) => p.partner).includes('swingleft');
    const mostRecentPartnerBlocksOptIn = this.props.partners
      && this.props.partners.length > 0
      && this.props.partners.slice(-1)[0].hideSwingLeftOptIn;
    return !alreadySLPartner && !mostRecentPartnerBlocksOptIn;
  };

  checkZip = async (zip) => {
    logger.info(`partner in Qualify: ${ sessionStorage.getItem('partner') }`);
    const accessToken = await this.props.auth.getAccessToken();
    try {
      const res = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        url: `${ process.env.REACT_APP_API_URL }/v1/districts/default`,
        params: {
          partner: sessionStorage.getItem('partner') || '',
        },
      });
      const userUpdate = { zip };
      if (res.data && res.data !== '') {
        userUpdate.current_district = res.data;
      }
      return this.props.updateUser(userUpdate);
    } catch (err) {
      if (err && err.response && err.response.status === 400 && err.response.data.match(/zip/i)) {
        this.setState({ zipError: true });
      }
    }
  };

  handleZipChange = (event) => {
    logger.info('handleZipChange');
    this.setState({ zipFormVal: event.target.value });
  };

  handleZipSubmit = (event) => {
    this.checkZip(this.state.zipFormVal.trim());
    event.preventDefault();
  };

  handlePledgedVote = () => {
    this.props.updateUser({ pledged_vote_at: true });
  };

  handleAgreedCode = () => {
    this.props.updateUser({ accepted_code_at: true });
  };

  handleSLContactChange = () => {
    this.setState({ acceptSLContact: !this.state.acceptSLContact });
  };

  handleSwingLeftSubmit = async () => {
    if (this.state.acceptSLContact === true) {
      await this.props.addSLPartner();
    }
    this.props.updateUser({ answered_sl_contact_question_at: true });
  };

  handleConfirmedUnderstoodInstructionsChange = () => {
    this.setState({ confirmedUnderstoodInstructions: !this.state.confirmedUnderstoodInstructions });
  };

  handleConfirmedUnderstoodInstructionsSubmit = () => {
    if (this.state.confirmedUnderstoodInstructions) {
      this.props.updateUser({ confirmed_understood_instructions_at: true });
    } else {
      this.setState(({ confirmedUnderstoodInstructionsError: true }));
    }
  };

  //////////////////////////////////////////////////////////////////////

  handleReasonChange = (event) => {
    this.setState({ reasonVal: event.target.value });
  };

  //////////////////////////////////////////////////////////////////////

  handleProfileSubmit = (event) => {
    event.preventDefault();
    const inputs = event.target.getElementsByClassName('js-profile-input');
    const letterWritingSample = inputs.letterWritingSample.value;

    if (!letterWritingSample || letterWritingSample.length < 15) {
      // Render error if there is no reason or the length is too short
      this.setState({ reasonError: true });
      return false;
    } else {
      this.props.updateUser({
        twitter_profile_url: inputs.profileTwitter.value,
        facebook_profile_url: inputs.profileFacebook.value,
        linkedin_profile_url: inputs.profileLinkedin.value,
        letter_writing_sample: inputs.letterWritingSample.value,
      });
    }
  };


  openBannedBeacon = (event) => {
    const { location } = event.currentTarget.dataset;
    initAndOpenBeacon({
      auth: this.props.auth,
      beaconArgs: {
        prefill: {
          subject: 'Disabled Account',
        },
      },
    });
    if (location) {
      navigateBeacon(location);
    }
  };

  render() {
    let formMarkup;

    const numQuestions = this.showSwingLeftOptIn() ? 8 : 7;

    const captchaQ = (
      <div className='px-4'>
        <h1 className='px-4 pt-4'>Welcome to Vote Forward</h1>
        <p>
          We’re very excited you’re getting involved! We have a few quick
          questions before you get started…
        </p>
        <h4>Are you a robot?</h4>
        <div className='mb-3'>
          <Recaptcha handleCaptchaResponse={ this.handleCaptcha } />
        </div>
      </div>
    );

    const pledgeQ = (
      <div>
        <div className='p-4'>
          <h4 className='mb-3'>Do you pledge to vote in every election?</h4>
          <button
            className='btn btn-primary btn-lg w-100'
            onClick={ this.handlePledgedVote }
          >
            Yes
          </button>
          <p className='my-3'>
            The letters you send will mention <strong>your</strong> commitment
            to voting, urging the recipient to follow your example.
          </p>
          <p className='small my-4'>
            <i>(Permanent resident or green card holder? We welcome your participation. <a href='https://help.votefwd.org/article/139-im-a-permanent-resident-how-do-i-handle-the-pledge-to-vote-when-registering' rel='noopener noreferrer' target='_blank'>Click here for details</a>.)</i>
          </p>
        </div>
        <ProgressIndicator current={ 1 } max={ numQuestions } />
      </div>
    );

    const nameQ = (
      <form onSubmit={ this.handleNameSubmit }>
        <div className='p-4'>
          <h4 className='mb-3'>What is your full name?</h4>
          <div className='input-group mb-3'>
            <input
              className='form-control form-control-lg'
              name='full-name'
              onChange={ this.handleNameChange }
              placeholder='Your full name (e.g. “Creola K. Johnson”)'
              required
              type='text'
              value={ this.state.nameFormVal }
            />
          </div>
          <h4 className='mb-3'>How do you prefer to be addressed?</h4>
          <p>I.e. if we sent you a letter, what would you want to go after “Dear...”?</p>
          <div className='input-group mb-3'>
            <input
              className='form-control form-control-lg'
              name='preferred-name'
              onChange={ this.handlePreferredNameChange }
              placeholder='Your preferred name (e.g. “Kat”)'
              required
              type='text'
              value={ this.state.prefNameFormVal }
            />
          </div>

          { this.state.nameFormError && (
            <div
              className='alert alert-danger alert-sm mt-3 mb-3 center'
              role='alert'
            >
              Please enter both a full name and your preferred name.
            </div>
          ) }

          <button className='btn btn-primary' type='submit'>
            Submit
          </button>
        </div>
        <ProgressIndicator current={ 2 } max={ numQuestions } />
      </form>
    );

    const zipQ = (
      <form onSubmit={ this.handleZipSubmit }>
        <div className='p-4'>
          <h4 className='mb-3'>What&rsquo;s your ZIP code?</h4>
          <div className='input-group mb-3'>
            <input
              className='form-control form-control-lg'
              id='zipInput'
              onChange={ this.handleZipChange }
              placeholder='12345'
              type='text'
              value={ this.state.zipFormVal }
            />
            <button className='btn btn-primary' type='submit'>
              Submit
            </button>
          </div>
          <p className='small mt-4'>
            <i>(Writing from outside the US? Enter the ZIP where you’re registered to vote.)</i>
          </p>
        </div>
        { this.state.zipError && (
          <div
            className='alert alert-danger alert-sm mt-3 mb-3 me-3 ms-3 center'
            role='alert'
          >
            We don‘t recognize that ZIP. Please enter a valid US ZIP code.
          </div>
        ) }
        <ProgressIndicator current={ 3 } max={ numQuestions } />
      </form>
    );

    const confirmedUnderstoodInstructionsQ = (
      <div>
        <div className='p-4'>
          <h4 className='mb-3'>A few key facts about Vote Forward letter campaigns...</h4>
          <ul>
            <li>
              <b>Handwritten</b>: You&rsquo;ll be adding handwritten messages to letter templates (not postcards) that you download and print.
            </li>
            <li>
              <b>Nonpartisan messaging</b>: You&rsquo;ll need to refrain from asking the recipient to vote for specific candidates or political parties.
            </li>
            <li>
              <b>Personalized</b>: Each letter is specific for a voter, so you&rsquo;ll need access to a
              printer, and templates can&rsquo;t be photocopied.
            </li>
            <li>
              <b>Envelopes</b>: The letters are mailed in envelopes with stamps, not emailed or written on postcards.
            </li>
          </ul>
          <p>
            More details are in our{ ' ' }
            <a href='https://youtu.be/fudmGjmUrW0' rel='noopener noreferrer' target='_blank'>
              getting started video
            </a>{ ' ' }
            and{ ' ' }
            <a href='/instructions' target='_blank'>
              instructions
            </a>.
          </p>

          <div className='form-check me-4 mb-3 ms-4'>
            <input
              checked={ this.state.confirmedUnderstoodInstructions }
              className='form-check-input mt-2'
              id='confirmed-understood-instructions'
              onChange={ this.handleConfirmedUnderstoodInstructionsChange }
              type='checkbox'
            />
            <label
              className='form-check-label'
              htmlFor='confirmed-understood-instructions'
            >
              I&rsquo;ve reviewed these materials and understand how letter campaigns work.
            </label>
          </div>

          { this.state.confirmedUnderstoodInstructionsError && (
            <div
              className='alert alert-danger alert-sm mt-3 mb-3 center'
              role='alert'
            >
              Please confirm that you&rsquo;ve reviewed the materials and understand how Vote Forward letter campaigns work.
            </div>
          ) }

          <button
            className='btn btn-primary w-100'
            onClick={ this.handleConfirmedUnderstoodInstructionsSubmit }
            type='submit'
          >
            Submit
          </button>

          <ProgressIndicator current={ 4 } max={ numQuestions } />
        </div>
      </div>
    );

    const codeQ = (
      <div>
        <div className='p-4'>
          <h4 className='mb-3'>Terms of Service</h4>
          <p>
            Do you agree to the{ ' ' }
            <a href='/terms-of-use' target='_blank'>
              Terms of Use
            </a>{ ' ' }
            and{ ' ' }
            <a href='/privacy-policy' target='_blank'>
              Privacy Policy
            </a>
            , and specifically, do you promise to be respectful at all times in
            your communications with fellow citizens via Vote Forward?
          </p>
          <button
            className='btn btn-primary w-100'
            onClick={ this.handleAgreedCode }
          >
            Yes
          </button>
        </div>
        <ProgressIndicator current={ 5 } max={ numQuestions } />
      </div>
    );

    const swingleftQ = (
      <div>
        <div className='p-4 item'>
          <p>Are you open to being contacted by our friends at <a href='https://swingleft.org?source=votefwd&utm_source=votefwd&utm_medium=web&utm_campaign=signup_questionnaire' rel='noopener noreferrer' target='_blank'>Swing Left</a>?</p>
          <input
            checked={ this.state.acceptSLContact }
            className='ms-3 mb-3'
            id='slcheck'
            onChange={ this.handleSLContactChange }
            type='checkbox'
          />
          <label className='ms-2' htmlFor='slcheck'>
            Yes, I’m open to being contacted by Swing Left.
          </label>
          <p className='small'>
            Swing Left team members assist many of our volunteers in organizing letter writing parties and events. If you agree, Swing Left may contact you to offer such help. You’ll also be added to the Swing Left email list.
          </p>
          <button
            className='btn btn-primary w-100'
            onClick={ this.handleSwingLeftSubmit }
            type='submit'
          >
            Submit
          </button>
        </div>
        <ProgressIndicator current={ 6 } max={ numQuestions } />
      </div>
    );

    const profileQ = (
      <div>
        <form className='p-4' onSubmit={ this.handleProfileSubmit }>
          <h3 className='mb-3'>Last step!</h3>
          <p className='mb-4'>
            Please provide a link to a profile if you have one. This is optional but helps us approve you quickly.
          </p>

          { /* ///////////////////////////////////////////////////////////////// */ }

          <div className='d-block d-sm-flex mb-3'>
            <label
              className='col-12 col-sm-3 m-0 ps-0 d-flex align-self-center'
              htmlFor='profileTwitter'
            >
              Twitter:
              <span className='small text-info ms-1'> (optional)</span>
            </label>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text pt-0'>@</span>
              </div>
              <input
                className='form-control js-profile-input'
                id='profileTwitter'
                placeholder='votefwd'
                type='text'
              />
            </div>
          </div>

          { /* ///////////////////////////////////////////////////////////////// */ }

          <div className='d-block d-sm-flex mb-3'>
            <label
              className='col-12 col-sm-3 m-0 ps-0 d-flex align-self-center'
              htmlFor='profileFacebook'
            >
              Facebook:
              <br />
              <span className='text-info small ms-1'>(optional)</span>
            </label>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>facebook.com/</span>
              </div>
              <input
                className='form-control js-profile-input'
                id='profileFacebook'
                placeholder='votefwd'
                type='text'
              />
            </div>
          </div>

          { /* ///////////////////////////////////////////////////////////////// */ }

          <div className='d-block d-sm-flex mb-3'>
            <label
              className='col-12 col-sm-3 m-0 ps-0 d-flex align-self-center'
              htmlFor='profileLinkedin'
            >
              LinkedIn:
              <span className='small text-info ms-1'> (optional)</span>
            </label>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>linkedin.com/in/</span>
              </div>
              <input
                className='form-control js-profile-input'
                id='profileLinkedin'
                placeholder='votefwd'
                type='text'
              />
            </div>
          </div>

          <label
            className='w-100 d-block d-sm-flex justify-content-between mt-4'
            htmlFor='letterWritingSample'
          >
            <span className='small text-danger d-block pe-4'>&#x2605; Required</span>
            <span>
              { message('verify.lettermessage.prompt') }
            </span>
          </label>

          <textarea
            className='form-control js-profile-input'
            id='letterWritingSample'
            onChange={ this.handleReasonChange }
            placeholder={ message('verify.lettermessage.prefill') }
            required
            rows='4'
            value={ this.state.reasonVal }
          />

          <span className='small'><i>{ message('verify.lettermessage.footnote') }</i></span>
          { this.state.reasonError && (
            <div
              className='alert alert-danger alert-sm mt-3 mb-3 center'
              role='alert'
            >
              Please share your response to the prompt — this helps us to
              verify volunteers.
            </div>
          ) }

          <div className='py-4'>
            <button className='btn btn-primary btn-lg w-100' type='submit'>
              Finish sign-up
            </button>
          </div>
        </form>
        <ProgressIndicator current={ numQuestions } max={ numQuestions } />
      </div>
    );

    const thankYou = (
      <div className='p-4'>
        <h2>Thanks for signing up!</h2>
        <p className='mt-4'>
          We’ll review and approve you to start writing letters shortly. You
          should receive an email with details on how to get started in the next
          12 to 36 hours.
        </p>
        <p>
          For now, please familiarize yourself with the process by reviewing the{ ' ' }
          <Link to='/instructions'>instructions</Link>. If you’re planning to host a
          virtual letter writing event, you can{ ' ' }
          <a href='/votefwd-letter-event-guide.pdf'>
            download an event-planning kit
          </a>
          . And for frequently asked questions, please visit the{ ' ' }
          <a href='https://help.votefwd.org' rel='noopener noreferrer' target='_blank'>help site</a>.
        </p>
        <p>
          And seriously, <strong>THANK YOU</strong>. We’re deeply grateful for
          your participation.
        </p>
        <p>
          <strong>- The Vote Forward Team</strong>
        </p>
        <div className='text-center p-4'>
          <img alt='' className='w-50' src='/images/bg-masthead.png' />
        </div>
      </div>
    );

    const readyToGo = (
      <div className='p-4'>
        <h2>You’re all set!</h2>
        <p>
          We’ve reviewed your profile and you’re ready to start adopting voters.
        </p>
        <p>
          <Link to='/dashboard'>Go to the Dashboard</Link>
        </p>
      </div>
    );

    const bannedMessage = (
      <div className='p-4'>
        <h2>{ message('verify.banned.heading') }</h2>
        <p className='mt-4'>
          { message('verify.banned.p1') }
        </p>
        <p className='mt-4'>
          { message('verify.banned.p2') } <button className='fw-bold blue-link' data-location='/ask/message/' id='getHelp' onClick={ this.openBannedBeacon }>{ message('contact.us') }</button>.
          { ' ' }{ message('previous.messages.instructions', { email: this.props.user.email }) }{ ' ' }
          <button className='fw-bold blue-link' data-location='/previous-messages/' onClick={ this.openBannedBeacon }>{ message('here') }</button>.
        </p>
      </div>
    );

    if (this.props.user.qual_state === 'banned') {
      formMarkup = bannedMessage;
    } else if (!this.props.user.is_human_at) {
      formMarkup = captchaQ;
    } else if (!this.props.user.pledged_vote_at) {
      formMarkup = pledgeQ;
    } else if (!this.props.user.full_name) {
      formMarkup = nameQ;
    } else if (!this.props.user.zip) {
      formMarkup = zipQ;
    } else if (!this.props.user.confirmed_understood_instructions_at) {
      formMarkup = confirmedUnderstoodInstructionsQ;
    } else if (!this.props.user.accepted_code_at) {
      formMarkup = codeQ;
    } else if (!this.props.user.answered_sl_contact_question_at && this.showSwingLeftOptIn()) {
      formMarkup = swingleftQ;
    } else if (!this.props.user.letter_writing_sample && !this.props.user.why_write_letters) {
      formMarkup = profileQ;
    } else if (isUserQualified(this.props.user)) {
      formMarkup = readyToGo;
    } else {
      formMarkup = thankYou;
    }

    return (
      <div id='user-sign-up'>
        { this.props.auth.isAuthenticated() && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <Fragment>{ formMarkup }</Fragment>) }
      </div>
    );
  }
}

export default Qualify;
