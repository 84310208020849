import 'core-js';

import { BrowserRouter } from 'react-router-dom';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { ErrorBoundary } from 'react-error-boundary';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import ErrorFallback from './components/ErrorFallback';
import logger from './lib/logger';
import PageHead from './page/PageHead';
import Routes from './Routes';
import ScrollToTop from './lib/ScrollToTop';
import SuspenseFallback from './components/SuspenseFallback';
import { AuthProvider } from './contexts/AuthContext';
import { UserProfilePictureProvider } from './contexts/UserProfilePictureContext';

import './scss/app.scss';


function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        notifyOnChangeProps: 'tracked',
      },
    },
  });
  if (
    (process.env.NODE_ENV === 'production' && (!process.env.BABEL_ENV || process.env.BABEL_ENV === 'production'))
    || process.env.REACT_APP_CACHE_QUERIES_IN_LOCAL_STORAGE
  ) {
    const persistor = createWebStoragePersistor({ storage: window.localStorage });
    persistQueryClient({
      buster: '',
      maxAge: 1000 * 60 * 60 * 24, // 24 hours
      persistor,
      queryClient,
    });
  }

  return (
    <ErrorBoundary FallbackComponent={ ErrorFallback } onError={ logger.error }>
      <BrowserRouter>
        <PageHead />
        <ScrollToTop />
        <QueryClientProvider client={ queryClient }>
          {
            process.env.NODE_ENV === 'development' && process.env.REACT_APP_REACT_QUERY_DEVTOOLS
              ? <ReactQueryDevtools />
              : null
          }

          <UserProfilePictureProvider>
            <AuthProvider>
              <Suspense fallback={ <SuspenseFallback /> }>
                <Routes />
              </Suspense>
            </AuthProvider>
          </UserProfilePictureProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
