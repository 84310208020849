import PropTypes from 'prop-types';
import BundleListItem from './BundleListItem';
import DelayedRenderColumn from './common/DelayedRenderColumn';
import EmptyColumn from './EmptyColumn';

const columnHeaders = {
  UNPREPPED: 'Unprepared',
  PREPPED: 'Prepared',
  SENT: 'Sent',
};

function ManageLettersColumn({ bundleData, letterFilterPresent, modifyAndDownloadState }) {
  return (
    <div className='col-sm-6 col-md-4 mb-4 mb-md-0 letters-col'>
      <div className='pb-3'>
        <span className='pt-2 mb-4 h5'>{ columnHeaders[bundleData.bundle_status] } </span>
        <span className='fs-5'>{ `(${ bundleData.letter_count } letters in ${ bundleData.bundle_count } ${ (bundleData.bundle_count) === 1 ? 'bundle' : 'bundles' })` }</span>
      </div>
      <DelayedRenderColumn
        items={ bundleData.bundles }
        render={ (bundle, testLabel) => (
          <BundleListItem
            bundle={ bundle }
            key={ bundle.hashid }
            modifyAndDownloadState={ modifyAndDownloadState }
            testLabel={ `${ testLabel }-${ bundleData.bundle_status }` }
          />
        ) }
        testLabel='bundle'
      />
      { bundleData.letter_count === 0 && (
        <EmptyColumn
          letterFilterPresent={ letterFilterPresent }
          status={ bundleData.bundle_status }
        />
      ) }
    </div>
  );
}

ManageLettersColumn.propTypes = {
  bundleData: PropTypes.object.isRequired,
  letterFilterPresent: PropTypes.bool.isRequired,
  modifyAndDownloadState: PropTypes.object.isRequired,
};

export default ManageLettersColumn;
