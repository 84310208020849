import PropTypes from 'prop-types';
import LetterListItem from './LetterListItem';
import ManageColumnCell from './common/ManageColumnCell';
import DownloadButton from './common/DownloadButton';
import classNames from 'classnames';
import { useDownloadBundleState } from '../../lib/queries/queries';
import ManageSlideOut from './common/ManageSlideOut';
import StatusChangeButton from './common/StatusChangeButton';
import SocialBundleInfo from './SocialBundleInfo';

function BundleListItem({ bundle, modifyAndDownloadState, testLabel }) {
  const downloadState = useDownloadBundleState({ letterBundleId: bundle.bundle_id, modifyAndDownloadState });
  const statusContent = {
    UNPREPPED: {
      name: 'unprepared',
      buttonRowClassNames: 'row flex-nowrap unprepared',
    },
    PREPPED: {
      name: 'prepared',
      buttonRowClassNames: 'row',
    },
    SENT: {
      name: 'sent',
      buttonRowClassNames: 'row bundle-status-row',
    },
  };

  const letterKitType = bundle.kit_type === 'FULL' ? 'Letter Kit' : 'Stamp Subsidy';
  const letterQuantityMessage = `${ bundle.letter_count }/${ bundle.total_bundle_size } ${ statusContent[bundle.bundle_status].name } letters`;

  return (
    <ManageColumnCell bundleData={ bundle } cellId={ bundle.hashid } testLabel={ testLabel }>
      { bundle.type === 'LETTERKIT' && (
        <div className='row fill-row'>
          <div className='col fw-bold fill-row'>
            { (bundle.letter_kit_status === 'REQUESTED') && (
              <span>
                { `${ letterKitType } order: Pending approval (${ bundle.letter_count } letters)` }
              </span>
            ) }
            { (bundle.letter_kit_status === 'APPROVED') && (
              <span>
                { `${ letterKitType } order: Not yet shipped` }
              </span>
            ) }
            { bundle.letter_kit_status === 'SENT' && bundle.usps_tracking_code && (
              <div> <span>{ letterKitType } order: </span>
                <a href={ `https://tools.usps.com/go/TrackConfirmAction?tLabels=${ bundle.usps_tracking_code }` } rel='noreferrer' target='_blank'>In transit</a>
              </div>
            ) }
            { bundle.letter_kit_status === 'SENT' && !bundle.usps_tracking_code && (<span>Shipped</span>) }

          </div>
        </div>
      ) }

      { bundle.letter_kit_status !== 'REQUESTED' && (
        <div>
          <div className={ classNames(statusContent[bundle.bundle_status].buttonRowClassNames) }>
            { bundle.bundle_status === 'UNPREPPED' && (
              <DownloadButton
                buttonType='columnCell'
                downloadState={ downloadState }
                modifyAndDownloadState={ modifyAndDownloadState }
              />
            ) }
            <StatusChangeButton
              buttonType='columnCell'
              isBackwardMutation
              lettersToMutate={ bundle.letters }
              modifyAndDownloadState={ modifyAndDownloadState }
              segmentTrackPropeties={{ bundleId: bundle.bundle_id, previousState: statusContent[bundle.bundle_status].name }}
              status={ bundle.bundle_status }
            />
            <StatusChangeButton
              buttonType='columnCell'
              lettersToMutate={ bundle.letters }
              mailOnDate={ bundle.mail_start_date }
              modifyAndDownloadState={ modifyAndDownloadState }
              status={ bundle.bundle_status }
            />
            { bundle.bundle_status === 'SENT'
              && (
                <div className='col'>
                  { /* TODO: implement on-click */ }
                  <div className='mailed-message fw-bold w-100'>
                    <i className='fa fa-check-circle' />
                    <span>Nice work!</span>
                  </div>
                </div>
              ) }
          </div>
          {
            bundle.social_token
              ? (<SocialBundleInfo bundle={ bundle } myLettersMessage={ letterQuantityMessage } />)
              : (
                <ManageSlideOut
                  items={ bundle.letters }
                  renderLineItem={ (letter) => (
                    <LetterListItem
                      key={ letter.hashid || letter.id }
                      letter={ letter }
                      mailOnDate={ bundle.mail_start_date }
                      modifyAndDownloadState={ modifyAndDownloadState }
                      status={ bundle.bundle_status }
                    />
                  ) }
                  slideOutId={ `${ bundle.hashid }-${ bundle.bundle_status }` }
                  viewLabel={ `View ${ letterQuantityMessage }` }
                />
                )
          }
        </div>
      ) }
    </ManageColumnCell>
  );
}

BundleListItem.propTypes = {
  bundle: PropTypes.object.isRequired,
  modifyAndDownloadState: PropTypes.object.isRequired,
  testLabel: PropTypes.string.isRequired,
};

export default BundleListItem;
