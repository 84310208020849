import axios from 'axios';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardStats from '../DashboardStats';
import deprecatedConfig from '../../lib/deprecatedConfig';
import Modal from '../../components/Modal';
import t from '../../lib/deprecated_i18n';
import { AuthContext } from '../../contexts/AuthContext';
import { denormalizeVoterLists, uniqueVoterStates } from '../../lib/stats';
import { openBeacon, initBeacon, destroyBeacon, navigateBeacon } from '../../Beacon';
import DistrictItemWindow from './DistrictItemWindow';

import '../../scss/campaigns/_campaign.scss';

class AdoptVoter extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      // We do not trust the props (currentDistrict.available_voter_count) to
      // accurately represent whether all voters have been adopted or not. Our
      // source of truth is the API response after clicking the button to adopt
      // new voters: if it says that no voters were adopted, we know that there
      // are none left, so we disable the buttons and display a warning.
      allAdopted: false,
      beaconOpened: false,
      district: {},
      qualNum: '300',
      showPausedModal: false,
      superqualNum: '15000',
      training: { pending: true },
      updatedAvailableVoterCount: '',
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.currentDistrict.district_id) {
      return { district: props.currentDistrict };
    } else {
      return null;
    }
  }

  async componentDidMount() {
    if (this.state.district.training_url && this.state.district.district_id) {
      this.getTraining();
    }
    const [qualNum, superQualNum] = await Promise.all([
      deprecatedConfig.get('qualNum'),
      deprecatedConfig.get('superqualNum'),
    ]);
    this.setState({ qualNum, superQualNum });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.district.training_url
      && this.state.district.district_id
      && (this.state.district.district_id !== prevState.district.district_id)
    ) {
      this.getTraining();
    }
  }

  componentWillUnmount() {
    if (this.state.beaconOpened) {
      destroyBeacon();
    }
  }

  hidePausedModal = () => {
    this.setState({ showPausedModal: false });
  };

  async getTraining() {
    const accessToken = await this.context.getAccessToken();
    const result = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/users/self/userDistrictTraining`, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      params: {
        district: this.state.district.district_id,
      },
    });
    if (result.data) {
      this.setState({ training: result.data });
    }
  }

  goToTraining = (component) => {
    location.assign(component.state.district.training_url);
  };

  async updateTraining({ finishedAt, startedAt }, component) {
    const accessToken = await component.context.getAccessToken();
    const result = await axios.post(
      `${ process.env.REACT_APP_API_URL }/v1/users/self/userDistrictTraining`,
      { finishedAt, startedAt },
      {
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        params: {
          district: component.state.district.district_id,
        },
      },
    );
    if (result.data) {
      component.setState({ training: result.data });
    }
  }

  requestSuperqualStatus = (event, component) => {
    const { location } = event.currentTarget.dataset;
    initBeacon({
      auth: component.context,
      type: 'superqual',
      beaconArgs: {
        prefill: {
          subject: 'Super Volunteer Request',
          text: `Thank you for requesting super-volunteer approval! Please take a moment to provide the following information:\n
1) Tell us about your letter writing efforts.\n\n
2) Let us know if you are engaging others to help write letters toward your goal. If so, how?\n\n
3) Type or send a photo of the personal message that you use in your letters.\n\n
4) Attach a quick photo of some letters you've written so far if you can.
        `,
        },
      },
    });
    openBeacon();
    navigateBeacon(location || '/ask/message/');

    component.setState({ beaconOpened: true });
  };

  render() {
    const { toSend, alreadySent } = denormalizeVoterLists(this.props.allVoters);
    const uniqueStates = uniqueVoterStates(this.props.allVoters);

    return (
      <div className='container-fluid p-0'>
        <Modal
          hideModal={ this.hidePausedModal }
          modalText={ t('campaign.paused.modal.text') }
          modalTitle={ t('campaign.paused.modal.title') }
          showModal={ this.state.showPausedModal }
        />
        <div className='row no-gutters position-relative bg-dark-blue mb-3 mb-sm-5'>
          <div className='col-xl-6 p-sm-5 p-3'>
            <DistrictItemWindow
              campaignState={ this.state }
              currentDistrictPending={ this.props.currentDistrictPending }
              goToTraining={ () => (this.goToTraining(this)) }
              handleAdoptionSuccess={ this.props.handleAdoptionSuccess }
              liveVoterCount={ this.props.liveVoterCount }
              maxQual={ this.context.getLetterAdoptionOverride() }
              requestSuperqualStatus={ (event) => (this.requestSuperqualStatus(event, this)) }
              setCampaignState={ (stateUpdate) => (this.setState(stateUpdate)) }
              updateTraining={ (trainingDates) => (this.updateTraining(trainingDates, this)) }
              user={ this.props.user }
            />
            <small className='d-block text-center text-white'>
              Need help with supplies? <Link to='/letter-kits'>Learn about Vote Forward letter kits.</Link>
            </small>
          </div>

          <DashboardStats
            adoptedVoters={ this.props.liveVoterCount }
            alreadySent={ alreadySent.length }
            toSend={ toSend.length }
            uniqueStates={ uniqueStates }
          />
        </div>
      </div>
    );
  }
}

AdoptVoter.propTypes = {
  allVoters: PropTypes.array,
  currentDistrict: PropTypes.object,
  currentDistrictPending: PropTypes.bool,
  handleAdoptionSuccess: PropTypes.func,
  liveVoterCount: PropTypes.number,
  user: PropTypes.object,
};

export default AdoptVoter;
