import ContactMailIcon from '@material-ui/icons/ContactMail';
import CreateIcon from '@material-ui/icons/Create';
import DefaultIcon from '@material-ui/icons/ViewList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getResources, MenuItemLink, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';

import { checkPermission } from '../lib/user';

function Menu({ onMenuClick, logout }) {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const { permissions } = usePermissions();

  return (
    <div>
      { resources
        .filter((resource) => !resource.options || !resource.options.hidden)
        .map((resource) => (
          <MenuItemLink
            key={ resource.name }
            leftIcon={ resource.icon ? <resource.icon /> : <DefaultIcon /> }
            onClick={ onMenuClick }
            primaryText={
              (resource.options && resource.options.label)
              || resource.name
            }
            sidebarIsOpen={ isOpen }
            to={ `/${ resource.name }` }
          />
        )) }
      { permissions
       && checkPermission({
         grants: permissions,
         resource: '*:*',
         permission: 'letter.reassign',
       })
        && (
          <MenuItemLink
            leftIcon={ <ContactMailIcon /> }
            onClick={ onMenuClick }
            primaryText='Reassign Letters'
            sidebarIsOpen={ isOpen }
            to='/reassign-letters'
          />
        ) }

      { permissions
       && checkPermission({ grants: permissions, resource: '*:*', permission: 'blog.admin' })
         && (
           <MenuItemLink
             leftIcon={ <CreateIcon /> }
             onClick={ onMenuClick }
             primaryText='Blog Admin'
             sidebarIsOpen={ isOpen }
             to='/blog/admin'
           />
         ) }

      { permissions
       && checkPermission({
         grants: permissions,
         resource: '*:*',
         permission: 'file.upload',
       }) && (
         <span>
           <MenuItemLink
             leftIcon={ <ImportExportIcon /> }
             onClick={ onMenuClick }
             primaryText='Import Voter File'
             sidebarIsOpen={ isOpen }
             to='/imports'
           />
         </span>
      ) }

      { isXSmall ? logout : null }
    </div>
  );
}

Menu.propTypes = {
  logout: PropTypes.object,
  onMenuClick: PropTypes.func,
};

export default Menu;
