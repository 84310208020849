import PropTypes from 'prop-types';
import { memo } from 'react';

import '../scss/campaigns/_campaign-labels.scss';
import '../scss/lib/_typography.scss';
import CampaignLabel from './CampaignLabel';

function CampaignBubble({
  isVisible,
  type,
}) {
  return isVisible
    ? (
      <figure className={ `campaign-bubble ${ type }` } title={ type } >
        {
          type === 'priority'
            ? (<i className='fa fa-star' />)
            : (<span>{ type.toUpperCase().charAt(0) }</span>)
        }
        <CampaignLabel
          isBundleDisplay
          isVisible={ isVisible }
          type={ type }
        />
      </figure>
      )
    : null;
}

CampaignBubble.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default memo(CampaignBubble);
