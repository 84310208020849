import moment from 'moment';
import PropTypes from 'prop-types';

import { createTimezoneDate } from './lib/dates';

import './scss/lib/_backgrounds.scss';
import './scss/lib/_fonts.scss';
import './scss/lib/_typography.scss';

function MailDate({ mailDate, mailDateFrom, mailDateType, short, iconClassname = 'fa-calendar-o', showOffset = false }) {
  const timezoneMailDate = createTimezoneDate(mailDate);
  const timezoneMailDateFrom = createTimezoneDate(mailDateFrom || mailDate);
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  let effectiveWindowStart = timezoneMailDateFrom;
  if (now > timezoneMailDateFrom) {
    // If we are in the middle of the mailing window, treat today as the effective start of the mailing window
    effectiveWindowStart = now;
  }
  // start date of window in "Fri, May 15" format
  const formattedEffectiveWindowStart = moment(effectiveWindowStart).format('ddd, MMM D');
  // mail date in "Oct 27" format.
  const shortFormattedMailDate = moment(timezoneMailDate).format('MMM D');
  const shortFormattedWindowStartDate = moment(timezoneMailDateFrom).format('MMM D');
  // end date of window in "Fri, Oct 15" format
  const formattedWindowEndDate = moment(timezoneMailDate).format('ddd, MMM D');

  // number of days until mail by dates.
  const offsetWindowEndDays = moment(timezoneMailDate).diff(moment({ hours: 0 }), 'days');
  const offsetEffectiveWindowStartDays = moment(effectiveWindowStart).diff(moment({ hours: 0 }), 'days');

  const timezoneMailDateOfYear = moment(timezoneMailDate).dayOfYear();
  const nowMailDayOfYear = moment(now).dayOfYear();
  // function to turn the number of days until something into text (e.g. "tomorrow" or "in 3 days")
  const offsetToText = (offsetDays) => {
    if (!showOffset) {
      return '';
    }

    let offsetString;
    if (offsetDays === -1) {
      offsetString = `yesterday`;
    } else if (offsetDays < 0) {
      offsetString = `${ Math.abs(offsetDays) } days ago`;
    } else if (offsetDays === 0) {
      offsetString = `today!`;
    } else if (offsetDays === 1) {
      offsetString = `tomorrow`;
    } else {
      offsetString = `in ${ offsetDays } days`;
    }
    return ` (${ offsetString })`;
  };

  const getShortWindowMessage = () => {
    if (now < timezoneMailDateFrom) {
      return ` Send between ${ shortFormattedWindowStartDate } and ${ shortFormattedMailDate }`;
    }
    if (mailDateType === 'ROLLING_ASAP') {
      return ` Send ASAP, by ${ shortFormattedMailDate }`;
    }
    return ` Send by ${ shortFormattedMailDate }`;
  };

  const icon = iconClassname !== '' && (
    <i className={ `fa ${ iconClassname }` } />
  );

  if (nowMailDayOfYear === timezoneMailDateOfYear) {
    // If we're on the last day of the mail window, then display a message to send ASAP.
    //  Use yesterday to decide if mailDate has passed (since mailDate is at midnight)
    return short
      ? (
        <span>{ icon }{ ' ' }<strong>Send ASAP</strong></span>
        )
      : (
        <p>{ icon } Send ASAP,
          by{ ' ' } { `${ formattedWindowEndDate }${ offsetToText(offsetWindowEndDays) }` } at the latest
        </p>
        );
  } else if (timezoneMailDate < yesterday) {
    // If mail date is in the past, indicate it in the message
    return (
      short
        ? (<span>{ icon }{ ' ' }<strong>Send ASAP</strong></span>)
        : (
          <p>{ icon } Send ASAP. The mail date
            was{ ' ' } { `${ formattedWindowEndDate }${ offsetToText(offsetWindowEndDays) }` }
          </p>
          )
    );
  } else if (timezoneMailDateFrom && timezoneMailDate > timezoneMailDateFrom) {
    // If the mail window is longer than a single day,
    //  then make the message reflect the window
    return (
      short
        ? (
          <span>{ icon }<strong>{ getShortWindowMessage() }</strong>{ `${ offsetToText(offsetWindowEndDays) }` }</span>
          )
        : (
          <p className='text-danger'>
            { icon }{ ' ' }
            <span className='fw-bold'>Send</span> as soon as { `${ formattedEffectiveWindowStart }${ offsetToText(offsetEffectiveWindowStartDays) }` } but no later
            than{ ' ' }{ `${ formattedWindowEndDate }${ offsetToText(offsetWindowEndDays) }` }
          </p>
          )
    );
  } else if (timezoneMailDateFrom && now < timezoneMailDateFrom) {
    // If mail date is in the future, but only a single day
    //  then just say to mail it on that date
    return (
      short
        ? (
          <span>{ icon }<strong>{ ` Send on ${ shortFormattedMailDate }` }</strong>{ `${ offsetToText(offsetWindowEndDays) }` }</span>
          )
        : (
          <p className='text-danger'>
            { icon }{ ' ' }
            <span className='fw-bold'>Send</span> on { `${ formattedWindowEndDate }${ offsetToText(offsetWindowEndDays) }` }
          </p>
          )
    );
  } else {
    return null;
  }
}

MailDate.propTypes = {
  iconClassname: PropTypes.string,
  mailDate: PropTypes.string,
  mailDateFrom: PropTypes.string,
  mailDateType: PropTypes.string,
  short: PropTypes.bool,
  showOffset: PropTypes.bool,
};

export default MailDate;
