import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils, HttpError } from 'react-admin';
import deprecatedConfig from '../lib/deprecatedConfig';

function adminHttpClient(auth) {
  return async function(url, options = {}) {
    //ideally we never use the async method because it makes the httpclient useless for the response
    let accessToken = localStorage.getItem('access_token') || '';
    if (!accessToken || accessToken === '') {
      accessToken = await auth.getAccessToken();
    }
    options.user = { authenticated: true, token: `Bearer ${ accessToken }` };
    if (options.body instanceof File) {
      options.headers = new Headers({ 'Content-Type': options.body.type || options.contentType });
    }
    return fetchUtils.fetchJson(url, options);
  };
}

const apiBaseUrl = `${ process.env.REACT_APP_API_URL }/v1`;
const labels = ['logo', 'metaTagImage'];

function adminDataProvider(auth) {
  const httpClient = adminHttpClient(auth);
  const provider = simpleRestProvider(apiBaseUrl, httpClient);
  return {
    ...provider,
    getList: (resource, params) => {
      if (resource === 'Voters') {
        const filterScores = {
          'firstName:startsWith': 1,
          'lastName:startsWith': 2,
          'districtId:startsWith': 1,
          'zip:startsWith': 1,
          state: 1,
        };
        const possibleFilters = Object.keys(filterScores);
        let completedFilters = 0;
        for (const filter of possibleFilters) {
          if (params.filter[filter] && params.filter[filter].length >= 2) {
            completedFilters += filterScores[filter];
          }
        }
        if (completedFilters >= 3
          || (params.filter.userOptOuts && params.filter.userOptOuts['createdAt:isNotNull'])
          || (params.filter['pledgeCount:greaterThanOrEqualTo'] && params.filter['pledgeCount:greaterThanOrEqualTo'] > 0)
          || (params.filter.hashid && params.filter.hashid.length > 5)
          || (params.filter.users && params.filter.users['email:startsWith'].length >= 5 && params.filter.users['email:startsWith'].indexOf('@') >= 0)
        ) {
          return provider.getList(resource, params);
        } else {
          return Promise.reject('Please fill out some more filters to see results.');
        }
      } else {
        return provider.getList(resource, params);
      }
    },
    update: async (resource, params) => {
      if (resource !== 'Partners') {
        return provider.update(resource, params);
      }
      if (
        !(
          (params.data.logo && params.data.logo.rawFile)
          || (params.data.metaTagImage && params.data.metaTagImage.rawFile)
        )
      ) {
        // Do the normal update thing if no files were submitted
        return provider.update(resource, params);
      }

      const filePromises = [params.data.logo, params.data.metaTagImage].map((fileData, i) => {
        if (!(fileData && fileData.rawFile)) {
          return null; // Default Promise.all() behavior is fine with this input
        }
        return httpClient(`${ apiBaseUrl }/partners/${ params.id }/images/${ labels[i] }`, {
          method: 'PUT',
          body: fileData.rawFile,
        });
      });

      const results = await Promise.all(filePromises);
      results.forEach((result, i) => {
        if (result) {
          params.data[labels[i]] = result.json.url;
        }
      });
      return provider.update(resource, params);
    },
    create: async (resource, params) => {
      if (resource === 'DistrictImports') {
        const file = params.data.file ? params.data.file.rawFile : undefined;
        const numericDistrictId = params.data.numericDistrictId;
        if (!numericDistrictId) { return HttpError('missing parameter: numericDistrictId', 400); }
        const { json: { districtId }} = await httpClient(`${ apiBaseUrl }/districts/${ numericDistrictId }`);
        const fileImportApiUrl = await deprecatedConfig.get('fileImportApiUrl');
        let response;
        if (file) {
          response = httpClient(`${ fileImportApiUrl }/districts/${ districtId }/files`, {
            method: 'POST',
            contentType: 'text/csv',
            body: file,
          });
          // The user can put a signed url in the fileLocation field to pull the file from an s3 bucket for instance.
          //  If that's the case, call an endpoint to retrieve that file and upload it to the bucket as though it
          //  were uploaded from the browser.
        } else if (params.data.fileLocation && params.data.fileLocation.match(/^https:/)) {
          try {
            response = await httpClient(`${ fileImportApiUrl }/districts/${ districtId }/file-from-url`, {
              method: 'POST',
              contentType: 'application/json',
              body: JSON.stringify({ url: params.data.fileLocation }),
            });
          } catch (err) {
            if (err.status === 403) {
              throw new Error('The file url is expired.');
            } else {
              throw new Error('There was a problem getting the file from the url.');
            }
          }
        }
        // Create a clean record without UI-only properties like `file` and
        // `numericDistrictId` and with added properties like `fileMd5`.
        const record = {
          ...params.data,
          districtId,
        };
        if (response && response.json && response.json.location) {
          const { hash, location } = response.json;
          record.fileMd5 = hash;
          record.fileLocation = location;
        }
        delete record.file;
        delete record.numericDistrictId;
        return provider.create(resource, { data: record });
      }

      return provider.create(resource, params);
    },
  };
}

export default adminDataProvider;
