import axios from 'axios';
import React from 'react';
import propTypes from 'prop-types';
import logger from './lib/logger';
import { Route } from 'react-router-dom';
import Loading from './components/Loading';


// Ensures the logged in user is an admin.  If not, redirects to /
class AdminChecker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAdmin: false,
    };
  }


  async componentDidMount() {
    const user_id = localStorage.getItem('user_id');
    if (user_id) {
      try {
        const accessToken = await this.props.auth.getAccessToken();
        const res = await axios({
          headers: {
            Authorization: 'Bearer '.concat(accessToken),
          },
          method: 'GET',
          url: `${ process.env.REACT_APP_API_URL }/v1/users/self`,
        });
        this.setState({
          loading: false,
          isAdmin: res.data.isAdmin,
        });
      } catch (err) {
        this.setState({ loading: false, isAdmin: false });
        logger.error(err);
      }
    } else {
      this.setState({ loading: false, isAdmin: false });
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading { ...this.props } />;
    } else if (this.state.isAdmin === false) {
      this.props.auth.forceLogin();
    }
    const Component = this.props.component;
    return <Component { ...this.props } />;
  }
}

AdminChecker.propTypes = {
  auth: propTypes.object,
  component: propTypes.oneOfType([propTypes.element, propTypes.func]),
};

// This higher-order component pattern taken from
// https://tylermcginnis.com/react-router-protected-routes-authentication/
// eslint-disable-next-line react/prop-types
const AdminRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    { ...rest }
    render={ (props) => (
      <AdminChecker auth={ auth } component={ Component } { ...props } />
    ) }
  />
);

export default AdminRoute;
