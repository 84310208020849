function ErrorFallback() {
  return (
    <main className='d-flex flex-column h-100 justify-content-center text-center'>
      <h1 className='mb-4'>
        Sorry, something went wrong!
      </h1>
      <p>
        If you need help,
        { ' ' }
        <a href='/contact'>send us a message</a>
        .
        Otherwise,
        { ' ' }
        <a href='/'>click here</a>
        { ' ' }
        to reload Vote Forward and try again.
      </p>
    </main>
  );
}

export default ErrorFallback;
