import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  Filter,
  FunctionField,
  Button,
  useRefresh,
} from 'react-admin';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  letterSample: {
    minWidth: '15rem',
    padding: '2px',
  },
  colSM: {
    maxWidth: '5rem',
    wordWrap: 'break-word',
    padding: '2px',
  },
  col: {
    wordWrap: 'break-word',
    padding: '2px',
  },
});

const UserTableBulkActionButtons = ({ selectedIds }:any) => {
  const refresh = useRefresh();
  const auth = useAuth();
  const handleBatchApprovePending = async (event) => {
    event.preventDefault();
    const accessToken = await auth.getAccessToken();
    await axios({
      method: 'POST',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      url: `${ process.env.REACT_APP_API_URL }/v1/users/batchApprove`,
      data: {
        ids: selectedIds,
      },
    });
    refresh();
  };

  return (
    <Button label='Approve Selected Users' onClick={ handleBatchApprovePending } />
  );
};


const SocialLink = () => {
  const classes = useStyles();
  return (
    <FunctionField
      cellClassName={ classes.colSM }
      headerClassName={ classes.colSM }
      label='Social Links'
      render={ (record) => (
        <React.Fragment>
          { record.twitterProfileUrl && (
            <a href={ record.twitterProfileUrl }>
              <i className='fa fa-twitter' />
            </a>
          ) }
          { record.facebookProfileUrl && (
            <a href={ record.facebookProfileUrl }>
              <i className='fa fa-facebook-f' />
            </a>
          ) }
          { record.linkedinProfileUrl && (
            <a href={ record.linkedinProfileUrl }>
              <i className='fa fa-linkedin' />
            </a>
          ) }
        </React.Fragment>
      ) }
    />
  );
};

const BatchApproveLink = () => {
  const classes = useStyles();
  return (
    <FunctionField
      cellClassName={ classes.colSM }
      headerClassName={ classes.colSM }
      label='Auth0Id'
      render={ (record) => (
        <Link target='_blank' to={ `/user/${ record.auth0Id }` }>
          { record.auth0Id }
        </Link>
      ) }
      sortable={ false }
    />
  );
};

const BatchApproveFilters = (props) => (
  <Filter { ...props }>
    <TextInput alwaysOn label='Full Name' source='fullName:contains' />
    <TextInput alwaysOn label='Auth0Id' source='auth0Id:startsWith' />
    <TextInput alwaysOn label='Email' source='email:startsWith' />
    <DateField alwaysOn label='Signup date' showTime source='createdAt' />
    <TextInput label='Partners' source='partners:startsWith' />
    <TextInput label='Zip' source='zip' />
  </Filter>
);


const BatchApprove = (props) => {
  const classes = useStyles();

  return (
    <div>
      <List
        basePath='/users'
        bulkActionButtons={ <UserTableBulkActionButtons { ...props } /> }
        filterDefaultValues={{
          qualState: 'pre_qualified',
          'letterWritingSample:isNotNull': null,
          'pledged_vote_at:isNotNull': null,
          'accepted_code_at:isNotNull': null,
          'full_name:isNotNull': null,
          'preferred_name:isNotNull': null,
          'is_human_at:isNotNull': null,
          'confirmed_understood_instructions_at:isNotNull': null,
          'zip:isNotNull': null,
        }}
        filters={ <BatchApproveFilters /> }
        resource='Users'
        sort={{ field: 'createdAt', order: 'ASC' }}
        title='Batch Approve'
      >
        <Datagrid
          optimized
        >
          <TextField cellClassName={ classes.col } headerClassName={ classes.col } source='fullName' />
          { BatchApproveLink() }
          <TextField cellClassName={ classes.colSM } headerClassName={ classes.colSM } source='email' />
          { SocialLink() }
          <DateField cellClassName={ classes.colSM } headerClassName={ classes.colSM } showTime source='createdAt' />
          <TextField cellClassName={ classes.letterSample } headerClassName={ classes.letterSample } label='Letter Writing Sample' sortable={ false } source='letterWritingSample' />
          <TextField cellClassName={ classes.colSM } headerClassName={ classes.colSM } source='partners' />
          <TextField cellClassName={ classes.colSM } headerClassName={ classes.colSM } source='zip' />
        </Datagrid>
      </List>
    </div>
  );
};
export default BatchApprove;
