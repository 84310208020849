import { useEffect, useState } from 'react';

function setLocalStorageState(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function useLocalStorageState(key, defaultValue) {
  const [value, setValue] = useState(
    () => {
      const localStorageValue = localStorage.getItem(key);
      // TODO: update this at some point, as it's just like this to transition pictureUrl usage
      try {
        return JSON.parse(localStorageValue) || defaultValue;
      } catch {
        return localStorageValue || defaultValue;
      }
    },
  );
  useEffect(
    () => { setLocalStorageState(key, value); },
    [key, value],
  );
  return [value, setValue];
}

export default useLocalStorageState;
export { setLocalStorageState };
