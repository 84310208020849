import propTypes from 'prop-types';
import TrainingRequiredDetails from './TrainingRequiredDetails';
import CampaignLabels from '../../campaigns/CampaignLabels';
import VoterAdoptionDetails from './VoterAdoptionDetails';

VoterAdoptionHeader.propTypes = {
  availableVoters: propTypes.number.isRequired,
  campaign: propTypes.object.isRequired,
  campaignClosed: propTypes.bool.isRequired,
  children: propTypes.any.isRequired,
  goToTraining: propTypes.func.isRequired,
  maxedOut: propTypes.bool.isRequired,
  requestSuperqualStatus: propTypes.func.isRequired,
  training: propTypes.object.isRequired,
  updateTraining: propTypes.func.isRequired,
};

export default function VoterAdoptionHeader({
  maxedOut,
  campaignClosed,
  campaign,
  training,
  goToTraining,
  updateTraining,
  availableVoters,
  children,
  requestSuperqualStatus,
}) {
  const trainingRequired = (campaign.training_url && !training.finished_at);
  const displayState = campaign.state || campaign.state_abbr || campaign.display_name;

  return (
    <div className='district-item-content'>
      <div>
        <div className='adopt-message'>
          <span className=''>
            { campaign.display_name }
          </span>
        </div>
        <div className='adopt-campaign-labels'>
          <CampaignLabels
            isBundleDisplay={ false }
            isLabs={ campaign.is_labs }
            isPolitical={ campaign.tax_type === 'political' }
            isPriority={ campaign.priority }
            isSocial={ campaign.tax_type === 'social' }
            isVoterRegistration={ campaign.tax_type === 'voter-engagement' }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <h2>
          { `Adopt Voters in ${ displayState }` }
        </h2>
      </div>

      { trainingRequired
        ? (
          <TrainingRequiredDetails
            goToTraining={ goToTraining }
            training={ training }
            updateTraining={ updateTraining }
          />
          )
        : (
          <VoterAdoptionDetails
            availableVoters={ availableVoters }
            campaign={ campaign }
            isAdopterMaxedOut={ maxedOut }
            isCampaignClosed={ campaignClosed }
            requestSuperqualStatus={ requestSuperqualStatus }
            updatedVoterCount={ Math.max(campaign.voter_count - availableVoters, 0) }
          >
            { children }
          </VoterAdoptionDetails>
          ) }

    </div>
  );
}
