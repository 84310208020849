import { useMemo } from 'react';

function useVoterStats(campaigns) {
  return useMemo(
    () => (
      campaigns.reduce(
        ({
          votersAdopted,
          votersRemaining,
        }, {
          availableVoterCount = 0,
          totalVoterCount = 0,
        }) => (
          {
            votersAdopted: Math.max(votersAdopted + (totalVoterCount - availableVoterCount), 0),
            votersRemaining: Math.max(votersRemaining + availableVoterCount, 0),
          }
        ),
        {
          votersAdopted: 0,
          votersRemaining: 0,
        },
      )
    ),
    [campaigns],
  );
}

// eslint-disable-next-line import/prefer-default-export
export { useVoterStats };
