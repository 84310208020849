
interface ExternalRedirectProps {
    location:any;
  }

function ExternalRedirect(props:ExternalRedirectProps) {
  window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }${ props.location.pathname }`;
  return null;
}
export default ExternalRedirect;
