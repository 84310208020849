import PropTypes from 'prop-types';

import '../scss/letter-kits/_letter-kit-icon.scss';

function LetterKitIcon({ alt, caption, src }) {
  return (
    <div className='col-md-4 letter-kit-icon'>
      <figure className='figure w-100'>
        <div>
          <img alt={ alt } className='figure-img' src={ src } />
        </div>
        <figcaption className='figure-caption'>
          { caption }
        </figcaption>
      </figure>
    </div>
  );
}

LetterKitIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default LetterKitIcon;
