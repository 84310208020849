import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Tracker from '../Tracker';
import { useAuth } from '../../contexts/AuthContext';

import UserChecker from './UserChecker';

function LoggedInRoute({ component, ...routeProps }) {
  const auth = useAuth();

  const tracker = useMemo(() => new Tracker({ auth }), [auth]);

  tracker.trackSegment();

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
    });
  }

  return (
    <Route { ...routeProps }>
      <UserChecker component={ component } />
    </Route>
  );
}

LoggedInRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default LoggedInRoute;
