import PropTypes from 'prop-types';

import '../scss/components/_materials-icons.scss';

import vfStampIcon from '../images/vf_icon_stamp.png';
import vfEnvelopeIcon from '../images/vf_icon_envelope.png';
import vfPenIcon from '../images/vf_icon_pen.png';
import vfPrinterIcon from '../images/vf_icon_printer.png';

import tbsStampIcon from '../images/big-send/icon_stamp.png';
import tbsEnvelopeIcon from '../images/big-send/icon_envelope.png';
import tbsPenIcon from '../images/big-send/icon_pen.png';
import tbsPrinterIcon from '../images/big-send/icon_printer.png';

import tbsCampusStampIcon from '../images/big-send/icon_stamp_campus.png';
import tbsCampusEnvelopeIcon from '../images/big-send/icon_envelope_campus.png';
import tbsCampusPenIcon from '../images/big-send/icon_pen_campus.png';
import tbsCampusPrinterIcon from '../images/big-send/icon_printer_campus.png';

function MaterialsIcons({ crunchOnMobile = false, numDesktopCols = 4, variant }) {
  let stampIcon = vfStampIcon;
  let envelopeIcon = vfEnvelopeIcon;
  let penIcon = vfPenIcon;
  let printerIcon = vfPrinterIcon;
  if (variant === 'big-send') {
    stampIcon = tbsStampIcon;
    envelopeIcon = tbsEnvelopeIcon;
    penIcon = tbsPenIcon;
    printerIcon = tbsPrinterIcon;
  } else if (variant === 'big-send-on-campus') {
    stampIcon = tbsCampusStampIcon;
    envelopeIcon = tbsCampusEnvelopeIcon;
    penIcon = tbsCampusPenIcon;
    printerIcon = tbsCampusPrinterIcon;
  }

  const colClasses = `col-${ crunchOnMobile ? '3' : '6' } col-lg-${ 12 / numDesktopCols } pb-${ crunchOnMobile ? '0' : '5' } pb-lg-5`;
  const pClasses = crunchOnMobile ? 'd-none d-lg-block' : '';
  const imgClasses = `materials-icon${ crunchOnMobile ? ' crunch' : '' }`;

  return (
    <div className={ `materials-icons-container container ${ variant }` }>
      <div className='justify-content-center row'>
        <div className={ `item ${ colClasses }` }>
          <div className='icon-container'>
            <img
              alt='icon of printer'
              className={ imgClasses }
              src={ printerIcon }
            />
          </div>
          <p className={ pClasses }>
            <strong>
              Printer & paper
            </strong>
          </p>
        </div>
        <div className={ `item ${ colClasses }` }>
          <div className='icon-container'>
            <img
              alt='icon of stamp'
              className={ imgClasses }
              src={ stampIcon }
            />
          </div>
          <p className={ pClasses }>
            <strong>
              Postage stamps
            </strong>
          </p>
        </div>
        <div className={ `item ${ colClasses }` }>
          <div className='icon-container'>
            <img
              alt='icon of envelope'
              className={ imgClasses }
              src={ envelopeIcon }
            />
          </div>
          <p className={ pClasses }>
            <strong>
              #10 envelopes
            </strong>
          </p>
        </div>
        <div className={ `item ${ colClasses }` }>
          <div className='icon-container'>
            <img
              alt='icon of pen'
              className={ imgClasses }
              src={ penIcon }
            />
          </div>
          <p className={ pClasses }>
            <strong>
              A blue pen
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}

MaterialsIcons.propTypes = {
  crunchOnMobile: PropTypes.bool,
  numDesktopCols: PropTypes.number,
  variant: PropTypes.oneOf(['dark-on-light', 'light-on-dark', 'big-send', 'big-send-on-campus']).isRequired,
};

export default MaterialsIcons;
