import React, { Component } from 'react';
import propTypes from 'prop-types';
import queryString from 'query-string';
import axios from 'axios';
import logger from '../lib/logger';
import Footer from '../page/Footer';
import Header from '../page/Header';
import i18n from '../locales/strings.json';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

class UserPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
      locale: props.locale || 'en',
      toAdopters: [],
      fromAdopters: [],
      toAdopter: '',
      fromAdopter: '',
      reassigning: false,
      successMessage: '',
      errorMessage: '',
      fromInput: '',
      toInput: '',
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
      showHeader: propTypes.bool,
      showFooter: propTypes.bool,
    };
  }

  componentDidMount() {
    return this.getUsers();
  }

  getUsers = async (inputValue = null, autoCompleteType = null) => {
    const filter = { 'email:isNotNull': null, qualState: ['qualified', 'super_qualified', 'pre_qualified'] };
    if (inputValue) {
      filter['auth0Id:contains'] = inputValue;
    }
    const queryParams = {
      filter: JSON.stringify(filter),
      range: JSON.stringify([0, 9]),
      sort: JSON.stringify(['auth0Id', 'ASC']),
    };

    try {
      const accessToken = await this.props.auth.getAccessToken();
      const res = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        url: `${ process.env.REACT_APP_API_URL }/v1/users?${ queryString.stringify(queryParams) }`,
      });
      if (!autoCompleteType) {
        this.setState({
          toAdopters: res.data,
          fromAdopters: res.data,
        });
      } else if (autoCompleteType === 'to') {
        this.setState({
          toAdopters: res.data,
        });
      } else if (autoCompleteType === 'from') {
        this.setState({
          fromAdopters: res.data,
        });
      }
    } catch (err) {
      logger.error(err);
      this.setState({
        errorMessage: err.message,
      });
    }
  };

  changeToHandler = (event, value) => {
    event.preventDefault();
    if (value) {
      if (this.state.fromAdopter && this.state.fromAdopter.auth0Id === value.auth0Id) {
        this.setState({ errorMessage: i18n[this.state.locale]['reassign.letters.error.sameaccount'] });
      } else {
        this.setState({ toAdopter: { auth0Id: value.auth0Id, email: value.email }, errorMessage: '' });
      }
    }
  };

  changeFromHandler = (event, value) => {
    event.preventDefault();
    if (value) {
      if (this.state.toAdopter && this.state.toAdopter.auth0Id === value.auth0Id) {
        this.setState({ errorMessage: i18n[this.state.locale]['reassign.letters.error.sameaccount'] });
      } else {
        this.setState({ fromAdopter: { auth0Id: value.auth0Id, email: value.email }, errorMessage: '' });
      }
    }
  };

  reassign = async () => {
    try {
      const accessToken = await this.props.auth.getAccessToken();
      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };
      await axios.patch(`${ process.env.REACT_APP_API_URL }/v2/users/${ this.state.fromAdopter.auth0Id }/letters`, {
        update: {
          adopterId: this.state.toAdopter.auth0Id,
        },
      }, {
        headers,
      });
      this.setState({
        reassigning: false,
        successMessage: i18n[this.state.locale]['reassign.letters.success'].replace('{{toAdopter}}', this.state.toAdopter.auth0Id).replace('{{fromAdopter}}', this.state.fromAdopter.auth0Id),
      });
    } catch (err) {
      logger.error(err);
      this.setState({ reassigning: false, errorMessage: err.message });
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ reassigning: true });
  };

  cancelReassign = () => {
    this.setState({ reassigning: false });
  };

  toSearch = (event, value) => {
    this.setState({ toInput: value });
    this.getUsers(value, 'to');
  };

  fromSearch = (event, value) => {
    this.setState({ fromInput: value });
    this.getUsers(value, 'from');
  };

  render() {
    return (
      <React.Fragment>
        <h4 className='mt-2 text-center'>{ i18n[this.state.locale]['reassign.letters'] }</h4>
        <div className='mt-4'>
          <form onSubmit={ this.submitHandler }>
            <div className='row justify-content-center'>
              <div className='mb-3 from-column d-inline-block pe-5'>
                <Autocomplete
                  freeSolo
                  getOptionDisabled={ (option) => this.state.toAdopter && option.auth0Id === this.state.fromAdopter.auth0Id }
                  getOptionLabel={ (option) => option && option.auth0Id }
                  id='to-user-select'
                  input={ this.state.fromInput }
                  onChange={ this.changeFromHandler }
                  onInputChange={ this.fromSearch }
                  options={ this.state.fromAdopters }
                  renderInput={ (params) => <TextField { ...params } disabled={ this.state.reassigning } id='select-original-adopter' label={ i18n[this.state.locale]['reassign.letters.original.adopter'] } required variant='outlined' /> }
                  style={{ width: 400 }}
                  value={ this.state.fromAdopter }
                />
                { this.state.fromAdopter.auth0Id
                 && (
                   <div className='adopter-link'>
                     <a
                       href={ `/admin#/user/${ this.state.fromAdopter.auth0Id }` }
                       rel='noopener noreferrer'
                       target='_blank'
                     >
                       { i18n[this.state.locale]['reassign.letters.from.adopter.link'] }
                     </a>
                   </div>
                 ) }
              </div>
              <div className='mb-3 to-column d-inline-block pe-5'>
                <Autocomplete
                  freeSolo
                  getOptionDisabled={ (option) => this.state.fromAdopter && option.auth0Id === this.state.fromAdopter.auth0Id }
                  getOptionLabel={ (option) => option && option.auth0Id }
                  id='to-user-select'
                  input={ this.state.toInput }
                  onChange={ this.changeToHandler }
                  onInputChange={ this.toSearch }
                  options={ this.state.toAdopters }
                  renderInput={ (params) => <TextField { ...params } disabled={ this.state.reassigning } id='select-new-adopter' label={ i18n[this.state.locale]['reassign.letters.new.adopter'] } required variant='outlined' /> }
                  style={{ width: 400 }}
                  value={ this.state.toAdopter }
                />
                { this.state.toAdopter.auth0Id
                 && (
                   <div className='adopter-link'>
                     <a
                       href={ `/admin#/user/${ this.state.toAdopter.auth0Id }` }
                       rel='noopener noreferrer'
                       target='_blank'
                     >
                       { i18n[this.state.locale]['reassign.letters.new.adopter.link'] }
                     </a>
                   </div>
                 ) }
              </div>
            </div>
            <div className='row justify-content-center'>
              <input className='btn btn-primary justify-content-center mt-3 ps-5 pe-5' disabled={ this.state.reassigning } type='submit' value={ i18n[this.state.locale].submit } />
            </div>
            <div className={ ['text-center', 'mt-2', 'mb-2', this.state.reassigning ? undefined : 'hidden'].join(' ') }>
              { this.state.reassigning
               && (
                 <div>
                   <p className='me-4 ms-4 small'>
                     { i18n[this.state.locale]['reassign.letters.warning'].replace('{{toAdopter}}', `${ this.state.toAdopter.auth0Id } (${ this.state.toAdopter.email })`).replace('{{fromAdopter}}', `${ this.state.fromAdopter.auth0Id } (${ this.state.fromAdopter.email })`) }
                   </p>
                   <button
                     className='btn btn-info btn-small me-2'
                     onClick={ this.cancelReassign }
                     type='cancel'
                   >
                     { i18n[this.state.locale].cancel }
                   </button>

                   <button
                     className='btn btn-danger btn-small ms-2'
                     onClick={ this.reassign }
                     type='submit'
                   >
                     { i18n[this.state.locale].confirm }
                   </button>
                 </div>
               ) }
            </div>
          </form>
        </div>
        <div className={ ['alert', 'alert-info', 'mt-2', this.state.successMessage ? '' : 'hidden'].join(' ') } role='alert'>
          { this.state.successMessage }
        </div>
        <div className={ ['alert', 'alert-danger', 'mt-2', this.state.errorMessage ? '' : 'hidden'].join(' ') } role='alert'>
          { this.state.errorMessage }
        </div>
      </React.Fragment>
    );
  }
}

class ReassignLetters extends Component {
  static get propTypes() {
    return {
      auth: propTypes.object,
      showHeader: propTypes.bool,
      showFooter: propTypes.bool,
    };
  }

  render() {
    return (
      <div className='position-relative'>
        { this.props.showHeader ? <Header /> : null }
        <UserPicker auth={ this.props.auth } />
        { this.props.showFooter ? <Footer /> : null }
      </div>
    );
  }
}

export default ReassignLetters;
