import PropTypes from 'prop-types';
import { useSendSocialReminderMutation } from '../../lib/queries/queries';

SocialSendEmail.propTypes = {
  bundle: PropTypes.object.isRequired,
};

export default function SocialSendEmail({ bundle }) {
  const { isLoading, isSuccess, mutate: sendReminder } = useSendSocialReminderMutation({ socialToken: bundle.social_token });

  if (isLoading) {
    return (
      <span className='text-gray disabled'>Sending reminder email...</span>
    );
  }

  if (isSuccess) {
    return (
      <span className='text-gray'>Reminder email sent!</span>
    );
  }

  return (
    <button className='text-gray blue-link' onClick={ sendReminder } >Send email reminder to prepare letters</button>
  );
}
