import PropTypes from 'prop-types';
import { memo } from 'react';

import CampaignBubble from './CampaignBubble';
import CampaignLabel from './CampaignLabel';

import '../scss/campaigns/_campaign-labels.scss';
import '../scss/lib/_typography.scss';

function CampaignLabels({
  isLabs,
  isPolitical,
  isPriority,
  isSocial,
  isVoterRegistration,
  isBundleDisplay,
}) {
  return isBundleDisplay
    ? (
      <div className='campaign-labels'>
        <CampaignBubble isVisible={ isPolitical } type='political' />
        <CampaignBubble isVisible={ isSocial } type='social' />
        <CampaignBubble isVisible={ isVoterRegistration } type='voter-registration' />
        <CampaignBubble isVisible={ isLabs } type='labs' />
        <CampaignBubble isVisible={ isPriority } type='priority' />
      </div>
      )
    : (
      <div className='campaign-labels'>
        <CampaignLabel isBundleDisplay={ false } isVisible={ isPolitical } type='political' />
        <CampaignLabel isBundleDisplay={ false } isVisible={ isSocial } type='social' />
        <CampaignLabel isBundleDisplay={ false } isVisible={ isVoterRegistration } type='voter-registration' />
        <CampaignLabel isBundleDisplay={ false } isVisible={ isLabs } type='labs' />
        <CampaignLabel isBundleDisplay={ false } isVisible={ isPriority } type='priority' />
      </div>
      );
}

CampaignLabels.propTypes = {
  isBundleDisplay: PropTypes.bool.isRequired,
  isLabs: PropTypes.bool.isRequired,
  isPolitical: PropTypes.bool.isRequired,
  isPriority: PropTypes.bool.isRequired,
  isSocial: PropTypes.bool.isRequired,
  isVoterRegistration: PropTypes.bool.isRequired,
};

export default memo(CampaignLabels);
