import axios from 'axios';
import i18n from './locales/strings.json';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Footer from './page/Footer';
import Header from './page/Header';
import { decodeToken } from './lib/jwt';
import { Link } from 'react-router-dom';

const exceptions = {
  'forgot.password.googleuser': {
    showGoogleButton: true,
  },
  'forgot.password.facebookuser': {
    showFacebookButton: true,
  },
  'forgot.password.nouser': {
    showGoogleButton: true,
    showFacebookButton: true,
    showEmailLoginButton: true, // Even though this is named a "login" button, copy will make it say "register" in this case
  },
  'register.user.googleuser': {
    showGoogleButton: true,
  },
  'register.user.facebookuser': {
    showFacebookButton: true,
  },
  'register.user.emailuser': {
    showEmailLoginButton: true,
    showResetPassword: true,
  },
  'register.user.unknownuser': {
    showReturnToLogin: true,
  },
  'forgot.password.unknownuser': {
    showReturnToLogin: true,
  },
  'verify.email.emailuser': {
    showContinueButton: true,
  },
  'verify.email.facebookuser': {
    showContinueButton: true,
  },
  'verify.email.unknownuser': {
    showContinueButton: true,
  },
  'verify.email.nouser': {
    showContinueButton: true,
  },
};
class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    const purpose = props.match.params.purpose || 'forgot-password';
    this.state = {
      locale: props.locale || 'en',
      purpose: purpose.replace('-', '.'),
      emailToken: props.match.params.emailToken || sessionStorage.getItem('emailToken'),
      email: '',
      user: {},
      authenticationPurpose: '',
      emailError: '',
      userType: '',
      emailSent: false,
      backupShowResetPassword: false,
    };
    // Set sessionStorage emailToken so we can remember it when returning from authentication
    const token = decodeToken(this.state.emailToken);
    if (token && token.email) {
      this.state.email = token.email;
    }
    // restore the partner value to sessionStorage so it can be picked up by user creation if necessary
    if (token && token.partner) {
      sessionStorage.setItem('partner', token.partner);
    }
    // restore the defaultDistrictId value to sessionStorage so it can be picked up by user creation if necessary
    if (token && token.defaultDistrictId) {
      sessionStorage.setItem('defaultDistrictId', token.defaultDistrictId);
    }
    sessionStorage.setItem('emailToken', this.state.emailToken);
  }

  static get propTypes() {
    return {
      auth: PropTypes.object,
      match: PropTypes.object,
      locale: PropTypes.string,
      purpose: PropTypes.string,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUserType = ({ user }) => {
    if (user && user.provider) {
      if (user.provider.match(/google/)) {
        return 'googleuser';
      } else if (user.provider.match(/facebook/)) {
        return 'facebookuser';
      } else if (user.provider.match(/auth0/)) {
        return 'emailuser';
      }
    }
    return '';
  };

  getUser = async () => {
    try {
      const res = await axios({
        method: 'GET',
        headers: {
          Authorization: `Bearer ::${ this.state.emailToken }`,
        },
        url: `${ process.env.REACT_APP_API_URL }/v1/users/verifyEmail?uniquify=${ Math.random() }`,
      });
      const user = res.data;
      if (this.state.purpose === 'forgot.password' && user.passwordResetLink) {
        // if window.location fails for some reason, at least show the user a button they can click
        setTimeout(() => {
          this.setState({
            backupShowResetPassword: true,
            user,
            userType: this.getUserType({ user: res.data }),
            authenticationPurpose: 'forgot-password',
          });
        }, 500);
        window.location = user.passwordResetLink;
      } else {
        this.setState({
          authenticationPurpose: 'login',
          user,
          userType: this.getUserType({ user: res.data }),
        });
      }
    } catch (err) {
      if (err && err.response) {
        if (err.response.status === 401) {
          this.setState({ userType: 'unknownuser' });
        } else if (err.response.status === 404) {
          // If the user asked to register, and there is no existing user, then immediately redirect to registration by email
          if (this.state.purpose === 'register.user') {
            this.props.auth.login(false, { email_registration: true, login_hint: this.state.email });
          } else {
            this.setState({ authenticationPurpose: 'register', userType: 'nouser' });
          }
        } else {
          this.setState({ userType: 'unknownuser' });
        }
      } else {
        this.setState({ userType: 'unknownuser' });
      }
    }
  };

  loginViaFacebook = () => this.props.auth.login(false, { connection: 'facebook' });

  loginViaGoogle = () => this.props.auth.login(false, { connection: 'google-oauth2' });

  loginWithHint = () => this.props.auth.login(true, {
    email_registration: this.state.authenticationPurpose === 'register' ? true : undefined,
    login_hint: this.state.email,
  });

  render() {
    const toggles = exceptions[`${ this.state.purpose }.${ this.state.userType }`] || {};
    let message = i18n[this.state.locale][`${ this.state.purpose }.${ this.state.userType }`];
    if (message) {
      message = message.replace('{{email}}', `${ this.state.email }`);
    }
    const verifyEmailForm = (
      <div>
        <p>{ message }</p>
        <div className='mb-3'>
          <div>
            <button className={ ['btn-social-login', toggles.showFacebookButton ? '' : 'hidden'].join(' ') } onClick={ this.loginViaFacebook }>
              <img alt='Facebook' src='/images/bigsend/tbs_login_icon_fb.png' />
              { i18n[this.state.locale][`${ this.state.authenticationPurpose }.facebook.buttontext`] }
            </button>
            <button className={ ['btn-social-login', toggles.showGoogleButton ? '' : 'hidden'].join(' ') } onClick={ this.loginViaGoogle }>
              <img alt='Google' src='/images/bigsend/tbs_login_icon_google.png' />
              { i18n[this.state.locale][`${ this.state.authenticationPurpose }.google.buttontext`] }
            </button>
            <div className={ (toggles.showEmailLoginButton && (toggles.showGoogleButton || toggles.showFacebookButton)) ? '' : 'hidden' }>
              <p className='text-center'>or</p>
              <hr />
            </div>
          </div>
          <button
            className={ ['btn', 'btn-primary', 'w-100', 'justify-content-center', toggles.showEmailLoginButton ? '' : 'hidden'].join(' ') }
            onClick={ this.loginWithHint }
          >
            { i18n[this.state.locale][`${ this.state.authenticationPurpose }.email.buttontext`] }
          </button>
          <a
            className={ ['btn', 'btn-primary', 'w-100', 'justify-content-center', toggles.showResetPassword || this.state.backupShowResetPassword ? '' : 'hidden'].join(' ') }
            href={ this.state.user.passwordResetLink }
            style={{ color: 'white' }}
          >
            { i18n[this.state.locale]['reset.password.buttontext'] }
          </a>
          <Link
            className={ ['btn', 'btn-primary', 'w-100', 'justify-content-center', toggles.showReturnToLogin ? '' : 'hidden'].join(' ') }
            style={{ color: 'white' }}
            to='/'
          >
            { i18n[this.state.locale]['return.to.login.buttontext'] }
          </Link>
          <Link
            className={ ['btn', 'btn-primary', 'w-100', 'justify-content-center', toggles.showContinueButton ? '' : 'hidden'].join(' ') }
            onClick={ (e) => {
              e.preventDefault();
              if (this.props.auth.isAuthenticated()) {
                document.location = '/dashboard';
              } else {
                this.props.auth.login(false);
              }
            } }
            style={{ color: 'white' }}
            to='/dashboard'
          >
            { i18n[this.state.locale]['continue.buttontext'] }
          </Link>
        </div>
      </div>
    );
    return (
      <div className={ this.state.userType === '' && !this.state.tokenInvalid ? 'hidden' : 'core-login' }>
        <Header />
        <br />
        <div className='row col d-flex justify-content-center'>
          <div className='card' style={{ width: '24rem' }}>
            <article className='card-body' style={{ display: 'block' }}>
              <img alt='logo' className='center-image' height='58px;' src='https://storage.googleapis.com/voteforward-production-static/bg-masthead.png' width='auto' />
              <h4 className='card-title mb-4 mt-1 text-center'>{ i18n[this.state.locale][`${ this.state.purpose }.title`] }</h4>
              <hr />
              { verifyEmailForm }
            </article>
          </div>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default VerifyEmail;
