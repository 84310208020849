import ReactDOM from 'react-dom';

import './lib/i18n';

import App from './App';
import { init } from './lib/Tracker';
import { initLogger } from './lib/logger';

init();
initLogger();

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
