import Box from '@material-ui/core/Box';

import '../scss/components/_suspense-fallback.scss';

function SuspenseFallback() {
  return (
    <Box
      alignItems='center'
      className='suspense-fallback'
      display='flex'
      justifyContent='center'
      minHeight='100vh'
    >
      <div className='fa fa-pulse fa-spinner fa-3x' />
    </Box>
  );
}

export default SuspenseFallback;
