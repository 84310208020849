import PropTypes from 'prop-types';
import { Fragment } from 'react';

import DonationButton from './DonationButton';

import '../scss/components/_support-vf.scss';

function SupportVF({ appeal, appealHeadline, donationRefCode, donationSlug }) {
  return (
    <section className='container mb-5 p-5 support-vf'>
      <div className='pb-3 row'>
        <div className='col'>
          <a id='support-vf'>
            <h2>{ appealHeadline || <Fragment>Support Vote Forward&rsquo;s Work</Fragment> }</h2>
          </a>

          <p className='mb-3'>
            {
              appeal
              || (
                <Fragment>
                  Please consider making a donation to help support Vote Forward so we can continue our efforts in the
                  coming years.
                </Fragment>
              )
            }
          </p>
          <p>
            <small className='font-italic'>
              If you&rsquo;ve saved your payment information with ActBlue Express, your donation will go through
              immediately:
            </small>
          </p>
        </div>
      </div>

      <div className='donation-buttons mb-3 row'>
        <DonationButton amount={ 20 } donationSlug={ donationSlug } refCode={ donationRefCode } />
        <DonationButton amount={ 40 } donationSlug={ donationSlug } refCode={ donationRefCode } />
        <DonationButton amount={ 100 } donationSlug={ donationSlug } refCode={ donationRefCode } />
        <div className='mb-3 w-100' />
        <DonationButton amount={ 200 } donationSlug={ donationSlug } refCode={ donationRefCode } />
        <DonationButton amount={ 400 } donationSlug={ donationSlug } refCode={ donationRefCode } />
        <DonationButton donationSlug={ donationSlug } refCode={ donationRefCode } />
      </div>
    </section>
  );
}

SupportVF.propTypes = {
  appeal: PropTypes.string,
  appealHeadline: PropTypes.string,
  donationRefCode: PropTypes.string.isRequired,
  donationSlug: PropTypes.string.isRequired,
};

export default SupportVF;
