import axios from 'axios';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';

import LeerEnEspañol from '../components/LeerEnEspanol';
import logger from '../lib/logger';

import '../scss/lib/_alerts.scss';
import '../scss/lib/_buttons.scss';
import '../scss/lib/_headings.scss';

import letterSampleEn from '../images/pledge-to-vote/letter-code-sample-en.png';
import letterSampleEs from '../images/pledge-to-vote/letter-code-sample-es.png';

const letterSamples = {
  en: letterSampleEn,
  es: letterSampleEs,
};

const placeholderCode = 'A1B2C34';

class EnterCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      pledgeError: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
      pledgeError: false,
    });
  };

  getVoterInfo = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios
        .post(
          `${ process.env.REACT_APP_API_URL || '' }/voter/info`,
          { code: this.state.value },
        );
      this.props.handleVoterInfo({
        urlElectionInfo: data.urlElectionInfo,
        voterState: data.voterState,
        code: this.state.value,
        codeSubmitted: true,
      });
    } catch (error) {
      this.setState({ pledgeError: true });
      logger.error(error);
    }
  };

  render() {
    return (
      <section className='container'>
        <div className='row'>
          <div className='col-12 pb-3'>
            <h1>
              { this.props.t('enterCode.title') }
            </h1>
            <p className='lead'>
              { this.props.t('enterCode.lead') }
              { ' ' }
              <LeerEnEspañol Element='span' />
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-xl-7 order-last order-xl-first pb-5'>
            <img
              alt={ this.props.t('enterCode.letterSample.alt') }
              className='img-fluid'
              src={ letterSamples[this.props.i18n.resolvedLanguage] }
            />
          </div>
          <div className='col-12 col-xl-5 order-first order-xl-last'>
            <div className='card mb-5'>
              <div className='card-body'>
                <form onSubmit={ this.getVoterInfo }>
                  {
                    this.state.pledgeError
                      ? (
                        <div className='alert alert-danger mb-2' role='alert'>
                          {
                            this.state.value === placeholderCode
                              ? (
                                <Trans i18nKey='enterCode.submittedPlaceholderCode' t={ this.props.t }>
                                  Whoops, you entered the sample code! Please enter the code from <i>your</i> letter.
                                </Trans>
                                )
                              : this.props.t('enterCode.unrecognizedCode')
                          }
                        </div>
                        )
                      : null
                  }
                  <p className='lead mb-3 mx-auto'>
                    { this.props.t('enterCode.formMessage') }
                  </p>
                  <input
                    className='form-control form-control-lg text-center mb-2 w-100'
                    onChange={ this.handleChange }
                    placeholder={ placeholderCode }
                    type='text'
                    value={ this.state.value }
                  />
                  <input
                    className='btn btn-primary btn-lg w-100'
                    type='submit'
                    value='Submit'
                  />
                </form>
              </div>
            </div>
            <div className='card mb-5'>
              <div className='card-body'>
                <p className='lead mb-3 mx-auto'>
                  <Trans i18nKey='enterCode.programOverview' t={ this.props.t }>
                    0
                    <Link className='program-overview' to='/program-overview'>
                      1
                    </Link>
                    2
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

EnterCode.propTypes = {
  handleVoterInfo: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('pledgeToVote')(EnterCode);
