import React, { Component } from 'react';
import { Admin as ReactAdmin, AppBar, defaultTheme, Layout, Resource } from 'react-admin';
import { Redirect, Route } from 'react-router-dom';
import propTypes from 'prop-types';
import Footer from '../page/Footer';
import AdminImport from './AdminImport';
import AdminRoute from '../AdminRoute';
import AdminUser from './AdminUser';
import authProvider from './authProvider';
import { checkPermission } from '../lib/user';
import adminDataProvider from './adminDataProvider';
import BatchApproveList from './BatchApprove';
import ReassignLetters from './ReassignLetters';
import Menu from './Menu';
import UserTable from './UserTable';
import Users from './Users';
import LuZipList, { LuZipCreate, LuZipEdit } from './LuZips';
import GroupList, { GroupCreate, GroupEdit } from './Groups';
import VoterList, { VoterView } from './Voters';
import LetterBundleList, { LetterBundleView, LetterBundleEdit } from './LetterBundles';
import BlogAdmin from './Blog';
import PartnerList, { PartnerCreate, PartnerEdit } from './Partners';
import AnnouncementList, { AnnouncementCreate, AnnouncementEdit } from './Announcements';
import DistrictImportList, { DistrictImportCreate, DistrictImportEdit } from './DistrictImports';
import SettingList, { SettingCreate, SettingEdit, SettingView } from './Settings';
import { UserRoleList, UserRoleCreate } from './UserRoles';
import BusinessIcon from '@material-ui/icons/Business';
import VoterIcon from '@material-ui/icons/HowToVote';
import LockOpen from '@material-ui/icons/LockOpen';
import EmailIcon from '@material-ui/icons/Email';
import CodeIcon from '@material-ui/icons/Code';
import GroupIcon from '@material-ui/icons/Group';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import { EmailPreferencesList, EmailPreferencesEdit } from './EmailPreferences';
import DistrictList, { DistrictEdit, DistrictCreate } from './Districts';
import DistrictIcon from '@material-ui/icons/Map';
import Header from '../page/Header';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DistrictTaxTypeList, { DistrictTaxTypeEdit } from './DistrictTaxType';
import GoalPageList, { GoalPageEdit } from './GoalPage';

const CustomAppBar = (props) => (
  // Positioning the AppBar as 'static' means that it will not float up to the
  // top of the screen over the header.
  <AppBar
    { ...props }
    position='static'
  />
);
const useStyles = makeStyles((theme) => ({
  // Change the margin top to bring the layout back to right below the header.
  // The "logic" below is stolen from the logic in the Layout:
  // https://github.com/marmelab/react-admin/blob/d285ac5e52a3560e4ac5e4f88153008125038ee2/packages/ra-ui-materialui/src/layout/Layout.tsx#L72
  root: {
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(6) * -1,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(7) * -1,
    },
  },
}));

const theme = {
  ...defaultTheme,
  overrides: {
    ...defaultTheme.overrides,
    RaSidebar: {
      fixed: {
        position: 'relative',
        height: 'auto',
        overflowX: 'auto',
      },
    },
  },
};

const CustomLayout = (props) => {
  const classes = useStyles();
  return (
    <Layout
      { ...props }
      appBar={ CustomAppBar }
      className={ classes.root }
      menu={ Menu }
      theme={ theme }
    />
  );
};

class Admin extends Component {
  static get propTypes() {
    return {
      auth: propTypes.object,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Header showBanner={ false } />
        <ReactAdmin
          authProvider={ authProvider }
          customRoutes={ this._customRoutes() }
          dataProvider={ adminDataProvider(this.props.auth) }
          layout={ CustomLayout }
          style={{ marginTop: 0 }}
        >
          { (grants) => [
            checkPermission({ grants, resource: '*:*', permission: 'user.read' })
              ? <Resource icon={ PeopleIcon } key='user' list={ UserTable } name='Users' options={{ label: 'Users' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'user.batchApprove' })
              ? <Resource icon={ DoneAllIcon } key='batchApprove' list={ BatchApproveList } name='batchapprove' options={{ label: 'Batch Approve' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'announcement.read' })
              ? <Resource create={ AnnouncementCreate } edit={ AnnouncementEdit } icon={ AnnouncementIcon } key='announcement' list={ AnnouncementList } name='Announcements' options={{ label: 'Announcements' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'district.update' })
              ? <Resource create={ DistrictCreate } edit={ DistrictEdit } icon={ DistrictIcon } key='districts' list={ DistrictList } name='Districts' options={{ label: 'Campaigns' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'districtImport.read' })
              ? <Resource create={ DistrictImportCreate } edit={ DistrictImportEdit } key='districtImports' list={ DistrictImportList } name='DistrictImports' options={{ label: 'Imports' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'district.read' }) && checkPermission({ grants, resource: '*:*', permission: 'district.update' })
              ? <Resource edit={ DistrictTaxTypeEdit } key='districtTaxType' list={ DistrictTaxTypeList } name='DistrictTaxType' options={{ label: 'District Tax Type' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'user.admin' })
              ? <Resource edit={ EmailPreferencesEdit } icon={ EmailIcon } key='emailPreferences' list={ EmailPreferencesList } name='EmailPreferences' options={{ label: 'Email Settings' }} />
              : null,
            checkPermission({ grants, resource: 'ANY', permission: 'partner.read' })
              ? (
                <Resource
                  create={ checkPermission({ grants, resource: 'ANY', permission: 'partner.create' }) ? PartnerCreate : null }
                  edit={ checkPermission({ grants, resource: 'ANY', permission: 'partner.update' }) ? PartnerEdit : null }
                  icon={ BusinessIcon }
                  key='partners'
                  list={ PartnerList }
                  name='Partners'
                />
                )
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'user.read' })
              ? (
                <Resource
                  edit={ GoalPageEdit }
                  icon={ GroupIcon }
                  key='goalPages'
                  list={ GoalPageList }
                  name='goalPages'
                  options={{ label: 'Goal Pages' }}
                />
                )
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'group.read' })
              ? <Resource create={ GroupCreate } edit={ GroupEdit } icon={ GroupIcon } key='groups' list={ GroupList } name='Groups' options={{ label: 'Groups' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'role.admin' })
              ? <Resource create={ UserRoleCreate } icon={ LockOpen } key='userRoles' list={ UserRoleList } name='UserRoles' options={{ label: 'User Roles' }} />
              : null,
            <Resource key='roles' name='Roles' options={{ hidden: true }} />,
            <Resource key='districtTemplates' name='DistrictTemplates' options={{ hidden: true }} />,
            checkPermission({ grants, resource: '*:*', permission: 'luZip.read' })
              ? <Resource create={ LuZipCreate } edit={ LuZipEdit } icon={ CodeIcon } key='luZips' list={ LuZipList } name='LuZips' options={{ label: 'Zipcodes' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'letter.search' })
              ? <Resource edit={ VoterView } icon={ VoterIcon } key='voters' list={ VoterList } name='Voters' options={{ label: 'Voters' }} />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'letterBundle.read' })
              ? <Resource edit={ LetterBundleEdit } icon={ AttachFileIcon } key='letterBundles' list={ LetterBundleList } name='LetterBundles' options={{ label: 'Letter Kits' }} show={ LetterBundleView } />
              : null,
            checkPermission({ grants, resource: '*:*', permission: 'setting.update' })
              ? <Resource create={ SettingCreate } edit={ SettingEdit } icon={ SettingsIcon } key='settings' list={ SettingList } name='Settings' options={{ label: 'Settings' }} show={ SettingView } />
              : null,
            <Resource key='settingsAudits' name='SettingsAudits' options={{ hidden: true }} />,
          ] }
        </ReactAdmin>
        <Footer />
      </React.Fragment>
    );
  }

  _customRoutes() {
    const auth = this.props.auth;
    const grants = this.props.auth.getGrants();
    let redirectRoute = null;
    if (checkPermission({ grants, resource: 'ANY', permission: 'partner.read' })) {
      redirectRoute = <Redirect exact from='/' key='home' to='/partners' />;
    }
    const routes = [
      redirectRoute,
      checkPermission({ grants, resource: '*:*', permission: 'letter.reassign' })
        && <AdminRoute auth={ auth } component={ ReassignLetters } key='reassignletters' path='/reassign-letters' />,
      checkPermission({ grants, resource: '*:*', permission: 'user.read' })
        && <AdminRoute auth={ auth } component={ AdminUser } key='user' path='/user/:id' />,
      checkPermission({ grants, resource: '*:*', permission: 'file.upload' })
        && <AdminRoute auth={ auth } component={ AdminImport } key='imports' path='/imports' />,
      checkPermission({ grants, resource: 'ANY', permission: 'partner.read' })
        && <AdminRoute auth={ auth } component={ Users } key='partners-admin' path='/partner-users/:partner' />,
      checkPermission({ grants, resource: '*:*', permission: 'districtImport.read' }) && checkPermission({ grants, resource: '*:*', permission: 'district.read' })
        && <AdminRoute auth={ auth } component={ DistrictImportList } key='district-imports' path='/district-imports' />,
      <Route key='blog-admin' path='/blog/admin' render={ (props) => <BlogAdmin { ...props } /> } />,
    ];
    return routes.filter((route) => route);
  }
}

export default Admin;
