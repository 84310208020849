import { datadogLogs } from '@datadog/browser-logs';

function isLogUploadEnabled() {
  return process.env.REACT_APP_DATADOG_LOGGER_CLIENT_TOKEN
    && process.env.REACT_APP_DATADOG_LOGGER_CLIENT_TOKEN !== ''
    && process.env.REACT_APP_DATADOG_SITE
    && process.env.REACT_APP_DATADOG_SITE !== '';
}

const logger = {
  debug: (...args) => {
    if (isLogUploadEnabled()) {
      datadogLogs.logger.debug(...args);
    } else {
      // eslint-disable-next-line no-console
      console.debug(...args);
    }
  },
  error: (...args) => {
    if (isLogUploadEnabled()) {
      datadogLogs.logger.error(...args);
    } else {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  },
  info: (...args) => {
    if (isLogUploadEnabled()) {
      datadogLogs.logger.info(...args);
    } else {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  },
};

function initLogger() {
  isLogUploadEnabled() && datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_LOGGER_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    forwardErrorsToLogs: true,
    env: process.env.NODE_ENV,
  });
}

export default logger;
export { initLogger };
