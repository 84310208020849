import { __setupAuth } from '../contexts/AuthContext';

// eslint-disable-next-line no-empty-function
const auth = __setupAuth({ setPictureUrl: () => {} });

const authProvider = {
  login: () => {
    auth.login();
    return Promise.resolve();
  },
  logout: () => {
    auth.logout();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(auth.getGrants()),
};
export default authProvider;
