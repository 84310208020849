import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

function usePartnerSlug() {
  const { params } = useRouteMatch();
  const partnerSlug = useMemo(
    () => params.partner?.toLowerCase() || '',
    [params.partner],
  );
  useEffect(
    () => {
      if (partnerSlug) {
        sessionStorage.setItem('partner', partnerSlug);
      }
    },
    [partnerSlug],
  );
  return partnerSlug;
}

// eslint-disable-next-line import/prefer-default-export
export { usePartnerSlug };
