import axios from 'axios';
import logger from './logger';

/**
 * Calls necessary API to get a link to download a pdf containing pdfs based on parameters.
 * Then "clicks" on the link to download the pdf. This is a bit hacky, but heuristically
 * was the only way we could get it to work on various devices (ipad, phone, desktop)
 */
async function downloadLetters({ auth, userId, letterBundleId = undefined, letterId = undefined, excludePrepped = true }) {
  logger.debug(`Entered downloadLetters with parameters: ${ JSON.stringify({ letterBundleId, letterId, userId, excludePrepped }) }`);
  const accessToken = await auth.getAccessToken();
  const queryParams = {
    letterBundleId,
    letterId,
  };
  if (excludePrepped) {
    queryParams.excludePrepped = excludePrepped;
  }
  logger.debug(`About to call letters-pdfUrl with userId: ${ userId } and queryParams: ${ JSON.stringify(queryParams) }`);
  const res = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/users/${ userId }/letters-pdfUrl`, {
    headers: {
      Authorization: 'Bearer '.concat(accessToken),
    },
    params: queryParams,
    responseType: 'json',
  });
  // might be a better way to do this, but leaving this for now
  const link = document.createElement('a');
  link.href = res.data.url;
  document.body.appendChild(link);
  link.click();
}

export default downloadLetters;
