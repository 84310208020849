import React, { Component } from 'react';
import propTypes from 'prop-types';
import { getBranchOverride, setBranchOverride } from '../CMSUtil';
import Link from './Link';
import LaunchIcon from '@material-ui/icons/Launch';

export default class BlogAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsBranchName: localStorage.getItem('cmsBranchName'),
      saved: false,
    };
  }

  handleNameSubmit = (event) => {
    setBranchOverride(this.state.cmsBranchName);
    this.setState({ saved: true });
    setTimeout(() => this.setState({ saved: false }), 1000);
    event.preventDefault();
  };

  onBranchChange = (event) => {
    this.setState({ cmsBranchName: event.target.value || '' });
    event.preventDefault();
  };

  static get propTypes() {
    return {
      announcement: propTypes.object,
    };
  }

  componentDidMount() {
    this.setState({ cmsBranchName: getBranchOverride() });
  }

  render() {
    return (
      <div>
        <p className='me-4 mt-3'>
          <Link href='https://github.com/vote-forward/blog' launchNow={ false } linkClass='mt-5' { ...this.props } target='_blank'><LaunchIcon />CMS Admin</Link>
        </p>
        <p className='me-4'>Choose a different branch to use for <Link to='/posts'>cms</Link> entries (this will only be visible to you):</p>
        <form onSubmit={ this.handleNameSubmit }>
          <div className='input-group mb-3'>
            <input
              className='form-control col-md-6 me-3'
              onChange={ this.onBranchChange }
              placeholder='Branch Name Override'
              type='text'
              value={ this.state.cmsBranchName }
            />
            <button className='btn btn-primary btn-sm' disabled={ this.state.saved } type='submit'>
              { this.state.saved ? 'Saved!' : 'Save' }
            </button>
          </div>
        </form>

      </div>
    );
  }
}
