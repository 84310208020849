import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import CampaignCard from './CampaignCard';

import noop from '../lib/functions/noop';
import { useVoterStats } from './lib/hooks';

import '../scss/lib/_headings.scss';

function CampaignsSection({
  campaigns,
  handleCampaignSelection,
  partnerSlug,
  type,
  unfilteredCampaigns,
}) {
  const { t } = useTranslation('campaigns');
  const voterStats = useVoterStats(unfilteredCampaigns || campaigns);

  if (campaigns.length === 0) {
    return null;
  }

  return (
    <section className={ `mb-5 row ${ type }-campaigns` } data-testid={ `${ type }-campaigns` }>
      <div className='col mb-md-3'>
        <div className='align-items-baseline d-md-flex'>
          <h2 className='flex-grow-1 fs-2 headline mb-1 mb-md-3 text-blue'>
            <a id={ type }>
              { t(`${ type }.heading`) }
            </a>
          </h2>

          <p className='fs-4 overflow-hidden text-sm-end voter-stats'>
            <strong className='text-nowrap'>
              { t('votersAdopted', { total: voterStats.votersAdopted.toLocaleString() }) }
            </strong>
            {
              type === 'completed'
                ? null
                : (
                  <Fragment>
                    { ' | ' }
                    <span className='text-nowrap'>
                      { t('votersRemaining', { total: voterStats.votersRemaining.toLocaleString() }) }
                    </span>
                  </Fragment>
                  )
            }
          </p>
        </div>

        <p className='border-top mb-3 mb-md-4 mt-0 pt-3'>
          { t(`${ type }.summary`, '') }
        </p>

        <div className='row'>
          {
            campaigns.map((campaign) => (
              <CampaignCard
                handleCampaignSelection={ handleCampaignSelection }
                isCompleted={ type === 'completed' }
                isPolitical={ campaign.taxType === 'political' }
                isSocial={ campaign.taxType === 'social' }
                isVoterRegistration={ campaign.taxType === 'voter-engagement' }
                key={ campaign.slug }
                partnerSlug={ partnerSlug }
                { ...campaign }
              />
            ))
          }
        </div>
      </div>
    </section>
  );
}

CampaignsSection.defaultProps = {
  campaigns: [],
  handleCampaignSelection: noop,
  partnerSlug: '',
  unfilteredCampaigns: undefined,
};

CampaignsSection.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object),
  handleCampaignSelection: PropTypes.func,
  partnerSlug: PropTypes.string,
  type: PropTypes.string.isRequired,
  unfilteredCampaigns: PropTypes.arrayOf(PropTypes.object),
};

export default CampaignsSection;
