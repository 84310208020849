import PropTypes from 'prop-types';
import DownloadButton from './DownloadButton';
import StatusChangeButton from './StatusChangeButton';

function SlideOutRow({
  children,
  lettersToMutate,
  downloadState,
  segmentTrackIds,
  downloadIconOnly = false,
  mailOnDate,
  modifyAndDownloadState,
  status,
}) {
  const statusNames = {
    unprepped: {
      name: 'unprepared',
    },
    prepped: {
      name: 'prepared',
    },
    sent: {
      name: 'sent',
    },
  };
  const statusName = statusNames[status.toLowerCase()].name;
  const segmentTrackProperties = { ...segmentTrackIds, previousState: statusName };

  return (
    <div className='row letter-item'>
      <StatusChangeButton
        isBackwardMutation
        lettersToMutate={ lettersToMutate }
        modifyAndDownloadState={ modifyAndDownloadState }
        segmentTrackPropeties={ segmentTrackProperties }
        status={ status }
      />
      { children }
      <DownloadButton
        downloadState={ downloadState }
        isIconOnly={ downloadIconOnly }
        modifyAndDownloadState={ modifyAndDownloadState }
      />
      <StatusChangeButton
        lettersToMutate={ lettersToMutate }
        mailOnDate={ mailOnDate }
        modifyAndDownloadState={ modifyAndDownloadState }
        segmentTrackPropeties={ segmentTrackProperties }
        status={ status }
      />
    </div>
  );
}

SlideOutRow.propTypes = {
  children: PropTypes.any.isRequired,
  downloadIconOnly: PropTypes.bool,
  downloadState: PropTypes.object.isRequired,
  lettersToMutate: PropTypes.array.isRequired,
  mailOnDate: PropTypes.string.isRequired,
  modifyAndDownloadState: PropTypes.object.isRequired,
  segmentTrackIds: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default SlideOutRow;
