import React, { Component } from 'react';
import axios from 'axios';
import Header from './page/Header';
import Footer from './page/Footer';
import EmailPreferencesForm from './EmailPreferencesForm';
import LetterSampleForm from './LetterSampleForm.js';
import propTypes from 'prop-types';
import logger from './lib/logger';
import { message } from './lib/deprecated_i18n';
import GA from './lib/GoogleAnalytics';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale || 'en',
      user: '',
      email: this.props?.auth.authenticatedEmail(),
      auth0_id: localStorage.getItem('user_id') || '',
      nameVal: '',
      showNameEdit: false,
      showFullNameEdit: false,
      showZipEdit: false,
      showZipError: false,
      zipVal: '',
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
    };
  }

  async getUser(auth0_id) {
    const accessToken = await this.props.auth.getAccessToken();
    try {
      const res = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        url: `${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }`,
      });
      this.setState({ user: res.data, nameVal: res.data.preferred_name || '', zipVal: res.data.zip || '' });
    } catch (error) {
      logger.error(error);
    }
  }

  async updateUser(key, value) {
    GA.trackEvent({ category: 'profile_update', action: key });
    const data = {};
    data[key] = value;
    try {
      const accessToken = await this.props.auth.getAccessToken();
      await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/self`, data, {
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
      });
      this.setState({
        user: { ...this.state.user, [key]: value },
        nameVal: this.state.user.preferred_name || '',
        fullNameVal: this.state.user.full_name || '',
        zipVal: this.state.user.zip || '',
        showZipError: false,
      });
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === 'invalid zip') {
          this.setState({ showZipError: true });
        } else {
          // catch any other response errors
          logger.error(error);
        }
      } else {
        //catchall any other error
        logger.error(error);
      }
    }
  }

  // preferred name edit funcs
  toggleNameEdit = () => {
    this.setState({ showNameEdit: !this.state.showNameEdit });
  };

  handleNameSubmit = (event) => {
    this.toggleNameEdit();
    event.preventDefault();
    this.updateUser('preferred_name', this.state.nameVal);
  };

  onNameChange = (event) => {
    this.setState({ nameVal: event.target.value });
    event.preventDefault();
  };

  // full name edit funcs
  toggleFullNameEdit = () => {
    this.setState({ showFullNameEdit: !this.state.showFullNameEdit });
  };

  handleFullNameSubmit = (event) => {
    this.toggleFullNameEdit();
    event.preventDefault();
    this.updateUser('full_name', this.state.fullNameVal);
  };

  onFullNameChange = (event) => {
    this.setState({ fullNameVal: event.target.value });
    event.preventDefault();
  };

  // zip code edit funcs
  toggleZipEdit = () => {
    this.setState({ showZipEdit: !this.state.showZipEdit });
  };

  handleZipSubmit = (event) => {
    this.toggleZipEdit();
    event.preventDefault();
    this.updateUser('zip', this.state.zipVal);
  };

  onZipChange = (event) => {
    this.setState({ zipVal: event.target.value });
    event.preventDefault();
  };

  componentDidMount() {
    this.getUser(this.state.auth0_id);
  }

  render() {
    return (
      <div>
        <Header />
        <br />
        <div className='d-flex justify-content-center title'>
          <h1>{ message('profile.title') }</h1>
        </div>
        <div className='container mb-4 mt-4'>
          <div className='mb-4'>
            <h4 className='mb-4'>{ message('profile.basics.title') }</h4>

            <div className={ !this.state.showNameEdit ? null : 'hidden' }>
              <p>
                <span className='me-4'>{ message('preferred.name.label') }</span>
                <span className='me-3'>{ this.state.user.preferred_name }</span> <button className='btn btn-primary btn-sm' onClick={ this.toggleNameEdit }>Edit</button>
              </p>
            </div>

            <div className={ this.state.showNameEdit ? null : 'hidden' }>
              <p className='me-4'>{ message('preferred.name.label') }</p>
              <form onSubmit={ this.handleNameSubmit }>
                <div className='input-group mb-3'>
                  <input
                    className='form-control col-md-6 me-3'
                    onChange={ this.onNameChange }
                    placeholder='Preferred display name'
                    required
                    type='text'
                    value={ this.state.nameVal }
                  />
                  <button className='btn btn-primary btn-sm' type='submit'>
                    Save
                  </button>
                </div>
              </form>
            </div>

            <div className={ !this.state.showFullNameEdit ? null : 'hidden' }>
              <p>
                <span className='me-4'>{ message('full.name.label') }</span>
                <span className='me-3'>{ this.state.user.full_name }</span> <button className='btn btn-primary btn-sm' onClick={ this.toggleFullNameEdit }>Edit</button>
              </p>
            </div>

            <div className={ this.state.showFullNameEdit ? null : 'hidden' }>
              <p className='me-4'>{ message('full.name.label') }</p>
              <form onSubmit={ this.handleFullNameSubmit }>
                <div className='input-group mb-3'>
                  <input
                    className='form-control col-md-6 me-3'
                    onChange={ this.onFullNameChange }
                    placeholder='Full name'
                    required
                    type='text'
                    value={ this.state.fullNameVal }
                  />
                  <button className='btn btn-primary btn-sm' type='submit'>
                    Save
                  </button>
                </div>
              </form>
            </div>

            <div className={ !this.state.showZipEdit ? null : 'hidden' }>
              <p>
                <span className='me-4'>{ message('zip.code.label') }</span>
                <span className='me-3'>{ this.state.user.zip }</span> <button className='btn btn-primary btn-sm' onClick={ this.toggleZipEdit }>Edit</button>
                <span className={ this.state.showZipError ? 'me-3' : 'hidden' } style={{ color: 'red' }}> { message('zip.code.error') }</span>
              </p>
            </div>

            <div className={ this.state.showZipEdit ? null : 'hidden' }>
              <p className='me-4'>{ message('zip.code.label') }</p>
              <form onSubmit={ this.handleZipSubmit }>
                <div className='input-group mb-3'>
                  <input
                    className='form-control col-md-6 me-3'
                    onChange={ this.onZipChange }
                    placeholder='Zip Code'
                    required
                    type='text'
                    value={ this.state.zipVal }
                  />
                  <button className='btn btn-primary btn-sm' type='submit'>
                    Save
                  </button>
                </div>
              </form>
            </div>

            <div>
              <p>
                <span className='me-4'>{ message('email.address.label') }</span>
                { this.state.email }
              </p>
            </div>

            <h4>{ message('profile.sample.title') }</h4>
            <div className='row'>
              <div className='col'>
                <p>{ message('profile.sample.prompt') }</p>
              </div>
              <div className='col'>
                <LetterSampleForm auth={ this.props.auth } />
              </div>
            </div>

          </div>
          <div className='mb-4'>
            <h4 className='mb-4'>{ message('email.preferences.subheading') }</h4>
            <EmailPreferencesForm
              auth={ this.props.auth }
              auth0_id={ this.state.auth0_id }
              locale={ this.state.locale }
            />
          </div>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Profile;
