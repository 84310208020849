
import { isDatePast } from '../../lib/dates.js';
import propTypes from 'prop-types';
import NoActiveCampaignsWindow from './NoActiveCampaignsWindow.js';
import VoterAdoptionTabs from './VoterAdoptionTabs.js';

DistrictItemWindow.propTypes = {
  campaignState: propTypes.object.isRequired,
  canAdoptSocial: propTypes.bool.isRequired,
  currentDistrictPending: propTypes.bool.isRequired,
  goToTraining: propTypes.func.isRequired,
  handleAdoptionSuccess: propTypes.func.isRequired,
  liveVoterCount: propTypes.number.isRequired,
  maxQual: propTypes.number,
  requestSuperqualStatus: propTypes.func.isRequired,
  setCampaignState: propTypes.func.isRequired,
  updateTraining: propTypes.func.isRequired,
  user: propTypes.object.isRequired,
};

export default function DistrictItemWindow({
  currentDistrictPending,
  campaignState,
  canAdoptSocial,
  maxQual,
  user,
  goToTraining,
  updateTraining,
  setCampaignState,
  liveVoterCount,
  handleAdoptionSuccess,
  requestSuperqualStatus,
}) {
  const hasCampaign = campaignState.district && (campaignState.district.id !== undefined);
  const isPast = hasCampaign ? (isDatePast(campaignState.district.election_date) || isDatePast(campaignState.district.mail_date)) : false;

  const campaignClosed = isPast || campaignState.allAdopted;

  const detailBoxClassNames = ['bg-white', 'district-item', 'district-item-dashboard', 'mb-3', 'rounded', campaignClosed ? 'closed' : ''].join(' ');

  if (hasCampaign) {
    return (
      <VoterAdoptionTabs
        campaignClosed={ campaignClosed }
        campaignState={ campaignState }
        canAdoptSocial={ canAdoptSocial }
        detailBoxClassNames={ detailBoxClassNames }
        goToTraining={ goToTraining }
        handleAdoptionSuccess={ handleAdoptionSuccess }
        liveVoterCount={ liveVoterCount }
        maxQual={ maxQual }
        requestSuperqualStatus={ requestSuperqualStatus }
        setCampaignState={ setCampaignState }
        updateTraining={ updateTraining }
        user={ user }
      />
    );
  }
  if (currentDistrictPending) {
    return null;
  }
  return (<NoActiveCampaignsWindow detailBoxClassNames={ detailBoxClassNames } />);
}
