import { memo } from 'react';
import { Link } from 'react-router-dom';

import { message } from '../lib/deprecated_i18n';

import bigSendLogo from '../images/big-send/tbs_mark_3.png';

import '../scss/lib/_buttons.scss';
import '../scss/lib/_typography.scss';
import '../scss/big-send/_footer.scss';

const helpUrl = 'https://help.votefwd.org';

function Footer() {
  return (
    <footer className='container-fluid pb-4'>
      <div className='container-lg'>
        <div className='row align-items-center'>
          <div className='col-lg-2 col-12 order-1'>
            <Link className='d-block mb-2 mb-lg-0 mt-n3 pb-2 text-center' to='/'>
              <img alt='Vote Forward logo' src={ bigSendLogo } />
            </Link>
          </div>

          <div className='col-lg-5 col-12 order-md-2 order-3 pt-lg-1'>
            <p className='fs-8 lh-1 mb-lg-0 p-0 pb-1 text-white text-lg-start text-center'>
              { message('footer.copyright') }
            </p>
          </div>

          <div className='col-lg-5 col-12 order-sm-3 order-2 pt-lg-1'>
            <p className='justify-content-center justify-content-lg-end fs-8 gap-3 hstack mb-lg-0 p-0 pb-1 text-white'>
              <a href={ helpUrl } rel='noopener noreferrer' target='_blank'>Help</a>
              <Link to='/terms-of-use'>Terms</Link>
              <Link to='/privacy-policy'>Privacy&nbsp;Promise</Link>
              <Link to='/contact'>Contact</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default memo(Footer);

