import axios from 'axios';

let configPromise;


async function get(key) {
  if (!configPromise) {
    configPromise = axios({
      method: 'GET',
      headers: {},
      url: `${ process.env.REACT_APP_API_URL }/v1/config`,
    })
      .then((configFromAPI) => configFromAPI.data.appConfig);
  }
  const config = await configPromise;
  return config[key];
}

const deprecatedConfig = {
  get,
};

export default deprecatedConfig;
