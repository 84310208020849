
import t, { message } from '../../lib/deprecated_i18n';
import AvailableVoterCount from '../campaign-details/AvailableVoterCount';
import MailDate from '../../MailDate';
import ProgressBar from '../campaign-details/ProgressBar';
import VrbmFormNotice from '../campaign-details/VrbmFormNotice';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

function VoterAdoptionDetails({ children, campaign, availableVoters, isAdopterMaxedOut, isCampaignClosed, requestSuperqualStatus, updatedVoterCount }) {
  const auth = useAuth();

  return (
    <Fragment>
      <ProgressBar
        current={ updatedVoterCount }
        max={ campaign.voter_count || 0 }
      >
        <AvailableVoterCount
          availableVoters={ availableVoters }
          campaignClosed={ !!isCampaignClosed }
          trainingRequired={ !!campaign.training_required }
        />
      </ProgressBar>

      <h5>How it works</h5>
      <ol className='fs-7 fw-bold adoption-info-list' type='1'>
        <li className='fs-7 mb-0'>
          <span className='fw-normal'>
            <MailDate mailDate={ campaign.mail_date } mailDateFrom={ campaign.mail_date_from } mailDateType={ campaign.mail_date_type } short={ false } showTimingPrefix />
          </span>
        </li>
        <li>
          <p className='fw-normal'>
            <strong>Commitment: </strong>Voters you adopt won‘t be assigned to anyone else. That means { ' ' }
            <strong>you&rsquo;re committing to send the letters</strong>.
            Visit the <Link to='/instructions'>instructions page</Link> { ' ' }
            for details on how this works{ campaign.training_url
              ? (
                <Fragment>
                  { ' ' }
                  and the <a href={ campaign.training_url } rel='noreferrer' target='_blank'>training page</a> for information specific to this
                  campaign.
                </Fragment>
                )
              : '.' }
          </p>
        </li>
      </ol>

      <VrbmFormNotice campaignType={ campaign.type || '' } />

      { children }

      {
        isAdopterMaxedOut
          ? (
            <p className='highlight mt-3'>
              <strong>You&rsquo;ve adopted the maximum allowed number of voters &mdash; fantastic!</strong>
              { ' ' }
              To become a Super Volunteer and adopt more voters please
              { ' ' }
              <button
                className='fw-bold blue-link'
                id='requestSuperqualStatus'
                onClick={ requestSuperqualStatus }
              >{ t('contact.us') }
              </button>
              { ' ' }
              to request approval.
              { ' ' }
              { message('previous.messages.instructions', { email: auth.authenticatedEmail() }) }
              { ' ' }
              <button
                className='fw-bold blue-link'
                data-location='/previous-messages/'
                onClick={ requestSuperqualStatus }
              >{ t('here') }
              </button>
              .
            </p>
            )
          : null
      }
    </Fragment>
  );
}

VoterAdoptionDetails.propTypes = {
  availableVoters: PropTypes.number,
  campaign: PropTypes.object,
  children: PropTypes.any.isRequired,
  isAdopterMaxedOut: PropTypes.bool,
  isCampaignClosed: PropTypes.bool,
  requestSuperqualStatus: PropTypes.func,
  updatedVoterCount: PropTypes.number,
};

export default VoterAdoptionDetails;
