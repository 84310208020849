import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  DateField,
  Toolbar,
  SaveButton,
  Create,
  AutocompleteInput,
  SimpleForm,
  Filter,
  required,
  useNotify,
} from 'react-admin';

const UserRoleFilter = (props) => (
  <Filter { ...props }>
    <ReferenceInput
      alwaysOn
      filter={{ 'email:isNotNull': null, qualState: ['qualified', 'super_qualified', 'pre_qualified'] }}
      filterToQuery={ (email) => ({ 'email:contains': email }) }
      label='User'
      reference='Users'
      sort={{ field: 'email', order: 'ASC' }}
      source='users.id'
    >
      <AutocompleteInput optionText='email' />
    </ReferenceInput>
    <ReferenceInput
      allowEmpty
      alwaysOn
      filterToQuery={ (slug) => ({ 'slug:contains': slug }) }
      label='Partner'
      reference='Partners'
      sort={{ field: 'slug', order: 'ASC' }}
      source='partnerId'
    >
      <AutocompleteInput allowEmpty optionText='slug' />
    </ReferenceInput>
    <ReferenceInput filter={{ assignable: true }} label='Role' reference='Roles' source='roleSlug'>
      <SelectInput optionText='slug' validate={ [required()] } />
    </ReferenceInput>
    <ReferenceInput
      filterToQuery={ (email) => ({ 'email:contains': email }) }
      label='Created By'
      reference='Users'
      sort={{ field: 'email', order: 'ASC' }}
      source='adminUsers.id'
    >
      <AutocompleteInput optionText='email' />
    </ReferenceInput>

  </Filter>
);
export const UserRoleList = (props) => (
  <List { ...props } filters={ <UserRoleFilter /> } title='User Roles'>
    <Datagrid>
      <TextField source='id' />
      <TextField label='User' source='users.email' />
      <TextField label='Partner' source='partners.name' />
      <TextField label='Role' source='roleSlug' />
      <TextField label='Created By' source='adminUsers.email' />
      <DateField source='createdAt' />
    </Datagrid>
  </List>
);
const UserCreateToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton
      label='create'
    />
  </Toolbar>
);

export const UserRoleCreate = (props) => {
  const notify = useNotify();
  const errorHandler = (error, notify) => {
    if (error.status === 409) {
      notify(`Error: Role already exists`);
    } else {
      notify(`Error: ${ error.body || error.message }`);
    }
  };
  const onFailure = (error) => {
    errorHandler(error, notify);
  };
  return (
    <Create onFailure={ onFailure } { ...props } title='Create User Role'>
      <SimpleForm toolbar={ <UserCreateToolbar /> }>
        <ReferenceInput
          filter={{ 'email:isNotNull': null, qualState: ['qualified', 'super_qualified', 'pre_qualified'] }}
          filterToQuery={ (email) => ({ 'email:contains': email }) }
          label='User'
          reference='Users'
          sort={{ field: 'email', order: 'ASC' }}
          source='userId'
          validate={ [required()] }
        >
          <AutocompleteInput optionText='email' />
        </ReferenceInput>
        <ReferenceInput filter={{ assignable: true }} label='Role' reference='Roles' source='roleSlug'>
          <SelectInput optionText='slug' validate={ [required()] } />
        </ReferenceInput>
        <ReferenceInput
          allowEmpty
          filterToQuery={ (slug) => ({ 'slug:contains': slug }) }
          label='Partner'
          reference='Partners'
          sort={{ field: 'slug', order: 'ASC' }}
          source='partnerId'
        >
          <AutocompleteInput allowEmpty optionText='slug' />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default UserRoleList;
