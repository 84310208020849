
import { useState } from 'react';
import PropTypes from 'prop-types';

ManageSlideOut.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderLineItem: PropTypes.func.isRequired,
  slideOutId: PropTypes.string.isRequired,
  viewLabel: PropTypes.string.isRequired,
};

export default function ManageSlideOut({ slideOutId, viewLabel, renderLineItem, items }) {
  const [isSlideOutShown, setIsSlideOutShown] = useState({});

  return (
    <div className='row' >
      <input
        checked={ isSlideOutShown[slideOutId] }
        className='slide-out-toggle'
        id={ `toggle-${ slideOutId }` }
        onClick={ () => {
          setIsSlideOutShown({
            ...isSlideOutShown,
            [slideOutId]: !isSlideOutShown[slideOutId],
          });
        } }
        type='checkbox'
      />
      <label className='letter-info' htmlFor={ `toggle-${ slideOutId }` }>
        <span className='text-gray'>{ viewLabel }</span>
        <i className='fa fa-chevron-down slide-out-arrow' />
        <i className='fa fa-chevron-up slide-out-arrow' />
      </label>
      <div className='slide-out-list' >
        { isSlideOutShown[slideOutId] && items.map(renderLineItem) }
      </div>
    </div>
  );
}
