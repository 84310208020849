import React, { Component } from 'react';
import Header from './page/Header';
import Footer from './page/Footer';
import { destroyBeacon, initAndOpenBeacon, navigateBeacon } from './Beacon';
import { message } from './lib/deprecated_i18n';

import propTypes from 'prop-types';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';

class AuthError extends Component {
  static get propTypes() {
    return {
      auth: propTypes.object,
      location: propTypes.object,
    };
  }

  componentWillUnmount() {
    destroyBeacon();
  }

  openAuthErrorBeacon = (event) => {
    const { location, refCode } = event.currentTarget.dataset;
    initAndOpenBeacon({
      auth: this.props.auth,
      beaconArgs: {
        prefill: {
          subject: `Auth Error: ${ refCode }`,
        },
      },
    });
    if (location) {
      navigateBeacon(location);
    }
  };

  render() {
    const duplicateContext = queryString.parse(this.props.location.search);
    let providerText;
    let duplicateText;
    //
    if (duplicateContext.duplicate === duplicateContext.provider) {
      const refCode = `dupAccount-${ duplicateContext.referenceCode }-${ moment().format('YYYYMMDDhhmmss') }`;
      return (
        <React.Fragment>
          <Header />
          <div className='row h-60'>
            <div className='col-sm-4 col-10 mx-auto my-5 mb-2'>
              <h1 className='text-center'>Whoops!</h1>
              <p>
                It looks like you have a duplicate account in our system. Please either register using a different email address, or{ ' ' }
                <button
                  className='fw-bold blue-link'
                  data-location='/ask/message/'
                  data-ref-code={ refCode }
                  id='getHelp'
                  onClick={ this.openAuthErrorBeacon }
                >{ message('contact.us') }
                </button>.
                { ' ' }{ message('previous.messages.instructions', { email: this.props.auth.authenticatedEmail() }) }{ ' ' }
                <button
                  className='fw-bold blue-link'
                  data-location='/previous-messages/'
                  data-ref-code={ refCode }
                  onClick={ this.openAuthErrorBeacon }
                >{ message('here') }
                </button>.
              </p>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      );
    }

    if (duplicateContext.provider === 'google-oauth2') {
      providerText = 'clicked ‘Sign up with Google’ or ‘Log in with Google,’';
    } else if (duplicateContext.provider === 'facebook') {
      providerText =
        'clicked ‘Sign up with Facebook’ or ‘Log in with Facebook,’';
    } else {
      providerText = 'attempted to sign up with an email address and password,';
    }

    if (duplicateContext.duplicate === 'google-oauth2') {
      duplicateText = 'clicking ‘Log in with Google’';
    } else if (duplicateContext.duplicate === 'facebook') {
      duplicateText = 'clicking ‘Log in with Facebook’';
    } else {
      duplicateText = 'providing your email address and password';
    }

    return (
      <React.Fragment>
        <Header />
        <div className='row h-50'>
          <div className='col-sm-4 col-10 mx-auto my-5'>
            <h1 className='text-center'>Whoops!</h1>
            <p>
              We detected a problem, but it’s easy to fix. It looks like you{ ' ' }
              { providerText } but your email address is already registered in our
              system under an account you made previously by { duplicateText }.
            </p>
            <p>To gain access to your existing account, please:</p>
            <ol>
              <li>
                Return to <Link to='/'>our home page</Link>
              </li>
              <li>Click “Login to get started”</li>
              <li>
                <strong>Important:</strong> Click “Not Your Account?” to reset
                the login system.
              </li>
              <li>Log in by { duplicateText }.</li>
            </ol>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default AuthError;
