import PropTypes from 'prop-types';
import { useLetterBundleMutation, useUser } from '../../lib/queries/queries';
import { getUserId } from '../../lib/user';
import VoterAdoptionButton from './VoterAdoptionButton';

IndividualAdoptionButtons.propTypes = {
  campaign: PropTypes.object,
  isAdopterMaxedOut: PropTypes.bool,
  isCampaignClosed: PropTypes.bool,
  liveVoterCount: PropTypes.number,
  onAdoptionQuerySuccess: PropTypes.func,
};

export default function IndividualAdoptionButtons({
  campaign,
  isAdopterMaxedOut,
  isCampaignClosed,
  liveVoterCount,
  onAdoptionQuerySuccess,
}) {
  const letterBundleMutation = useLetterBundleMutation({ onSuccess: onAdoptionQuerySuccess });
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });

  return (
    <div>
      <div className='row default-adoption-options-row'>
        <VoterAdoptionButton
          campaign={ campaign }
          isAdopterMaxedOut={ isAdopterMaxedOut }
          isCampaignClosed={ isCampaignClosed }
          liveVoterCount={ liveVoterCount }
          subtitleMessage='5 letters • 15 min to prepare'
          voterAdoptionMutation={ letterBundleMutation }
          voterQuantity={ 5 }
        />
        <VoterAdoptionButton
          campaign={ campaign }
          isAdopterMaxedOut={ isAdopterMaxedOut }
          isCampaignClosed={ isCampaignClosed }
          liveVoterCount={ liveVoterCount }
          subtitleMessage='20 letters • 60 min to prepare'
          voterAdoptionMutation={ letterBundleMutation }
          voterQuantity={ 20 }
        />
      </div>
      { user.qual_state === 'super_qualified'
        ? (
          <div className='row'>
            <VoterAdoptionButton
              campaign={ campaign }
              isAdopterMaxedOut={ isAdopterMaxedOut }
              isCampaignClosed={ isCampaignClosed }
              liveVoterCount={ liveVoterCount }
              subtitleMessage='Super volunteers only'
              voterAdoptionMutation={ letterBundleMutation }
              voterQuantity={ 100 }
            />
            <VoterAdoptionButton
              campaign={ campaign }
              isAdopterMaxedOut={ isAdopterMaxedOut }
              isCampaignClosed={ isCampaignClosed }
              liveVoterCount={ liveVoterCount }
              subtitleMessage='Super volunteers only'
              voterAdoptionMutation={ letterBundleMutation }
              voterQuantity={ 500 }
            />
          </div>
          )
        : null }
    </div>
  );
}
