import propTypes from 'prop-types';
import logger from '../../lib/logger';
import t from '../../lib/deprecated_i18n';

NoActiveCampaignsWindow.propTypes = {
  detailBoxClassNames: propTypes.string.isRequired,
};

export default function NoActiveCampaignsWindow({
  detailBoxClassNames,
}) {
  logger.debug('Displaying No Active Campaigns message.');
  return (
    <div className={ detailBoxClassNames }>
      <div className='p-4'>
        <div className='mt-3 mb-3 mb-sm-5 p-2'>
          <h2 className='mb-3'>
            { t('campaign.picker.completed') }
          </h2>
          <p>
            { t('campaign.picker.checkback') }
          </p>
        </div>
      </div>
    </div>
  );
}
