import React, { Component } from 'react';
import Header from './page/Header';
import Footer from './page/Footer';
import EmailPreferencesForm from './EmailPreferencesForm';
import propTypes from 'prop-types';
import i18n from './locales/strings.json';


class EmailPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale || 'en',
      email: '',
      preferences: {
        reminders: true,
        newsletter: false,
        labs: false,
        unsubscribe: false,
        receive_fewer: false,
      },
    };

    if (props.match && props.match.params && props.match.params.composite_update_token) {
      this.state.composite_update_token = props.match.params.composite_update_token;
      this.state.email = window.atob(this.state.composite_update_token).split(':')[1];
      this.state.auth0_id = localStorage.getItem('user_id') || '';
    }
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
      match: propTypes.object,
    };
  }

  render() {
    return (
      <div>
        <Header />
        <br />
        <div className='row col d-flex justify-content-center'>
          <div className='card' style={{ width: '24rem' }}>
            <article className='card-body' style={{ display: 'block' }}>
              <img alt='logo' className='center-image' height='58px;' src='https://storage.googleapis.com/voteforward-production-static/bg-masthead.png' width='auto' />
              <h4 className='card-title mb-4 mt-1 text-center'>{ i18n[this.state.locale]['email.preferences.title'] }</h4>
              <div className='row'>
                <div className='col text-center'>
                  <span className='text-center'>{ this.state.email }</span>
                </div>
              </div>
              <hr />
              <EmailPreferencesForm
                auth={ this.props.auth }
                composite_update_token={ this.state.composite_update_token }
                locale={ this.props.locale }
              />
            </article>
          </div>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default EmailPreferences;
