import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { segmentTrack } from '../../../lib/segment';
import { useLettersMutation } from '../../../lib/queries/queries';

StatusChangeButton.propTypes = {
  buttonType: PropTypes.string,
  isBackwardMutation: PropTypes.bool,
  lettersToMutate: PropTypes.arrayOf(PropTypes.object).isRequired,
  mailOnDate: PropTypes.string,
  modifyAndDownloadState: PropTypes.object.isRequired,
  segmentTrackPropeties: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default function StatusChangeButton({
  isBackwardMutation = false,
  status,
  modifyAndDownloadState,
  lettersToMutate,
  segmentTrackPropeties,
  mailOnDate = '',
  buttonType = 'slideOutRow',
}) {
  const statusConfigs = {
    unprepped: {
      segmentTrackName: 'UpdateBundle:prepared',
      mutationFields: !isBackwardMutation && { confirmedPrepped: true },
      buttonTitle: 'Mark Prepared from Unprepared',
      type: {
        slideOutRow: {
          cssClassNames: {
            column: 'col side-col letter-text',
            button: 'btn-secondary-v2 btn-sm btn-letter-status indv-voter rounded-pill',
          },
        },
        columnCell: {
          extraButtonText: !isBackwardMutation && 'Mark Prepared',
          cssClassNames: {
            column: 'col',
            button: 'btn-bundle-status btn-secondary-v2 rounded-pill w-100 status-secondary',
          },
        },
      },
    },
    prepped: {
      segmentTrackName: isBackwardMutation ? 'UpdateBundle:unprepared' : 'UpdateBundle:sent',
      mutationFields: isBackwardMutation ? { confirmedPrepped: false } : { confirmedSent: true },
      buttonTitle: isBackwardMutation ? 'Mark Unprepared from Prepared' : 'Mark Sent from Prepared',
      disableBeforeDate: !isBackwardMutation,
      type: {
        slideOutRow: {
          cssClassNames: {
            column: 'col side-col letter-text',
            button: 'btn-secondary-v2 btn-sm btn-letter-status indv-voter rounded-pill',
          },
        },
        columnCell: {
          extraButtonText: !isBackwardMutation && 'Mark Sent',
          cssClassNames: {
            column: isBackwardMutation ? 'col side-col' : 'col',
            button: `btn-bundle-status rounded-pill ${ isBackwardMutation ? 'btn-secondary-v2 status-secondary' : 'btn-primary-v2 w-100' }`,
          },
        },
      },
    },
    sent: {
      segmentTrackName: 'UpdateBundle:prepared',
      mutationFields: isBackwardMutation && { confirmedSent: false },
      buttonTitle: 'Mark Prepared from Sent',
      type: {
        slideOutRow: {
          cssClassNames: {
            column: 'col side-col letter-text',
            button: 'btn-secondary-v2 btn-sm btn-letter-status indv-voter rounded-pill',
          },
        },
        columnCell: {
          cssClassNames: {
            column: 'col side-col',
            button: 'btn-bundle-status btn-secondary-v2 rounded-pill status-secondary',
          },
        },
      },
    },
  };
  const statusConfig = statusConfigs[status.toLowerCase()];
  const cssClassNames = statusConfig.type[buttonType].cssClassNames;

  // Mutations are defined here, rather than in parent components, because the mutationFields
  // are the same for a given status transition regardless of whether it's for a social bundle, individual letter,
  // or a bundle column cell.
  const mutation = useLettersMutation(
    modifyAndDownloadState,
    { letters: lettersToMutate },
    statusConfig.mutationFields,
  );
  if (!statusConfig.mutationFields) {
    return null;
  }
  const isDisabled = modifyAndDownloadState.isModifyDisabled || (statusConfig.disableBeforeDate && new Date(mailOnDate) > new Date());

  return (
    <div className={ classNames(cssClassNames.column) }>
      <button
        className={ classNames(cssClassNames.button) }
        disabled={ isDisabled }
        onClick={ () => {
          segmentTrack(statusConfig.segmentTrackName, segmentTrackPropeties);
          mutation.mutate();
        } }
        title={ (isDisabled && statusConfig.disableBeforeDate) ? ` Mail on ${ moment(mailOnDate).format('M/DD') }` : statusConfig.buttonTitle }
      >
        { statusConfig.type[buttonType].extraButtonText
          && (
            <span className={ `bundle-status-message ${ mutation.isLoading ? 'is-loading' : '' }` }>
              { statusConfig.type[buttonType].extraButtonText }
            </span>
          ) }
        {
          mutation.isLoading
            ? (
              <i className='fa fa-spin fa-spinner' />
              )
            : (
              <i className={ `fa fa-chevron-${ isBackwardMutation ? 'left' : 'right' }` } />
              )
        }
      </button>
    </div>
  );
}
