// Don't use this! Use Number.toLocaleString() instead
// Its browser support has caught up:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#browser_compatibility
//
function deprecated_formatWithCommas(number) {
  if (typeof number === 'undefined') {
    return '';
  } else {
    return number.toLocaleString();
  }
}

function formatBigNumber(value) {
  if (Number(value) >= 1.0e+6) {
    const millions = Number(value) / 1.0e+6;
    return `${ millions.toPrecision(3) }M`;
  } else if (Number(value) >= 1.0e+3) {
    const thousands = Number(value) / 1.0e+3;
    return `${ thousands.toPrecision(3) }K`;
  } else {
    return value;
  }
}

export {
  deprecated_formatWithCommas,
  formatBigNumber,
};
