import React, { Component } from 'react';
import propTypes from 'prop-types';

class PartnerAgreementTerms extends Component {
  render() {
    return (
      <div>
        <h4 style={{ textAlign: 'center' }}>VOTE FORWARD PARTNERSHIP AGREEMENT</h4>
        <br />
        <p>By reading these terms and clicking “I Agree,” you agree to the terms of this Agreement, as stated below, on behalf of your organization (the “Organization”).</p>
        <p />
        <table>
          <tbody>
            <tr>
              <td>
                <p>Name:</p>
              </td>
              <td style={{ paddingLeft: '30px' }}>
                <p>{ this.props.userFullName }</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Organization:</p>
              </td>
              <td style={{ paddingLeft: '30px' }}>
                <p>{ this.props.partnerName }</p>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <ol type='A'>
          <li>
            <h5 style={{ display: 'inline' }}><span>PURPOSE</span></h5>
            <p />
            <p>The purpose of this agreement (the “Agreement”) is to describe the terms under which Vote Forward and Organization (each a “Partner” and collectively the “Partners”) shall work together in support of Vote Forward&apos;s civic engagement campaigns (“Campaigns”).</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>RELATIONSHIP BETWEEN PARTNERS</span></h5>
            <p />
            <p>This Agreement shall not be deemed to create any relationship of agency, partnership or joint venture between the Partners. Each Partner shall control its own activities, and neither organization shall be responsible for the actions of the other in connection with the Campaigns.</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>ACTIVITIES OF PARTNERS</span></h5>
            <p />
            <p>The purpose of this Agreement will be accomplished by undertaking the following activities.</p>
            <p />
            <p>Organization agrees to:</p>
            <ol start={ 1 }>
              <li>Encourage volunteers to sign-up with the Campaigns by sharing information about the Campaigns on social media and via email and other channels at Organization&apos;s discretion.</li>
              <li>Direct individuals to sign-up at a unique URL (e.g., votefwd.org/[organization] (“Website”) provided to Organization by Vote Forward.</li>
              <li>Only send text messages to individuals who have opted in to receive text messages for the exclusive purpose of informing individuals about the Campaigns. Organization will be responsible for complying with all relevant requirements of federal and state law pertaining to the sending of text messages.</li>
            </ol>
            <p />
            <p>Vote Forward agrees to:</p>
            <ol start={ 1 }>
              <li>Provide Organization with visual assets, promotional copy, and other materials so that Organization may promote the Campaigns. Organization acknowledges that the Campaigns assets are owned by Vote Forward, and Organization will not claim any right or interest in any intellectual property or other proprietary rights in the Campaigns assets.</li>
              <li>Make available for download in CSV form a list of all email addresses and related data fields that the Organization drove to the Website so that Organization can follow up with volunteers.</li>
              <li>Operate and administer the Vote Forward website to support the activities of the volunteers.</li>
              <li>Ensure that all activities undertaken by Vote Forward in connection with any 501(c)(3) Campaigns are compliant with the IRS prohibition on political activity by 501(c)(3) organizations.</li>
            </ol>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>COORDINATION</span></h5>
            <p />
            <p>Vote Forward is on the “coordinated” side for purposes of federal campaign finance law and is able to coordinate with federal candidates and party committees to the extent permitted by federal law. Vote Forward shall not provide to Organization any non-public information regarding candidates or party committees or their campaigns. Organization is responsible for ensuring its own compliance with all relevant provisions of federal law. If Organization has established a firewall pursuant to 11 C.F.R. § 109.21, and notifies Vote Forward of the personnel on the coordinated side of the firewall, Vote Forward will only communicate with those personnel regarding the Campaigns.</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>LIABILITY</span></h5>
            <p />
            <p>No liability will be assumed between the Partners as a result of this Agreement. Organization assumes all risks and liabilities arising out of its use of any assets or data from the Campaigns.</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>GOVERNING LAW AND COMPLIANCE</span></h5>
            <p />
            <p>This Agreement shall be construed in accordance with the laws of the District of Columbia. Both parties agree to comply with all applicable laws, including but not limited to federal campaign finance laws, in conducting the activities described in this Agreement.</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>ASSIGNMENT</span></h5>
            <p />
            <p>Neither Partner may assign or transfer the responsibilities or agreement made herein without the prior written consent of the non-assigning Partner, whose approval shall not be unreasonably withheld.</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>ENTIRE AGREEMENT; AMENDMENT</span></h5>
            <p />
            <p>This Agreement sets forth the entire agreement between the parties, and replaces and supersedes all other contracts, agreements and understandings, written or oral, relating to the subject matter hereof. Vote Forward may amend the terms of this Agreement at any time, except that Vote Forward must provide Organization with 30 days&apos; advance notice of any material changes to the Agreement, whereupon Organization shall have 30 days to terminate the Agreement or shall be deemed to have accepted the amended terms. This Agreement may be amended or supplemented in writing, if the writing is signed by both Partners under this Agreement.</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>UNDERSTANDING BETWEEN PARTNERS</span></h5>
            <p />
            <p>It is mutually agreed upon and understood by and among the Partners of this Agreement that:</p>
            <p />
            <ol start={ 1 }>
              <li>Each Partner will work together in coordinated fashion for the fulfillment of the Campaigns;</li>
              <li>In no way does this Agreement restrict involved Partners from participating in similar agreements with other public or private agencies, organizations, or individuals;</li>
              <li>Organization acknowledges that the Website, Campaigns assets and data may contain errors, mistakes, or be out of date. Organization acknowledges that Vote Forward makes no representations about the suitability, reliability, timeliness, or accuracy of the information contained in the Campaigns assets and data. All information is provided “as is” and “as available” without warranty of any kind. Vote Forward disclaims all representations and warranties with regard to the Website, Campaigns assets and transferred data. Nothing in this Agreement shall obligate any Partner to the transfer of any funds. Any endeavor involving reimbursement or contribution of funds between the Partners of this Agreement will be handled in accordance with applicable federal campaign finance laws, regulations, and requirements.</li>
              <li>This Agreement is not intended to and does not create any right, benefit, or trust responsibility;</li>
              <li>This Agreement will be effective upon the acceptance of Organization by clicking the “I Agree” button below;</li>
              <li>Any Partner may terminate its participation in this Agreement by providing written notice to other Partner;</li>
            </ol>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>CONFIDENTIALITY</span></h5><p />
            <p>The Partners may not, directly or indirectly, at any time during the term of this Agreement or thereafter, and without regard to when or for what reason this Agreement terminates, divulge, furnish, make accessible, or permit the disclosure to anyone (other than the Parties or other persons employed or designated by the disclosing Party) any Confidential Information.</p>
            <p />
            <p>“Confidential Information” means any non-public knowledge or information of any type whatsoever acquired in the course of this Agreement, including, but not limited to, knowledge or information relating to the plans, strategies, business or activities of the Parties, business and activities relating to the exchange of data rendered under this Agreement, all originals, recorded, and unrecorded copies of Confidential Information (including information derived therefrom and portions thereof), all written or audio materials obtained, generated, produced or otherwise acquired during the course of this Agreement, notes, charts, plans, strategies, lists, computer files, electronic mail messages, phone logs or other memoranda (whether handwritten, typed, or otherwise created), data and other proprietary information related to this Agreement. Information shall be deemed to be Confidential Information even if no legal protection has been obtained or sought for such information under applicable laws and whether or not the receiving Party has been notified that such information is Confidential Information.</p>
            <p />
            <p>A receiving Party shall not be liable for disclosure of Confidential Information if such disclosure is pursuant to judicial action or other lawfully compelled disclosure, provided that the receiving Party notifies the disclosing Party, by certified mail, electronic mail, or facsimile machine of the need for such disclosure within five (5) days after such need becomes known and gives the disclosing Party a reasonable opportunity to contest such disclosure.</p>
            <p />
          </li>
          <li>
            <h5 style={{ display: 'inline' }}><span>SEVERABILITY; WAIVER</span></h5><p />
            <p>If any provision of this Agreement shall be held to be invalid or unenforceable for any reason, the remaining provisions shall continue to be valid and enforceable. The failure of either party to enforce any provision of this Agreement shall not be construed as a waiver or limitation of that party&apos;s right to subsequently enforce every provision of this Agreement.</p>
          </li>
        </ol>
      </div>
    );
  }
}

PartnerAgreementTerms.propTypes = {
  partnerName: propTypes.string,
  userFullName: propTypes.string,
};

export default PartnerAgreementTerms;
