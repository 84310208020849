import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MailingList from '../components/MailingList';
// import LeerEnEspañol from '../components/LeerEnEspanol';

function encodeTweet(text) {
  const encodedTweetIntentText = encodeURIComponent(text);
  return `https://twitter.com/intent/tweet?text=${ encodedTweetIntentText }`;
}

function PledgeConfirmation({ urlElectionInfo }) {
  const { t } = useTranslation('pledgeToVote');

  const tweetHref = useMemo(
    () => encodeTweet(t('makePledge.confirmation.tweetYourPlan.text')),
    [t],
  );

  return (
    <section className='container'>
      <div className='row justify-content-center'>
        <div className='col-12 col-lg-8 p-5'>
          <h1>
            { t('makePledge.confirmation.title') }
          </h1>
          <p>
            { t('makePledge.confirmation.lead') }
            { /*{ ' ' }*/ }
            { /*<LeerEnEspañol Element='span' />*/ }
          </p>
          <p>
            <a
              className='btn btn-info btn-lg w-100 mt-4'
              href={ urlElectionInfo }
              rel='noreferrer noopener'
              target='_blank'
            >
              { t('makePledge.confirmation.findPolling') }
            </a>
            <a
              className='btn btn-info btn-lg w-100 mt-4'
              href={ tweetHref }
              rel='noreferrer noopener'
              target='blank'
            >
              { t('makePledge.confirmation.tweetYourPlan.title') }
            </a>
            <Link
              className='btn btn-info btn-lg w-100 mt-4'
              to='/?utm_source=pledge'
            >
              { t('makePledge.confirmation.volunteer') }
            </Link>
          </p>
          <div className='card mt-5'>
            <div className='card-body'>
              <h3>
                { t('makePledge.confirmation.mailingList.title') }
              </h3>
              <p>
                { t('makePledge.confirmation.mailingList.lead') }
              </p>
              <MailingList />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

PledgeConfirmation.defaultProps = {
  urlElectionInfo: 'https://fwd.vote/',
};

PledgeConfirmation.propTypes = {
  urlElectionInfo: PropTypes.string,
};

export default PledgeConfirmation;
