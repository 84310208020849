import moment from 'moment';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import Loading from '../components/Loading';
import QAndA from '../components/QAndA';
import SupportVF from '../components/SupportVF';
import t from '../lib/deprecated_i18n';
import { getPosts } from '../CMSUtil';

import '../scss/pages/_impact2020.scss';

import happyVolunteerOne from '../images/impact2020/vf_eoy_1.jpg';
import happyVolunteerTwo from '../images/impact2020/vf_eoy_2.jpg';
import happyVolunteerThree from '../images/impact2020/vf_eoy_3.jpg';
import happyVolunteerFour from '../images/impact2020/vf_eoy_4.jpg';
import happyVolunteerFive from '../images/impact2020/vf_eoy_5.jpg';
import happyVolunteerSix from '../images/impact2020/vf_eoy_6.jpg';

function Impact2020() {
  const { data: labPosts } = useQuery(
    ['blog', 'get', { tag: 'impact2020' }],
    async () => await getPosts({ sortBy: 'title:asc', tag: 'impact2020' }),
  );

  return (
    <Fragment>
      <Helmet>
        <title> Impact 2020</title>
      </Helmet>

      <main className='container impact2020 p-lg-5 p-sm-3 pb-3 pt-2'>
        <section className='mb-5'>
          <h1 className='display-4'>{ t('impact2020.headline') }</h1>
          <p className='fw-bold'>{ t('impact2020.intro') }</p>
          <p>{ t('impact2020.p2') }</p>
        </section>

        <section className='mb-5'>
          <h2 className='h1'>{ t('impact2020.results.headline') }</h2>
          <p>{ t('impact2020.results.p1') }</p>
          <table className='table'>
            <tbody>
              <tr>
                <th className='p-0 py-3 pe-3 pe-sm-5'>{ t('impact2020.resultstable.row1.label') }</th>
                <td className='p-0 py-3 pe-1 pe-sm-2'>0.8 percentage points</td>
              </tr>
              <tr>
                <th className='p-0 py-3 pe-3 pe-sm-5'>{ t('impact2020.resultstable.row2.label') }</th>
                <td className='p-0 py-3 pe-1 pe-sm-2'>126,000 net votes</td>
              </tr>
            </tbody>
          </table>
          <p>{ t('impact2020.results.p2') }</p>
          <p>{ t('impact2020.results.p3') }</p>
          <p>{ t('impact2020.results.p4') }</p>
          <p>{ t('impact2020.results.p5') }</p>
          <p className='small'>{ t('impact2020.results.footnote.ai') }</p>
          <p>Questions about this analysis? <a href='#faqs'>Please review the FAQs below</a>.</p>
        </section>

        <SupportVF
          donationRefCode='impact2020'
          donationSlug='votefwd-2020'
        />

        <section>
          <h2 className='h1 pt-4'>{ t('impact2020.yearreview.headline') }</h2>
          <div className='container-lg d-flex my-4 my-md-5 promo-video px-0'>
            <div className='container-fluid mx-auto px-0'>
              <iframe
                allow='accelerometer; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                frameBorder='0'
                src='https://www.youtube.com/embed/Mb7M9QHi8IA?rel=0'
                title='Vote Forward 2020 Year in Review'
              />
            </div>
          </div>
        </section>

        <section className='mb-5'>
          <h2 className='h1'>{ t('impact2020.spotlight.headline') }</h2>
          <p>{ t('impact2020.spotlight.p1') }</p>
          <table className='table'>
            <thead>
              <tr>
                <th className='border-0 p-0 pe-3 pe-sm-5' scope='col'>{ t('impact2020.spotlight.state') }</th>
                <th className='border-0 p-0 pe-1 pe-sm-2' scope='col'>{ t('impact2020.spotlight.stateletters') }</th>
                <th className='border-0 p-0' scope='col'>{ t('impact2020.spotlight.votemargin') }</th>
              </tr>
            </thead>
            <tbody className=''>
              <tr>
                <td className='p-0 py-3 pe-3 pe-sm-5'>Arizona</td>
                <td className='p-0 py-3 pe-1 pe-sm-2'>879,994</td>
                <td className='p-0 py-3'>10,457</td>
              </tr>
              <tr>
                <td className='p-0 py-3 pe-3 pe-sm-5'>Georgia</td>
                <td className='p-0 py-3 pe-1 pe-sm-2'>1,550,406</td>
                <td className='p-0 py-3'>12,670</td>
              </tr>
              <tr>
                <td className='p-0 py-3 pe-3 pe-sm-5'>Wisconsin</td>
                <td className='p-0 py-3 pe-1 pe-sm-2'>291,074</td>
                <td className='p-0 py-3'>20,682</td>
              </tr>
            </tbody>
          </table>
          <p className='font-italic small mt-3'>
            { t('impact2020.spotlight.source') }:
            { ' ' }
            <a href='https://cookpolitical.com/2020-national-popular-vote-tracker' rel='noreferrer' target='_blank'>
              Cook Political
            </a>
          </p>
        </section>

        <section className='mb-5 state-spotlight-garunoff'>
          <h3>{ t('impact2020.spotlight.garunoff.h3') }</h3>
          <p>{ t('impact2020.spotlight.garunoff.blurb') }</p>
        </section>

        <section className='mb-5'>
          <h2 className='h1'>{ t('impact2020.labs.headline') }
          </h2>
          <p>{ t('impact2020.labs.p1') }</p>
          <p>{ t('impact2020.labs.p2') }</p>
          <p>{ t('impact2020.labs.p3') }</p>
          <div className='container p-0 pe-3'>
            <div className='mt-4 mt-lg-5 mx-0 row'>
              {
                labPosts
                  ? labPosts.map((post) => (
                    <div className='col-xs-12 col-lg-4 mb-3' key={ post.slug }>
                      <Link className='labs-report-card' to={ `/posts/${ post.slug || post.id }` }>
                        <div className='title'>{ post.title }</div>
                        <div className='date'>{ moment(post.content_date || post.created_at).format('MMMM YYYY') }</div>
                      </Link>
                    </div>
                  ))
                  : <Loading style={{ margin: '0 auto' }} />
              }
            </div>
          </div>
        </section>

        <section className='mb-5'>
          <h2 className='h1'>{ t('impact2020.partners.headline') }</h2>
          <p>{ t('impact2020.partners.p1') }</p>
          <p>{ t('impact2020.partners.p2') }</p>

          <h3 className='py-1 py-sm-3 py-md-4'>{ t('impact2020.partners.bigsend.headline') }</h3>
          <p>{ t('impact2020.partners.bigsend.p1') }</p>

          <h3 className='py-1 py-sm-3 py-md-4'>{ t('impact2020.partners.bigsendwork.headline') }</h3>
          <p>{ t('impact2020.partners.bigsendwork.p1') }</p>
        </section>

        <section className='mb-5'>
          <h2 className='h1'>{ t('impact2020.thankyou.headline') }</h2>
          <p>{ t('impact2020.thankyou.p1') }</p>

          <div className='my-4 my-lg-5 row'>
            <div className='col-4 mb-1 mb-md-3'>
              <img alt='Child holding letters in front of mailbox' className='img-fluid' src={ happyVolunteerOne } />
            </div>
            <div className='col-4 mb-1 mb-md-3'>
              <img alt='Smiling volunteer holding box of letters' className='img-fluid' src={ happyVolunteerTwo } />
            </div>
            <div className='col-4 mb-1 mb-md-3'>
              <img alt='Volunteer placing letters in blue mailbox' className='img-fluid' src={ happyVolunteerThree } />
            </div>
          </div>
          <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote'>
            { t('impact2020.quote1.sally') }
          </p>
          <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote-name'>
            Sally Y. / Arizona
          </p>

          <div className='my-4 my-lg-5 row'>
            <div className='col-4 mb-1 mb-md-3'>
              <img alt='Volunteer in yellow dress with letters making peace sign.' className='img-fluid' src={ happyVolunteerFour } />
            </div>
            <div className='col-4 mb-1 mb-md-3'>
              <img alt='Stacks of colorful envelopes arranged to spell V-O-T-E' className='img-fluid' src={ happyVolunteerFive } />
            </div>
            <div className='col-4 mb-1 mb-md-3'>
              <img alt='Two smiling volunteers holding a box of letters' className='img-fluid' src={ happyVolunteerSix } />
            </div>
          </div>
          <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote'>
            { t('impact2020.quote2.jim') }
          </p>
          <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote-name'>
            Jim T. / Colorado
          </p>
        </section>

        <section className='faqs mb-5 pt-3'>
          <h2 id='faqs'>Frequently Asked Questions</h2>
          {
              ['q1', 'q2', 'q3', 'q4', 'q5'].map((question) => (
                <QAndA
                  answer={ t(`impact2020.${ question }.outcome.a`) }
                  key={ question }
                  question={ t(`impact2020.${ question }.outcome.q`) }
                />
              ))
            }
        </section>

        <section>
          <h2 className='h1'>{ t('impact2020.support.headline') }</h2>
          <p className='mb-4 mb-md-5'>{ t('impact2020.support.p1') }</p>
          <div className='mb-4 mb-md-5 text-center'>
            <a
              className='btn btn-donate btn-lg'
              href='https://secure.actblue.com/donate/votefwd-2020?refcode=impact2020'
              rel='noopener noreferrer'
              target='_blank'
            >
              <span className='h3 px-3 px-sm-5'>
                { t('impact2020.support.button.label') }
              </span>
            </a>
          </div>
        </section>
      </main>
    </Fragment>
  );
}

export default Impact2020;
