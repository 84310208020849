import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import metaImage from '../images/big-send/tbs_meta.png';

function PageHead({ stringsetSlug }) {
  const { t } = useTranslation('big-send');
  const baseUrl = process.env.REACT_APP_URL;
  const imageUrl = baseUrl + metaImage;

  return (
    <Helmet titleTemplate='%s'>
      <title>{ t(`titles.${ stringsetSlug }`) }</title>

      { /* General */ }
      <meta content={ imageUrl } name='image' />
      <meta content={ t('meta.title') } name='description' />

      { /* Google */ }
      <meta content={ imageUrl } itemProp='image' />
      <meta content={ t('meta.description') } itemProp='description' />
      <meta content={ t('meta.title') } itemProp='name' />

      { /* Twitter */ }
      <meta content={ imageUrl } name='twitter:image' />
      <meta content={ t('meta.description') } name='twitter:description' />
      <meta content={ t('meta.imageAlt') } name='twitter:image:alt' />
      <meta content={ t('meta.title') } name='twitter:title' />
      <meta content={ t('twitter.card', { ns: 'social' }) } name='twitter:card' />
      <meta content={ t('twitter.site', { ns: 'social' }) } name='twitter:site' />

      { /* Open Graph general (Facebook, Pinterest & Google+) */ }
      <meta content={ imageUrl } name='og:image' />
      <meta content={ t('meta.description') } name='og:description' />
      <meta content={ t('og.type', { ns: 'social' }) } name='og:type' />
      <meta content={ t('meta.title') } name='og:title' />
      <meta content={ t(`meta.urls.${ stringsetSlug }`) } name='og:url' />
      <meta content={ t(`titles.${ stringsetSlug }`) } name='og:site_name' />

      { /* Facebook */ }
      <meta content={ t('facebook.admins', { ns: 'social' }) } name='fb:admins' />
    </Helmet>
  );
}

PageHead.propTypes = {
  stringsetSlug: PropTypes.string,
};

export default memo(PageHead);
