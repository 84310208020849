import { Link } from 'react-router-dom';

import t from '../lib/deprecated_i18n';

import '../scss/lib/_backgrounds.scss';
import '../scss/lib/_buttons.scss';
import '../scss/lib/_headings.scss';
import '../scss/home/_callouts.scss';

import homeResults from '../images/home/vf_home_results_blue.png';
import votedChart from '../images/home/vf_voted_chart.png';

function Callouts() {
  return (
    <section className='bg-light callouts'>
      <div className='container-fluid p-0'>
        <div className='no-gutters row'>
          <div className='col-lg-6'>
            <img
              alt='Chart of percentage point increases from experiments (+4.2pp, +2.8pp, +3.4pp, +1.4pp, +1.9pp)'
              className='callout-img img-fluid'
              src={ votedChart }
            />
          </div>
          <div className='col-lg-6 my-auto callout-text'>
            <h2>{ t('home.impact.header') }</h2>
            <p className='lead mb-2 pe-2'>
              { t('home.impact.description') }
            </p>
            <Link className='btn btn-primary-blue my-3 px-5' to='/impact'>
              { t('home.impact.button') }
            </Link>
          </div>
        </div>

        <div className='big-send-results no-gutters row'>
          <div className='col-lg-6 my-auto callout-text'>
            <h2>{ t('home.bigsendresults.header') }</h2>
            <p className='lead mb-2 pe-2'>
              { t('home.bigsendresults.description') }
            </p>
            <Link className='btn btn-primary-blue my-3 px-5' to='/impact2020'>
              { t('home.bigsendresults.button') }
            </Link>
          </div>
          <div className='col-lg-6'>
            <Link to='/impact2020'>
              <img alt='The Big Send 2020 Results' className='callout-img img-fluid mt-4' src={ homeResults } />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Callouts;
