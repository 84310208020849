import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import Header from './page/Header';
import Footer from './page/Footer';
import Loading from './components/Loading';
import logger from './lib/logger';
import { message } from './lib/deprecated_i18n';
import { Link } from 'react-router-dom';

function SuccessfulSubmission() {
  return (
    <Fragment>
      <p>
        <strong>{ message('relinquish.success.p1') }</strong>
      </p>
      <p>
        { message('relinquish.success.p2') }{ ' ' }
        <a href='https://secure.actblue.com/donate/vote-forward-c4?refcode=relinquish'>
          { message('relinquish.success.link') }
        </a>.
      </p>
    </Fragment>
  );
}

class RelinquishLetters extends Component {
  static get propTypes() {
    return {
      match: propTypes.shape({
        params: propTypes.shape({
          composite_update_token: propTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      submissionError: false,
      submissionSuccess: false,
      unpreppedCount: null,
      unsentCount: null,
      blockingError: null,
    };
  }

  get compositeUpdateToken() {
    return this.props.match.params.composite_update_token;
  }

  async fetchUnpreppedLetters() {
    const request = {
      method: 'GET',
      url: `${ process.env.REACT_APP_API_URL }/v1/emails/preferences`,
      params: {
        composite_update_token: this.compositeUpdateToken,
      },
    };
    try {
      const response = await axios(request);
      if (!response.data) { throw new Error('No data in response'); }
      this.setState({
        unpreppedCount: response.data.unpreppedCount,
        unsentCount: response.data.unsentCount,
      });
    } catch (error) {
      logger.error(error);
      const blockingError = (error.response && error.response.status === 404)
        ? message('relinquish.error.notfound')
        : message('relinquish.error.generic');
      this.setState({ blockingError });
    }
  }

  async wrapSubmission(fn) {
    this.setState({
      submitting: true,
      submissionSuccess: false,
      submissionError: false,
    });
    try {
      await fn();
      this.setState({
        submitting: false,
        submissionSuccess: true,
        submissionError: false,
      });
    } catch (error) {
      logger.error(error);
      this.setState({
        submitting: false,
        submissionSuccess: false,
        submissionError: true,
      });
    }
  }

  relinquishAllLetters = () => this.wrapSubmission(() => {
    const request = {
      method: 'POST',
      url: `${ process.env.REACT_APP_API_URL }/v1/emails/preferences`,
      data: {
        composite_update_token: this.compositeUpdateToken,
        relinquishUnsentLetters: true,
      },
    };
    return axios(request);
  });

  relinquishUnpreppedLetters = () => this.wrapSubmission(() => {
    const request = {
      method: 'POST',
      url: `${ process.env.REACT_APP_API_URL }/v1/emails/preferences`,
      data: {
        composite_update_token: this.compositeUpdateToken,
        relinquishUnpreppedLetters: true,
      },
    };
    return axios(request);
  });

  async componentDidMount() {
    await this.fetchUnpreppedLetters();
    this.setState({ loading: false });
  }

  renderBody() {
    const {
      unpreppedCount,
      unsentCount,
      blockingError,
      submitting,
      submissionError,
      submissionSuccess,
    } = this.state;

    if (blockingError) {
      return <p>{ blockingError }</p>;
    }

    if (submissionSuccess) {
      return <SuccessfulSubmission />;
    }

    if (unpreppedCount === 0) {
      return (
        <Fragment>
          <p>{ message('relinquish.nounprepared.p1') }</p>
          <Link
            className='btn btn-primary mt-4 d-block w-100'
            to='/dashboard/'
          >
            { message('relinquish.nounprepared.link') }
          </Link>
        </Fragment>
      );
    }

    const submissionErrorAlert = submissionError
      ? (
        <div className='mt-3 alert alert-danger'>
          { message('relinquish.error.generic') }
        </div>
        )
      : null;
    const counts = unsentCount
      ? message('relinquish.count.all', { unsentCount, unpreppedCount })
      : message('relinquish.count.unprepped', { unpreppedCount });

    const unsentPrompt = unsentCount
      ? (
        <p>
          { message('relinquish.body.p3') }
        </p>
        )
      : null;

    const unsentButton = unsentCount
      ? (
        <button
          className='btn btn-link mt-3 d-block w-100'
          disabled={ submitting }
          onClick={ this.relinquishAllLetters }
        >
          { message('relinquish.button.all', { totalCount: unpreppedCount + unsentCount }) }
        </button>
        )
      : null;
    return (
      <Fragment>
        <p>
          <strong>{ counts }</strong>
        </p>
        <p>
          { message('relinquish.body.p1') }{ ' ' }
          <Link to='/dashboard'>{ message('relinquish.body.link') }</Link>
        </p>
        <p>
          { message('relinquish.body.p2', { unpreppedCount }) }
        </p>
        <button
          className='btn btn-primary mt-4 mb-4 d-block w-100'
          disabled={ submitting }
          onClick={ this.relinquishUnpreppedLetters }
        >
          { message('relinquish.button.unprepped', { unpreppedCount }) }
        </button>
        { unsentPrompt }
        { unsentButton }
        { submissionErrorAlert }
      </Fragment>
    );
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Fragment>
        <Header />
        <div className='my-4 col d-flex justify-content-center p-0'>
          <div className='card' style={{ maxWidth: '24rem' }}>
            <div className='card-body d-block'>
              <img
                alt='logo'
                className='center-image'
                height='58px;'
                src='https://storage.googleapis.com/voteforward-production-static/bg-masthead.png'
                width='auto'
              />
              <h4 className='card-title mb-4 mt-1 text-center'>
                { message('relinquish.title') }
              </h4>
              <hr />
              <div className='mt-4'>
                { this.renderBody() }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default RelinquishLetters;
