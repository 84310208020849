import PropTypes from 'prop-types';

import t from '../../lib/deprecated_i18n';

function AvailableVoterCount({ availableVoters, campaignClosed, trainingRequired }) {
  if (campaignClosed) {
    return (
      <div className='available-voter-count'>
        Campaign closed
      </div>
    );
  }
  if (trainingRequired) {
    return null;
  }
  return (
    <div className='available-voter-count'>
      <strong>{ availableVoters.toLocaleString() }</strong> { t('adopt.voters.still.available') }
    </div>
  );
}

AvailableVoterCount.propTypes = {
  availableVoters: PropTypes.number.isRequired,
  campaignClosed: PropTypes.bool.isRequired,
  trainingRequired: PropTypes.bool.isRequired,
};

export default AvailableVoterCount;
