import axios from 'axios';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';

import logger from '../lib/logger';
import LeerEnEspañol from '../components/LeerEnEspanol';

class MakePledge extends Component {
  handleVotePledge = async (event) => {
    event.preventDefault();
    try {
      await axios.post(
        `${ process.env.REACT_APP_API_URL || '' }/voter/pledge`,
        { code: this.props.code },
      );
    } catch (error) {
      logger.error(error);
    } finally {
      this.props.handlePledgeThanks();
    }
  };

  render() {
    const optOutLinkPrefilled = `/opt-out/${ this.props.code }`;

    return (
      <section className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-8 p-5'>
            <form onSubmit={ this.handleVotePledge }>
              <h1>
                { this.props.t('makePledge.title') }
              </h1>
              <p className='lead'>
                { this.props.t('makePledge.registrationSummary', { state: this.props.voterState }) }
                { ' ' }
                <LeerEnEspañol Element='span' />
              </p>
              <div className='mt-4 pt-2'>
                <h2>
                  { this.props.t('makePledge.pledge.title') }
                </h2>
                <p>
                  { this.props.t('makePledge.pledge.lead') }
                </p>
                <p className='small'>
                  <Trans i18nKey='makePledge.pledge.note' t={ this.props.t }>
                    Note: we’ll <strong>anonymously</strong> inform the volunteer who wrote to you that one of their
                    addressees is planning to vote. It will mean a lot to them!
                  </Trans>
                </p>
                <input
                  className='btn btn-primary btn-lg w-100'
                  type='submit'
                  value={ this.props.t('makePledge.pledge.button') }
                />
              </div>
              <div className='mt-4 pt-2'>
                <h2>
                  { this.props.t('makePledge.electionInfo.title') }
                </h2>
                <p>
                  { this.props.t('makePledge.electionInfo.lead', { state: this.props.voterState }) }
                </p>
                <a
                  className='btn btn-info btn-lg w-100'
                  href={ this.props.urlElectionInfo }
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  { this.props.t('makePledge.electionInfo.button') }
                </a>
              </div>
              <div className='mt-4 pt-2'>
                <h2>
                  { this.props.t('makePledge.optOut.title') }
                </h2>
                <p>
                  { this.props.t('makePledge.optOut.lead') }
                </p>
                <Link
                  className='btn btn-secondary btn-lg w-100'
                  to={ optOutLinkPrefilled }
                >
                  { this.props.t('makePledge.optOut.button') }
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

MakePledge.propTypes = {
  code: PropTypes.string.isRequired,
  handlePledgeThanks: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  urlElectionInfo: PropTypes.string.isRequired,
  voterState: PropTypes.string.isRequired,
};

export default withTranslation('pledgeToVote')(MakePledge);
