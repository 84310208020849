import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import logger from '../lib/logger';
import Tracker from '../lib/Tracker';
import { AuthContext } from '../contexts/AuthContext';

import '../scss/lib/_forms.scss';

class MailingList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      formSubmitSuccess: false,
    };
    this.tracker = new Tracker({ auth: this.context });
  }

  submitMailingList = async () => {
    const payload = {
      email: this.state.email,
      newsletter: true,
    };
    this.setState({ emailError: '' });
    if (this.state.email === '') {
      this.setState({ emailError: this.props.t('error.required') });
    } else if (!this.validateEmail(this.state.email)) {
      this.setState({ emailError: this.props.t('error.valid') });
    } else {
      try {
        await axios.post(`${ process.env.REACT_APP_API_URL || '' }/v1/emails/mailing-lists`, payload);
        this.setState({ formSubmitSuccess: true });
        this.tracker.logEvent('EmailPreferences:function:mailingList', { status: 200 });
      } catch (error) {
        logger.error(error);
        this.setState({ emailError: this.props.t('error.signUp') });
        this.tracker.logEvent('EmailPreferences:function:mailingList', { status: 500 });
      }
    }
  };

  validateEmail(email) {
    // eslint-disable-next-line regexp/no-unused-capturing-group
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleChange = (event) => {
    this.setState({ emailError: '' });
    this.setState({ email: event.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submitMailingList();
    }
  };

  render() {
    return this.state.formSubmitSuccess
      ? (
        <p className='pt-3 text-center text-purple'>
          { this.props.t('success', { email: this.state.email }) }
        </p>
        )
      : (
        <div>
          <input
            className='form-control me-2 mb-2 mb-md-0 pt-2 pb-2'
            name='email'
            onChange={ this.handleChange }
            onKeyDown={ this.handleKeyDown }
            placeholder={ this.props.t('placeholder') }
            type='email'
            value={ this.state.email }
          />
          {
            this.state.emailError === ''
              ? null
              : (
                <p className='pt-2 text-center text-danger'>
                  { this.state.emailError }
                </p>
                )
          }
          <button
            className={ classNames('btn btn-primary col-12 col-sm-4 d-block mt-3 mx-auto', { disabled: this.state.disableButton }) }
            disabled={ this.state.disableButton }
            onClick={ this.submitMailingList }
            style={{ color: 'white' }}
          >
            { this.props.t('button') }
          </button>
        </div>
        );
  }
}

MailingList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('mailingList')(MailingList);
