import { message } from './lib/deprecated_i18n';
import deprecatedConfig from './lib/deprecatedConfig';
import axios from 'axios';

export function destroyBeacon() {
  window.Beacon('destroy');
}
export function initAndOpenBeacon(initArgs = {}) {
  initBeacon(initArgs);
  openBeacon();
}
export async function initBeacon({ type = 'help', auth, beaconArgs = {}} = {}) {
  // looking for a key like 'helpBeaconId'
  const beaconId = await deprecatedConfig.get(`${ type }BeaconId`);
  window.Beacon('init', beaconId);
  if (auth.isAuthenticated()) {
    const accessToken = await auth.getAccessToken();
    const result = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/users/self/beaconInfo`, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      params: {
        type,
      },
    });
    window.Beacon('identify', result.data);
  }
  const beaconConfig = beaconArgs.config || {};
  window.Beacon('prefill', beaconArgs.prefill || { subject: '' });
  window.Beacon('config', {
    ...beaconConfig,
    color: '#00e6ff',
    labels: {
      noTimeToWaitAround: message('beacon.response.time'),
      responseTime: message('beacon.response.time'),
      messageConfirmationText: message('beacon.response.time'),
    },
  });
}
export function openBeacon() {
  window.Beacon('open');
}
export function navigateBeacon(location) {
  window.Beacon('navigate', location);
}
