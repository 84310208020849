import React, { Component } from 'react';
import propTypes from 'prop-types';

class ProgressIndicator extends Component {
  setWidth() {
    return this.props.max > 0 ? `${ (this.props.current / this.props.max) * 100 }%` : 0;
  }

  static get propTypes() {
    return {
      current: propTypes.number,
      max: propTypes.number,
    };
  }

  render() {
    return (
      <div className='mt-1 p-4 bg-light border-top'>
        <div className='progress'>
          <div
            aria-valuemax={ this.props.max }
            aria-valuemin='0'
            aria-valuenow={ this.props.current }
            className='progress-bar bg-success'
            role='progressbar'
            style={{ width: this.setWidth() }}
          />
        </div>
      </div>
    );
  }
}

export default ProgressIndicator;
