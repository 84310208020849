import PropTypes from 'prop-types';

function VrbmFormNotice({ campaignType }) {
  if (campaignType === 'vrbm-form' || campaignType === 'vrbm-no9' || campaignType === 'vrbm-form-labs') {
    return (
      <p className='mb-2'>
        <i className='fa fa-exclamation-circle' />
        { ' ' }
        Registration Forms Enclosed. Please review <a href='/vf-instructions-vr-form.pdf'>instructions</a> carefully.
      </p>
    );
  }
  return null;
}

VrbmFormNotice.propTypes = {
  campaignType: PropTypes.string.isRequired,
};

export default VrbmFormNotice;
