import React, { Component } from 'react';
import Modal from './Modal';
import Loading from './Loading';
import PartnerAgreementTerms from './documents/PartnerAgreementTerms';
import propTypes from 'prop-types';
import { getNextSignablePartner, signNextPartner, updateNextSignablePartner } from '../lib/unsignedPartners';

const SCROLL_RATIO_THRESHOLD = 0.99;

class ClickToAgree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showSpinner: false,
      agreementModalTitle: '',
      nextSignablePartner: getNextSignablePartner(),
    };
  }

  render() {
    if (!this.state.nextSignablePartner || !this.state.nextSignablePartner.slug || this.state.nextSignablePartner.slug === '') {
      return null;
    }
    return (
      <div>
        <div className='announcement-banner partner-terms'>
          <p>
            <span>
              One or more Partners you administer have unsigned Partner Agreements.
            </span>
            { ' ' }
            <a
              className='fw-bold btn-link-underline'
              onClick={ this.showModal }
            >
              View and sign Partner Agreements.
            </a>
          </p>
        </div>
        <Modal
          hideModal={ this.hideModal }
          modalBlockContentJsx={
            (this.state.nextSignablePartner && !this.state.showSpinner)
              ? this.generateTerms(this.state.nextSignablePartner.name)
              : (<Loading />)
          }
          modalButtons={ this.generateModalButtons() }
          modalClassName='partner-terms'
          modalTitle={ this.state.agreementModalTitle }
          onBodyScroll={ this.onBodyScroll }
          showModal={ this.state.showModal }
        />
      </div>
    );
  }

  showModal = async () => {
    this.setState({
      agreementModalTitle: 'Checking for unsigned Partner Agreements...',
      showModal: true,
      showSpinner: true,
    });
    // Need to retrieve the next unsigned partner at runtime,
    // because another admin may have already signed the agreement after this admin logged in.
    await updateNextSignablePartner(this.props.auth);
    const nextSignablePartner = getNextSignablePartner();
    this.setState({
      agreementModalTitle: `Partner Agreement: ${ nextSignablePartner ? nextSignablePartner.name : '' }`,
      nextSignablePartner,
      showSpinner: false,
    });
  };

  generateTerms = (partnerName) => (
    <PartnerAgreementTerms
      partnerName={ partnerName }
      userFullName={ this.props.auth.getUserName() }
    />
  );

  hideModal = (() => {
    this.setState({ agreementBottomReached: false, showModal: false });
  });

  onBodyScroll = ((event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const scrollRatio = scrollTop / (scrollHeight - clientHeight);

    if (scrollRatio >= SCROLL_RATIO_THRESHOLD) {
      this.setState({ agreementBottomReached: true });
    }
  });

  generateModalButtons = () => (
    <div style={{ width: '100%' }}>
      <button
        className='btn btn-secondary'
        data-dismiss='modal'
        onClick={ this.hideModal }
        style={{ float: 'left' }}
        type='button'
      >
        Close
      </button>

      <div style={{ float: 'right' }} >
        <button
          className='btn btn-secondary'
          data-dismiss='modal'
          disabled={ this.state.showSpinner || !this.state.nextSignablePartner || !this.state.agreementBottomReached }
          onClick={ this.signAgreement }
          type='button'
        >
          I Agree
        </button>
      </div>
    </div>
  );

  signAgreement = async () => {
    this.setState({ showSpinner: true, agreementModalTitle: 'Signing Partner Agreement...' });
    await signNextPartner(this.props.auth);
    this.setState({ agreementBottomReached: false, showModal: false, showSpinner: false, nextSignablePartner: getNextSignablePartner() });
  };
}

ClickToAgree.propTypes = {
  auth: propTypes.object,
};

export default ClickToAgree;
