import { Component } from 'react';

import EnterCode from './EnterCode';
import MakePledge from './MakePledge';
import PledgeConfirmation from './PledgeConfirmation';

class PledgeToVote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pledgeStatus: '', // possible values '' and 'thanks'
      voterState: '',
      code: '',
      urlElectionInfo: '',
    };
  }

  handlePledgeThanks = () => {
    this.setState({ pledgeStatus: 'thanks' });
  };

  handleVoterInfo = (payload) => {
    this.setState(payload);
  };

  render() {
    return (
      <main className='bg-white py-5 mb-5'>
        {
          this.state.codeSubmitted
            ? null
            : <EnterCode handleVoterInfo={ this.handleVoterInfo } />
        }
        {
          this.state.codeSubmitted && this.state.pledgeStatus !== 'thanks'
            ? (
              <MakePledge
                code={ this.state.code }
                handlePledgeThanks={ this.handlePledgeThanks }
                urlElectionInfo={ this.state.urlElectionInfo }
                voterState={ this.state.voterState }
              />
              )
            : null
        }
        {
          this.state.pledgeStatus === 'thanks'
            ? <PledgeConfirmation urlElectionInfo={ this.state.urlElectionInfo } />
            : null
        }
      </main>
    );
  }
}

export default PledgeToVote;
