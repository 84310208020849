/* eslint-disable import/no-named-as-default-member */

// Thanks, https://vanja.gavric.org/blog/integrate-google-analytics-with-react-router-v4/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search,
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${ location.origin }${ page }`,
      ...this.props.options,
    });
    ReactGA.pageview(page);
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
      });
    }
  }

  render() {
    return null;
  }
}

function trackEvent({ category, action, label, value }) {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: category,
      eventProps: {
        action,
        label,
        value,
      },
    });
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.object,
};

const RouteTracker = () => <Route component={ GoogleAnalytics } />;

const init = (options = {}) => {
  const isGAEnabled = !!process.env.REACT_APP_GA_TRACKING_ID;

  if (isGAEnabled) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      debug: process.env.REACT_APP_GA_DEBUG === 'true',
      ...options,
    });
  }

  return isGAEnabled;
};

const GA = {
  GoogleAnalytics,
  RouteTracker,
  trackEvent,
  init,
};

export default GA;
