import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function DashboardStats({ adoptedVoters, alreadySent, toSend, uniqueStates }) {
  return (
    <div className='col-xl-6 p-sm-5 p-3 dashboard-stats-panel'>
      <h2 className='mb-5'>Current campaign stats:</h2>
      <div className='row stats-row'>
        <div className='col-xs-12 col-lg-4 mb-3'>
          <h6>
            <span className='side-by-side'>Voters you&rsquo;ve<br />adopted:</span>
            <span className='stacked'>Voters you&rsquo;ve adopted:</span>
          </h6>
          <div>{ adoptedVoters }</div>
        </div>
        <div className='col-xs-12 col-lg-4 mb-3'>
          <h6>
            <span className='side-by-side'>Letters you&rsquo;ve<br />prepared:</span>
            <span className='stacked'>Letters you&rsquo;ve prepared:</span>
          </h6>
          <div>{ toSend + alreadySent }</div>
        </div>
        <div className='col-xs-12 col-lg-4 mb-3'>
          <h6>
            <span className='side-by-side'>Letters you&rsquo;ve<br />sent:</span>
            <span className='stacked'>Letters you&rsquo;ve sent:</span>
          </h6>
          <div>{ alreadySent }</div>
        </div>
      </div>
      <hr />
      <div className='row stats-row'>
        <div className='col-xs-12 mb-3'>
          <h6>Places you&rsquo;re writing to:</h6>
          <div>{ uniqueStates.join(', ') }</div>
        </div>
      </div>
      <hr />
      <div className='row stats-info'>
        <div className='col-xs-12 mb-3'>
          Want to see stats from campaigns you&rsquo;ve worked on in the past?
          { ' ' }
          <br className='d-none d-lg-block' />
          Check out your <Link to='/history'>Vote Forward history</Link>.
        </div>
      </div>
    </div>
  );
}

DashboardStats.propTypes = {
  adoptedVoters: PropTypes.number.isRequired,
  alreadySent: PropTypes.number.isRequired,
  toSend: PropTypes.number.isRequired,
  uniqueStates: PropTypes.array.isRequired,
};

export default DashboardStats;
