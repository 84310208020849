
import t from '../../lib/deprecated_i18n';
import { useState } from 'react';
import PropTypes from 'prop-types';

function VoterAdoptionButton({
  voterQuantity,
  subtitleMessage,
  voterAdoptionMutation,
  liveVoterCount,
  isAdopterMaxedOut,
  campaign,
  isCampaignClosed,
  socialBatchQuantity = 0,
  adoptionType = 'individual',
}) {
  const [mostRecentRequestedAmount, setMostRecentRequestedAmount] = useState(0);

  function adoptVoter() {
    setMostRecentRequestedAmount(voterQuantity);
    voterAdoptionMutation.mutate({
      bundleSize: Number(voterQuantity),
      batchSize: Number(socialBatchQuantity),
      districtId: campaign.district_id,
      type: 'BUNDLE',
    });

    // Only show the 'success' message for a short time, if at all.
    setTimeout(() => {
      setMostRecentRequestedAmount(0);
    }, 5000);
  }

  function getAdoptButtonMessageJsx(lettersPerBundle, bundlesPerBatch) {
    if (voterAdoptionMutation.isSuccess && !isCampaignClosed && mostRecentRequestedAmount === voterQuantity) {
      return (<div>{ t('adopt.button.success') } <i className='fa fa-check' /></div>);
    }
    let messageKey;
    if (adoptionType === 'party') {
      messageKey = `adopt.button.social.message${ socialBatchQuantity === 1 ? '.singular' : '' }`;
    } else {
      messageKey = liveVoterCount > 1 ? 'adopt.button.more.message' : 'adopt.button.initial.message';
    }
    let message = t(messageKey);
    message = message
      .replace('{{count}}', lettersPerBundle)
      .replace('{{bundleCount}}', bundlesPerBatch)
      .replace('{{stateAbbr}}', campaign.state_abbr);
    return (<div>{ message }</div>);
  }

  const disableButtons = voterAdoptionMutation.isLoading || isAdopterMaxedOut || isCampaignClosed;

  return (
    <div className='col-md'>
      <button
        className={ `btn btn-primary w-100 mt-1 ${ adoptionType }` }
        disabled={ disableButtons }
        onClick={ adoptVoter }
      >
        { getAdoptButtonMessageJsx(voterQuantity, socialBatchQuantity) }
      </button>
      <div className='small mt-1 text-center fw-bold text-gray'>
        { subtitleMessage }
      </div>
    </div>
  );
}

VoterAdoptionButton.propTypes = {
  adoptionType: PropTypes.string,
  campaign: PropTypes.object,
  isAdopterMaxedOut: PropTypes.bool,
  isCampaignClosed: PropTypes.bool,
  liveVoterCount: PropTypes.number,
  socialBatchQuantity: PropTypes.number,
  subtitleMessage: PropTypes.string,
  voterAdoptionMutation: PropTypes.object,
  voterQuantity: PropTypes.number,
};

export default VoterAdoptionButton;
