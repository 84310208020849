import React, { useState } from 'react';
import VerifyEmailRequest from '../VerifyEmailRequest';
import i18n from '../locales/strings.json';

function Masthead() {
  const [locale] = useState('en');
  return (
    <header className='landing--masthead p-4 py-xl-5 px-xl-0'>
      <div className='container-fluid'>
        <div className='row hero-wrapper justify-content-center'>
          <div className='col-12 col-xl-4 position-relative d-flex flex-column justify-content-center text-white px-4'>
            <h1>{ i18n[locale]['home.masthead.headline'] }</h1>
            <p>{ i18n[locale]['home.masthead.description'] }</p>
          </div>
          <div className='col-12 col-xl-5 d-flex align-items-center justify-content-center masthead-login-widget '>
            <VerifyEmailRequest
              getStartedMessage={ false }
              hideFooter
              hideHeader
              hideMasthead
              purpose='register.user'
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Masthead;
