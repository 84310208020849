import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Tracker from '../Tracker';
import { useAuth } from '../../contexts/AuthContext';

import Page from '../../page/Page';

function PageRoute({ component: Component, hasMinimalHeader, ...routeProps }) {
  const auth = useAuth();

  const tracker = useMemo(() => new Tracker({ auth }), [auth]);

  tracker.trackSegment();

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
    });
  }

  return (
    <Route { ...routeProps }>
      <Page hasMinimalHeader={ hasMinimalHeader }>
        <Component />
      </Page>
    </Route>
  );
}

PageRoute.defaultProps = {
  hasMinimalHeader: false,
};

PageRoute.propTypes = {
  component: PropTypes.func.isRequired,
  hasMinimalHeader: PropTypes.bool,
};

export default PageRoute;
