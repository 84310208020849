import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';

import CallToAction from './campaigns/CallToAction';
import Footer from './page/Footer';
import Header from './page/Header';
import i18n from './locales/strings.json';
import logger from './lib/logger';
import MailDate from './MailDate';
import Modal from './components/Modal';

import { deprecated_formatWithCommas } from './lib/formatNumbers.js';
import { Link } from 'react-router-dom';

class DistrictView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      district: null,
      loadedStats: false,
      errorLoadingStats: false,
      districtStats: {},
      showModal: false,
    };
  }

  static get propTypes() {
    return {
      district: propTypes.object,
      auth: propTypes.object,
    };
  }

  componentDidMount() {
    this.getDistrictStats(this.props.district);
    this.setDefaultDistrictInSession(this.props.district.district_id);
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  showModal = () => this.setState({ showModal: true });

  setDefaultDistrictInSession(districtId) {
    sessionStorage.setItem('defaultDistrictId', districtId);
  }

  getDistrictStats(district) {
    if (district && district.district_id) {
      const voterCount = parseInt(district.voter_count || 0, 10);
      const availableVoterCount = parseInt(district.available_voter_count || 0, 10);
      const adoptedVoterCount = voterCount - availableVoterCount;

      const processedStats = {
        adoptedVoterCount,
        voterCount,
        availableVoterCount,
      };
      if (voterCount > 0) {
        processedStats.percentComplete =
          Math.round((adoptedVoterCount / voterCount) * 1000) / 10;
      } else {
        processedStats.percentComplete = 0;
      }

      processedStats.allDone = false;
      if (processedStats.percentComplete === 100) {
        processedStats.allDone = true;
      }

      this.setState({
        loadedStats: true,
        processedStats,
      });
    } else {
      this.setState({ errorLoadingStats: true });
    }
  }

  render() {
    const stats = this.state.processedStats ? this.state.processedStats : {};

    let allDoneNotice;
    if (stats && stats.allDone) {
      allDoneNotice = (
        <div className='alert alert-info mb-3'>
          <i className='fa fa-check' /> { ' ' }
          This campaign has been completed! <Link to='/campaigns'>Click here to select another</Link>.
        </div>
      );
    }

    const d = this.props.district;
    let showResources;

    if (
      !d.url_ballotpedia
      && !d.url_wikipedia
      && !d.url_swingleft
      && !d.url_election_info
    ) {
      showResources = false;
    } else {
      showResources = true;
    }

    let campaignGoal = 'voter turnout';
    if (d.type && d.type.slice(0, 4) === 'vrbm') {
      campaignGoal = 'voter registration';
    }

    let targetingDescription = i18n.en['campaign.targetingDescription.social'];
    let taxTypeDisclaimer = i18n.en['campaign.disclaimer.social'];

    if (d.tax_type === 'political') {
      targetingDescription = i18n.en['campaign.targetingDescription.political'];
      taxTypeDisclaimer = i18n.en['campaign.disclaimer.political'];
    }

    const modalTitle = i18n.en['labs.modal.title'];
    const modalText = i18n.en['labs.modal.text'];

    let vrFormNoticeMarkup;
    if (d.type === 'vrbm-form' || d.type === 'vrbm-no9' || d.type === 'vrbm-form-labs') {
      vrFormNoticeMarkup =
        (
          <p className='mb-1 text-danger'>
            <i className='fa fa-exclamation-circle' /> Registration Forms Enclosed.
            { ' ' }
            <a href='/vf-instructions-vr-form.pdf'>Download instructions.</a>
          </p>
        );
    }

    let labsNoticeMarkup;
    if (d.is_labs) {
      labsNoticeMarkup = (
        <div>
          <span
            onClick={ this.showModal }
            style={{ cursor: 'pointer' }}
          >
            <img
              alt='Vote Forward Labs Icon Beaker'
              className='img-fluid float-start me-2'
              src='/images/labs_icon_blue.svg'
              style={{
                height: '2em',
                marginLeft: '-0.25em',
              }}
            />
            <p className='mb-1'>
              { /* FIXME: What does this hash link do? Nothing AFAICT - cb */ }
              Vote Forward Labs Campaign. <a href='#labs-popup'>Learn more.</a>
            </p>
          </span>
        </div>
      );
    }

    return (
      <div className='container pt-5 pb-5 mb-5'>
        <Modal
          hideModal={ this.hideModal }
          modalText={ modalText }
          modalTitle={ modalTitle }
          showModal={ this.state.showModal }
        />
        <div className='row'>
          <div className='col-12 mb-4'>
            { allDoneNotice }
            <Link to='/campaigns'>
              <i className='fa fa-arrow-left' />
              <span className='ps-2'>View all campaigns</span>
            </Link>
          </div>
        </div>
        <div className='row'>
          <div className='col-md mt-2'>
            <div className='row mb-4'>
              <h1 className='col mt-2'>
                { d.display_name }
              </h1>
            </div>
            { labsNoticeMarkup }
            { vrFormNoticeMarkup }
            <MailDate
              campaignType={ d.type }
              districtId={ d.district_id }
              mailDate={ d.mail_date }
              mailDateFrom={ d.mail_date_from }
              mailDateType={ d.mail_date_type }
            />
            { d.is_labs
              && (
                <p className='mb-4 mt-4'>
                  <strong>Labs Question: </strong> { d.labs_description }
                </p>
              ) }
            <p className='mb-4'>
              <strong>Overview: </strong> { d.description }
            </p>
            <p className='mb-4'>
              <strong>Background: </strong> { d.why_this_district }
            </p>
            <p className='mb-4'>
              <strong>Targeting: </strong> { targetingDescription }
            </p>
            <p className='mb-4'>
              <strong>Goal: </strong>This campaign seeks to increase{ ' ' }
              <strong>{ campaignGoal }</strong>.
            </p>
            { this.state.loadedStats
              && (
                <CallToAction
                  allDone={ stats.allDone }
                  auth={ this.props.auth }
                  district={ d }
                />
              ) }
          </div>
        </div>
        <div className='row'>
          <div className='col-12 pt-3 mt-3'>
            { this.state.loadedStats
              && (
                <DistrictStats
                  auth={ this.props.auth }
                  district={ this.props.district }
                  districtStats={ stats }
                />
              ) }
          </div>
        </div>
        { showResources && (
          <div className='row'>
            <div className='col-12 pt-3 mt-3'>
              <DistrictLinks district={ d } />
            </div>
          </div>
        ) }
        <p className='small mt-4'>
          { i18n.en['campaign.disclaimer.base'] } { taxTypeDisclaimer }
        </p>
      </div>
    );
  }
}

class DistrictLinks extends Component {
  static get propTypes() {
    return {
      district: propTypes.object,
    };
  }

  render() {
    return (
      <div>
        <h4 className='u-quiet mb-4 fw-normal'>Other resources</h4>

        { this.props.district.training_url && (
          <div className='mt-1'>
            <a
              href={ this.props.district.training_url }
              rel='noreferrer noopener'
              target='_blank'
            >
              <i className='fa fa-external-link' /> Training for Letter Writers
            </a>
          </div>
        ) }

        { this.props.district.url_ballotpedia && (
          <div className='mt-1'>
            <a
              href={ this.props.district.url_ballotpedia }
              rel='noreferrer noopener'
              target='_blank'
            >
              <i className='fa fa-external-link' /> Read about the race
              (Ballotpedia)
            </a>
          </div>
        ) }

        { this.props.district.url_wikipedia && (
          <div className='mt-1'>
            <a
              href={ this.props.district.url_wikipedia }
              rel='noreferrer noopener'
              target='_blank'
            >
              <i className='fa fa-external-link' /> Read about the district
              (Wikipedia)
            </a>
          </div>
        ) }

        { this.props.district.url_swingleft && (
          <div className='mt-1'>
            <a
              href={ `${ this.props.district.url_swingleft }?utm_source=votefwd` }
              rel='noopener noreferrer'
              target='_blank'
            >
              <i className='fa fa-external-link' /> Learn more about this
              district (Swing Left)
            </a>
          </div>
        ) }

        { this.props.district.url_election_info && (
          <div className='mt-1'>
            <a
              href={ this.props.district.url_election_info }
              rel='noreferrer noopener'
              target='_blank'
            >
              <i className='fa fa-external-link' /> Review official{ ' ' }
              { this.props.district.state } election info
            </a>
          </div>
        ) }
      </div>
    );
  }
}

class DistrictStats extends Component {
  static get propTypes() {
    return {
      districtStats: propTypes.object,
    };
  }

  render() {
    const stats = this.props.districtStats ? this.props.districtStats : {};
    return (
      <div className='pt-3 pb-3 bw-2'>
        <h4 className='mb-3'>Letter-writing progress</h4>
        { !stats.allDone && (
          <p>
            Volunteers have adopted { deprecated_formatWithCommas(stats.adoptedVoterCount) } voters in this campaign,{ ' ' }
            { stats.percentComplete }% of the { deprecated_formatWithCommas(stats.voterCount) } targeted voters.
          </p>
        ) }
        { stats.allDone && (
          <p className='mb-3'>
            All { deprecated_formatWithCommas(stats.voterCount) } target voters in this campaign have been
            adopted!{ ' ' }
            <span className='fw-bold fw-italic'>
              Great work, volunteers.{ ' ' }
            </span>
            <Link to='/campaigns'>Find another campaign.</Link>
          </p>
        ) }
        <div className='p-statusBar mb-3'>
          <div
            className={ `p-statusBar_bar ${ stats.allDone ? ' complete' : '' }` }
            style={{
              width: `${ stats.percentComplete }%`,
            }}
          />
          <div className='p-statusBar_status'>
            <strong>{ deprecated_formatWithCommas(stats.availableVoterCount) }</strong> voters remaining
          </div>
        </div>
      </div>
    );
  }
}

class District extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: null,
    };
    this.setPartnerInSession(props.match?.params?.partner);
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      match: propTypes.object,
    };
  }

  componentDidMount() {
    this.getDistrict(this.props.match.params.id);
  }

  async getDistrict(districtId) {
    if (districtId) {
      try {
        const res = await axios.get(`${ process.env.REACT_APP_API_URL }/lookup-district`, {
          params: {
            districtId,
            auth0Id: this.props.auth.getUserId(),
            userPartners: this.props.auth.getUserPartners().join(','),
          },
        });
        if (res.data.length !== 0 && res.data !== false) {
          const district = res.data[0];
          if (district.pause_adoption) {
            window.location.replace('/dashboard');
          } else {
            this.setState({ district });
          }
        } else {
          logger.error('getDistrict: no district returned from /lookup-district, redirecting to /');
          window.location.replace('/');
        }
      } catch (error) {
        logger.error(error);
        window.location.replace('/');
      }
    } else {
      logger.error('getDistrict: no districtId, redirecting to /');
      window.location.replace('/');
    }
  }

  setPartnerInSession(partner) {
    if (partner) {
      sessionStorage.setItem('partner', partner);
    }
  }

  render() {
    return (
      <div>
        <Header />
        { this.state.district && (
          <div>
            <DistrictView
              auth={ this.props.auth }
              district={ this.state.district }
            />
          </div>
        ) }
        <Footer />
      </div>
    );
  }
}

export default District;
