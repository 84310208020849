

import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  TextInput,
  Filter,
  FunctionField,
} from 'react-admin';

import { Link } from 'react-router-dom';

export const statusOptions = ({ mode = 'filter' } = {}) => {
  if (['filter', 'edit'].indexOf(mode) < 0) {
    throw { status: 400, message: `invalid mode ${ mode } passed` };
  }
  const statuses = [
    { id: 'banned', name: 'Banned' },
    { id: 'pre_qualified', name: 'Pre-Qualified' },
    { id: 'qualified', name: 'Qualified' },
    { id: 'super_qualified', name: 'Super Qualified' },
  ];
  return statuses;
};

const UserLink = () => (
  (
    <FunctionField
      label='auth0Id'
      render={ (record) => (
        <Link target='_blank' to={ `/user/${ record.auth0Id }` }>
          { record.auth0Id }
        </Link>
      ) }
    />
  )
);

const UserFilters = (props) => (
  <Filter { ...props }>
    <TextInput alwaysOn label='Full Name' source='fullName:contains' />
    <TextInput alwaysOn label='Auth0Id' source='auth0Id:startsWith' />
    <TextInput alwaysOn label='Email' source='email:startsWith' />
    <DateField alwaysOn label='Signup date' showTime source='createdAt' />
    <SelectInput choices={ statusOptions() } label='Status' source='qualState' />
    <TextInput label='Partners' source='partners:startsWith' />
    <TextInput label='Zip' source='zip' />
  </Filter>
);
const UserTable = (props) => (
  <List
    { ...props }
    filters={ <UserFilters /> }
    sort={{ field: 'createdAt', order: 'ASC' }}
  >
    <Datagrid optimized>
      <TextField source='fullName' />
      { UserLink() }
      <TextField source='email' />
      <DateField showTime source='createdAt' />
      <TextField label='status' source='qualState' />
      <TextField source='partners' />
      <TextField source='current_district' />
      <TextField source='zip' />
    </Datagrid>
  </List>
);


export default UserTable;
