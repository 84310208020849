import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Error from '@material-ui/icons/Error';
import axios from 'axios';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  TextInput,
  Edit,
  DateField,
  Toolbar,
  SaveButton,
  SelectInput,
  BooleanInput,
  BulkExportButton,
  AutocompleteInput,
  Pagination,
  ReferenceInput,
  SimpleForm,
  NumberInput,
  EditButton,
  Filter,
} from 'react-admin';
import propTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Warning from '@material-ui/icons/Warning';

import logger from '../lib/logger';
import { __setupAuth } from '../contexts/AuthContext';

// eslint-disable-next-line no-empty-function
const auth = __setupAuth({ setPictureUrl: () => {} });
const EmailPreferencesPagination = (props) => <Pagination rowsPerPageOptions={ [10, 25, 50, 100, 500] } { ...props } />;
const EmailPreferencesBulkActionButtons = (props) => (
  <BulkExportButton { ...props } />
);
const EmailPreferencesFilter = (props) => (
  <Filter { ...props }>
    <TextInput alwaysOn label='Email' source='email:contains' />
    <BooleanInput defaultValue source='newsletter' />
    <BooleanInput defaultValue source='labs' />
    <BooleanInput defaultValue source='reminders' />
    <BooleanInput defaultValue source='unsubscribe' />
    <BooleanInput defaultValue source='receiveFewer' />
    <ReferenceInput
      filterToQuery={ (slug) => ({ 'slug:contains': slug }) }
      label='Group'
      reference='Groups'
      sort={{ field: 'slug', order: 'ASC' }}
      source='groups.id'
    >
      <AutocompleteInput optionText='slug' />
    </ReferenceInput>
    <SelectInput
      choices={ [
        { id: 'banned', name: 'banned' },
        { id: 'pre_qualified', name: 'pre_qualified' },
        { id: 'qualified', name: 'qualified' },
        { id: 'super_qualified', name: 'super_qualified' },
      ] }
      label='Qual State'
      source='users.qualState'
    />
    <TextInput label='State' source='users.state' />
    <NumberInput label='Prepped Voter Count >= to' source='users.preppedVoterCount:greaterThanOrEqualTo' />
  </Filter>
);
export const EmailPreferencesList = (props) => (
  <List { ...props } bulkActionButtons={ <EmailPreferencesBulkActionButtons /> } filters={ <EmailPreferencesFilter /> } pagination={ <EmailPreferencesPagination /> } title='Email Preferences'>
    <Datagrid>
      <TextField source='id' />
      <TextField label='Email' source='email' />
      <TextField label='Auth0 Id' source='auth0Id' />
      <DateField source='createdAt' />
      <BooleanField source='newsletter' />
      <BooleanField source='labs' />
      <BooleanField source='reminders' />
      <BooleanField source='unsubscribe' />
      <BooleanField source='receiveFewer' />
      <EditButton { ...props } />
    </Datagrid>
  </List>
);
const EmailPreferencesEditToolbar = (props) => (
  //removes default delete option from toolbar
  <Toolbar { ...props } >
    <SaveButton />
  </Toolbar>
);
class SecureLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailLinkType: null,
      emailLinkUrl: null,
      showEmailLinkForm: false,
      apiError: '',
      showEmailHelp: false,
    };
  }

  hideEmailHelp = () => {
    this.setState({ showEmailHelp: false });
  };

  static get propTypes() {
    return {
      record: propTypes.object,
      auth: propTypes.object,
    };
  }

  handleToggleEmailHelp = () => this.setState({ showEmailHelp: !this.state.showEmailHelp });

  render() {
    const emailLinkGenerator = this.renderEmailLinkGenerator(this.props);
    return (
      <React.Fragment>
        <div>
          <p>
            { this.state.emailLinkWarned && emailLinkGenerator }
          </p>
        </div>

        <div>
          { !this.state.emailLinkWarned
          && (
            <p>
              <span className='ms-3'>(click{ ' ' }
                <span
                  className='btn-link'
                  onClick={ this.handleToggleEmailHelp }
                  style={{ cursor: 'pointer' }}
                >
                  here
                </span>{ ' ' }
                if the user is having trouble receiving email)
              </span>
              <span className={ [this.state.showEmailHelp ? '' : 'hidden'] }>
                <Alert
                  action={
                    <Button
                      color='inherit'
                      onClick={ this.hideEmailHelp }
                      size='small'
                    >
                      CLOSE
                    </Button>
                  }
                  severity='info'
                >
                  Please confirm the user has checked their spam folder
                  and added “hello@votefwd.org” to their contact list. As a last resort,
                  you can generate a secure link and email it to them directly.
                  Be careful with this link, and use it sparingly.
                  Type “last resort” to generate a secure link: <input onChange={ (event) => { this.setState({ showEmailLinkForm: event.target.value === 'last resort' }); } } type='text' />.
                  { this.renderEmailLinkGenerator() }
                </Alert>
              </span>
            </p>
          ) }
        </div>
      </React.Fragment>
    );
  }

  generateEmailLink = async () => {
    try {
      const accessToken = await this.props.auth.getAccessToken();
      const res = await axios({
        method: 'POST',
        withCredentials: true,
        url: `${ process.env.REACT_APP_API_URL }/v1/emailPreferences/${ this.props.record.id }/secureEmailLink`,
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        data: { purpose: this.state.emailLinkType },
      });
      this.setState({ apiError: '', emailLinkUrl: res.data.url });
    } catch (err) {
      logger.error(err);
      this.setState({
        apiError:
        (err.response && err.response.data && err.response.data.message)
        || err.message
        || 'An error occurred. Please try again.',
      });
    }
  };

  renderEmailLinkGenerator() {
    return (
      <div className={ this.state.showEmailLinkForm ? '' : 'hidden' }>
        <label className='mb-3 mt-3'>Type of secure link: </label>
        <select
          className='form-control ms-5'
          onChange={ (event) => this.setState({ emailLinkType: event.target.value, emailLinkUrl: null }) }
          style={{ width: '200px' }}
          value={ this.state.emailLinkType }
        >
          <option value=''> </option>
          <option value='forgot.password'>Forgot password</option>
          <option value='register.user'>Register User</option>
          <option value='verify.email'>Verify Email</option>
          <option value='email.preferences'>Update Preferences</option>
        </select>

        <Button
          className='ms-5 mt-2'
          color='inherit'
          disabled={ this.state.emailLinkType === null }
          onClick={ () => {
            this.generateEmailLink();
          } }
        >
          Generate Secure Link
        </Button>
        { this.state.emailLinkUrl
          && (
            <div className='ms-5' style={{ width: '500px', wordWrap: 'break-word' }}>
              <label>
                Here is the{ ' ' }{ this.state.emailLinkType.replace('.', ' ') }{ ' ' }
                link for <strong>{ this.props.record.email }</strong>
              </label>:
              <br />
              <Warning />
              <strong>Do not click on this link yourself. Only copy/paste it into an email for { this.props.record.email }.</strong>
              <br />
              { this.state.emailLinkUrl }
            </div>
          ) }
        { this.state.apiError
          && (
            <Fragment>
              <br />
              <Error /><strong>{ this.state.apiError }</strong>
            </Fragment>
          ) }
      </div>
    );
  }
}
export const EmailPreferencesEdit = (props) => (
  <Edit undoable={ false } { ...props }>
    <SimpleForm toolbar={ <EmailPreferencesEditToolbar /> }>
      <TextInput disabled source='id' />
      <TextInput disabled source='email' />
      <SecureLink auth={ auth } />
      <TextInput disabled source='auth0Id' />
      <BooleanInput source='newsletter' />
      <BooleanInput source='labs' />
      <BooleanInput source='reminders' />
      <BooleanInput source='unsubscribe' />
      <BooleanInput source='receiveFewer' />
    </SimpleForm>
  </Edit>
);


export default EmailPreferencesList;
