import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  NumberInput,
  BulkExportButton,
  Create,
  SimpleForm,
  required,
  regex,
  Toolbar,
  SaveButton,
  Edit,
  EditButton,
  useNotify,
} from 'react-admin';


const errorHandler = (error, notify) => {
  if (error.status === 'Conflict') {
    notify(`Error: Zip must be Unique`);
  } else {
    notify(`Error: ${ error.body || error.message }`);
  }
};

const SharedInputs = () => (
  <Fragment>
    <TextInput source='city' validate={ [required()] } /> <br />
    <TextInput label='State Abbreviation' source='state' validate={ [required(), regex(/^[A-Z]{2}$/, 'State should be two uppercase characters')] } /> <br />
    <NumberInput source='lat' /> <br />
    <NumberInput source='long' /> <br />
  </Fragment>
);

const LuZipCreateToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton
      label='create'
    />
  </Toolbar>
);
const LuZipEditToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton />
  </Toolbar>
);

export const LuZipEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };

  return (
    <Edit onFailure={ onFailure } undoable={ false } { ...props } toolbar={ <LuZipEditToolbar /> }>
      <SimpleForm redirect='edit' toolbar={ <LuZipEditToolbar /> }>
        <TextInput disabled source='zip' validate={ [required()] } />
        <SharedInputs />
      </SimpleForm>
    </Edit>
  );
};

export const LuZipCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };
  return (
    <Create onFailure={ onFailure } { ...props } toolbar={ <LuZipCreateToolbar /> }>
      <SimpleForm toolbar={ <LuZipCreateToolbar /> }>
        <TextInput source='zip' validate={ [regex(/^\d{5}$/, 'zip should be 5 digits')] } />
        <SharedInputs />
      </SimpleForm>
    </Create>
  );
};

const LuZipListBulkActionButtons = (props) => (
  <BulkExportButton { ...props } />
);

const LuZipFilter = (props) => (
  <Filter { ...props }>
    <TextInput alwaysOn label='Zip' source='zip:startsWith' />
  </Filter>
);

const LuZipList = (props) => (
  <List
    { ...props }
    bulkActionButtons={ <LuZipListBulkActionButtons /> }
    filters={ <LuZipFilter /> }
    sort={{ field: 'zip', order: 'ASC' }}
  >
    <Datagrid>
      <TextField source='zip' />
      <TextField source='state' />
      <TextField source='city' />
      <EditButton { ...props } />
    </Datagrid>
  </List>
);

export default LuZipList;
