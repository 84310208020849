import React, { Fragment } from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Pagination,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  SimpleForm,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

const SettingEditToolbar = (props) => (
//removes default delete option from toolbar
  <Toolbar { ...props } >
    <SaveButton />
  </Toolbar>
);
const validateSettingValue = (values) => {
  const errors = {};
  if (!values.value) {
    errors.value = 'The value is required';
  } else if (['LETTERKIT_LIMIT', 'STAMPKIT_LIMIT'].includes(values.type) && !`${ values.value }`.match(/^\d+$/)) {
    // You can return translation keys
    errors.value = 'The value must be an integer.';
  }
  return errors;
};
const SharedInputs = () => (
  <Fragment>
    <SelectInput
      choices={ [
        { id: 'LETTERKIT_LIMIT', name: 'Letter Kit Limit' },
        { id: 'STAMPKIT_LIMIT', name: 'Stamp Kit Limit' },
      ] }
      source='type'
      validate={ [required()] }
    /><br />
    <TextInput source='value' validate={ [required()] } /><br />
  </Fragment>
);

export const SettingEdit = (props) => (
  <Edit undoable={ false } { ...props }>
    <SimpleForm redirect='show' toolbar={ <SettingEditToolbar /> } validate={ validateSettingValue }>
      <TextInput disabled source='id' />
      <SharedInputs />
      <TextField source='currentCount' />
    </SimpleForm>
  </Edit>
);
export const SettingView = (props) => (
  <Show { ...props }>
    <SimpleShowLayout>
      <TextField source='id' />
      <TextField source='type' />
      <TextField source='value' />
      <TextField source='currentCount' />
      <ReferenceManyField label='Audit' pagination={ <Pagination /> } reference='SettingsAudits' sort={{ field: 'createdAt', order: 'DESC' }} target='settingId'>
        <Datagrid>
          <DateField label='Set on' showTime source='createdAt' />
          <TextField label='Set by' source='users.email' />
          <TextField source='newValue' />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const SettingCreateToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton
      label='create'
    />
  </Toolbar>
);

export const SettingCreate = (props) => (
  <Create { ...props }>
    <SimpleForm redirect='show' toolbar={ <SettingCreateToolbar /> }>
      <SharedInputs />
    </SimpleForm>
  </Create>
);


const SettingList = (props) => (
  <List { ...props }>
    <Datagrid>
      <TextField source='id' />
      <TextField source='type' />
      <TextField source='value' />
      <TextField source='currentCount' />
      <TextField source='createdAt' />
      <ShowButton { ...props } />
    </Datagrid>
  </List>
);

export default SettingList;
