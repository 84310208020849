import axios from 'axios';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import VerifyEmailRequest from '../VerifyEmailRequest';

import { isDatePast } from '../lib/dates';
import logger from '../lib/logger';
import { segmentTrack } from '../lib/segment';

function CallToAction({ allDone, auth, district }) {
  const isAuthenticated = auth.isAuthenticated();
  const isCampaignPast = isDatePast(district.mail_date) || isDatePast(district.election_date);
  const isCampaignClosed = isCampaignPast || allDone;

  const [partnerId, setPartnerId] = useState(sessionStorage.getItem('partner'));
  const [partnerName, setPartnerName] = useState();
  useEffect(() => {
    async function getPartnerName() {
      try {
        const url = `${ process.env.REACT_APP_API_URL }/v1/partners/${ partnerId }`;
        const { data: partner } = await axios.get(url);
        setPartnerName(partner.name);
      } catch (error) {
        logger.error(error);
        sessionStorage.removeItem('partner');
        setPartnerId(null);
      }
    }

    if (!isAuthenticated && !isCampaignClosed && partnerId) {
      getPartnerName();
    }
  }, [isAuthenticated, isCampaignClosed, partnerId]);

  const trackCtaClick = () => {
    segmentTrack('CampaignCTA:click', {
      category: 'cta',
      campaign: district.district_id,
      isPolitical: district.tax_type === 'political',
      isSocial: district.tax_type === 'social',
    });
  };

  return isAuthenticated
    ? (
      <div>
        { !isCampaignClosed && (
          <Link
            className='btn btn-primary btn-lg d-block'
            onClick={ trackCtaClick }
            to={ `/dashboard/${ district.district_id }` }
          >
            Write letters to voters in { district.state }
          </Link>
        ) }

        { isCampaignClosed && (
          <Link
            className='btn btn-primary btn-lg d-block'
            to='/campaigns'
          >
            Campaign completed! Find another campaign
          </Link>
        ) }
      </div>
      )
    : (
      <div>
        { !isCampaignClosed && (
          <Fragment>
            <VerifyEmailRequest
              getStartedMessage={ false }
              hideFooter
              hideHeader
              hideMasthead
              purpose='register.user'
            />
            { partnerName && (
              <div className='partner-affiliation-disclaimer login-widget'>
                <div className='card border-0 small text-center'>
                  <div className='card-body mt-n5 pt-0'>
                    <strong>
                      Vote Forward and { partnerName } are working together to increase civic participation by sending letters to voters.
                    </strong>
                    { ' ' }
                    { partnerName } can see the email addresses and info about the letter-writing activities of volunteers who sign up through this page. They use this information to measure their impact. They may also follow up with you by email.
                  </div>
                </div>
              </div>
            ) }
          </Fragment>
        ) }

        { isCampaignClosed && (
          <Link
            className='btn btn-primary btn-lg d-block'
            to='/campaigns'
          >
            Campaign completed! Find another campaign
          </Link>
        ) }
      </div>
      );
}

CallToAction.propTypes = {
  allDone: propTypes.bool,
  auth: propTypes.object,
  district: propTypes.object,
};

export default CallToAction;
