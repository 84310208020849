import classNames from 'classnames';
import { Fragment, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link, useRouteMatch } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { get } from '../lib/apiClient';
import { usePartnerSlug } from '../partners/lib/hooks';
import { usePartner } from '../partners/lib/queries';

import Footer from './Footer';
import TitleAndMeta from './PageHead';
import PartnersSection from './PartnersSection';
import ProgressBox from './ProgressBox';
import EventBox from '../components/EventBox';
import VerifyEmailRequest from '../VerifyEmailRequest';
import MaterialsIcons from '../components/MaterialsIcons';

import '../scss/lib/_buttons.scss';
import '../scss/lib/_fonts.scss';
import '../scss/_login.scss';
import '../scss/big-send/_landing.scss';

import bigSendLogo from '../images/big-send/tbs_logo_1.png';
import bigSendAtWorkLogo from '../images/big-send/tbs-at-work-logo.png';
import bigSendOnCampusLogo from '../images/big-send/tbs-on-campus-logo.png';
import photoOne from '../images/big-send/tbs_photo_2.png';
import photoTwo from '../images/big-send/tbs_photo_3.png';
import photoThree from '../images/big-send/tbs_photo_4.png';
import photoFour from '../images/big-send/tbs_photo_5.png';

const placeholderGoalData = {
  partners: [],
  progress: 0,
  target: 0,
};

const pageVariants = [
  {
    slug: 'bigsendatwork',
    urlPattern: /^\/bigsendatwork/,
    className: 'at-work',
    logo: bigSendAtWorkLogo,
    partnerTitleSelector: 'partners.atWorkTitle',
    stringsetSlug: 'isAtWork',
    iconVariant: 'big-send',
  },
  {
    slug: 'bigsendoncampus',
    urlPattern: /^\/bigsendoncampus/,
    className: 'on-campus',
    logo: bigSendOnCampusLogo,
    partnerTitleSelector: 'partners.title',
    stringsetSlug: 'isOnCampus',
    iconVariant: 'big-send-on-campus',
  },
];
const defaultVariant = {
  slug: 'bigsend',
  urlPattern: /^\/bigsend/,
  className: '',
  logo: bigSendLogo,
  partnerTitleSelector: 'partners.title',
  stringsetSlug: 'default',
  iconVariant: 'big-send',
};

function useVariant() {
  const { url } = useRouteMatch();
  return useMemo(
    () => findVariant(url),
    [url],
  );
}

function findVariant(url) {
  return pageVariants.find((variant) => (
    variant.urlPattern.test(url)
  )) || defaultVariant;
}

function useGoal({ variant = defaultVariant }) {
  const apiPath = useMemo(
    () => `/v1/goals/${ variant.slug }`,
    [variant.slug],
  );
  const query = useCallback(
    async () => await get(apiPath),
    [apiPath],
  );
  const queryKey = useMemo(
    () => [apiPath, 'get'],
    [apiPath],
  );
  return useQuery(
    queryKey,
    query,
    { placeholderData: placeholderGoalData },
  );
}

function BigSend() {
  const { t } = useTranslation('big-send');
  const variant = useVariant();
  const partnerSlug = usePartnerSlug();
  const { data: partner, isLoading: isPartnerLoading } = usePartner({
    noPartnerPath: `/${ variant.slug }`,
    partnerSlug,
  });
  const { data: goal, isLoading: isGoalLoading } = useGoal({ variant });

  return (
    <Fragment>
      <TitleAndMeta stringsetSlug={ variant.stringsetSlug } />

      <div className={ classNames('tbs-landing', variant.className) }>
        <section className='container-lg'>
          <div className='row'>
            <div className='col px-4 px-lg-5'>
              <header className='py-4 text-center'>
                <img
                  alt={ t('logoAltText') }
                  className='d-block logo mx-auto'
                  src={ variant.logo }
                />

                <h1 className='fw-bold fs-0 mt-3 pb-2 pt-4 px-lg-5 text-center'>
                  { t('introduction.heading') }
                </h1>

                <p className='fs-5 mb-4 mt-3 pb-2 pb-lg-3 px-lg-5'>
                  <Trans i18nKey='introduction.subheading' t={ t }>
                    0
                    <Link to='/campaigns'>
                      1
                    </Link>
                    2
                  </Trans>
                </p>

                <a className='border-0 btn btn-primary-blue d-inline-block fs-3 mb-2 mb-md-4 mb-lg-5 pb-4 px-5' href='#register-user'>
                  { t('cta') }
                </a>
              </header>

              {
                goal.target && !isGoalLoading
                  ? (
                    <div className='card bs-card mb-4 mb-md-5'>
                      <div className='card-body bs-card-body'>
                        <ProgressBox progress={ goal.progress } target={ goal.target } />
                      </div>
                    </div>
                    )
                  : null
              }
            </div>
          </div>
        </section>

        <section className='container-fluid mail-date-reminder'>
          <div className='container-lg'>
            <div className='row'>
              <div className='col'>
                <p className='fs-5 px-2 py-3 mb-0 text-center'>
                  <Trans i18nKey='mailDateReminder' t={ t }>
                    <strong>
                      0
                    </strong>
                    1
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className='container-fluid pb-lg-5 pt-5' id='register-user'>
          <div className='container-lg'>
            <div className='row'>
              <div className='col-12 col-lg-6 pb-4 px-lg-5'>
                <h2 className='px-2 text-center text-white'>
                  { t('registerUser.heading') }
                </h2>
                <MaterialsIcons
                  crunchOnMobile
                  numDesktopCols={ 2 }
                  variant={ variant.iconVariant }
                />
                <p className='fs-6 mb-lg-0 mt-2 mt-lg-0 pt-lg-4 px-2 text-center text-white'>
                  { t('registerUser.text') }
                </p>
              </div>

              <div className='col-12 col-lg-6'>
                <div className='px-2'>
                  <VerifyEmailRequest
                    getStartedMessage={ false }
                    hideFooter
                    hideHeader
                    hideMasthead
                    purpose='register.user'
                  />

                  {
                    partnerSlug && !isPartnerLoading
                      ? (
                        <p className='fs-8 fst-italic pb-4 px-2 text-center text-white'>
                          { t('partner.disclaimer', { partner: partner.name }) }
                        </p>
                        )
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container-fluid p-0 volunteer-photos'>
          <div className='g-0 row'>
            <div className='col-3'>
              <img alt='Vote Forward volunteers mailing their letters at a bank of blue mailboxes' src={ photoOne } />
            </div>
            <div className='col-3'>
              <img alt='Vote Forward volunteer wearing a "Vote" mask holding a stack of prepared letters' src={ photoTwo } />
            </div>
            <div className='col-3'>
              <img alt='Vote Forward volunteer mailing a stack of stamped envelopes in a blue mailbox' src={ photoThree } />
            </div>
            <div className='col-3'>
              <img alt='The US flag over a pile of prepared and stamped envelopes' src={ photoFour } />
            </div>
          </div>
        </section>

        <section className='container-lg pt-5'>
          <div className='row'>
            <div className='col px-4'>
              <div className='card bs-card mb-5 pb-lg-4'>
                <div className='card-body bs-card-body'>
                  <EventBox variant='bigsend' />
                </div>
              </div>

              {
                goal.partners && !isGoalLoading
                  ? (
                    <div className='card bs-card'>
                      <PartnersSection
                        partners={ goal.partners }
                        titleSelector={ variant.partnerTitleSelector }
                      />
                    </div>
                    )
                  : null
              }
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
}

export default BigSend;
