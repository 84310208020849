
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CampaignLabels from '../../../campaigns/CampaignLabels';
import MailDate from '../../../MailDate';

ManageColumnCell.propTypes = {
  bundleData: PropTypes.object.isRequired,
  cellId: PropTypes.string.isRequired,
  children: PropTypes.any,
  testLabel: PropTypes.string.isRequired,
};

const getPartyLetterLabel = (bundle) => {
  if (bundle.social_assignee_email && bundle.email === bundle.social_assignee_email) {
    return 'MY';
  }
  if (bundle.social_assignee_first_name) {
    return `${ bundle.social_assignee_first_name }'S`.toUpperCase();
  }
  return 'UNCLAIMED';
};

export default function ManageColumnCell({
  children,
  cellId,
  bundleData,
  testLabel,
}) {
  const mailDateFrom = (bundleData.mail_date_type === 'ROLLING' || bundleData.mail_date_type === 'ROLLING_ASAP')
    ? bundleData.mail_start_date
    : bundleData.mail_end_date;
  const cellType = bundleData.social_token ? 'party' : 'individual';
  const cellLabel = bundleData.social_token
    ? `EVENT LETTER CAMPAIGN - ${ getPartyLetterLabel(bundleData) } LETTERS`
    : 'INDIVIDUAL LETTER CAMPAIGN';

  return (
    <li className={ `bundle-list-item ${ cellType }` } data-testid={ testLabel } key={ cellId } >
      <p className='fill-row cell-label'>{ cellLabel }</p>
      <div className='row'>
        <div className='col fill-row'>
          <h5 className='fill-row'>{ bundleData.short_description }</h5>
        </div>
        <div className='col side-col'>
          <CampaignLabels
            isBundleDisplay
            isLabs={ bundleData.is_labs }
            isPolitical={ bundleData.tax_type === 'political' }
            isPriority={ bundleData.is_priority }
            isSocial={ bundleData.tax_type === 'social' }
            isVoterRegistration={ bundleData.tax_type === 'voter-engagement' }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col fill-row fw-bold'>
          <span className='adopted-on'>
            ID: <span className='font-monospace'>{ ` ${ cellId }` }</span>
            { ` | ${ bundleData.letter_kit_status === 'REQUESTED' ? 'Requested' : 'Adopted' }: ${ moment(bundleData.adopted_at).format('M/DD [at] hh:ssa') }` }
          </span>
        </div>
        <div className='col fw-bold side-col end-col text-gray mail-by-message'>
          <div className='mail-by-bubble'>
            <MailDate iconClassname='fa-envelope-o' mailDate={ bundleData.mail_end_date } mailDateFrom={ mailDateFrom } mailDateType={ bundleData.mail_date_type } short showOffset={ false } />
          </div>
          <div
            className={ classNames(
              'mail-by-label rounded-pill mail-by-bubble-label', 'text-gray',
            ) }
          >
            <MailDate iconClassname='' mailDate={ bundleData.mail_end_date } mailDateFrom={ mailDateFrom } mailDateType={ bundleData.mail_date_type } short={ false } />
          </div>
        </div>
      </div>
      { children }
    </li>
  );
}
