import PropTypes from 'prop-types';
import SocialSendEmail from './SocialSendEmail';

SocialBundleInfo.propTypes = {
  bundle: PropTypes.object.isRequired,
  myLettersMessage: PropTypes.string.isRequired,
};

export default function SocialBundleInfo({ bundle, myLettersMessage }) {
  if (!bundle.social_assignee_email) {
    return null;
  }

  return (
    <div className='row' >
      <label className='letter-info' >
        {
          bundle.social_assignee_email === bundle.email
            ? (
              <span className='text-gray'>{ myLettersMessage }</span>
              )
            : (<SocialSendEmail bundle={ bundle } />)
        }
      </label>
    </div>
  );
}

