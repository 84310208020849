import PropTypes from 'prop-types';

LetterFilter.propTypes = {
  className: PropTypes.string,
  letterFilter: PropTypes.string,
  updateFilter: PropTypes.func,
};


export default function LetterFilter({
  className,
  letterFilter,
  updateFilter,
}) {
  const onFilterChange = (event) => {
    const filter = event.target.value;
    updateFilter(filter);
  };


  return (
    <div className={ className }>
      <div className='row'>
        <input
          aria-label='Filter letters'
          className='col input-col'
          id='filterInput'
          onChange={ onFilterChange }
          onSubmit={ (e) => e.preventDefault() }
          placeholder='Search for a voter, campaign, or bundle ID'
          type='text'
          value={ letterFilter }
        />
        <i className='fa fa-search col decoration-col' />
        <button className='btn-bundle-status btn-secondary-v2 clear-filter rounded-pill col decoration-col' onClick={ () => updateFilter('') } type='button'>
          <i className='fa fa-times' /> Clear filter
        </button>
      </div>
    </div>
  );
}
