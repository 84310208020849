import React, { Component } from 'react';
import propTypes from 'prop-types';
import Header from './page/Header';
import Footer from './page/Footer';
import Qualify from './Qualify';
import axios from 'axios';
import GA from './lib/GoogleAnalytics';

class Verify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      user_partners: [],
    };
  }

  static get propTypes() {
    return {
      user: propTypes.object,
      auth: propTypes.object,
      match: propTypes.object,
    };
  }

  async getUserPartners() {
    const accessToken = await this.props.auth.getAccessToken();
    const res = await axios
      .get(`${ process.env.REACT_APP_API_URL }/v1/users/self/partners`,
        {
          headers: {
            Authorization: 'Bearer '.concat(accessToken),
          },
        });
    this.setState({ user_partners: res.data });
  }

  addSLPartner = async () => {
    GA.trackEvent({ category: 'userPartnerAdd', action: 'swingleft' });
    const data = {
      partner: 'swingleft',
      source: 'new_user_sl_optin',
    };
    const accessToken = await this.props.auth.getAccessToken();
    await axios.post(`${ process.env.REACT_APP_API_URL }/v1/users/self/partners`, data, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
    });
  };

  updateUser = async (data) => {
    GA.trackEvent({ category: 'profile_update', action: Object.keys(data).sort().join('-') });
    const accessToken = await this.props.auth.getAccessToken();
    await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/self`, data, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
    });
    this.setState({ user: { ...this.state.user, ...data }});
  };

  componentDidMount() {
    this.getUserPartners();
  }

  render() {
    return (
      <div>
        <Header path={ this.props.match.path } />
        <div className='py-5 bg-pattern d-flex justify-content-center'>
          <div className='d-flex w-100' style={{ maxWidth: '700px' }}>
            <div className='bg-white w-100 align-self-center'>
              <Qualify
                addSLPartner={ this.addSLPartner }
                auth={ this.props.auth }
                partners={ this.state.user_partners }
                updateUser={ this.updateUser }
                user={ this.state.user }
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Verify;
