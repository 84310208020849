import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';

import i18n from '../locales/strings.json';

class AdminUserOverrides extends Component {
  static get propTypes() {
    return {
      auth: propTypes.object.isRequired,
      locale: propTypes.string,
      user: propTypes.object.isRequired,
      onUserUpdated: propTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      expandLetterAdoptionOverride: null,
      locale: props.locale || 'en',
    };
  }

  expandLetterAdoptionOverride() {
    return this.state.expandLetterAdoptionOverride || this.props.user.letter_adoption_override;
  }

  letterAdoptionOverrideFromEvent(event) {
    const value = event.target.value;
    if (value === 'default') {
      return null;
    }

    return Number(value);
  }

  async updateUser(payload) {
    const { auth, onUserUpdated, user } = this.props;
    const accessToken = await auth.getAccessToken();
    const response = await axios({
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      url: `${ process.env.REACT_APP_API_URL }/v1/users/${ user.auth0_id }/admin`,
      data: payload,
    });
    onUserUpdated(response.data);
  }

  handleOverrideLetterAdoptionToggle = () => {
    this.setState({ expandLetterAdoptionOverride: !this.expandLetterAdoptionOverride() });
    this.updateUser({
      letter_adoption_override: null,
    });
  };

  handleLetterAdoptionOverrideSelect = (event) => {
    const letterAdoptionOverride = this.letterAdoptionOverrideFromEvent(event);
    const payload = {
      letter_adoption_override: letterAdoptionOverride,
    };

    this.updateUser(payload);
  };

  renderLetterAdoptionOverrideSelection = () => {
    if (!this.expandLetterAdoptionOverride()) {
      return false;
    }

    const value = this.props.user.letter_adoption_override || 'default';

    return (
      <div style={{ marginTop: '1rem' }}>
        <select
          className='form-control'
          onChange={ this.handleLetterAdoptionOverrideSelect }
          value={ value }
        >
          <option value='default'>(default)</option>
          <option value={ 50000 }>50,000</option>
          <option value={ 100000 }>100,000</option>
          <option value={ 200000 }>200,000</option>
        </select>
      </div>
    );
  };

  render() {
    return (
      <div className='overrides'>
        <div className='row'>
          <div className='col-9'>
            <label>{ i18n[this.state.locale]['admin.user.letterAdoptionOverride.label'] }</label><br />
            <span>{ i18n[this.state.locale]['admin.user.letterAdoptionOverride.description'] }</span>
          </div>
          <div className='col'>
            <label className='switch float-end'>
              <input checked={ this.expandLetterAdoptionOverride() } onChange={ this.handleOverrideLetterAdoptionToggle } type='checkbox' />
              <span className='slider round' />
            </label>
          </div>
        </div>
        { this.renderLetterAdoptionOverrideSelection() }
      </div>
    );
  }
}

export default AdminUserOverrides;
