function denormalizeVoterLists(voters) {
  return {
    toPrep: voters.filter(
      (voter) => voter.toPrep,
    ) || [],
    toSend: voters.filter(
      (voter) => voter.toSend,
    ) || [],
    alreadySent: voters.filter(
      (voter) => voter.alreadySent,
    ) || [],
  };
}

function uniqueVoterStates(voters) {
  return [...new Set(voters.map((voter) => voter.state))] || [];
}

export {
  denormalizeVoterLists,
  uniqueVoterStates,
};
