import auth0 from 'auth0-js';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { createContext, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import deprecatedConfig from '../lib/deprecatedConfig';
import logger from '../lib/logger';
import Tracker from '../lib/Tracker';
import { extraTokenClaim } from '../lib/jwt';
import { getGrants, getUserId, getUserPartners, isAuthenticated, nullifySession } from '../lib/user';
import { updateNextSignablePartner } from '../lib/unsignedPartners';
import { useUserProfilePicture } from './UserProfilePictureContext';

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [, setPictureUrl] = useUserProfilePicture();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [auth] = useState(
    setupAuth({
      history,
      queryClient,
      setPictureUrl,
    }),
  );
  return useMemo(() => (
    <AuthContext.Provider value={ auth }>
      { children }
    </AuthContext.Provider>
  ), [auth, children]);
}

AuthProvider.propTypes = {
  children: PropTypes.element,
};

function useAuth() {
  const context = useContext(AuthContext);
  if (context === null) {
    logger.error('useAuth() must be called within a AuthProvider');
  }
  return context;
}

function setupAuth({ history, queryClient, setPictureUrl }) {
  let webAuth;

  function authenticatedEmail() {
    return localStorage.getItem('email');
  }

  function getCallbackOrigin() {
    const currentOrigin = window.location.origin;
    // Even if test logins are enabled, don't use current origin if this is being rendered from the server side.
    return (process.env.REACT_APP_ALLOW_TEST_LOGINS && (process.env.REACT_APP_URL !== currentOrigin))
      ? currentOrigin
      : process.env.REACT_APP_NEXT_JS_URL;
  }

  async function exchangeAuthorizationCode({ code, nonce }) {
    const partner = sessionStorage.getItem('partner') ? sessionStorage.getItem('partner') : '';
    const defaultDistrictId = sessionStorage.getItem('defaultDistrictId') ? sessionStorage.getItem('defaultDistrictId') : '';
    const emailToken = sessionStorage.getItem('emailToken') || '';
    const callbackOrigin = getCallbackOrigin();
    nullifySession();
    await axios.get(
      `${ process.env.REACT_APP_API_URL }/v1/oauth2/logout`,
      { withCredentials: true },
    );
    return axios.get(`${ process.env.REACT_APP_API_URL }/v1/oauth2/callback?code=${ code }&nonce=${ nonce }&partner=${ partner }&defaultDistrictId=${ defaultDistrictId }&callbackOrigin=${ callbackOrigin }`, {
      headers: { Authorization: 'Bearer '.concat(`::${ emailToken }`) },
      withCredentials: true,
    });
  }

  function forceLogin() {
    // Remember where we were so we can navigate back post login
    rememberLocation();
    logger.error('forceLogin: location stored, redirecting to /');
    history.replace('/');
  }

  async function getAccessToken() {
    //  check to see if the access token is still valid, then get a new one if it isn't
    const accessToken = localStorage.getItem('access_token') || '';
    const emailToken = sessionStorage.getItem('emailToken') || '';
    const partner = sessionStorage.getItem('partner') ? sessionStorage.getItem('partner') : '';
    let token;
    if (isAuthenticated() && accessToken !== '') {
      if (isQualified() && isEmailVerified()) {
        token = accessToken;
      } else {
        // If user isn't qualified, check to see if the database has changed since
        //  last checked.  If not, then return the token we have.  If it has changed,
        //  and the user is now qualified, then don't return what we have, and instead
        //  fall down to the next code block to refresh the token to reflect the newly
        //  qualified status
        try {
          const userData = await axios({
            method: 'PATCH',
            headers: {
              Authorization: 'Bearer '.concat(`${ accessToken }:${ emailToken }`),
            },
            url: `${ process.env.REACT_APP_API_URL }/v1/users/self`,
          });
          const user = userData.data;
          tracker.logEvent('Auth:function:getAccessToken', {
            status: 200,
            message: 'got userData from PATCH self',
            emailToken: emailToken.length,
            accessToken: accessToken.length,
            serverQualState: user.qual_state,
            localQualState: localStorage.getItem('user_qualified_state'),
            serverEmailVerified: user.email_verified,
            localEmailVerified: extraTokenClaim('emailVerified'),
          });
          // If nothing has changed, then just return the existing token
          if (
            user.qual_state === localStorage.getItem('user_qualified_state')
            && user.email_verified === extraTokenClaim('emailVerified')
          ) {
            token = accessToken;
          }
        } catch (err) {
          tracker.logEvent('Auth:function:getAccessToken', {
            status: err.response.status,
            message: 'error PATCHing self',
            emailToken: emailToken.length,
            accessToken: accessToken.length,
            localQualState: localStorage.getItem('user_qualified_state'),
            localEmailVerified: extraTokenClaim('emailVerified'),
          });
          if (err.response.status === 404) {
            token = accessToken;
          } else if (err.response.status === 409) {
            throw err;
          }
        }
      }
    }
    try {
      if (token) {
        return token;
      } else {
        const results = await axios({
          method: 'POST',
          withCredentials: true,
          url: `${ process.env.REACT_APP_API_URL }/v1/oauth2/token`,
          headers: {
            Authorization: `Bearer ::${ emailToken }`,
          },
          data: {
            partner,
          },
        });
        const authResult = results.data;
        await handleAuthResult(authResult);
        return localStorage.getItem('access_token');
      }
    } catch (err) {
      // If we attempted to update the email to a dup email, trap the error here
      if (err.response && err.response.data && err.response.data.duplicateEmail && err.response.data.status === 409) {
        tracker.logEvent('Auth:function:getAccessToken', {
          status: err.response.data.status,
          message: 'error getting tokens',
        });
        await logout({ returnTo: `/auth-error?provider=${ err.response.data.provider }&duplicate=${ err.response.data.duplicateProvider }&referenceCode=${ err.response.data.userError.referenceCode }` });
      } else if (err.response && err.response.status && err.response.status === 401) {
        // in this case, we have a specific error response, so we know the user needs to log back in
        nullifySession(queryClient);
        await axios.get(`${ process.env.REACT_APP_API_URL }/v1/oauth2/logout`, { withCredentials: true });
        tracker.logEvent('Auth:function:getAccessToken', {
          status: 401,
          message: 'unauthorized error getting tokens',
        });
        forceLogin();
      } else {
        tracker.logEvent('Auth:function:getAccessToken', {
          status: err.response && err.response.data ? err.response.data.status : 500,
          message: 'unspecified error getting tokens',
        });
        // If this has failed twice before, then log the user out
        //  Don't want to do it the first time, in case it's just a network blip - don't
        //  unnecessarily log the user out if we don't have to.
        const tokenFailureCount = parseInt(sessionStorage.getItem('tokenFailureCount') || '0') + 1;
        sessionStorage.setItem('tokenFailureCount', `${ tokenFailureCount }`);
        if (tokenFailureCount > 2) {
          nullifySession(queryClient);
          await axios.get(`${ process.env.REACT_APP_API_URL }/v1/oauth2/logout`, { withCredentials: true });
          tracker.logEvent('Auth:function:getAccessToken', {
            status: 401,
            message: 'too many failures - logging the user out',
          });
          sessionStorage.removeItem('tokenFailureCount');
        }
        forceLogin();
      }
    }
  }

  function getLetterAdoptionOverride() {
    return localStorage.getItem('letter_adoption_override');
  }

  async function getWebAuth() {
    if (!webAuth) {
      // eslint-disable-next-line import/no-named-as-default-member
      webAuth = new auth0.WebAuth({
        domain: await deprecatedConfig.get('auth0BrowserDomain') || await deprecatedConfig.get('auth0Domain'),
        clientID: await deprecatedConfig.get('auth0ClientId'),
        redirectUri: `${ process.env.REACT_APP_NEXT_JS_URL }/callback`,
        audience: await deprecatedConfig.get('auth0Id'),
        responseType: 'code',
        scope: 'openid profile email read:user offline_access',
      });
    }
    return webAuth;
  }

  async function handleAuthentication({ location }) {
    const params = queryString.parse(location.search);
    if (params.state !== localStorage.getItem('oauth_state')) {
      logger.error('there was an error logging in');
      tracker.logEvent('Auth:function:handleAuthentication', {
        status: 400,
        message: 'state does not match oauth_state',
        localState: localStorage.getItem('oauth_state'),
        urlState: params.state,
        urlKeys: params ? Object.keys(params).join(',') : '',
      });
      history.replace('/');
    } else {
      try {
        const results = await exchangeAuthorizationCode({
          code: params.code,
          nonce: localStorage.getItem('oauth_nonce'),
        });
        localStorage.removeItem('oauth_state');
        localStorage.removeItem('oauth_nonce');
        const authResult = results.data;

        return handleAuthResult(authResult);
      } catch (err) {
        logger.error(err);
        tracker.logEvent('Auth:function:handleAuthentication', {
          status: 500,
          message: 'error exchanging auth code',
        });
        history.replace('/');
      }
    }
  }

  async function handleAuthResult(authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      await setSession(authResult);
      if (authResult.userError && authResult.userError.duplicateEmail) {
        logout({ returnTo: `/auth-error?provider=${ authResult.userError.provider }&duplicate=${ authResult.userError.duplicateProvider }&referenceCode=${ authResult.userError.referenceCode }` });
      } else if (!isEmailVerified()) {
        if (authResult.email) {
          history.replace(`/verify-email/${ authResult.email }`);
        } else {
          history.replace(`/verify-email`);
        }
      } else if (!isQualified(authResult.qualifiedState)) {
        history.replace('/verify');
      } else {
        const postLoginPath = localStorage.getItem('postLoginPath');
        if (postLoginPath && postLoginPath !== '') {
          localStorage.removeItem('postLoginPath');
          history.replace(postLoginPath);
        } else {
          history.replace('/dashboard');
        }
      }
    }
  }

  function isAdmin() {
    return localStorage.getItem('is_admin') === 'true';
  }

  function isEmailVerified() {
    return extraTokenClaim('emailVerified');
  }

  function isQualified(qualifiedStateValue) {
    const qualifiedState = qualifiedStateValue || localStorage.getItem('user_qualified_state');
    return qualifiedState && ['qualified', 'super_qualified'].indexOf(qualifiedState) >= 0;
  }

  async function login(showLoginPage, extraArgs = {}) {
    const stateNoncePromises = [];
    for (let i = 0; i < 2; i++) {
      stateNoncePromises.push(axios({
        method: 'GET',
        url: `${ process.env.REACT_APP_API_URL }/v1/random`,
      }));
    }
    await getWebAuth();
    const values = await Promise.all(stateNoncePromises);
    localStorage.setItem('oauth_state', values[0].data);
    localStorage.setItem('oauth_nonce', values[1].data);
    webAuth.authorize({
      state: values[0].data,
      nonce: values[1].data,
      ...extraArgs,
    });
  }

  async function logout({ returnTo = '' } = {}) {
    tracker.logEvent('Auth:function:logout');
    const webAuth = await getWebAuth();
    //Use current location rather than configured value, to support pass-through pages from Next.js.
    const appUrl = window.location.origin;
    const clientId = await deprecatedConfig.get('auth0ClientId');
    nullifySession(queryClient);
    await axios.get(`${ process.env.REACT_APP_API_URL }/v1/oauth2/logout`, {
      withCredentials: true,
    });
    webAuth.logout({
      returnTo: `${ appUrl }${ returnTo }`,
      client_id: clientId,
    });
  }

  function rememberLocation() {
    const postLoginUrl = window.location.href;
    const postLoginPath = postLoginUrl.replace(/^https?:\/\/[^/]+/, '');
    localStorage.setItem('postLoginPath', postLoginPath);
  }

  async function setSession(authResult) {
    const expiresAt = JSON.stringify(
      (authResult.expiresIn * 1000) + new Date().getTime(),
    );
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('user_id', authResult.idTokenPayload.sub);
    localStorage.setItem('email', authResult.email);
    localStorage.setItem('grants', JSON.stringify(authResult.grants));
    localStorage.setItem('groups', JSON.stringify(authResult.groups));
    localStorage.setItem('user_name', authResult.idTokenPayload.name);
    localStorage.setItem('partners', JSON.stringify(authResult.partners));
    localStorage.setItem('is_admin', authResult.isAdmin);
    tracker.setUserId(authResult.email);
    // Unset the following values to trigger recalling the token-exchange endpoint to get a new access_token_supplement
    localStorage.setItem('user_qualified_state', authResult.qualifiedState);
    if (authResult.letterAdoptionOverride) {
      localStorage.setItem('letter_adoption_override', authResult.letterAdoptionOverride);
    }

    // if user is logging in with the wrong provider it will cause an infinite redirect
    if (!authResult.userError.duplicateEmail) {
      await updateNextSignablePartner({ getAccessToken });
    }

    await getWebAuth();
    webAuth.client.userInfo(authResult.accessToken.split(':')[0], (err, profile) => {
      setPictureUrl(profile.picture);
    });
    sessionStorage.removeItem('tokenFailureCount');
  }

  function getUserName() {
    return localStorage.getItem('user_name');
  }

  const auth = {
    authenticatedEmail,
    forceLogin,
    getAccessToken,
    getGrants,
    getLetterAdoptionOverride,
    getUserId,
    getUserName,
    getUserPartners,
    handleAuthentication,
    isAdmin,
    isAuthenticated,
    isEmailVerified,
    isQualified,
    login,
    logout,
    rememberLocation,
  };
  const tracker = new Tracker({ auth });
  return auth;
}

export { AuthContext, AuthProvider, setupAuth as __setupAuth, useAuth };
