import { Link } from 'react-router-dom';

import { message } from '../lib/deprecated_i18n';
import { useAuth } from '../contexts/AuthContext';

import Logo from '../components/Logo';

import '../scss/lib/_buttons.scss';
import '../scss/lib/_typography.scss';
import '../scss/page/_footer.scss';

const helpUrl = 'https://help.votefwd.org';

function Footer() {
  const auth = useAuth();

  return (
    <footer className='footer'>
      <div className='top'>
        <div className='container'>
          <div className='row p-4'>
            <div className='col-xs-12 col-lg-6 left vstack justify-content-center p-8'>
              <div className='logo d-flex'>
                <Logo />
              </div>
              <div className='about'>
                <p className='text-white lead'>
                  Our small team is hard at work developing new campaigns,
                  analyzing data, and working to improve the letter writing
                  experience for volunteers like you! If you can, consider
                  making a contribution to help keep our work going.
                </p>
              </div>
              <div className='donate-button-container'>
                <a
                  className='btn btn-primary-white'
                  href='https://secure.actblue.com/donate/vote-forward-c4?refcode=webfooter'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Support this work
                </a>
              </div>
            </div>
            <div className='col-xs-12 col-lg-6 links-container vstack right'>
              <div className='link-group vstack'>
                <h6 className='text-white'>About us</h6>
                <div className='link-list hstack'>
                  <Link to='/about-us'>Who we are</Link>
                  <span className='separator' />
                  <Link to='/blog'>Blog</Link>
                  <span className='separator' />
                  <Link to='/open-positions'>Positions</Link>
                  <span className='separator' />
                  <Link to='/contact'>Contact</Link>
                </div>
              </div>
              <div className='link-group vstack'>
                <h6 className='text-white'>Resources</h6>
                <div className='link-list hstack'>
                  <Link to='/partners'>Partners</Link>
                  <span className='separator' />
                  <a href='/events' target='_blank'>
                    Events
                  </a>
                  <span className='separator' />
                  <Link to='/faq'>FAQs</Link>
                </div>
              </div>
              <div className='link-group vstack'>
                <h6 className='text-white'>Settings</h6>
                <div className='link-list hstack'>
                  <Link
                    to={
                      auth.isAuthenticated()
                        ? '/profile'
                        : '/update-preferences'
                    }
                  >
                    Email Preferences
                  </Link>
                  <span className='separator' />
                  <Link to='/opt-out'>Opt Out</Link>
                  <span className='separator' />
                  <a href={ helpUrl } rel='noopener noreferrer' target='_blank'>
                    Help
                  </a>
                </div>
              </div>
              <div className='link-group vstack'>
                <h6 className='text-white'>Voting</h6>
                <div className='link-list hstack'>
                  <Link to='/pledge'>Pledge</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='copyright-container col-xs-12 col-sm-6'>
              <p className='text-white small m-0'>
                { message('footer.copyright') }
                  &nbsp;
                <Link to='/privacy-policy'>Privacy</Link>
                &nbsp;/&nbsp;<Link to='/terms-of-use'>Terms</Link>
              </p>
            </div>
            <div className='col-xs-12 col-sm-6 social-icons h-100 text-center align-middle text-lg-end my-auto justify-content-md-end justify-content-sm-start '>
              <ul className='list-inline mb-0 hstack justify-content-sm-start justify-content-md-end'>
                <li className='list-inline-item mb-0'>
                  <a
                    href='https://www.instagram.com/votefwd/'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <i className='text-lightblue fa fa-instagram fa-2x fa-fw' />
                  </a>
                </li>
                <li className='list-inline-item me-3 mb-0'>
                  <a
                    href='https://twitter.com/votefwd'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <i className='text-lightblue fa fa-twitter fa-2x fa-fw' />
                  </a>
                </li>
                <li className='list-inline-item me-3 mb-0'>
                  <a
                    href='https://fb.me/votefwd'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <i className='text-lightblue fa fa-facebook fa-2x fa-fw' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

