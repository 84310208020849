import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import useLocalStorageState from '../lib/hooks/useLocalStorageState';

import '../scss/page/_banner.scss';
import closeIcon from '../images/page/icon-close.svg';

function getLocalStorageKey(id) {
  return `vfBannerRendered-${ id }`;
}
function Banner({ data }) {
  const localStorageKey = useMemo(
    () => getLocalStorageKey(data?.id),
    [data?.id],
  );
  const [hasBannerRendered, setHasBannerRendered] = useLocalStorageState(
    localStorageKey,
    false,
  );
  const [isBannerVisible, setIsBannerVisible] = useState(!hasBannerRendered);


  const toggleIsBannerVisible = () => {
    setIsBannerVisible(false);
    setHasBannerRendered(true);
  };

  if (!isBannerVisible) {
    return null;
  }

  if (!data?.content) {
    return null;
  }

  return (
    <div className='announcement-banner'>
      <p>
        <span dangerouslySetInnerHTML={{ __html: data.content }} />
        { ' ' }
        <a
          className='fw-bold btn-link-underline'
          href={ data.ctaUrl }
          rel='noreferrer noopener'
          target='_blank'
        >
          { data.ctaText }
        </a>

      </p>
      <button aria-label='Close' className='btn' onClick={ toggleIsBannerVisible } >
        <img alt='Close this announcement' aria-hidden src={ closeIcon } />
      </button>
    </div>
  );
}
Banner.propTypes = {
  data: PropTypes.object,
};

export default Banner;
