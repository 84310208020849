import PropTypes from 'prop-types';

function CampaignDescription({ isLabs, longDescription }) {
  return isLabs && longDescription
    ? (
      <div
        className='bg-blue bottom-50 description fs-6 fw-normal mb-5 pb-4 px-4 pt-3 position-absolute start-0 text-white w-100 z-1000'
      >
        { longDescription }
        <svg
          className='bi bi-caret-down-fill position-absolute start-50 top-100 translate-middle'
          fill='#2C74FF'
          height='1em'
          viewBox='0 0 16 16'
          width='1em'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'
          />
        </svg>
      </div>
      )
    : null;
}

CampaignDescription.propTypes = {
  isLabs: PropTypes.bool.isRequired,
  longDescription: PropTypes.string,
};

export default CampaignDescription;
