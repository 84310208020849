import axios from 'axios';
import i18n from './locales/strings.json';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AuthContext } from './contexts/AuthContext';
import Footer from './page/Footer';
import GA from './lib/GoogleAnalytics';
import Header from './page/Header';
import { decodeToken } from './lib/jwt';
import logger from './lib/logger';
import Tracker from './lib/Tracker';
import { Link } from 'react-router-dom';

const displayConfig = {
  'forgot.password': {
    emailForm: true,
  },
  'register.user': {
    socialButtons: true,
    emailForm: true,
  },
  'password.updated': {
    loginButton: true,
  },
  'verify.email': {
    emailForm: true,
  },
  'update.preferences': {
    emailForm: true,
  },
};

class VerifyEmailRequest extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const emailToken = sessionStorage.getItem('emailToken');
    let email = '';
    let emailFixed = false;
    if (props.match?.params?.email) {
      email = props.match.params.email;
      emailFixed = true;
    } else if (emailToken && emailToken !== '') {
      try {
        const token = decodeToken(emailToken);
        email = token.email;
      } catch (err) {
        logger.error(err);
      }
    }
    this.state = {
      defaultDistrictId: sessionStorage.getItem('defaultDistrictId') || '',
      disableButton: false,
      email,
      emailError: '',
      emailFixed,
      emailSent: false,
      getStartedMessage: props.getStartedMessage,
      hideFooter: props.hideFooter,
      hideHeader: props.hideHeader,
      hideMasthead: props.hideMasthead,
      landingPage: props.landingPage,
      locale: props.locale,
      partner: sessionStorage.getItem('partner') || '',
      purpose: props.purpose,
      socialButtonPurpose: props.purpose === 'forgot.password' ? 'login' : 'register',
    };
    this.tracker = new Tracker({ auth: this.context });
  }

  verifyForm = () => {
    if (this.state.email === '') {
      this.setState({ emailError: i18n[this.state.locale]['email.required.message'] });
      return false;
    }
    if (!this.state.email.match(/([^@]+)@([^@]+)\.[^@]+/)) {
      this.setState({ emailError: i18n[this.state.locale]['email.format.error.message'] });
      return false;
    }
    return true;
  };

  verifyFormReset = () => {
    this.setState({ emailError: '' });
  };

  socialLogin = (connection) => {
    GA.trackEvent({ category: 'click', action: `${ connection }Login`, label: this.state.purpose });
    this.context.login(false, { connection });
  };

  submitVerifyEmailRequestForm = async () => {
    if (this.verifyForm()) {
      GA.trackEvent({ category: 'click', action: 'submitVerifyEmail', label: this.state.purpose });
      this.setState({ disableButton: true });
      try {
        await axios.post(`${ process.env.REACT_APP_API_URL }/v1/users/verifyEmail`, {
          email: this.state.email,
          purpose: this.state.purpose,
          partner: this.state.partner,
          defaultDistrictId: this.state.defaultDistrictId,
          locale: this.state.locale,
        });
        this.tracker.logEvent('VerifyEmailRequest:function:sendEmail', { status: 200 });
        this.setState({ emailSent: true });
        setTimeout(
          () => {
            this.setState({ disableButton: false });
          },
          1000 * 10,
        );
      } catch (err) {
        logger.error(err);
        this.tracker.logEvent('VerifyEmailRequest:function:sendEmail', { status: 500 });
        this.setState({ disableButton: false });
      }
    }
  };

  logout = () => {
    GA.trackEvent({
      category: 'click',
      action: 'clickedLogOutButton',
    });
    this.context.logout();
  };

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  _handleKeyDown = (e) => {
    this.verifyFormReset();
    if (e.key === 'Enter' && !this.state.disableButton) {
      this.submitVerifyEmailRequestForm();
    }
  };

  login = () => {
    if (this.props.rememberLocation) {
      this.context.rememberLocation();
    }
    this.context.login(true);
  };

  loginViaFacebook = () => this.socialLogin('facebook');

  loginViaGoogle = () => this.socialLogin('google-oauth2');

  loginWithHint = () => this.context.login(false, {
    login_hint: this.state.email,
  });

  render() {
    const toggles = displayConfig[this.state.purpose];
    const {
      isAuthenticated,
      authenticatedEmail,
    } = this.context;
    let notYou = i18n[this.state.locale]['not.you'];
    notYou = notYou.replace('{{email}}', authenticatedEmail() || 'you');

    const verifyEmailRequestForm = (
      <div>
        <div className={ isAuthenticated() && this.state.purpose === 'register.user' ? 'hidden' : 'mb-3' }>
          <div className={ toggles.socialButtons ? 'card-body inner' : 'hidden' }>
            <div className='row mb-3'>
              <div className='social-button-column'>
                <button className='btn-social-login' onClick={ this.loginViaFacebook }>
                  <img alt='Facebook' src='/images/bigsend/tbs_login_icon_fb.png' />
                  { i18n[this.state.locale][`${ this.state.socialButtonPurpose }.facebook.buttontext`] }
                </button>
              </div>
              <div className='social-button-column'>
                <button className='btn-social-login' onClick={ this.loginViaGoogle }>
                  <img alt='Google' src='/images/bigsend/tbs_login_icon_google.png' />
                  { i18n[this.state.locale][`${ this.state.socialButtonPurpose }.google.buttontext`] }
                </button>
              </div>
            </div>
          </div>
          <div className={ toggles.emailForm ? 'email-signup' : 'hidden' }>
            <p className='instructions'>{ i18n[this.state.locale][`${ this.state.purpose }.instructions`] }</p>
            <input
              className={ ['form-control', 'login-widget', this.state.emailError === '' ? '' : 'is-invalid'].join(' ') }
              // eslint-disable-next-line react/no-unknown-property
              email={ this.state.email }
              name=''
              onChange={ this.handleChange }
              onKeyDown={ this._handleKeyDown }
              placeholder={ i18n[this.state.locale]['email.placeholder'] }
              readOnly={ this.state.emailFixed }
              type='email'
              value={ this.state.email }
            />
            <small className='text-danger' id='passwordHelp'>
              { this.state.emailError }
            </small>
            <br />
            <div className='card-body inner'>
              <button className={ ['btn', 'btn-primary-red', 'w-100', 'justify-content-center', this.state.disableButton ? 'disabled' : ''].join(' ') } disabled={ this.state.disableButton } onClick={ this.submitVerifyEmailRequestForm } style={{ color: 'white' }}>
                { i18n[this.state.locale][`${ this.state.purpose }.verification.buttontext`] }
              </button>
            </div>
          </div>
          <button
            className={ ['btn', 'w-100', 'btn-primary-red', 'w-100', 'justify-content-center', toggles.loginButton ? '' : 'hidden'].join(' ') }
            onClick={ this.loginWithHint }
            style={{ color: 'white' }}
          >
            { i18n[this.state.locale]['login.email.buttontext'] }
          </button>
        </div>
        <div className={ this.state.purpose === 'register.user' && isAuthenticated() ? 'mb-3' : 'hidden' }>
          <div className='email-signup'>
            <div className='card-body inner'>
              <Link
                className='btn btn-primary-red w-100 justify-content-center'
                style={{ color: 'white' }}
                to='/dashboard'
              >{ i18n[this.state.locale]['visit.dashboard'] }
              </Link>

              <div className='not-you'>
                <span>{ i18n[this.state.locale]['already.logged.in'] }<br /></span>
                { notYou }
                <button
                  className='btn btn-link'
                  onClick={ this.logout }
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    let sentmessage = i18n[this.state.locale]['email.sentmessage'];
    sentmessage = sentmessage.replace('{{email}}', this.state.email);

    const helpMessage = i18n[this.state.locale]['email.sentmessage.help'];

    const verifyEmailRequestFormSubmitted = (
      <div>
        <h3>{ sentmessage }</h3>
        <p>{ helpMessage }</p>
      </div>
    );
    let titleMessage;
    if (this.state.emailSent) {
      titleMessage = i18n[this.state.locale][`${ this.state.purpose }.thank.you`];
    } else {
      titleMessage = i18n[this.state.locale][`${ this.state.purpose }.title`];
    }

    return (
      <div className='core-login'>
        { this.state.hideHeader ? undefined : (<div><Header /><br /></div>) }
        <div className={ ['login-widget'].join(' ') }>
          <div className='card'>
            <article
              className={ [
                'card-body',
                !isAuthenticated() ? 'unauthenticated-reg-form' : undefined,
                this.state.emailSent ? 'reg-form-email-sent' : undefined,
              ].filter(Boolean).join(' ') }
              style={{ display: 'block' }}
            >
              { this.state.hideMasthead
                ? undefined
                : (
                  <img
                    alt='logo'
                    className='center-image'
                    height='58px;'
                    src='https://storage.googleapis.com/voteforward-production-static/bg-masthead.png'
                    width='auto'
                  />
                  ) }
              <h4
                className={ [
                  'card-title',
                  'mt-1',
                  isAuthenticated() ? 'text-center' : undefined,
                  this.state.landingPage ? 'bigsend-main-title' : undefined,
                  isAuthenticated() && this.state.purpose === 'register.user' ? 'hidden' : undefined,
                ].join(' ') }
                id='register-user'
              >
                { titleMessage }
              </h4>
              { this.state.getStartedMessage && !isAuthenticated() && !this.state.emailSent
                ? (
                  <h3>{ i18n[this.state.locale]['register.post.redirect.message'] }</h3>)
                : undefined }
              { this.state.emailSent ? verifyEmailRequestFormSubmitted : verifyEmailRequestForm }
            </article>
            <div className={ this.context.isAuthenticated() ? 'hidden' : 'card-footer' }>
              <p className='already-signed-up mb-0'>
                { i18n[this.state.locale]['already.have.account'] }<button className='btn btn-link' id='loginButton' onClick={ this.login }>{ i18n[this.state.locale]['login.to.get.started'] }.</button>
              </p>
            </div>
          </div>
          <small className='text-white'>Got a letter? { ' ' }
            <Link to='/program-overview'>
              See Program Overview
            </Link>
            { ' ' }&#9702;{ ' ' }
            <Link to='/pledge'>Pledge To Vote</Link>
            { ' ' }&#9702;{ ' ' }
            <Link to='/opt-out'>Opt Out</Link>
          </small>
        </div>
        <br />
        { this.state.hideFooter ? undefined : <Footer /> }
      </div>
    );
  }
}

VerifyEmailRequest.propTypes = {
  getStartedMessage: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideMasthead: PropTypes.bool,
  landingPage: PropTypes.bool,
  locale: PropTypes.string,
  match: PropTypes.object,
  purpose: PropTypes.string,
  rememberLocation: PropTypes.bool,
};

VerifyEmailRequest.defaultProps = {
  getStartedMessage: false,
  hideFooter: false,
  hideHeader: false,
  hideMasthead: false,
  landingPage: false,
  locale: 'en',
  purpose: 'forgot.password',
  rememberLocation: false,
};

export default VerifyEmailRequest;
