import { isCampaignCompleted } from './accessors';
import { stateNamesByState } from './constants';

function countCampaigns(campaigns) {
  return campaigns.length;
}

function selectAvailableCampaigns(campaigns) {
  return campaigns.filter((campaign) => !isCampaignCompleted(campaign));
}

function selectCompletedCampaigns(campaigns) {
  return campaigns.filter((campaign) => isCampaignCompleted(campaign));
}

function selectCampaignStates(campaigns) {
  const allMappedStates = campaigns.map(({ state }) => state.toUpperCase());
  const uniqueMappedStates = new Set(allMappedStates);
  return [...uniqueMappedStates].map((state) => [state, stateNamesByState[state]]);
}

function selectPoliticalCampaigns(campaigns) {
  return campaigns.filter((campaign) => campaign.isPolitical || campaign.taxType === 'political');
}

function selectSocialCampaigns(campaigns) {
  return campaigns.filter((campaign) => campaign.isSocial || campaign.taxType === 'social');
}

export {
  countCampaigns,
  selectAvailableCampaigns,
  selectCampaignStates,
  selectCompletedCampaigns,
  selectPoliticalCampaigns,
  selectSocialCampaigns,
};
