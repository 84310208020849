import React, { Component } from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
import GA from './lib/GoogleAnalytics';
import { message } from './lib/deprecated_i18n';

const letterSampleMinLength = 50;

class LetterSampleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleError: false,
      sampleVal: '',
      initialSampleVal: '',
      auth0_id: localStorage.getItem('user_id') || '',
      auth: this.props.auth,
      showSampleEdit: false,
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
    };
  }

  async getUser(auth0_id) {
    const accessToken = await this.props.auth.getAccessToken();
    const res = await axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      url: `${ process.env.REACT_APP_API_URL }/v1/users/${ auth0_id }`,
    });
    const newState = {
      sampleVal: res.data.letter_writing_sample || '',
      initialSampleVal: res.data.letter_writing_sample || '',
    };
    if (this.state.sampleVal === '') {
      newState.showSampleEdit = true;
    }
    this.setState(newState);
  }

  toggleSampleEdit = () => {
    this.setState({ showSampleEdit: !this.state.showSampleEdit });
  };

  cancelSampleEdit = () => {
    this.setState({
      sampleVal: this.state.initialSampleVal,
      showSampleEdit: false,
    });
  };

  handleSampleChange = (event) => {
    this.setState({ sampleVal: event.target.value, sampleError: false });
  };

  updateUser = async (key, value) => {
    GA.trackEvent({ category: 'profile_update', action: key });
    const data = {};
    data[key] = value;
    const accessToken = await this.props.auth.getAccessToken();
    await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/self`, data, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
    });
    this.setState({
      nameVal: this.state.user.preferred_name || '',
      user: { ...this.state.user, [key]: value },
    });
  };

  handleSampleSubmit = (event) => {
    event.preventDefault();
    const letterWritingSample = this.state.sampleVal;
    if (!letterWritingSample || letterWritingSample.length < letterSampleMinLength) {
      // Render error if there is no sample or the length is too short
      this.setState({ sampleError: true });
      return false;
    } else {
      this.updateUser('letter_writing_sample', this.state.sampleVal);
      this.toggleSampleEdit();
    }
  };

  componentDidMount() {
    this.getUser(this.state.auth0_id);
  }

  render() {
    const { isAuthenticated } = this.state.auth;
    let sampleErrorMessage = 'Please share your response to the prompt.';
    if (this.state.sampleError && this.state.sampleVal.length < letterSampleMinLength && this.state.sampleVal.length > 0) {
      sampleErrorMessage = 'Your response seems a bit too brief. Can you say more?';
    }
    return (
      <div>
        { isAuthenticated()
          && (
            <div>
              <div className={ !this.state.showSampleEdit ? null : 'hidden' }>
                <div>
                  <p><i>&ldquo;{ this.state.sampleVal }&rdquo;</i></p>
                  <button className='btn btn-primary btn-sm' onClick={ this.toggleSampleEdit }>Edit</button>
                </div>
              </div>
              <div className={ this.state.showSampleEdit ? null : 'hidden' }>
                <form onSubmit={ this.handleSampleSubmit }>
                  <textarea
                    className='form-control js-profile-input'
                    id='letterWritingSample'
                    onChange={ this.handleSampleChange }
                    placeholder={ message('verify.lettermessage.prefill') }
                    required
                    rows='4'
                    value={ this.state.sampleVal }
                  />

                  { this.state.sampleError && (
                    <div
                      className='alert alert-danger alert-sm mt-3 mb-3 center'
                      role='alert'
                    >
                      { sampleErrorMessage }
                    </div>
                  ) }

                  <div className='py-4 btn-group w-100'>
                    { this.state.initialSampleVal
                    && (
                      <button className='btn btn-secondary btn-sm' onClick={ this.cancelSampleEdit } type='button'>
                        Cancel
                      </button>
                    ) }
                    <button className='btn btn-primary btn-sm' type='submit'>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) }
      </div>
    );
  }
}

export default LetterSampleForm;
