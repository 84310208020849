import classNames from 'classnames';
import PropTypes from 'prop-types';

function Loading({ className, style }) {
  const containerStyle = {
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: 'white',
    ...style,
  };

  return (
    <div
      className={ classNames('loading-spinner', className) }
      style={ containerStyle }
    >
      <div className='p-3 fa fa-pulse fa-spinner waiting fa-3x' title='Loading' />
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Loading;
