import moment from 'moment';

// Determines if date is past according to date in local time zone
// { hours: 0 } is meant to match election and mail dates which by default start at midnight, so that after really means the next day or greater.
function isDatePast(date) {
  const [yyyy, mm, dd] = date.split('T')[0].split('-');
  const justDate = moment([yyyy, parseInt(mm) - 1, dd]);
  return moment({ hours: 0 }).isAfter(justDate);
}

function isDateSameOrPast(date) {
  const [yyyy, mm, dd] = date.split('T')[0].split('-');
  const justDate = moment([yyyy, parseInt(mm) - 1, dd]);
  return moment({ hours: 0 }).isSameOrAfter(justDate);
}

/**
 * takes a date string, assumes the *date* part is the date we want to display to the user
 * (e.g. 2020-06-14T00:00:00.000Z shouldn't mean 2020-06-13 at 8pm, but rather 2020-06-14 in the timezone of the user)
 * So returns a date object at the date part of the supplied date at midnight of the user's timezone.
 * @param {string} date either in zulu time (e.g. 2020-06-14T00:00:00.000Z) or without the timestamp (2020-06-14)
 */
function createTimezoneDate(date) {
  const [yyyy, mm, dd] = date.split('T')[0].split('-');
  const hh = (new Date()).getTimezoneOffset() / 60;
  // we must subtract 1 from mm because oddly, the Date object month parameter goes from 0-11
  return new Date(yyyy, parseInt(mm) - 1, dd, hh);
}

export { createTimezoneDate, isDatePast, isDateSameOrPast };
