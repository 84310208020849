
import propTypes from 'prop-types';
import moment from 'moment';
import t from '../../lib/deprecated_i18n';
import Loading from '../../components/Loading';

TrainingRequiredDetails.propTypes = {
  goToTraining: propTypes.func.isRequired,
  training: propTypes.object.isRequired,
  updateTraining: propTypes.func.isRequired,
};

export default function TrainingRequiredDetails({
  training,
  goToTraining,
  updateTraining,
}) {
  const startTraining = async () => {
    await updateTraining({ startedAt: moment().toISOString() });
    goToTraining();
  };
  const finishTraining = () => (updateTraining({ finishedAt: moment().toISOString() }));

  // wait for training data to be available
  if (training.pending) {
    return (
      <div>
        <h4>Checking training status...</h4>
        <Loading />
      </div>
    );
  }

  return (
    <div className='training-required'>
      <h4>{ t('campaign.trainingRequired.header') }</h4>
      <p>{ t('campaign.trainingRequired.description') }</p>
      <div className='row'>
        <div className='col-md'>
          <button
            className='btn btn-primary w-100 mt-1'
            onClick={ training.started_at ? goToTraining : startTraining }
            role='button'
          >
            {
              training.started_at
                ? t('campaign.trainingRequired.resume')
                : t('campaign.trainingRequired.start')
            }
          </button>
        </div>
        <div className='col-md'>
          <button
            className='btn btn-primary w-100 mt-1'
            disabled={ !training.started_at }
            onClick={ finishTraining }
          >
            { t('campaign.trainingRequired.finish') }
          </button>
        </div>
      </div>
    </div>
  );
}
