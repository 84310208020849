import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';

import CampaignDescription from './CampaignDescription';
import CampaignLabels from './CampaignLabels';
import MailDate from '../MailDate';

import noop from '../lib/functions/noop';
import { segmentTrackWrapper } from '../lib/segment';

import '../scss/lib/_borders.scss';
import '../scss/lib/_typography.scss';
import '../scss/campaigns/_campaign-card.scss';

function CampaignCard({
  availableVoterCount,
  handleCampaignSelection,
  isCompleted,
  isLabs,
  isPolitical,
  isPriority,
  isSocial,
  isVoterRegistration,
  labsDescription,
  mailEndDate,
  mailStartDate,
  partnerSlug,
  slug,
  title,
  totalVoterCount,
  mailDateType,
}) {
  const { t } = useTranslation('campaigns');

  const adoptedVoterCount = useMemo(
    () => totalVoterCount - availableVoterCount,
    [availableVoterCount, totalVoterCount],
  );
  const progressBarWidth = useMemo(
    () => {
      if (isCompleted) {
        return '100%';
      }
      return totalVoterCount > 0
        ? `${ (adoptedVoterCount / totalVoterCount) * 100 }%`
        : '0';
    },
    [adoptedVoterCount, isCompleted, totalVoterCount],
  );
  const campaignPath = useMemo(
    () => (
      partnerSlug
        ? `/${ partnerSlug }/district/${ slug }`
        : `/district/${ slug }`
    ),
    [partnerSlug, slug],
  );

  const handleCampaignSelectionWrapper = segmentTrackWrapper(handleCampaignSelection, 'CampaignCard:click', {
    campaignSlug: slug,
    partnerSlug,
    isPolitical,
    isSocial,
  });

  return (
    <Link
      className='campaign campaign-card col-12 col-sm-6 col-md-4 col-xl-3 d-block hover-grow mb-3 reset-link'
      data-campaign-slug={ slug }
      key={ slug }
      onClick={ handleCampaignSelectionWrapper }
      to={ campaignPath }
    >
      <div className='border border-darkblue pb-2 pt-2 pt-sm-3 px-3 rounded shadow'>
        <CampaignLabels
          isBundleDisplay={ false }
          isLabs={ isLabs }
          isPolitical={ isPolitical }
          isPriority={ isPriority }
          isSocial={ isSocial }
          isVoterRegistration={ isVoterRegistration }
        />
        <h3 className='fw-bold pb-1 position-relative pt-2 text-blue'>
          <CampaignDescription isLabs={ isLabs } longDescription={ labsDescription } />
          { title }
        </h3>
        <p className='fs-8 me-2 overflow-hidden'>
          <strong className='text-nowrap'>
            { t('card.votersAdopted', { total: (adoptedVoterCount || 0).toLocaleString() }) }
          </strong>
          {
            isCompleted
              ? null
              : (
                <Fragment>
                  { ' | ' }
                  <span className='text-nowrap'>
                    { t('card.votersRemaining', { total: (availableVoterCount || 0).toLocaleString() }) }
                  </span>
                </Fragment>
                )
          }
        </p>

        <div className='pb-3'>
          <div className='campaign-progress'>
            <div
              aria-valuemax={ totalVoterCount }
              aria-valuemin='0'
              aria-valuenow={ adoptedVoterCount }
              className='campaign-progress-bar'
              role='progressbar'
              style={{ width: progressBarWidth }}
            />
          </div>
        </div>

        {
          !isCompleted && mailStartDate && mailEndDate
            ? (
              <p className='bg-dark-blue fs-7 mb-n2 mx-n3 py-3 px-3 text-center text-white'>
                <MailDate
                  mailDate={ mailEndDate }
                  mailDateFrom={ mailStartDate }
                  mailDateType={ mailDateType }
                  short
                />
              </p>
              )
            : null
        }
      </div>
    </Link>
  );
}

CampaignCard.defaultProps = {
  handleCampaignSelection: noop,
  partnerSlug: '',
};

CampaignCard.propTypes = {
  availableVoterCount: PropTypes.number.isRequired,
  handleCampaignSelection: PropTypes.func,
  isCompleted: PropTypes.bool.isRequired,
  isLabs: PropTypes.bool.isRequired,
  isPolitical: PropTypes.bool.isRequired,
  isPriority: PropTypes.bool.isRequired,
  isSocial: PropTypes.bool.isRequired,
  isVoterRegistration: PropTypes.bool.isRequired,
  labsDescription: PropTypes.string,
  mailDateType: PropTypes.string.isRequired,
  mailEndDate: PropTypes.string.isRequired,
  mailStartDate: PropTypes.string.isRequired,
  partnerSlug: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalVoterCount: PropTypes.number.isRequired,
};

export default CampaignCard;
