import propTypes from 'prop-types';
import React, { Component } from 'react';

import { getPosts } from '../CMSUtil';
import i18n from '../locales/strings.json';
import logger from '../lib/logger';
import { message } from '../lib/deprecated_i18n';

class Impact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale || 'en',
      labPosts: [],
    };
  }

  static get propTypes() {
    return {
      locale: propTypes.string,
    };
  }

  componentDidMount() {
    this.getLabPosts().catch(logger.error);
  }

  getLabPosts = async () => {
    this.setState({ loading: true });
    try {
      const posts = await getPosts({ sortBy: 'title:asc', tag: 'impact2020' });
      this.setState({ labPosts: posts || [], loading: false });
    } catch (err) {
      this.setState({ loading: false });
      logger.error(err);
    }
  };

  render() {
    return (
      <section className='container impact pt-2 pb-3 p-sm-3 p-lg-5'>
        <h1 className='display-4 mt-3 mb-5'>
          { message('impact.headline') }
        </h1>
        <p className='mb-4'>
          { message('impact.p1') }
        </p>
        <p className='mb-2'>
          { message('impact.p2') }
        </p>

        <div className='p-5 mb-2'>
          <img alt='Three stats represent the impact of Vote Forward: 250,000+ volunteers, 31 million+ letters sent, and 20+ randomized controlled turnout trials conducted and publicly shared' className='callout-img img-fluid' src='/images/vf_impact_stats.png' />
        </div>

        <p className='mb-5'>
          { message('impact.p3') }
        </p>

        <div className='mb-4 mb-md-5 text-center'>
          <a
            className='btn btn-lg btn-donate'
            href='http://votefwd.org/labs'
          >
            <span className='h3 px-3 px-sm-5'>
              See more experiments
            </span>
          </a>
        </div>

        <p style={{ marginBottom: '1rem' }}>
          { message('impact.p4') }
        </p>

        <ul style={{ marginBottom: '3rem' }}>
          <li><p><b>voter registration letters on registration rates</b></p></li>
          <li><p><b>letters to boost both turnout and candidate performance</b></p></li>
          <li><p><b>including voting-themed items inside an envelope along with a letter</b></p></li>
          <li><p><b>issue-focused messaging</b></p></li>
          <li><p><b>including nonpartisan candidate guides in letter templates</b></p></li>
        </ul>


        <p className='mb-5'>
          { message('impact.p5') }
        </p>

        <p className='mb-5'>
          { message('impact.p6') }
        </p>

        <div className='my-4 my-lg-5 row'>
          <div className='col-4 mb-1 mb-md-3'>
            <img alt='Child holding letters in front of mailbox' className='img-fluid' src='/images/impact2020/vf_eoy_1.jpg' />
          </div>
          <div className='col-4 mb-1 mb-md-3'>
            <img alt='Smiling volunteer holding box of letters' className='img-fluid' src='/images/impact2020/vf_eoy_2.jpg' />
          </div>
          <div className='col-4 mb-1 mb-md-3'>
            <img alt='Volunteer placing letters in blue mailbox' className='img-fluid' src='/images/impact2020/vf_eoy_3.jpg' />
          </div>
        </div>
        <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote'>
          { message('impact2020.quote1.sally') }
        </p>
        <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote-name'>
          Sally Y. / Arizona
        </p>

        <hr className='my-4 my-lg-5' />

        <div className='my-4 my-lg-5 row'>
          <div className='col-4 mb-1 mb-md-3'>
            <img alt='Volunteer in yellow dress with letters making peace sign.' className='img-fluid' src='/images/impact2020/vf_eoy_4.jpg' />
          </div>
          <div className='col-4 mb-1 mb-md-3'>
            <img alt='Stacks of colorful envelopes arranged to spell V-O-T-E' className='img-fluid' src='/images/impact2020/vf_eoy_5.jpg' />
          </div>
          <div className='col-4 mb-1 mb-md-3'>
            <img alt='Two smiling volunteers holding a box of letters' className='img-fluid' src='/images/impact2020/vf_eoy_6.jpg' />
          </div>
        </div>
        <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote'>
          { i18n[this.state.locale]['impact2020.quote2.jim'] }
        </p>
        <p className='fw-bold mx-lg-3 mx-xl-5 px-sm-3 px-md-5 volunteer-quote-name'>
          Jim T. / Colorado
        </p>

        <hr className='mt-3 mb-4 my-lg-5' />

        <br />
        <h2 className='h1'>
          { i18n[this.state.locale]['impact2020.support.headline'] }
        </h2>
        <p style={{ marginTop: '1rem' }}>
          { message('impact.p9') }
        </p>
        <div className='mb-4 mb-md-5 text-center'>
          <a
            className='btn btn-lg btn-donate'
            href='https://secure.actblue.com/donate/vote-forward-c4?refcode=impact_landing'
            rel='noopener noreferrer'
            style={{ marginTop: '2rem' }}
            target='_blank'
          >
            <span className='h3 px-3 px-sm-5'>
              { i18n[this.state.locale]['impact2020.support.button.label'] }
            </span>
          </a>
        </div>
      </section>
    );
  }
}

export default Impact;
