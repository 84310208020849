import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import '../scss/big-send/_partners-section.scss';

function PartnersSection({ titleSelector, partners }) {
  const { t } = useTranslation('big-send');

  return (
    <Fragment>
      <h2 className='ff-body fs-1 fw-bold pb-5 px-md-3 px-lg-0 text-center text-tbs-darkblue'>
        { t(titleSelector) }
      </h2>

      <div className='row'>
        {
          partners.map(({ slug, partnerUrl, logo }) => (
            <div className='col-4 col-lg-2 partners-logo-box py-3' key={ `partner-url-${ slug }` }>
              <a href={ partnerUrl } rel='noopener noreferrer' target='_blank' >
                <img alt={ slug } className='partner-logo' src={ `${ logo }` } />
              </a>
            </div>
          ))
        }
      </div>

      <p className='fs-6 mb-5 learn-more px-4 text-center'>
        <Trans i18nKey='partners.learnMore' t={ t }>
          0
          <Link className='fw-bold' to='/partners'>
            1
          </Link>
        </Trans>
      </p>
    </Fragment>
  );
}

PartnersSection.propTypes = {
  partners: PropTypes.array.isRequired,
  titleSelector: PropTypes.string.isRequired,
};

export default PartnersSection;
