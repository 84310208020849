import React, { Component } from 'react';
import propTypes from 'prop-types';
import deprecatedConfig from '../lib/deprecatedConfig';

class Link extends Component {
  static get propTypes() {
    return {
      href: propTypes.string,
      baseUrlFromConfig: propTypes.string,
      target: propTypes.string,
      linkClass: propTypes.string,
      launchNow: propTypes.bool,
      children: propTypes.any,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  async componentDidMount() {
    if (this.props.baseUrlFromConfig) {
      const url = await deprecatedConfig.get(this.props.baseUrlFromConfig);
      this.setState({ baseUrl: url });
    }
  }

  render() {
    const fullUrl = this.state.baseUrl ? `${ this.state.baseUrl }/${ this.props.href }` : this.props.href;
    if (this.props.launchNow && !this.state.launched) {
      window.open(fullUrl, this.props.target);
      this.setState({ launched: true });
    }
    if (this.state.baseUrl) {
      return (
        <a className={ this.props.linkClass || '' } href={ fullUrl } target={ this.props.target || '' }>
          { this.props.children }
        </a>
      );
    } else {
      return (
        <a className={ this.props.linkClass || '' } href={ this.props.href } target={ this.props.target || '' }>
          { this.props.children }
        </a>
      );
    }
  }
}

export default Link;
