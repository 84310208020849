import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  Edit,
  EditButton,
  useNotify,
  maxLength,
} from 'react-admin';


const errorHandler = (error, notify) => {
  if (error.status === 'Conflict') {
    notify(`Error: Tax Type must be Unique`);
  } else {
    notify(`Error: ${ error.body || error.message }`);
  }
};

const SharedInputs = () => (
  <Fragment>
    <BooleanInput source='isShown' validate={ [required()] } /> <br />
    <TextInput fullWidth label='Header' max source='headerText' validate={ [required(), maxLength(32)] } /> <br />
    <TextInput fullWidth label='SubText' source='subtext' validate={ [maxLength(96)] } /> <br />

  </Fragment>
);

const DistrictTaxTypeEditToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton />
  </Toolbar>
);

export const DistrictTaxTypeEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };

  return (
    <Edit onFailure={ onFailure } undoable={ false } { ...props } toolbar={ <DistrictTaxTypeEditToolbar /> }>
      <SimpleForm redirect='edit' toolbar={ <DistrictTaxTypeEditToolbar /> }>
        <TextInput disabled source='taxType' validate={ [required()] } />
        <SharedInputs />
      </SimpleForm>
    </Edit>
  );
};


const DistrictTaxTypeList = (props) => (
  <List
    { ...props }
    bulkActionButtons={ false }
    sort={{ field: 'tax_type', order: 'DESC' }}
  >
    <Datagrid >
      <TextField source='tax_type' />
      <BooleanField source='is_shown' />
      <TextField source='header_text' />
      <TextField source='subtext' />
      <EditButton { ...props } />
    </Datagrid>
  </List>
);

export default DistrictTaxTypeList;
