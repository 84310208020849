import React, { Component } from 'react';
import propTypes from 'prop-types';
import download from 'js-file-download';
import axios from 'axios';
import Header from '../page/Header';
import Loading from '../components/Loading';
import i18n from '../locales/strings.json';
import { deprecated_formatWithCommas } from '../lib/formatNumbers';
import { createTimezoneDate } from '../lib/dates';
import logger from '../lib/logger';
import moment from 'moment';

import { TextField } from '@material-ui/core';

import '../scss/admin/_users.scss';


class PendingLoaded extends Component {
  static get propTypes() {
    return {
      loaded: propTypes.bool,
      children: propTypes.node,
    };
  }

  render() {
    return this.props.loaded
      ? this.props.children
      : <Loading />;
  }
}


class DistrictRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale || 'en',
      district: props.district,
    };
  }

  static get propTypes() {
    return {
      locale: propTypes.string,
      district: propTypes.object,
    };
  }

  render() {
    const district = this.state.district;
    const election_date = moment(createTimezoneDate(district.election_date)).format('MM/DD/YY');
    const mail_date = moment(createTimezoneDate(district.mail_date)).format('MM/DD/YY');
    return (
      <tr className='district-row'>
        <td>
          { district.state } ({ district.district_id })
          <span className='below-info'>Election { election_date }</span>
        </td>
        <td>{ deprecated_formatWithCommas(district.adopted_count) }</td>
        <td>{ deprecated_formatWithCommas(district.prepped_count) }</td>
        <td>{ deprecated_formatWithCommas(district.sent_count) }</td>
        <td>{ mail_date }</td>
      </tr>
    );
  }
}

// eslint-disable-next-line react/no-unsafe
class BasicStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: props.locale || 'en',
      error: '',
      partner: props.match.params.partner || props.partner || '',
      userStatsLoaded: false,
      districtStatsLoaded: false,
      filterStartDate: null,
      filterEndDate: null,
    };
  }

  static get propTypes() {
    return {
      locale: propTypes.string,
      partner: propTypes.string,
      match: propTypes.object,
      auth: propTypes.object,
      endpoint: propTypes.string,
    };
  }

  getStats = async () => {
    const params = {
      startDate: this.state.filterStartDate,
      endDate: this.state.filterEndDate,
    };
    this.setState({
      userStatsLoaded: false,
      districtStatsLoaded: false,
    });

    const accessToken = await this.props.auth.getAccessToken();
    try {
      const res = await axios({
        method: 'HEAD',
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        url: this.props.endpoint,
        params,
      });
      this.setState({
        counts: {
          user_count: res.headers['x-user-count'],
          adopted_count: res.headers['x-adopted-count'],
          prepped_count: res.headers['x-prepped-count'],
          sent_count: res.headers['x-sent-count'],
        },
        userStatsLoaded: true,
      });

      const districtsRes = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        // TODO consider doing this less jankily
        url: this.props.endpoint.replace('users.csv', 'districts'),
        params,
      });
      this.setState({
        districts: districtsRes.data,
        districtStatsLoaded: true,
      });
    } catch (err) {
      logger.error(err);
      if (err.response && err.response.status === 401) {
        this.setState({ error: i18n[this.state.locale].errors.unauthorized });
      } else {
        this.setState({ error: i18n[this.state.locale].errors.unspecified });
      }
    }
  };

  UNSAFE_componentWillMount() {
    this.getStats();
  }

  render() {
    let userCount, adoptedCount, preppedCount, sentCount;
    if (this.state.counts) {
      userCount = this.state.counts.user_count;
      adoptedCount = this.state.counts.adopted_count;
      preppedCount = this.state.counts.prepped_count;
      sentCount = this.state.counts.sent_count;
    }
    let districts;
    if (this.state.districts) {
      districts = this.state.districts
        // omit rows without data in this time period
        .filter((district) => parseInt(district.adopted_count) || parseInt(district.prepped_count) || parseInt(district.sent_count))
        // descending
        .sort((a, b) => (a.election_date < b.election_date ? 1 : -1));
    }

    return this.state.error
      ? (<div className='alert alert-danger m-4' role='alert'><p>{ this.state.error }</p></div>)
      : (
        <React.Fragment>
          <form
            className='date-picker-section'
            onSubmit={ this.getStats }
          >
            <TextField
              className='date-picker'
              defaultValue='2018-01-01'
              id='start-date'
              label='Start date'
              onChange={ (newValue) => {
                this.setState({ filterStartDate: newValue.target.value });
              } }
              type='date'
              value={ this.state.filterStartDate }
            />
            <TextField
              className='date-picker'
              defaultValue={ moment().format('YYYY-MM-DD') }
              id='end-date'
              label='End date'
              onChange={ (newValue) => {
                this.setState({ filterEndDate: newValue.target.value });
              } }
              type='date'
              value={ this.state.filterEndDate }
            />
            <button
              className='btn btn-primary'
              disabled={ !this.state.userStatsLoaded || !this.state.districtStatsLoaded }
              type='submit'
            >
              Filter
            </button>
            {
              (
                (!this.state.userStatsLoaded || !this.state.districtStatsLoaded)
                && (this.state.filterStartDate || this.state.filterEndDate)
              )
                && (
                  <span className='px-3' role='alert'>
                    This might take a while...
                  </span>
                )
            }
          </form>
          <div className='stats-section'>
            <h4>Attributed volunteers</h4>
            <PendingLoaded loaded={ this.state.userStatsLoaded }>
              <div className='stat'>
                <span className='stat-itself' id='attributed-volunteers'>{ deprecated_formatWithCommas(userCount) }</span>
                <label htmlFor='attributed-volunteers'>New volunteers attributable to { this.state.partner }</label>
              </div>
            </PendingLoaded>
          </div>
          <div className='stats-section'>
            <h4>Letters</h4>
            <PendingLoaded loaded={ this.state.userStatsLoaded }>
              <div className='row'>
                <div className='col-md-4 stat'>
                  <span className='stat-itself' id='letters-adopted'>{ deprecated_formatWithCommas(adoptedCount) }</span>
                  <label htmlFor='letters-adopted'>Letters adopted</label>
                </div>
                <div className='col-md-4 stat'>
                  <span className='stat-itself' id='letters-prepped'>{ deprecated_formatWithCommas(preppedCount) }</span>
                  <label htmlFor='letters-prepped'>Letters marked prepared</label>
                </div>
                <div className='col-md-4 stat'>
                  <span className='stat-itself' id='letters-sent'>{ deprecated_formatWithCommas(sentCount) }</span>
                  <label htmlFor='letters-sent'>Letters marked sent</label>
                </div>
              </div>
            </PendingLoaded>
          </div>
          <div className='stats-section'>
            <h4>Campaigns</h4>
            <PendingLoaded loaded={ this.state.districtStatsLoaded }>
              { districts && districts.length
                ? (
                  <table>
                    <tr className='header'>
                      <th>Campaign</th>
                      <th># adopted</th>
                      <th># prepared</th>
                      <th># sent</th>
                      <th>Mail date</th>
                    </tr>
                    {
                      districts.map((district) => (
                        <DistrictRow district={ district } key={ district.district_id } />
                      ))
                    }
                  </table>
                  )
                // note that this is not role=alert despite styling
                // (bc it is fairly pedestrian and shouldn't be announced to screen readers)
                : <div className='alert alert-danger m-4'><p>No campaign stats available for this time period.</p></div> }
            </PendingLoaded>
          </div>
        </React.Fragment>
        );
  }
}

class DownloadUsersButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      locale: props.locale || 'en',
      error: '',
      partner: props.match.params.partner || props.partner || '',
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
      match: propTypes.object,
      partner: propTypes.string,
      endpoint: propTypes.string,
    };
  }

  async downloadCsvForUser() {
    const accessToken = await this.props.auth.getAccessToken();
    const res = await axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
      url: this.props.endpoint,
      responseType: 'blob',
    });
    download(res.data, res.headers.filename);
  }

  render() {
    return this.state.error
      ? (<div className='alert alert-danger m-4' role='alert'><p>{ this.state.error }</p></div>)
      : (
        <button
          className='btn btn-large btn-primary'
          onClick={ () => this.downloadCsvForUser() }
        >
          Download all volunteers as .csv
        </button>
        );
  }
}

// eslint-disable-next-line react/no-unsafe
class Users extends Component {
  constructor(props) {
    super(props);
    const partner = props.match.params.partner || props.partner || '';
    this.state = {
      partnerObject: {},
      locale: props.locale || 'en',
      partner,
      endpoint: `${ process.env.REACT_APP_API_URL }/v1/partners/${ partner }/users.csv`,
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
      match: propTypes.object,
      partner: propTypes.string,
      showHeader: propTypes.bool,
    };
  }

  render() {
    let headerText = i18n[this.state.locale]['admin.user.dashboard'];
    if (this.state.partnerObject.name) {
      headerText = `${ this.state.partnerObject.name } ${ headerText }`;
    }
    return (
      <div className='position-relative users-page'>
        { this.props.showHeader ? <Header /> : null }
        <div className='container mt-4'>
          <div className='row'>
            <div className='col text-center'>
              <h1>{ headerText }</h1>
              <BasicStats auth={ this.props.auth } endpoint={ this.state.endpoint } { ...this.props } />
              <br />
              <DownloadUsersButton auth={ this.props.auth } endpoint={ this.state.endpoint } { ...this.props } />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getPartner = async () => {
    const result = await axios({
      method: 'GET',
      url: `${ process.env.REACT_APP_API_URL }/v1/partners/${ this.state.partner }`,
    });
    this.setState({
      partnerObject: result.data,
    });
  };

  UNSAFE_componentWillMount() {
    if (this.state.partner) {
      this.getPartner();
    }
  }
}

export default Users;
