import React, { Component } from 'react';
import propTypes from 'prop-types';

class Modal extends Component {
  escModal = (event) => {
    if (event.keyCode === 27) {
      this.props.hideModal();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.escModal, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escModal, false);
  }

  render() {
    if (this.props.showModal === false) {
      return null;
    } else {
      return (
        <div className={ `modal ${ this.props.modalClassName }` } role='dialog' tabIndex='-1'>
          <div className='modal-dialog modal-dialog-scrollable' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{ this.props.modalTitle }</h5>
                <button
                  aria-label='Close'
                  className='close'
                  data-dismiss='modal'
                  onClick={ this.props.hideModal }
                  type='button'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div
                className='modal-body'
                onScroll={ this.props.onBodyScroll }
              >
                { this.props.modalBlockContentJsx
                  || (
                    <p>
                      { this.props.modalText || this.props.modalTextJsx }
                    </p>
                  ) }
                { this.props.children }
              </div>
              <div className='modal-footer container'>
                { this.props.modalButtons
                  ? this.props.modalButtons
                  : (
                    <button
                      className='btn btn-secondary'
                      data-dismiss='modal'
                      onClick={ () => { this.props.hideModal(); } }
                      type='button'
                    >
                      Close
                    </button>
                    ) }
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

Modal.propTypes = {
  children: propTypes.node,
  hideModal: propTypes.func,
  modalBlockContentJsx: propTypes.object,
  modalButtons: propTypes.instanceOf(<div />),
  modalClassName: propTypes.string,
  modalText: propTypes.string,
  modalTextJsx: propTypes.object,
  modalTitle: propTypes.string,
  onBodyScroll: propTypes.func,
  showModal: propTypes.bool,
};

export default Modal;
