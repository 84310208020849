import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import CampaignsSection from '../campaigns/CampaignsSection';
import Footer from '../page/Footer';
import Loading from '../components/Loading';
import Logo from '../components/Logo';
import MaterialsIcons from '../components/MaterialsIcons';
import VerifyEmailRequest from '../VerifyEmailRequest';

import { selectAvailableCampaigns, selectCompletedCampaigns } from '../campaigns/lib/selectors';
import { usePartner, usePartnerCampaigns } from './lib/queries';
import { usePartnerSlug } from './lib/hooks';

import '../scss/lib/_borders.scss';
import '../scss/lib/_backgrounds.scss';
import '../scss/pages/_campaigns.scss';
import '../scss/pages/_partner.scss';

import defaultMetaImage from '../images/meta-share-image.jpg';

function Partner() {
  const { t } = useTranslation('partner');
  const partnerSlug = usePartnerSlug();
  const { data: partner, isLoading: partnerIsLoading } = usePartner({ partnerSlug });
  const { data: availableCampaigns } = usePartnerCampaigns({
    partnerSlug,
    selector: selectAvailableCampaigns,
  });
  const { data: completedCampaigns } = usePartnerCampaigns({
    partnerSlug,
    selector: selectCompletedCampaigns,
  });
  const meta = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_URL,
      description: partner
        ? t('meta.description', { partner: partner.name })
        : t('description', { ns: 'social' }),
      imageAlt: partner?.metaTagImage
        ? t('meta.imageAlt', { partner: partner.name })
        : t('imageAlt', { ns: 'social' }),
      imageUrl: partner?.metaTagImage || `${ process.env.REACT_APP_URL }${ defaultMetaImage }`,
      title: partner
        ? t('meta.title', { partner: partner.name })
        : t('title', { ns: 'social' }),
    }),
    [partner, t],
  );

  if (partnerIsLoading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Helmet titleTemplate='%s'>
        <title>{ meta.title }</title>

        { /* General */ }
        <meta content={ meta.description } itemProp='description' />
        <meta content={ meta.imageUrl } name='image' />

        { /* Google */ }
        <meta content={ meta.imageUrl } itemProp='image' />
        <meta content={ meta.description } itemProp='description' />
        <meta content={ meta.title } itemProp='name' />

        { /* Twitter */ }
        <meta content={ meta.description } name='twitter:description' />
        <meta content={ meta.imageAlt } name='twitter:image:alt' />
        <meta content={ meta.imageUrl } name='twitter:image' />
        <meta content={ meta.title } name='twitter:title' />
        <meta content={ t('twitter.card', { ns: 'social' }) } name='twitter:card' />
        <meta content={ t('twitter.site', { ns: 'social' }) } name='twitter:site' />

        { /* Open Graph general (Facebook, Pinterest & Google+) */ }
        <meta content={ meta.baseUrl } name='og:url' />
        <meta content={ meta.description } name='og:description' />
        <meta content={ meta.title } name='og:site_name' />
        <meta content={ meta.title } name='og:title' />
        <meta content={ meta.imageUrl } name='og:image' />
        <meta content={ t('og.type', { ns: 'social' }) } name='og:type' />
      </Helmet>

      <div id='partner'>
        <header className='container px-lg-5'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='align-items-sm-baseline row py-sm-5 logos-row'>
                <div className='col-12 col-sm-5 logo-col-left vertical-line-right'>
                  <Logo stampColor='#204369' textColor='#204369' wooshColor='#F65650' />
                </div>
                <div className='col-12 col-sm-5 logo-col-right'>
                  <a href={ partner.partnerUrl } rel='noreferrer' target='_blank'>
                    <img alt={ t('logoAlt', { partner: partner.name }) } className='img-fluid' src={ partner.logo } />
                  </a>
                </div>
              </div>
              <div className='col-12 col-lg-11 pb-3 px-4 px-lg-0 description-col'>
                <h2 className='text-darkblue'>
                  { t('description.title', { partner: partner.name }) }
                </h2>
                <p className='text-darkblue'>
                  <strong>{ t('description.partOne') }</strong> { t('description.partTwo', { partner: partner.name }) }
                </p>

                <hr className='text-darkblue' />

                <p className='disclaimer text-darkblue'>
                  { t('description.disclaimer', { partner: partner.name }) }
                </p>
              </div>
            </div>
            <div className='col-12 col-lg-6 py-lg-5'>
              <VerifyEmailRequest
                getStartedMessage={ false }
                hideFooter
                hideHeader
                hideMasthead
                purpose='register.user'
              />
            </div>
          </div>
        </header>

        <main>
          <div className='materials-section bg-purple py-4'>
            <div className='container-lg px-lg-5 py-5'>
              <h2>
                { t('materialsSection.header') }
              </h2>
              <div className='py-3'>
                <MaterialsIcons variant='light-on-dark' />
              </div>
              <p className='mb-0'>
                <Trans i18nKey='materialsSection.cta' t={ t }>
                  0
                  <a href='/instructions'>
                    1
                  </a>
                  2
                </Trans>
              </p>
            </div>
          </div>

          <div className={ `container-lg px-lg-5 ${ availableCampaigns.length !== 0 ? 'mt-5' : '' }` } id='campaigns'>
            <div className='px-4'>
              <CampaignsSection
                campaigns={ availableCampaigns }
                partnerSlug={ partnerSlug }
                type='partner.available'
              />
              <CampaignsSection
                campaigns={ completedCampaigns }
                partnerSlug={ partnerSlug }
                type='completed'
              />
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </Fragment>
  );
}

export default Partner;
