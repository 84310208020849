import PropTypes from 'prop-types';

function ProgressBar({ current, max, children }) {
  const minPercentage = (current > 0) ? 1 : 0;
  const ratio = (max > 0) ? (current / max) : 1;

  const percentage = Math.min(
    100,
    Math.max(
      minPercentage,
      Math.floor(ratio * 100),
    ),
  );

  return (
    <div>
      <h5>Help us meet our goal</h5>
      <div className='progress'>
        <div
          aria-valuemax={ max }
          aria-valuemin='0'
          aria-valuenow={ current }
          className='progress-bar'
          role='progressbar'
          style={{ width: `${ percentage }%` }}
        />
      </div>
      <div className='row fs-7 mt-1'>
        <div className='col-8'>
          { children }
        </div>
        <div className='col-4 percent-message'>
          <strong>{ percentage }</strong>% of goal
        </div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  children: PropTypes.any,
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default ProgressBar;
