import PropTypes from 'prop-types';
import { useReducer } from 'react';

import '../scss/components/_q-and-a.scss';

function QAndA({ answer, question }) {
  const [isCollapsed, toggleIsCollapsed] = useReducer((isCollapsed) => !isCollapsed, true);

  return (
    <div className='py-3 q-and-a'>
      <h3 onClick={ toggleIsCollapsed } role='button'>
        { question }
      </h3>
      { isCollapsed ? null : <p>{ answer }</p> }
    </div>
  );
}

QAndA.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export default QAndA;
