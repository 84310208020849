import React, { Component } from 'react';
import Header from './page/Header';
import Footer from './page/Footer';
import propTypes from 'prop-types';
import { openBeacon, initBeacon, destroyBeacon } from './Beacon';
import { message } from './lib/deprecated_i18n';
import { Link } from 'react-router-dom';
import './scss/pages/_contact.scss';

class Contact extends Component {
  static get propTypes() {
    return {
      auth: propTypes.object,
    };
  }

  componentDidMount() {
    initBeacon({ auth: this.props.auth });
  }

  componentWillUnmount() {
    destroyBeacon();
  }

  render() {
    return (
      <div className='contact-page-container vstack'>
        <Header />

        <header className='header bg-light'>
          <div className='container'>
            <div className='row'>
              <div className='h-100 text-center m-auto'>
                <h1 className='mb-4 mt-4 pb-4 mb-lg-0'>Contact Us</h1>
              </div>
            </div>
          </div>
        </header>

        <div className='container'>
          <div className='row col-lg-8 m-auto'>
            <div className='text-body h-100 mt-4 mb-4 text-lg-start'>
              <p>
                Vote Forward is a 501(c)(4) nonprofit organization that
                connects citizens to one another to increase voter turnout
                and voter registration.
              </p>
              <h4>Mailing Address</h4>
              <p>
                611 Pennsylvania Ave SE #192
                <br />
                Washington, DC 20003
              </p>
              <p>
                <strong>Questions?</strong> As a first step, please review our{ ' ' }
                <Link to='/instructions'>instructions</Link> and { ' ' }
                <a href='https://help.votefwd.org' rel='noopener noreferrer' target='_blank'>help site</a>.
              </p>
              <p>
                <strong>Still need assistance?</strong>{ ' ' }
                <button className='blue-link' id='getHelp' onClick={ () => openBeacon() }>{ message('get.help') }</button>
              </p>
              <p>
                <strong>General inquiries:</strong>{ ' ' }
                <a href='mailto:hello@votefwd.org'>hello@votefwd.org</a>
              </p>
              <p>
                <strong>Press inquiries only:</strong>{ ' ' }
                <a href='mailto:press@votefwd.org'>press@votefwd.org</a>
              </p>
            </div>
          </div>
        </div>
        <div className='contact-footer-container align-items-stretch'>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Contact;
