import amplitude from 'amplitude-js';
import { segmentIdentifyAndTrackPage } from './segment';

function safeCall(func) {
  try {
    return func();
  } catch {
    return null;
  }
}

function init() {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
}

class Tracker {
  constructor(params) {
    this.auth = params.auth;
    this.globalProperties = {};
  }

  setGlobalProperties(properties) {
    for (const key of Object.keys(properties)) {
      this.globalProperties[key] = properties[key];
    }
  }

  setAmplitudeUserDevice = (installationToken) => {
    amplitude.getInstance().setDeviceId(installationToken);
  };

  setUserId = (userId) => {
    amplitude.getInstance().setUserId(userId);
  };

  /**
   * This will set user properties for any utm_ parameters in the url and return those properties
   *  to be put in event properties
   */
  setUtmParameters() {
    const utmProperties = {};
    if (window.location.href) {
      const utmMatches = window.location.href.match(/utm_(medium|source|campaign|content)=([^&]*)/g) || [];
      if (utmMatches.length > 0) {
        for (const utmMatch of utmMatches) {
          const [key, value] = utmMatch.split('=');
          utmProperties[key] = value;
        }
        this.setUserProperties(utmProperties);
      }
    }
    return utmProperties;
  }

  setUserProperties = (properties) => {
    amplitude.getInstance().setUserProperties(properties);
  };

  logEvent = (eventType, eventProperties) => {
    try {
      amplitude.getInstance().logEvent(
        eventType, {
          ...eventProperties,
          ...this.commonProperties(),
          ...this.globalProperties,
          ...this.setUtmParameters(),
        },
      );
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: eventType,
          eventProps: {
            ...this.globalProperties,
            ...this.commonProperties(),
            ...this.setUtmParameters(),
            ...eventProperties,
          },
        });
      }
    } catch {
      //do nothing
    }
  };

  trackSegment = () => {
    segmentIdentifyAndTrackPage(this.auth.getUserId());
  };

  commonProperties = () => {
    const properties = {};
    if (this.auth) {
      properties.isAuthenticated = safeCall(this.auth.isAuthenticated);
      properties.isQualified = safeCall(this.auth.isQualified);
      properties.isEmailVerified = safeCall(this.auth.isEmailVerified);
      properties.localQualifiedState = localStorage.getItem('user_qualified_state');
    }
    return properties;
  };
}

export default Tracker;
export { init };
