import PropTypes from 'prop-types';
import RecaptchaWrapper from 'react-google-recaptcha';
import { Component } from 'react';

import deprecatedConfig from './lib/deprecatedConfig';

class Recaptcha extends Component {
  async componentDidMount() {
    const recaptchaSiteKey = await deprecatedConfig.get('recaptchaSiteKey');
    this.setState({ recaptchaSiteKey });
  }

  constructor(props) {
    super(props);
    this.state = { recaptchaSiteKey: '' };
    this.setRef = (element) => {
      this.recaptchaRef = element;
    };
  }

  render() {
    if (!this.state.recaptchaSiteKey) {
      return null;
    } else {
      return (
        <RecaptchaWrapper
          onChange={ this.props.handleCaptchaResponse }
          ref={ this.setRef }
          sitekey={ this.state.recaptchaSiteKey }
          size='normal'
        />
      );
    }
  }
}

Recaptcha.propTypes = {
  handleCaptchaResponse: PropTypes.func.isRequired,
};

export default Recaptcha;
