import React, { Component } from 'react';
import axios from 'axios';
import logger from '../lib/logger';
import propTypes from 'prop-types';


class GroupImportRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale || 'en',
      title: props.title || 'Import Group Users',
      formStatus: '',
      formError: '',
      errorMessage: '',
      disableButton: false,
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
      groupId: propTypes.number,
      title: propTypes.string,
    };
  }

  verifyForm = () => {
    if (!this.state.insertDelete) {
      this.setState({ formError: 'The insert/delete dropdown is required.' });
      return false;
    }
    if (!this.state.auth0Ids) {
      this.setState({ formError: 'The auth0 ids are required.' });
      return false;
    }
    return true;
  };

  verifyFormReset = () => {
    this.setState({ formError: '' });
  };

  submitGroupImportRequestForm = async () => {
    if (this.verifyForm()) {
      this.setState({ disableButton: true });
      const url = `${ process.env.REACT_APP_API_URL }/v1/userGroups`;
      try {
        const accessToken = await this.props.auth.getAccessToken();
        this.setState({ formStatus: 'submitted' });
        const data = { groupId: this.props.groupId };
        if (this.state.config) {
          data.config = this.state.config;
        }
        if (this.state.insertDelete) {
          data.action = this.state.insertDelete;
        }
        if (this.state.auth0Ids) {
          data.auth0Ids = this.state.auth0Ids.split(/\n|\s+|,/)
            .map((auth0Id) => auth0Id.replace(/\s+/, ''))
            .filter((auth0Id) => auth0Id !== '');
        }
        const res = await axios({
          method: 'POST',
          headers: {
            Authorization: 'Bearer '.concat(accessToken),
          },
          url,
          data,
        });
        const newState = { disableButton: false };
        if (res.code >= 400) {
          logger.log(res);
          newState.formStatus = 'error';
          newState.errorMessage = res.data;
        } else {
          newState.stats = res.data;
          newState.formStatus = 'completed';
        }
        this.setState(newState);
      } catch (err) {
        const newState = { disableButton: false, formStatus: 'error' };
        if (err.response && err.response.data) {
          newState.errorMessage = err.response.data;
        }
        this.setState({ newState });
      }
    }
  };

  handleChange = (event) => {
    this.setState({ file: event.target.value });
  };

  _handleKeyDown = (e) => {
    this.verifyFormReset();
    if (e.key === 'Enter' && !this.state.disableButton) {
      this.submitGroupImportRequestForm();
    }
  };

  handleTryAgain = () => this.setState({
    formStatus: '',
    stats: undefined,
    errorMessage: '',
  });

  render() {
    const insertDeleteSelect = (
      <div>
        <label>Insert/Delete</label>
        <select
          className='form-control'
          onChange={ (event) => this.setState({ insertDelete: event.target.value }) }
          value={ this.state.insertDelete }
        >
          <option value='' />
          { ['insert', 'delete'].map((config) => (<option key={ config } value={ config }>{ config }</option>)) }
        </select>
        <br />
      </div>
    );
    const auth0IdInput = (
      <div>
        <label>Auth0 Ids</label>
        <br />
        <textarea
          className='form-control'
          onChange={ (event) => this.setState({ auth0Ids: event.target.value }) }
          placeholder='Specify comma or newline-separated auth0 ids.'
          rows='4'
          value={ this.state.auth0Ids }
        />
      </div>
    );
    const groupImportRequestForm = (
      <div>
        <div className='mb-3'>
          <div>
            { insertDeleteSelect }
            { auth0IdInput }
            <button className={ ['btn', 'btn-primary', 'w-100', 'justify-content-center', this.state.disableButton ? 'disabled' : ''].join(' ') } disabled={ this.state.disableButton } onClick={ this.submitGroupImportRequestForm } style={{ color: 'white' }}>
              Import Users
            </button>
            <div className={ this.state.formError ? 'alert alert-danger mt-2' : '' }>{ this.state.formError }</div>
          </div>
        </div>
      </div>
    );
    const groupImportRequestFormSubmitted = (
      <p>{ (() => {
        let message;
        if (this.state.formStatus === 'submitted') {
          message = 'processing...';
        } else if (this.state.formStatus === 'completed') {
          const resultRows = [];
          if (this.state.insertDelete === 'insert') {
            resultRows.push(`Added: ${ this.state.stats.added.length }`);
          } else {
            resultRows.push(`Deleted: ${ this.state.stats.deleted.length }`);
          }
          resultRows.push(`Skipped: ${ this.state.stats.skipped.length }`);
          if (this.state.stats.userNotFound.length > 0) {
            resultRows.push(`User Not Found: ${ this.state.stats.userNotFound.length } (${ this.state.stats.userNotFound.join(',') })`);
          }
          message = (
            <div>
              import complete!<br />
              { resultRows.reduce((all, cur) => [
                ...all,
                <br key='dummy' />,
                cur,
              ]) }
            </div>
          );
        } else if (this.state.formStatus === 'error') {
          message = this.state.errorMessage || 'an error occurred';
        }
        return (
          <div>
            { message }
            <button
              className={ ['btn', 'btn-primary', 'w-100', 'justify-content-center', this.state.disableButton ? 'disabled' : ''].join(' ') }
              disabled={ this.state.disableButton }
              onClick={ this.handleTryAgain }
              style={{ color: 'white' }}
            >
              Try Again
            </button>
          </div
          >
        );
      })() }
      </p>
    );
    return (
      <div>
        <div className='card' style={{ width: '24rem' }}>
          <article className='card-body' style={{ display: 'block' }}>
            <h4 className='card-title mb-4 mt-1 text-center'>{ this.state.title }</h4>
            <hr />
            { this.state.formStatus !== '' ? groupImportRequestFormSubmitted : groupImportRequestForm }
          </article>
        </div>
      </div>
    );
  }
}

export default GroupImportRequest;
