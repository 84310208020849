import {
  Button,
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  BulkExportButton,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Create,
  SimpleForm,
  required,
  regex,
  minLength,
  Toolbar,
  SaveButton,
  Edit,
  EditButton,
  useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import queryString from 'query-string';

import GroupImport from './GroupImport';
import { useAuth } from '../contexts/AuthContext';

const GroupEditToolbar = (props) => (
//removes default delete option from toolbar
  <Toolbar { ...props } >
    <SaveButton />
  </Toolbar>
);

const errorHandler = (error, notify) => {
  if (error.status === 'Conflict') {
    notify(`Error: Name and Slug must be Unique`);
  } else {
    notify(`Error: ${ error.body || error.message }`);
  }
};

const OptionalInputs = () => (
  <ReferenceArrayInput
    allowEmpty
    filter={{ 'electionDate:greaterThanOrEqualTo': (new Date()).toISOString() }}
    helperText='Districts this group should see.'
    label='districts'
    perPage={ 100 }
    reference='Districts'
    source='districtIds'
  >
    <AutocompleteArrayInput optionText='districtId' />
  </ReferenceArrayInput>
);

function GroupEdit(props) {
  const notify = useNotify();
  const auth = useAuth();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };
  return (
    <div>
      <Edit onFailure={ onFailure } undoable={ false } { ...props }>
        <SimpleForm redirect='edit' toolbar={ <GroupEditToolbar /> }>
          <TextInput disabled source='id' />
          <TextInput disabled source='slug' />
          <TextInput
            source='name'
            validate={ [required(), minLength(3)] }
          />
          <OptionalInputs />
          <Link
            className='button'
            to={{
              pathname: `/EmailPreferences`,
              search: queryString.stringify({
                displayedFilters: JSON.stringify({ 'groups.id': true }),
                filter: JSON.stringify({ groups: { id: parseInt(props.id) }}),
                order: 'ASC',
                perPage: 10,
                page: 1,
                sort: 'id',
              }),
            }}
          >
            <Button color='primary' label='Show Group Users' />
          </Link><br />
          <GroupImport auth={ auth } title='Import Group Users' { ...props } groupId={ props.id } />
        </SimpleForm>
      </Edit>
    </div>
  );
}

GroupEdit.propTypes = {
  id: propTypes.number,
};

const GroupCreateToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton
      label='create'
    />
  </Toolbar>
);

export const GroupCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };
  return (
    <Create onFailure={ onFailure } { ...props }>
      <SimpleForm toolbar={ <GroupCreateToolbar /> }>
        <TextInput
          label='Group name'
          source='name'
          validate={ [required(), minLength(3)] }
        />
        <TextInput
          helperText='This cannot be changed or deleted.'
          label='Group slug'
          source='slug'
          validate={ [
            required(),
            regex(/^[a-z\d]*$/, 'Slug must be alphanumeric with lowercase letters'),
            regex(/[a-z]/, "Slug can't be all numbers"),
            minLength(3),
          ] }
        />
        <OptionalInputs />
      </SimpleForm>
    </Create>
  );
};

const GroupListBulkActionButtons = (props) => (
  <BulkExportButton { ...props } />
);

const GroupFilter = (props) => (
  <Filter { ...props }>
    <TextInput alwaysOn label='Name' source='name:contains' />
    <TextInput alwaysOn label='Slug' source='slug:contains' />
  </Filter>
);
const GroupList = (props) => (
  <div>
    <List { ...props } bulkActionButtons={ <GroupListBulkActionButtons /> } filters={ <GroupFilter /> } >
      <Datagrid>
        <TextField source='id' />
        <TextField source='slug' />
        <TextField source='name' />
        <DateField showTime source='createdAt' />
        <EditButton { ...props } />
      </Datagrid>
    </List>
  </div>
);

export default GroupList;
export { GroupEdit };
