import propTypes from 'prop-types';
import VoterAdoptionHeader from './VoterAdoptionHeader.js';
import IndividualAdoptionButtons from './IndividualAdoptionButtons.js';
import PartyAdoptionButtons from './PartyAdoptionButtons.js';
import { segmentTrack } from '../../lib/segment';
import TabUnstyled from '@mui/material/Tab';
import TabsUnstyled from '@mui/material/Tabs';
import { Fragment, useState } from 'react';
import { canUserAdoptSocialBatches } from '../../lib/user';

VoterAdoptionTabs.propTypes = {
  campaignClosed: propTypes.bool.isRequired,
  campaignState: propTypes.object.isRequired,
  detailBoxClassNames: propTypes.string.isRequired,
  goToTraining: propTypes.func.isRequired,
  handleAdoptionSuccess: propTypes.func.isRequired,
  isPast: propTypes.bool.isRequired,
  liveVoterCount: propTypes.number.isRequired,
  maxQual: propTypes.number,
  requestSuperqualStatus: propTypes.func.isRequired,
  setCampaignState: propTypes.func.isRequired,
  updateTraining: propTypes.func.isRequired,
  user: propTypes.object.isRequired,
};

export default function VoterAdoptionTabs({
  campaignState,
  maxQual,
  user,
  setCampaignState,
  liveVoterCount,
  detailBoxClassNames,
  goToTraining,
  updateTraining,
  isPast,
  campaignClosed,
  handleAdoptionSuccess,
  requestSuperqualStatus,
}) {
  const [adoptionType, setAdoptionType] = useState('individual');
  const { allAdopted, district: campaign, training, superqualNum, qualNum, updatedAvailableVoterCount } = campaignState;

  if (maxQual === null) {
    maxQual = (user.qual_state === 'super_qualified')
      ? parseInt(superqualNum, 10)
      : parseInt(qualNum, 10);
  }
  const maxedOut = (liveVoterCount >= maxQual);

  const onAdoptionQuerySuccess = (responseData) => {
    if (responseData.campaignStatus === 'PAUSED') {
      setCampaignState({ showPausedModal: true });
    } else {
      onSuccessfulAdoption(responseData);
    }
  };

  const onSuccessfulAdoption = (responseData) => {
    const { voters, districtId, bundleSize, availableVoterCount, id: letterBundleId } = responseData;

    segmentTrack('AdoptVoter:success', {
      campaignSlug: districtId,
      letterBundleId,
      bundleSize,
    });

    const stateUpdate = {};
    if (voters.length === 0) {
      stateUpdate.allAdopted = true;
    }
    if (availableVoterCount !== '') {
      stateUpdate.updatedAvailableVoterCount = availableVoterCount;
    }
    handleAdoptionSuccess();
    setCampaignState(stateUpdate);
  };

  const availableVoters = updatedAvailableVoterCount || campaign.available_voter_count || 0;

  const adoptionTypeConfig = {
    individual: {
      headerMessage: 'Adopt Voters as an Individual',
      buttons: (
        <IndividualAdoptionButtons
          campaign={ campaign }
          isAdopterMaxedOut={ maxedOut }
          isCampaignClosed={ campaignClosed }
          liveVoterCount={ liveVoterCount }
          onAdoptionQuerySuccess={ onAdoptionQuerySuccess }
        />
      ),
    },
    party: {
      headerMessage: 'Adopt Voters as a Party',
      buttons: (
        <PartyAdoptionButtons
          availableVoters={ availableVoters }
          campaign={ campaign }
          isAdopterMaxedOut={ maxedOut }
          isCampaignClosed={ campaignClosed }
          liveVoterCount={ liveVoterCount }
          maxQual={ maxQual }
          onAdoptionQuerySuccess={ onAdoptionQuerySuccess }
        />
      ),
    },
  };

  return (
    <Fragment >
      { canUserAdoptSocialBatches() && (
        <TabsUnstyled
          className='adoption'
          onChange={ (event, newValue) => (setAdoptionType(newValue)) }
          value={ adoptionType }
          variant='fullWidth'
        >
          <TabUnstyled className='adoption individual' label="I'm writing letters myself." value='individual' />
          <TabUnstyled className='adoption party' label="I'm hosting a writing event." value='party' />
        </TabsUnstyled>
      ) }
      <div className={ detailBoxClassNames }>
        <VoterAdoptionHeader
          availableVoters={ availableVoters }
          campaign={ campaign }
          campaignClosed={ campaignClosed }
          goToTraining={ goToTraining }
          headerMessage={ adoptionTypeConfig[adoptionType].headerMessage }
          maxedOut={ maxedOut }
          requestSuperqualStatus={ requestSuperqualStatus }
          showSeeAllCampaignsButtonText={ isPast || allAdopted }
          training={ training }
          updateTraining={ updateTraining }
        >
          { adoptionTypeConfig[adoptionType].buttons }
        </VoterAdoptionHeader>
      </div>
    </Fragment >
  );
}
