import axios from 'axios';
import logger from './logger';

/**
 * Calls necessary API to get a link to download a pdf containing pdfs based on parameters.
 * Then "clicks" on the link to download the pdf. This is a bit hacky, but heuristically
 * was the only way we could get it to work on various devices (ipad, phone, desktop)
 */

async function downloadGoalPageStats({ auth, goalPage }) {
  logger.debug(`Entered downloadgoalPageStats with parameters: ${ goalPage }) }`);
  const accessToken = await auth.getAccessToken();

  const res = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/goalPages/${ goalPage }`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer '.concat(accessToken),
    },
  });

  const items = res.data.userStats;
  const header = Object.keys(items[0]);

  const csv = [
    header.join(','), // header row first
    ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], (key, value) => (value === null ? '' : value))).join(',')),
  ].join('\r\n');

  const blob = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
  const link = document.createElement('a');
  link.href = blob;
  document.body.appendChild(link);
  link.click();
}

export default downloadGoalPageStats;
