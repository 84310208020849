import { Helmet } from 'react-helmet';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import metaImage from '../images/meta-share-image.jpg';

function PageHead() {
  const { t } = useTranslation('social');
  const baseUrl = process.env.REACT_APP_URL;
  const imageUrl = `${ baseUrl }${ metaImage }`;

  return (
    <Helmet defaultTitle={ t('title') } titleTemplate={ `${ t('title') } – %s` }>
      {
        process.env.NODE_ENV === 'development' && process.env.REACT_APP_FORCE_REACT_DEVTOOLS_CONNECTION
          ? <script src='http://localhost:8097' />
          : null
      }

      <meta charSet='utf-8' />
      <meta content='width=device-width, initial-scale=1, shrink-to-fit=no' name='viewport' />

      { /* General */ }
      <meta content={ imageUrl } name='image' />
      <meta content={ t('description') } name='description' />

      { /* Google */ }
      <meta content={ imageUrl } itemProp='image' />
      <meta content={ t('description') } itemProp='description' />
      <meta content={ t('title') } itemProp='name' />

      { /* Twitter */ }
      <meta content={ imageUrl } name='twitter:image' />
      <meta content={ t('description') } name='twitter:description' />
      <meta content={ t('title') } name='twitter:title' />
      <meta content={ t('twitter.card') } name='twitter:card' />
      <meta content={ t('twitter.imageAlt') } name='twitter:image:alt' />
      <meta content={ t('twitter.site') } name='twitter:site' />

      { /* Open Graph general (Facebook, Pinterest & Google+) */ }
      <meta content={ baseUrl } name='og:url' />
      <meta content={ imageUrl } name='og:image' />
      <meta content={ t('description') } name='og:description' />
      <meta content={ t('title') } name='og:site_name' />
      <meta content={ t('title') } name='og:title' />
      <meta content={ t('og.type') } name='og:type' />

      { /* Facebook */ }
      <meta content={ t('facebook.admins') } name='fb:admins' />
    </Helmet>
  );
}

export default memo(PageHead);
