import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import useLocalStorageState, { setLocalStorageState } from '../lib/hooks/useLocalStorageState';

import '../scss/page/_lightbox.scss';

import closeIcon from '../images/page/icon-close.svg';

function getLocalStorageKey(id) {
  return `vfModalRendered-${ id }`;
}

function disableLightbox(id) {
  if (id) {
    setLocalStorageState(getLocalStorageKey(id));
  }
}


function Lightbox({ announcement, resetLightBox, onLightboxClose }) {
  const localStorageKey = useMemo(
    () => getLocalStorageKey(announcement?.id),
    [announcement?.id],
  );
  const [hasLightboxRendered, setHasLightboxRendered] = useLocalStorageState(
    localStorageKey,
    false,
  );
  const [isVisible, toggleIsVisible] = useReducer(
    (isVisible) => !isVisible,
    undefined,
    () => { setHasLightboxRendered(true); return !hasLightboxRendered; },
  );
  useEffect(() => {
    if (resetLightBox) {
      toggleIsVisible();
    }
  }, [resetLightBox]);
  useEffect(() => {
    if (!isVisible) {
      onLightboxClose();
    }
  }, [isVisible, onLightboxClose]);


  const handleKeydown = useCallback(
    ({ key }) => {
      if (key === 'Escape' && isVisible) {
        toggleIsVisible();
      }
    },
    [toggleIsVisible, isVisible],
  );
  useEffect(
    () => {
      document.addEventListener('keydown', handleKeydown);
      return () => { document.removeEventListener('keydown', handleKeydown); };
    },
    [handleKeydown],
  );

  if (!isVisible) {
    return null;
  }

  if (!announcement?.content
      && !(announcement?.ctaText && announcement?.ctaUrl)) {
    return null;
  }

  return (
    <div className='d-block modal lightbox text-center'>
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
        <div className='modal-content'>
          <div className='border-bottom-0 modal-header'>
            <button aria-label='Close' className='close px-3 py-2' onClick={ toggleIsVisible } type='button'>
              <img alt='Close this announcement' aria-hidden src={ closeIcon } />
            </button>
          </div>

          <div className='modal-body pt-0 px-0 px-lg-5'>
            <div className='primary-content pb-0 pb-lg-3 pt-0 px-5'>
              {
                announcement.header
                  ? (
                    <h1 className='mb-0 pb-3 px-lg-5'>
                      { announcement.header }
                    </h1>
                    )
                  : null
              }

              <div className='content-inner' dangerouslySetInnerHTML={{ __html: announcement.content }} />

              {
                announcement.ctaText
                  ? (
                    <a
                      className='btn btn-primary-red border-0 cta fw-bold h-auto mb-3 mt-2 px-5 py-3'
                      href={ announcement.ctaUrl }
                    >
                      { announcement.ctaText }
                    </a>
                    )
                  : null
              }
            </div>
            {
               (announcement.secondaryHeader || announcement.secondaryContent)
                 ? (
                   <div className='mx-5 pb-2 pb-lg-4 pt-4 pt-md-5 secondary-content'>
                     <h2 className='pb-3 pb-lg-2'>{ announcement.secondaryHeader }</h2>
                     <div className='content-inner' dangerouslySetInnerHTML={{ __html: announcement.secondaryContent }} />
                   </div>
                   )
                 : null
             }
          </div>
        </div>
      </div>
    </div>
  );
}

Lightbox.propTypes = {
  announcement: PropTypes.object,
  onLightboxClose: PropTypes.func,
  resetLightBox: PropTypes.bool,
};

export default Lightbox;
export { disableLightbox };
