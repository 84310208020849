import PropTypes from 'prop-types';

function ProgressBar({ current, max }) {
  const width = max > 0 ? `${ (current / max) * 100 }%` : '100%';
  return (
    <div className='progress mb-3'>
      <div
        aria-valuemax={ max }
        aria-valuemin='0'
        aria-valuenow={ current }
        className='progress-bar'
        role='progressbar'
        style={{ width }}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default ProgressBar;
