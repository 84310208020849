import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  FormDataConsumer,
  TextInput,
  BulkExportButton,
  SelectInput,
  Create,
  SimpleForm,
  Toolbar,
  required,
  SaveButton,
  Edit,
  DateInput,
  DateTimeInput,
  EditButton,
  useNotify,
} from 'react-admin';
import { createTimezoneDate } from '../lib/dates';

const AnnouncementEditToolbar = (props) => (
//removes default delete option from toolbar
  <Toolbar { ...props } >
    <SaveButton />
  </Toolbar>
);

const LightboxInputs = () => (
  <div>
    <TextInput helperText='Nav Bar Text to Trigger Lightbox' source='navBarText' src='url' /><br />
    <TextInput helperText='Header' source='header' src='url' /><br />
    <TextInput helperText='Secondary header' source='secondaryHeader' src='url' /><br />
    <TextInput fullWidth helperText='Secondary content; only a i p and strong tags allowed' multiline source='secondaryContent' src='url' /><br />
  </div>
);

const EventInputs = () => (
  <Fragment>
    <TextInput helperText='URL of image' source='imgSrc' src='url' validate={ [required()] } /><br />
    <TextInput helperText='URL where the button goes' source='buttonHref' src='url' validate={ [required()] } /><br />
    <DateTimeInput source='eventStartAt' validate={ [required()] } /><br />
  </Fragment>
);

const NonEventInputs = () => (
  <Fragment>
    <TextInput fullWidth helperText="Call-to-action text, like 'click here'; only a i and strong tags allowed" source='ctaText' /><br />
    <TextInput helperText='Call-to-action URL' source='ctaUrl' src='url' /><br />
  </Fragment>
);

const SharedInputs = () => (
  <Fragment>
    <SelectInput
      choices={ [
        { id: 'BANNER', name: 'Banner' },
        { id: 'LIGHTBOX', name: 'Lightbox' },
        { id: 'EVENT', name: 'Event' },
      ] }
      source='type'
      validate={ [required()] }
    />
    <br />
    <TextInput fullWidth helperText='For events this is the event name. Only a i p and strong tags allowed.' multiline source='content' validate={ [required()] } /><br />
    <FormDataConsumer>
      { ({ formData, ...rest }) => (
        <Fragment>

          { formData.type === 'LIGHTBOX' && <LightboxInputs { ...rest } /> }
          { ['LIGHTBOX', 'BANNER'].includes(formData.type) && <NonEventInputs /> }
          { formData.type === 'EVENT' && <EventInputs /> }
          <br />
        </Fragment>
      ) }
    </FormDataConsumer>
    <DateInput format={ (v) => (v ? createTimezoneDate(v).toISOString().split('T')[0] : v) } source='activeFrom' validate={ [required()] } /><br />
    <DateInput format={ (v) => (v ? createTimezoneDate(v).toISOString().split('T')[0] : v) } source='activeTo' validate={ [required()] } /><br />
  </Fragment>
);


const AnnouncementEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };

  return (
    <Edit undoable={ false } { ...props } onFailure={ onFailure }>
      <SimpleForm redirect='edit' toolbar={ <AnnouncementEditToolbar /> }>
        <TextInput disabled source='id' />
        <SharedInputs />
      </SimpleForm>
    </Edit>
  );
};

const AnnouncementCreateToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton
      label='create'
    />
  </Toolbar>
);
const errorHandler = (error, notify) => {
  notify(`Error: ${ error.body || error.message }`);
};

const AnnouncementCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };
  return (
    <Create { ...props } onFailure={ onFailure }>
      <SimpleForm toolbar={ <AnnouncementCreateToolbar /> }>
        <SharedInputs />
      </SimpleForm>
    </Create>
  );
};
const AnnouncementListBulkActionButtons = (props) => (
  <BulkExportButton { ...props } />
);

const AnnouncementFilter = (props) => (
  <Filter { ...props }>
    <DateInput label='Active From <=' source='activeFrom:lessThanOrEqualTo' />
    <DateInput label='Active To >=' source='activeTo:greaterThanOrEqualTo' />
  </Filter>
);

const AnnouncementList = (props) => (
  <List { ...props } bulkActionButtons={ <AnnouncementListBulkActionButtons /> } filters={ <AnnouncementFilter /> } sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField source='id' />
      <TextField source='type' />
      { /*<ImageField source="logo" />*/ }
      <TextField source='content' />
      <FunctionField
        label='Cta'
        render={ (record) => (
          <a href={ record.ctaUrl } rel='noreferrer' target='_blank'>
            { record.ctaText }
          </a>
        ) }
        sortBy='ctaText'
      />
      <FunctionField render={ ({ activeFrom }) => (activeFrom ? createTimezoneDate(activeFrom).toISOString().split('T')[0] : activeFrom) } source='activeFrom' />
      <FunctionField render={ ({ activeTo }) => (activeTo ? createTimezoneDate(activeTo).toISOString().split('T')[0] : activeTo) } source='activeTo' />
      <EditButton { ...props } />
    </Datagrid>
  </List>
);

export default AnnouncementList;
export { AnnouncementCreate, AnnouncementEdit };
