import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';
import {
  List,
  BulkUpdateButton,
  BulkExportButton,
  Button,
  Datagrid,
  Create,
  Edit,
  ExportButton,
  FileField,
  FileInput,
  FunctionField,
  TextField,
  DateField,
  NumberField,
  Pagination,
  SaveButton,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  Toolbar,
  TopToolbar,
  Filter,
  useNotify,
  usePermissions,
} from 'react-admin';
import PropTypes from 'prop-types';
import { useState } from 'react';
import SendIcon from '@material-ui/icons/Send';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import { checkPermission, getUserId } from '../lib/user';
import downloadLetters from '../lib/downloadLetters';
import downloadLetterBundles from '../lib/downloadLetterBundles';
import { useAuth } from '../contexts/AuthContext';
import AddressColumn from './tables/AddressColumn';

const Spacer = () => <Box component='span' width={ 20 } />;

const LetterBundlePagination = (props) => <Pagination rowsPerPageOptions={ [5, 10, 25, 50, 100, 500] } { ...props } />;


export const statusOptions = ({ mode = 'filter', permissions } = {}) => {
  if (['filter', 'edit'].indexOf(mode) < 0) {
    throw { status: 400, message: `invalid mode ${ mode } passed` };
  }
  const statuses = [
    { id: 'REQUESTED', name: 'Requested' },
    { id: 'APPROVED', name: 'Approved (ready for sending)' },
    { id: 'SENT', name: 'Sent' },
    { id: 'REJECTED', name: 'Rejected' },
  ];
  if (mode === 'filter'
    && permissions
    && !checkPermission({ grants: permissions, resource: '*:*', permission: 'letterBundle.approve' })
    && !checkPermission({ grants: permissions, resource: '*:*', permission: 'letterBundle.update' })
    && checkPermission({ grants: permissions, resource: '*:*', permission: 'letterBundle.send' })
  ) {
    return statuses.filter((status) => ['APPROVED', 'SENT'].indexOf(status.id) >= 0);
  } else {
    return statuses;
  }
};

/**
 * @param {Object} props
 * @returns 'APPROVED', 'SENT' or null based on what the next status (for bulk action button)
 *  should be based on the current status filter.
 */
export const getNextBulkStatus = (props) => {
  const permissions = props.permissions;
  if (props.filterValues?.status === 'REQUESTED'
    && permissions
    && checkPermission({ grants: permissions, resource: '*:*', permission: 'letterBundle.approve' })
  ) {
    return 'APPROVED';
  } else if (props.filterValues?.status === 'APPROVED'
    && permissions
    && checkPermission({ grants: permissions, resource: '*:*', permission: 'letterBundle.send' })
  ) {
    return 'SENT';
  } else {
    return null;
  }
};

const LetterBundleBulkActionButtons = (props) => {
  const nextBulkStatus = getNextBulkStatus(props);
  const auth = useAuth();
  const userId = getUserId();
  if (nextBulkStatus === 'APPROVED') {
    return (
      <BulkUpdateButton
        data={{ status: nextBulkStatus }}
        icon={ <ThumbUpIcon /> }
        label='Approve Selected'
        { ...props }
      />
    );
  } else if (nextBulkStatus === 'SENT') {
    return (
      <div>
        <BulkExportButton
          exporter={ async (records) => {
            const filters = { letterBundleIds: records.map((r) => r.id) };
            await downloadLetterBundles({ filters, auth, userId });
          } }
          icon={ <CloudDownloadIcon /> }
          label='Download PDFs'
          { ...props }
        />
        <BulkUpdateButton
          data={{ status: nextBulkStatus }}
          icon={ <SendIcon /> }
          label='Mark Sent'
          { ...props }
        />
      </div>
    );
  } else {
    return null;
  }
};

const CombinedAddressField = () => (
  <FunctionField
    label='Address'
    render={ AddressColumn }
  />
);

/**
 * Helper function that returns a "User Email" field with a link to the user profile page if the
 * current user has access to the user profile page.
 */
const EmailToUserProfileField = ({ permissions }) => {
  if (permissions && checkPermission({ grants: permissions, resource: '*:*', permission: 'user.read' })) {
    return (
      <FunctionField
        label='User Email'
        render={ (record) => (
          <Link target='_blank' to={ `/user/${ record.auth0Id }` }>
            { record['users.email'] }
          </Link>
        ) }
      />
    );
  } else {
    return (<TextField label='User Email' source='users.email' />);
  }
};

EmailToUserProfileField.propTypes = {
  permissions: PropTypes.string,
};

const ListActions = ({ showModal, permissions }) => (
  <TopToolbar style={{ alignItems: 'center' }}>


    <Filter>
      <SelectInput alwaysOn choices={ statusOptions({ permissions }) } id='status' source='status' />
      <SelectInput
        alwaysOn
        choices={ [
          { id: 'FULL', name: 'Full kits' },
          { id: 'STAMPS_ONLY', name: 'Stamp-only kits' },
        ] }
        source='kitType'
      />
      <TextInput alwaysOn source='hashid' />
      <TextInput alwaysOn label='District ID' source='districtId:startsWith' />
      <TextInput alwaysOn label='User Email' source='users.email:startsWith' />
    </Filter>

    { /* Add your custom actions */ }
    <ExportButton />
    <Button
      label='Upload tracking'
      onClick={ showModal }
    />
  </TopToolbar>
);

ListActions.propTypes = {
  permissions: PropTypes.any,
  showModal: PropTypes.func,
};

const LetterBundles = (props) => {
  const { permissions } = usePermissions();

  const [showTrackingUploadModal, setShowTrackingUploadModal] = useState(false);

  return (
    <div>
      <Modal
        hideModal={ () => setShowTrackingUploadModal(false) }
        modalText='Upload a CSV to add tracking to posted letter kits. The first column should be the ID of the letter bundle, and the second column should be the USPS tracking code.'
        modalTitle='Upload USPS tracking codes'
        showModal={ showTrackingUploadModal }
      >
        <Create
          basePath={ null }
          onSuccess={ () => {
            setShowTrackingUploadModal(false);
          } }
          resource='letterBundles/uploadTracking'
          transform={ async ({ file }) => {
            const resp = await fetch(file.file);
            const data = await resp.text();
            const rows = data.split(/\r?\n/);

            return { rows: rows.slice(1) };
          } }
        >
          <SimpleForm>
            <FileInput
              accept='.csv'
              label='Tracking codes file'
              placeholder='Drop a CSV file with USPS tracking codes, or click to select one'
              source='file'
            >
              <FileField source='file' title='File' />
            </FileInput>
          </SimpleForm>
        </Create>

      </Modal>
      <List
        { ...props }
        actions={ <ListActions permissions={ permissions } showModal={ () => setShowTrackingUploadModal(true) } /> }
        bulkActionButtons={ <LetterBundleBulkActionButtons { ...props } permissions={ permissions } /> }
        filter={{ type: 'LETTERKIT' }}
        pagination={ <LetterBundlePagination /> }
        sort={{ field: 'createdAt', order: 'ASC' }}
      >
        <Datagrid>
          { PdfLink({ field: 'hashid', label: 'Hashid', sortBy: 'hashid' }) }
          { EmailToUserProfileField({ permissions }) }
          <TextField source='kitType' />
          <TextField source='status' />
          <TextField source='districtId' />
          { CombinedAddressField() }
          <TextField source='statement' />
          <DateField showTime source='createdAt' />
          <TextField source='uspsTrackingCode' />
          <ShowButton { ...props } />
        </Datagrid>
      </List>
    </div>
  );
};

const LetterBundleEditToolbar = (props) => (
  <Toolbar { ...props }>
    <SaveButton />
  </Toolbar>
);

const PdfLink = (props = {}) => {
  const { field, ...rest } = props;
  const [letterLoading, setLetterLoading] = useState({});
  const auth = useAuth();
  return (
    <FunctionField
      { ...rest }
      render={ (record) => (
        <div>
          { field ? record[field] : null }
          {
            ['APPROVED', 'SENT'].indexOf(record.status) >= 0 && record.kitType === 'FULL'
              ? (
                <span
                  className='fw-bold blue-link m-2'
                  onClick={ async () => {
                    setLetterLoading({ ...letterLoading, [record.id]: true });
                    await downloadLetters({ userId: record.auth0Id, letterBundleId: record.id, auth, excludePrepped: false });
                    setLetterLoading({ ...letterLoading, [record.id]: false });
                  } }
                  style={{ cursor: 'pointer' }}
                >
                  {
                      letterLoading[record.id]
                        ? <span className='fa fa-pulse fa-spinner waiting fa-1x' />
                        : <span><i className='fa fa-arrow-circle-o-down' /> pdf</span>
                    }
                </span>
                )
              : null
          }
        </div>
      ) }
    />
  );
};

PdfLink.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  sortBy: PropTypes.string,
};

export const LetterBundleView = (props) => {
  const { permissions } = usePermissions();

  return (
    <Show { ...props }>
      <SimpleShowLayout>
        { PdfLink({ field: 'hashid', label: 'Hashid' }) }
        <TextField source='status' />
        <TextField source='districtId' />
        { EmailToUserProfileField({ permissions }) }
        <TextField source='preferredName' />
        { CombinedAddressField() }
        <TextField source='type' />
        <TextField source='kitType' />
        <NumberField source='bundleSize' />
        <TextField source='statement' />
        <TextField source='uspsTrackingCode' />
        <DateField showTime source='createdAt' />
        <DateField showTime source='approvedAt' />
        <DateField showTime source='sentAt' />
        <DateField showTime source='rejectedAt' />
      </SimpleShowLayout>
    </Show>
  );
};

export const LetterBundleEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => notify(`Error: ${ error.body || error.message }`);
  const { permissions } = usePermissions();
  return (
    <Edit onFailure={ onFailure } undoable={ false } { ...props }>
      <SimpleForm redirect='show' toolbar={ <LetterBundleEditToolbar /> }>
        <TextInput disabled source='id' />
        { PdfLink({ label: 'Hashid', field: 'hashid' }) }
        <SelectInput
          choices={ statusOptions({ mode: 'edit', permissions }) }
          helperText='Letter Kit Status'
          source='status'
        />
        <TextField source='districtId' />
        { EmailToUserProfileField({ permissions }) }
        <TextField source='preferredName' />
        {
          permissions && checkPermission({ grants: permissions, resource: '*:*', permission: 'letterBundle.update' })
            ? (
              <div>
                <TextInput source='preferredName' />
                <TextInput source='address' />
                <Box display='flex'>
                  <TextInput source='city' />
                  <Spacer />
                  <TextInput source='state' />
                  <Spacer />
                  <TextInput source='zipCode' />
                </Box>
                <TextInput source='uspsTrackingCode' />
              </div>
              )
            : CombinedAddressField()
        }
        <TextField source='type' />
        <NumberField source='bundleSize' />
        <TextField source='statement' />
        <DateField showTime source='createdAt' />
        <DateField showTime source='approvedAt' />
        <DateField showTime source='sentAt' />
        <DateField showTime source='rejectedAt' />
      </SimpleForm>
    </Edit>
  );
};

export default LetterBundles;
