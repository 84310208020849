import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import bigSendEn from '../locales/en/big-send.json';
import campaignsEn from '../locales/en/campaigns.json';
import commonEn from '../locales/en/common.json';
import commonEs from '../locales/es/common.json';
import optOutEn from '../locales/en/opt-out.json';
import optOutEs from '../locales/es/opt-out.json';
import mailingListEn from '../locales/en/mailing-list.json';
import mailingListEs from '../locales/es/mailing-list.json';
import partnerEn from '../locales/en/partner.json';
import pledgeToVoteEn from '../locales/en/pledge-to-vote.json';
import pledgeToVoteEs from '../locales/es/pledge-to-vote.json';
import programOverviewEn from '../locales/en/program-overview.json';
import programOverviewEs from '../locales/es/program-overview.json';
import socialEn from '../locales/en/social.json';

const isEnvProductionForReal = process.env.NODE_ENV === 'production'
                               && (!process.env.BABEL_ENV || process.env.BABEL_ENV === 'production');

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: !isEnvProductionForReal,
    defaultNS: 'common',
    fallbackLng: 'en',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    nonExplicitSupportedLngs: true,
    ns: [
      'big-send',
      'campaigns',
      'common',
      'optOut',
      'partner',
      'programOverview',
      'social',
    ],
    resources: {
      en: {
        'big-send': bigSendEn,
        campaigns: campaignsEn,
        common: commonEn,
        mailingList: mailingListEn,
        optOut: optOutEn,
        partner: partnerEn,
        pledgeToVote: pledgeToVoteEn,
        programOverview: programOverviewEn,
        social: socialEn,
      },
      es: {
        common: commonEs,
        mailingList: mailingListEs,
        optOut: optOutEs,
        pledgeToVote: pledgeToVoteEs,
        programOverview: programOverviewEs,
      },
    },
    supportedLngs: ['en', 'es'],
  });

export default i18next;
