import classNames from 'classnames';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import LetterKitIcon from '../letter-kits/LetterKitIcon';
import LetterKitRequestForm from '../letter-kits/LetterKitRequestForm';
import SupportVF from '../components/SupportVF';
import VerifyEmailRequest from '../VerifyEmailRequest';
import { get } from '../lib/apiClient';
import { isAuthenticated } from '../lib/user';

import '../scss/pages/_letter-kits-overview.scss';

import envelopeIcon from '../images/vf_icon_envelope.png';
import letterIcon from '../images/vf_icon_letter.png';
import stampIcon from '../images/vf_icon_stamp.png';

function LetterKitsOverview() {
  const useKitLimit = (queryKey, endpoint) => useQuery(
    ['settings', queryKey],
    async () => await get(`/v1/settings/${ endpoint }`),
    { select: (limit) => (limit ? Math.max(limit.value - limit.currentCount, 0) : 0) },
  );
  const { data: availableLetterKitCount } = useKitLimit(
    'availableLetterKitCount',
    'LETTERKIT_LIMIT',
  );
  const { data: availableStampKitCount } = useKitLimit(
    'availableStampKitCount',
    'STAMPKIT_LIMIT',
  );

  function isAnyKitAvailable() {
    return availableLetterKitCount !== 0 || availableStampKitCount !== 0;
  }

  return (
    <Fragment>
      <Helmet>
        <title> Letter Kits</title>
      </Helmet>

      <main className='col-lg-8 mx-auto my-5' id='letter-kits-overview'>
        <section className='container mb-4 overview'>
          <div className='row'>
            <div className='col'>
              <h1>Letter kits</h1>

              <p>
                Vote Forward letters are one of the
                { ' ' }
                <Link to='/impact'>
                  most effective known ways
                </Link>
                { ' ' }
                to boost voter turnout. But for some
                volunteers, there are practical and financial barriers, including access to a printer, stamps,
                envelopes.
                { ' ' }
                <b>
                  That&rsquo;s why we offer letter kits!
                </b>
              </p>
            </div>
          </div>
        </section>

        <section className='container how-it-works mb-5'>
          <div className='row'>
            <div className='col'>
              <a id='how-it-works'>
                <h2 className='h3 headline'>
                  How it works
                </h2>
              </a>
              <p>
                Complete a brief application form with your mailing address, and we&rsquo;ll send you supplies for letters to 20 voters in one of our campaigns. You can request a complete kit including the letters and envelopes, or if you&rsquo;ve got that part covered, just the stamps.
              </p>
              <p>
                In either case, 20 voters from the campaign you choose will be automatically &ldquo;adopted&rdquo; into your account and appear on your <Link to='/dashboard'>dashboard</Link>, where you can mark them &ldquo;prepared&rdquo; and then &ldquo;sent&rdquo; after the mail date.
              </p>
            </div>
          </div>

          <div className='row'>
            <LetterKitIcon alt='icon of stamp on dark blue background' caption='20 stamps' src={ stampIcon } />
            <LetterKitIcon
              alt='icon of letter on dark blue background'
              caption='20 printed letters (optional)'
              src={ letterIcon }
            />
            <LetterKitIcon
              alt='icon of envelope on dark blue background'
              caption='20 blank envelopes (optional)'
              src={ envelopeIcon }
            />
          </div>

          <div className='row'>
            <div className='col'>
              <p>
                Just prepare the letters, mail them on the mail date, and mark them &ldquo;sent&rdquo;
                on your Vote Forward dashboard once you&rsquo;ve done so.
              </p>
            </div>
          </div>
        </section>

        <section className='request-kit container mb-4'>
          <div className='row'>
            <div className='col'>
              <a id='request-a-kit'>
                <h2 className='h3 headline'>
                  Current Inventory
                </h2>
                <h3>
                  Full kits (envelopes, letters, stamps):
                  { ' ' }
                  { availableLetterKitCount }
                </h3>
                <h3>
                  Postage-only kits (just the stamps):
                  { ' ' }
                  { availableStampKitCount }
                </h3>
              </a>
            </div>
          </div>
          <div
            className={ classNames(
              'row',
              {
                'mb-1': isAnyKitAvailable(),
                'mb-5': !isAnyKitAvailable(),
              },
            ) }
          >
            <div className='col'>
              {
                !isAnyKitAvailable()
                  ? (
                    <Fragment>
                      <p>
                        Thank you for your interest in writing Vote Forward letters. We&rsquo;ve received requests for the
                        as many kits as are currently available, so we cannot accept additional requests at this
                        time. If you requested a kit and received an approval email, but have not yet received your kit,
                        it is being assembled and will be mailed to you soon.
                      </p>
                      <p>
                        If you&rsquo;d like to help supply more letter kits to more volunteers,
                        { ' ' }
                        <a href='https://secure.actblue.com/donate/vote-forward-letter-kits?refcode=letterkits_landing'>
                          please consider making a donation
                        </a>
                        { ' ' }
                        to support this and all of Vote Forward&rsquo;s programs.
                      </p>
                    </Fragment>
                    )
                  : null
              }
            </div>
          </div>
          {
            isAnyKitAvailable() && isAuthenticated()
              ? (
                <div className='row'>
                  <div className='col'>
                    <LetterKitRequestForm
                      isFullKitAvailable={ availableLetterKitCount !== 0 }
                      isPostageOnlyKitAvailable={ availableStampKitCount !== 0 }
                    />
                  </div>
                </div>
                )
              : null
          }
          {
            isAnyKitAvailable() && !isAuthenticated()
              ? (
                <div className='row'>
                  <div className='col'>
                    <p>
                      While your application is very welcome, we ask that you refrain from applying if you don&rsquo;t need the support, so that other volunteers can make use of the program. Volunteers are able to request one letter or stamp kit every 30 days.
                    </p>

                    <VerifyEmailRequest hideFooter hideHeader hideMasthead purpose='register.user' rememberLocation />
                  </div>
                </div>
                )
              : null
          }
        </section>

        <SupportVF
          appeal='If you can, please consider making a donation to help support Vote Forward&rsquo;s work and the letter kits program. Each letter kit costs approximately $20 to produce and distribute.'
          appealHeadline='Support Vote Forward & This Program'
          donationRefCode='letterkits_landing'
          donationSlug='vote-forward-letter-kits'
        />
      </main>
    </Fragment>
  );
}

export default LetterKitsOverview;
