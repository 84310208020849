import PropTypes from 'prop-types';

DownloadButton.propTypes = {
  buttonType: PropTypes.string,
  downloadState: PropTypes.object.isRequired,
  isIconOnly: PropTypes.bool,
  modifyAndDownloadState: PropTypes.object.isRequired,
};

export default function DownloadButton({
  downloadState,
  isIconOnly = false,
  modifyAndDownloadState,
  buttonType = 'slideOutRow',
}) {
  const buttonClassNameConfigs = {
    slideOutRow: {
      column: 'col col-download-link letter-text',
      row: 'row download-link',
      button: 'btn btn-link fill-row fw-bold download-link',
      buttonText: 'download-text',
    },
    columnCell: {
      column: 'col',
      row: 'row px-2',
      button: 'btn-bundle-status btn-primary-v2 rounded-pill',
      buttonText: `download-message ${ downloadState.downloading ? 'is-loading' : '' }`,
    },
  };
  const buttonClassNames = buttonClassNameConfigs[buttonType];

  return (
    <div className={ buttonClassNames.column } >
      <div className={ buttonClassNames.row } >
        <button
          className={ buttonClassNames.button }
          disabled={ downloadState.downloading || modifyAndDownloadState.isDownloadDisabled }
          onClick={ downloadState.startDownload }
        >
          { downloadState.downloading && (<i className='fa fa-spin fa-spinner' />) }
          { !downloadState.downloading && buttonType === 'slideOutRow' && (
            <div>
              <i className={ `fa ${ downloadState.downloadSuccess ? 'fa-check-circle-o' : 'fa-arrow-circle-o-down' }` } />
              <i className='fa fa-arrow-circle-down' />
            </div>
          ) }
          { !downloadState.downloading && buttonType === 'columnCell' && (<i className='fa fa-arrow-circle-down' />) }
          { !isIconOnly && (<span className={ buttonClassNames.buttonText } >Download</span>) }
        </button>
      </div>
      { buttonType === 'columnCell' && downloadState.downloading && (
        <div className='row download-message'>
          <span>Preparing PDF file for download</span>
        </div>
      ) }
      { downloadState.downloadError
        ? (
          <div className='row download-message download-error'>
            <span>Download Failed</span>
          </div>
          )
        : (null) }
    </div>
  );
}
