import propTypes from 'prop-types';
import PhotoBox from '../components/PhotoBox';

import '../scss/_progress-bar.scss';
import '../scss/big-send/_progress-box.scss';

import progressBoxPhoto from '../images/big-send/progress-box-photo.png';

const calculatePercentage = (progress, target) => (
  Math.round(progress / target * 100 * 100) / 100
);

const convertToMillions = (value) => (
  parseFloat((value / 1000000).toFixed(2))
);

function ProgressBox({ progress, target }) {
  // if it's not literally 0, show a 1% bar, just so there's something
  const goalProgressPercent = progress === 0 ? 0 : Math.max(calculatePercentage(progress, target), 1);

  const imageCol = (
    <div className='image-inner'>
      <img alt='user holding letters next to a mailbox' className='pull-right' src={ progressBoxPhoto } />
    </div>
  );

  const contentCol = progress < target
    ? (
      <div className='content-inner not-completed'>
        <div className='count-text'>
          Letters in 2022 so far:
        </div>
        <div className='count-count'>
          { progress.toLocaleString() }
        </div>
        <div className='progress-container'>
          <div className='progress'>
            <div
              aria-valuemax={ 100 }
              aria-valuemin={ 0 }
              aria-valuenow={ goalProgressPercent }
              className={ `progress-bar ${ goalProgressPercent >= 99 ? 'complete' : '' }` }
              role='progressbar'
              style={{ width: `${ goalProgressPercent }%` }}
            />
          </div>
          <div className='labels'>
            <span className='label min'>0</span>
            <span className='label mid'>{ convertToMillions(target / 2.0) }M</span>
            <span className='label max'>{ convertToMillions(target) }M</span>
          </div>
        </div>
      </div>
      )
    : (
      <div className='content-inner completed'>
        <div className='count-text'>
          <span className='highlight'>
            We hit our 10 million letter goal for 2022!
          </span> But we&apos;re not stopping there:
        </div>
        <div className='count-count'>
          { progress.toLocaleString() }
        </div>
      </div>
      );

  return (
    <div className='progress-box'>
      <PhotoBox
        contentCol={ contentCol }
        imageCol={ imageCol }
      />
    </div>
  );
}

ProgressBox.propTypes = {
  progress: propTypes.number.isRequired,
  target: propTypes.number.isRequired,
};

export default ProgressBox;
