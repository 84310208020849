import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || '';

async function get(path, options = {}) {
  const { data } = await axios.get(`${ apiUrl }${ path }`, options);
  return data;
}

async function getWithOptionalToken(path, auth, options = {}) {
  if (auth) {
    return getWithToken(path, auth, options);
  }
  return await get(path, options);
}

async function getWithToken(path, auth, options = {}) {
  const accessToken = await auth.getAccessToken();
  return await get(path, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${ accessToken }`,
    },
  });
}

async function post(path, postData, options = {}) {
  const { data } = await axios.post(`${ apiUrl }${ path }`, postData, options);
  return data;
}

async function postWithToken(path, postData, auth, options = {}) {
  const accessToken = await auth.getAccessToken();
  return await post(path,
    postData,
    {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `Bearer ${ accessToken }`,
      },
    });
}

async function patch(path, postData, options = {}) {
  const { data } = await axios.patch(`${ apiUrl }${ path }`, postData, options);
  return data;
}

async function patchWithToken(path, postData, auth, options = {}) {
  const accessToken = await auth.getAccessToken();
  return await patch(path,
    postData,
    {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `Bearer ${ accessToken }`,
      },
    });
}

export {
  get,
  getWithOptionalToken,
  getWithToken,
  patch,
  patchWithToken,
  post,
  postWithToken,
};
