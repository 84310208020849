import moment from 'moment';
import PropTypes from 'prop-types';

import { letterKitPropType } from './letterKitTypes';

import '../scss/letter-kits/_letter-kit-request-summary.scss';

const approvalStatuses = {
  APPROVED: 'Approved',
  REJECTED: 'Canceled',
  REQUESTED: 'Approval pending',
  SENT: 'Shipped',
};

function LetterKitRequestSummaryRow({
  kit: {
    createdAt,
    'districts.displayName': displayName,
    status,
  },
}) {
  const approvalStatus = approvalStatuses[status];
  const requestedAt = moment(createdAt).format('MMM. D, YYYY');
  return (
    <tr>
      <td className='col-6 h5 px-0' title={ displayName }>
        { displayName }
      </td>
      <td className='col-3 h5 pe-0' title={ requestedAt }>
        { requestedAt }
      </td>
      <td className='col-3 h5 pe-0' title={ approvalStatus }>
        { approvalStatus }
      </td>
    </tr>
  );
}

LetterKitRequestSummaryRow.propTypes = {
  kit: letterKitPropType.isRequired,
};

function LetterKitRequestSummary({ kits }) {
  return (
    <table className='border-0 letter-kit-request-summary table table-borderless w-100'>
      <thead className='small'>
        <tr>
          <th className='col-6 pb-0 px-0' scope='col'>Letter kit</th>
          <th className='col-3 pb-0 pe-0' scope='col'>Request date</th>
          <th className='col-3 pb-0 pe-0' scope='col'>Status</th>
        </tr>
      </thead>
      <tbody>
        {
          kits.map((kit) => (
            <LetterKitRequestSummaryRow key={ kit.id } kit={ kit } />
          ))
        }
      </tbody>
    </table>
  );
}

LetterKitRequestSummary.propTypes = {
  kits: PropTypes.arrayOf(letterKitPropType).isRequired,
};

export default LetterKitRequestSummary;
export { LetterKitRequestSummaryRow };
