function decodeToken(token) {
  return JSON.parse(window.atob(token.split('.')[1]));
}

function extraTokenClaim(claim) {
  const accessToken = localStorage.getItem('access_token') || '';
  const extraToken = accessToken.split(':').length > 1 ? accessToken.split(':')[1] : undefined;
  const decodedExtraToken = extraToken ? decodeToken(extraToken) : {};
  return decodedExtraToken[claim];
}

export {
  decodeToken,
  extraTokenClaim,
};
