import PropTypes from 'prop-types';

SocialSendEmail.propTypes = {
  bundle: PropTypes.object.isRequired,
};

export default function SocialSendEmail({ bundle }) {
  // TODO: this should be a link to auto-send an email to the assignee.
  return (
    <span className='text-gray'>Assignee Email: { bundle.social_assignee_email }</span>
  );
}
