import { useHistory, useRouteMatch } from 'react-router-dom';

import Loading from './Loading';
import Page from '../page/Page';
import { disableLightbox } from '../page/Lightbox';
import { get } from '../lib/apiClient';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

function GuidedLogin() {
  const history = useHistory();
  const { getUserId, login } = useAuth();
  const { params: { compositeUpdateToken }} = useRouteMatch();
  const { data: lightbox, isSuccess: lightboxIsSuccess } = useQuery(
    ['lightbox', 'get'],
    async () => await get('/v1/announcements/lightbox/current'),
  );
  const { data: emailPreferences, isSuccess: emailPreferencesIsSuccess } = useQuery(
    ['emails', 'preferences', 'get', { compositeUpdateToken }],
    async () => await get(
      '/v1/emails/preferences',
      { params: { composite_update_token: compositeUpdateToken }},
    ),
  );

  useEffect(
    () => {
      if (lightboxIsSuccess) {
        disableLightbox(lightbox.id);
      }
    },
    [lightbox, lightboxIsSuccess],
  );

  useEffect(
    () => {
      if (emailPreferencesIsSuccess) {
        const auth0Id = emailPreferences.auth0_id;
        const userId = getUserId();

        if (auth0Id === userId) {
          history.push('/dashboard');
          return;
        }

        if (/^google/.test(auth0Id)) {
          login(false, { connection: 'google-oauth2' }); // NB: This is async
        } else if (/^facebook/.test(auth0Id)) {
          login(false, { connection: 'facebook' }); // NB: This is async
        } else {
          login(false, { login_hint: emailPreferences.email }); // NB: This is async
        }
      }
    },
    [emailPreferences, emailPreferencesIsSuccess, getUserId, history, login],
  );

  return (
    <Page>
      <Loading />
    </Page>
  );
}

export default GuidedLogin;
