import axios from 'axios';
import PropTypes from 'prop-types';
import { Component } from 'react';
import AdoptVoter from '../dashboard/voter-adoption/AdoptVoter';
import logger from '../lib/logger';
import Page from '../page/Page';
import Tracker from '../lib/Tracker';
import ManageLetters from '../dashboard/manage-letters/ManageLetters';
import { AuthContext } from '../contexts/AuthContext';
import { isDatePast } from '../lib/dates.js';
import ScrollToTop from '../lib/ScrollToTop';
import { canUserAdoptSocialBatches } from '../lib/user';


class Dashboard extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.tracker = new Tracker({ auth: this.context });

    this.state = {
      allVoters: [],
      currentDistrict: {},
      currentDistrictPending: true,
      isQualified: false,
      voters: [],
      letterFilter: '',
    };
  }

  async componentDidMount() {
    this.unmounted = false;
    if (!this.context.isEmailVerified()) {
      if (this.props.user.email) {
        location.replace(`/verify-email/${ this.props.user.email }`);
      } else {
        location.replace(`/verify-email`);
      }
    } else if (!this.context.isQualified()) {
      location.replace('/verify');
    } else if (this.props.match.params.districtId) {
      const districtToFetch = this.props.match.params.districtId.toUpperCase();
      await this.updateDistrict(districtToFetch);
      this.setDefaultDistrictInSession();
      this.getAdoptedVoters();
    } else {
      this.getAdoptedVoters();
      this.tracker.setGlobalProperties({ currentDistrict: this.props.user.current_district });
      this.getCurrentDistrict(this.props.user.current_district);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  setDefaultDistrictInSession = () => {
    sessionStorage.setItem('defaultDistrictId', this.props.match.params.districtId.toUpperCase());
  };

  getCurrentDistrict = async (districtId) => {
    this.setState({ currentDistrictPending: true });
    try {
      const accessToken = await this.context.getAccessToken();
      const res = await axios
        .get(`${ process.env.REACT_APP_API_URL }/lookup-district`,
          {
            headers: {
              Authorization: 'Bearer '.concat(accessToken),
            },
            params: {
              districtId,
              autoAssign: true,
              userPartners: this.context.getUserPartners().join(','),
            },
          });
      if (!this.unmounted) {
        if (res.data.length > 0) {
          this.setState({ currentDistrict: res.data[0], currentDistrictPending: false });
        } else {
          logger.debug('Found no active campaigns to display.');
          this.setState({ currentDistrictPending: false });
        }
      }
    } catch (err) {
      if (!this.unmounted) {
        this.setState({ currentDistrictPending: false });
        logger.error(err);
      }
    }
  };


  updateDistrict = async (newDistrictId, onSuccessCallback = () => (null)) => {
    if (this.state.awaitingDistrictUpdate) {
      return;
    }
    this.setState({ awaitingDistrictUpdate: true });

    const data = { current_district: newDistrictId };
    try {
      const accessToken = await this.context.getAccessToken();
      await axios.patch(`${ process.env.REACT_APP_API_URL }/v1/users/self`,
        data,
        {
          headers: {
            Authorization: 'Bearer '.concat(accessToken),
          },
        });
      if (!this.unmounted) {
        onSuccessCallback();
        this.tracker.setGlobalProperties({ currentDistrict: newDistrictId });
        await this.getCurrentDistrict(newDistrictId);
      }
    } finally {
      this.setState({ awaitingDistrictUpdate: false });
    }
  };

  async getAdoptedVoters() {
    const user_id = localStorage.getItem('user_id');
    if (user_id) {
      const accessToken = await this.context.getAccessToken();
      const headers = {
        Authorization: 'Bearer '.concat(accessToken),
      };
      const res = await axios
        .get(`${ process.env.REACT_APP_API_URL }/v1/users/${ user_id }/letters`, {
          headers,
          // Specify specific fields so large requests don't die (see VF-653)
          params: {
            campaignTimeframe: 'active',
            fields: [
              'id',
              'election_date',
              'toPrep',
              'toSend',
              'alreadySent',
              'letter_bundle_id',
              'letter_bundle_type',
              'mail_date_from',
              'mail_date',
              'first_name',
              'last_name',
              'city',
              'state',
            ].join(','),
          },
        });
      if (!this.unmounted) {
        this.setState({
          allVoters: res.data,
          voters: res.data.filter((voter) => !voter.letter_bundle_type || voter.letter_bundle_type === 'BUNDLE'),
        });
      }
    }
  }

  handleAdoptionSuccess = () => {
    this.getAdoptedVoters();
    this.updateFilter('');
  };

  updateFilter = (updatedFilter) => {
    if (!updatedFilter || updatedFilter === '') {
      this.setState({ letterFilter: updatedFilter });
    } else {
      this.setState({ letterFilter: updatedFilter.toLowerCase() });
    }
  };

  render() {
    const { allVoters, voters } = this.state;
    this.tracker.logEvent('Dashboard:function:render', { voterCount: voters ? voters.length : -1 });
    const allLiveVoters = allVoters.filter((voter) => !isDatePast(voter.election_date));
    const canAdoptSocial = canUserAdoptSocialBatches();

    return (
      <Page>
        <main>
          <ScrollToTop />
          <AdoptVoter
            allVoters={ allVoters }
            auth={ this.context }
            canAdoptSocial={ canAdoptSocial }
            currentDistrict={ this.state.currentDistrict }
            currentDistrictPending={ this.state.currentDistrictPending }
            handleAdoptionSuccess={ this.handleAdoptionSuccess }
            liveVoterCount={ allLiveVoters.length }
            user={ this.props.user }
            voters={ allLiveVoters }
          />


          <div className='container-fluid py-3'>
            <ManageLetters canAdoptSocial={ canAdoptSocial } letterFilter={ this.state.letterFilter } updateFilter={ this.updateFilter } />
          </div>
        </main>
      </Page>
    );
  }
}

Dashboard.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default Dashboard;
