import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-query';
import { get } from '../lib/apiClient';
import PhotoBox from './PhotoBox';

import '../scss/components/_event-box.scss';

const fetchCurrentEvent = async () => {
  const data = await get('/v1/announcements/event/current');
  if (!data?.content || !data?.eventStartAt || !data?.imgSrc) {
    return null;
  }
  return data;
};


function useCurrentEvent() {
  return useQuery(['events', 'current'], fetchCurrentEvent);
}

function EventBox({ variant = '' }) {
  const { data: event, isLoading } = useCurrentEvent();

  const imgSrc = useMemo(() => event?.imgSrc, [event]);
  const content = useMemo(() => event?.content, [event]);
  const buttonHref = useMemo(() => event?.buttonHref, [event]);
  const startAt = useMemo(() => new Date(event?.eventStartAt), [event]);

  const pstStartAt = useMemo(() => new Date(startAt.toLocaleString('en-us', { timeZone: 'America/Los_Angeles' })), [startAt]);
  const estStartAt = useMemo(() => new Date(startAt.toLocaleString('en-us', { timeZone: 'America/New_York' })), [startAt]);
  const estDate = useMemo(() => estStartAt.getDate(), [estStartAt]);
  const estMonth = useMemo(() => estStartAt.toLocaleString('default', { month: 'long' }), [estStartAt]);
  const pstHour = useMemo(() => pstStartAt.toLocaleString('default', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }), [pstStartAt]);

  const estHour = useMemo(() => estStartAt.toLocaleString('default', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }), [estStartAt]);

  if (!event || isLoading) {
    return null;
  }

  const imageCol = (
    <div className='image-inner'>
      <img alt='' className='event-image' src={ imgSrc } />
    </div>
  );


  const contentCol = (
    <div className='content-inner'>
      <div className='vstack align-items-center align-self-center text-center gap-4'>
        <h3 className='header'>Upcoming virtual event</h3>
        <h2 className='event-title'>{ content }</h2>
        <p className='event-date'>{ estMonth } { estDate } at { estHour } ET / { pstHour } PT</p>
        <div className='rsvp-button-container d-flex justify-content-center'>
          { /* TODO do these need to have sourcing on them particular to embedding page? */ }
          <a className='rsvp-button py-xs-0 py-md-4 fs-3 btn btn-primary-lightblue' href={ buttonHref } rel='noreferrer' target='_blank'>RSVP Today</a>
        </div>
      </div>
    </div>
  );

  return (
    <div className={ `event-box ${ variant }` }>
      <PhotoBox
        contentCol={ contentCol }
        imageCol={ imageCol }
        photoOnRight={ variant === 'bigsend' }
      />
    </div>
  );
}

EventBox.propTypes = {
  variant: propTypes.oneOf(['bigsend']),
};

export default EventBox;
