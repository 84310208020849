import PropTypes from 'prop-types';
import { useSocialBatchMutation } from '../../lib/queries/queries';
import VoterAdoptionButton from './VoterAdoptionButton';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import { useCallback, useEffect, useState } from 'react';

const VOTERS_PER_BUNDLE = 10;

PartyAdoptionButtons.propTypes = {
  availableVoters: PropTypes.any.isRequired,
  campaign: PropTypes.object,
  isAdopterMaxedOut: PropTypes.bool,
  isCampaignClosed: PropTypes.bool,
  liveVoterCount: PropTypes.number,
  maxQual: PropTypes.number.isRequired,
  onAdoptionQuerySuccess: PropTypes.func,
};

export default function PartyAdoptionButtons({
  campaign,
  isAdopterMaxedOut,
  isCampaignClosed,
  liveVoterCount,
  onAdoptionQuerySuccess,
  maxQual,
  availableVoters,
}) {
  const socialBatchMutation = useSocialBatchMutation({ onSuccess: onAdoptionQuerySuccess });
  const maxFromQual = Math.max((maxQual - liveVoterCount), 0);
  const maxBundlesCanAdopt = Math.floor(Math.min(maxFromQual, availableVoters) / VOTERS_PER_BUNDLE);

  const [bundleQuantity, setBundleQuantity] = useState(Math.min(5, maxBundlesCanAdopt));

  const resetBundleQuantity = useCallback((newMaximum) => (setBundleQuantity(Math.min(bundleQuantity, newMaximum))), [bundleQuantity]);
  useEffect(() => (resetBundleQuantity(maxBundlesCanAdopt)), [resetBundleQuantity, maxBundlesCanAdopt]);

  // Since social bundles need be full quantities, rather than partial bundles, treat the user as maxed out if they can't adopt a full bundle.
  const isMaxedOutForBundles = isAdopterMaxedOut || (maxFromQual < VOTERS_PER_BUNDLE);
  const isCampaignClosedForBundles = isCampaignClosed || (maxBundlesCanAdopt === 0);

  return (
    <div>
      <div className='row default-adoption-options-row'>
        <h5>How many people at your event?</h5>
        <p>
          { `We recommend 1 bundle of letters for each member of your writing party (1 bundle = ${ VOTERS_PER_BUNDLE } letters).` }
        </p>
      </div>
      <div className='row default-adoption-options-row'>
        <div className='col side-col'>
          <NumberInput
            aria-label='Bundle Quantity Input'
            max={ maxBundlesCanAdopt }
            min={ Math.min(1, maxBundlesCanAdopt) }
            onChange={ (event, newValue) => {
              setBundleQuantity(Math.min(maxBundlesCanAdopt, newValue));
            } }
            slotProps={{
              input: {
                className: 'input-element',
              },
              incrementButton: {
                children: '+',
                className: 'increment input-element btn-number-input',
              },
              decrementButton: {
                children: '-',
                className: 'input-element btn-number-input',
              },
            }}
            value={ bundleQuantity }
          />
        </div>
        <div className='col fill-row'>
          <VoterAdoptionButton
            adoptionType='party'
            campaign={ campaign }
            isAdopterMaxedOut={ isMaxedOutForBundles }
            isCampaignClosed={ isCampaignClosedForBundles }
            liveVoterCount={ liveVoterCount }
            socialBatchQuantity={ bundleQuantity }
            voterAdoptionMutation={ socialBatchMutation }
            voterQuantity={ VOTERS_PER_BUNDLE }
          />
        </div>
      </div>
    </div>
  );
}
