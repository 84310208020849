import PropTypes from 'prop-types';

import '../scss/lib/_buttons.scss';

function DonationButton({ amount, donationSlug, refCode }) {
  const params = { refcode: refCode };
  if (amount) {
    params.amount = amount;
    params.express_lane = true;
  }
  const hrefParams = new URLSearchParams(params);
  const donationPrompt = amount ? `Express donate: $${ amount }` : 'Donate other amount';

  return (
    <div className='col donation-button'>
      <a
        className='btn btn-primary-lightblue w-100'
        href={ `https://secure.actblue.com/donate/${ donationSlug }?${ hrefParams }` }
        rel='noopener noreferrer'
        target='_blank'
      >
        { donationPrompt }
      </a>
    </div>
  );
}

DonationButton.propTypes = {
  amount: PropTypes.number,
  donationSlug: PropTypes.string.isRequired,
  refCode: PropTypes.string.isRequired,
};

export default DonationButton;
