import { useCallback } from 'react';

import identity from './functions/identity';

function useComposedSelector(selectors) {
  return useCallback(
    (collection) => selectors[1](selectors[0](collection)),
    [selectors],
  );
}

function useSortedSelector({ selector = identity, sorter = identity } = { selector: identity, sorter: identity }) {
  return useCallback(
    (collection) => selector(collection.sort(sorter)),
    [selector, sorter],
  );
}

export { useComposedSelector, useSortedSelector };
