import propTypes from 'prop-types';
import TrainingRequiredDetails from './TrainingRequiredDetails';
import CampaignLabels from '../../campaigns/CampaignLabels';
import VoterAdoptionDetails from './VoterAdoptionDetails';

VoterAdoptionHeader.propTypes = {
  availableVoters: propTypes.number.isRequired,
  campaign: propTypes.object.isRequired,
  campaignClosed: propTypes.bool.isRequired,
  children: propTypes.any.isRequired,
  goToTraining: propTypes.func.isRequired,
  headerMessage: propTypes.string.isRequired,
  maxedOut: propTypes.bool.isRequired,
  requestSuperqualStatus: propTypes.func.isRequired,
  showSeeAllCampaignsButtonText: propTypes.bool.isRequired,
  training: propTypes.object.isRequired,
  updateTraining: propTypes.func.isRequired,
};

export default function VoterAdoptionHeader({
  maxedOut,
  campaignClosed,
  showSeeAllCampaignsButtonText,
  campaign,
  training,
  goToTraining,
  updateTraining,
  availableVoters,
  children,
  requestSuperqualStatus,
  headerMessage,
}) {
  const seeAllCampaignsButtonText = showSeeAllCampaignsButtonText ? 'See all active campaigns' : 'Adopt from a different campaign';
  const trainingRequired = (campaign.training_url && !training.finished_at);

  return (
    <div className='district-item-content'>
      <div className='row'>
        <div className='col-6'>
          <h2 className='text-gray adopt-message'>
            { headerMessage }
          </h2>
        </div>
        <div className='col-6'>
          <a className='btn-secondary-v2 btn-district-item-campaigns-link btn-see-all-campaigns' href='/campaigns' >
            <span className='see-all-campaigns-button-text-lg'>{ seeAllCampaignsButtonText }</span>
            <span className='see-all-campaigns-button-text-sm'> Switch campaign </span>
          </a>
        </div>
      </div>
      <div className='row'>
        <h2>
          { campaign.display_name }
        </h2>
      </div>
      <div className='row'>
        <CampaignLabels
          isBundleDisplay={ false }
          isLabs={ campaign.is_labs }
          isPolitical={ campaign.tax_type === 'political' }
          isPriority={ campaign.priority }
          isSocial={ campaign.tax_type === 'social' }
          isVoterRegistration={ campaign.tax_type === 'voter-engagement' }
        />
      </div>

      { trainingRequired
        ? (
          <TrainingRequiredDetails
            goToTraining={ goToTraining }
            training={ training }
            updateTraining={ updateTraining }
          />
          )
        : (
          <VoterAdoptionDetails
            availableVoters={ availableVoters }
            campaign={ campaign }
            isAdopterMaxedOut={ maxedOut }
            isCampaignClosed={ campaignClosed }
            requestSuperqualStatus={ requestSuperqualStatus }
            updatedVoterCount={ Math.max(campaign.voter_count - availableVoters, 0) }
          >
            { children }
          </VoterAdoptionDetails>
          ) }

    </div>
  );
}
