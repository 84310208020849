import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Callouts from './Callouts';
import MailingList from '../components/MailingList';
import EventBox from '../components/EventBox';
import Masthead from '../page/Masthead';

import i18n from '../locales/strings.json';
import logger from '../lib/logger';
import { formatBigNumber } from '../lib/formatNumbers';
import { getUserId } from '../lib/user';
import { isDatePast } from '../lib/dates.js';
import { message } from '../lib/deprecated_i18n';
import { useAuth } from '../contexts/AuthContext';
import { useCampaigns } from '../lib/queries/queries';
import { initBeacon, destroyBeacon } from '../Beacon';

function isLiveCampaign(campaign) {
  return !isDatePast(campaign.mail_date) && campaign.available_voter_count > 0;
}

function HomeCampaigns() {
  const auth = useAuth();
  const [locale] = useState('en');
  const auth0Id = getUserId();
  const userPartners = auth.getUserPartners().filter(Boolean).join(' ');
  const { data: liveCampaignCount } = useCampaigns(
    { auth0Id, userPartners },
    {
      placeholderData: [],
      select: (campaigns) => campaigns.filter(isLiveCampaign).length,
    },
  );

  return (
    <section className='home--campaigns'>
      <h1 className='pt-3 mb-4 text-center' id='target-districts'>
        <span>
          { liveCampaignCount } { ' ' }
        </span>
        { message('home.campaigns.header', {}, liveCampaignCount) }
      </h1>
      { liveCampaignCount > 0
        ? (
          <div className='campaigns-button container'>
            <Link className='btn btn-primary-red' to='/campaigns'>{ i18n[locale]['home.campaigns.button'] }</Link>
          </div>
          )
        : (
          <div>
            <p>{ i18n[locale]['home.campaigns.planning'] }<br />
              <span className='fw-bold'>{ i18n[locale]['home.campaigns.soon'] }</span>
            </p>
          </div>
          ) }
    </section>
  );
}

function HomeStats() {
  const [locale] = useState('en');
  const [progress, setProgress] = useState(0);
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    async function updateData() {
      try {
        const { data } = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/goals/alltime`);
        setProgress(data.progress || progress);
        setUserCount(data.userCount || userCount);
      } catch (error) {
        logger.error(error);
      }
    }

    updateData();
  }, [progress, userCount]);

  return (
    <section className='home--stats'>
      <div className='container-fluid p-0'>
        <div className='row no-gutters align-items-center'>
          <div
            className='col-lg-6 d-flex flex-column justify-content-center text-white home-stats-img text-center'
            style={{
              backgroundImage: 'url("/images/vf_home_letters.jpg")',
            }}
          >
            <div className='home-stats-text px-5'>
              <h2>
                { formatBigNumber(progress) }
              </h2>
              <p>
                { i18n[locale]['home.stats.letters'] }
              </p>
            </div>
          </div>
          <div
            className='col-lg-6 d-flex flex-column justify-content-center text-white home-stats-img text-center'
            style={{
              backgroundImage: 'url("/images/vf_home_volunteers.jpg")',
            }}
          >
            <div className='home-stats-text px-5'>
              <h2>
                { formatBigNumber(userCount) }
              </h2>
              <p>
                { i18n[locale]['home.stats.volunteers'] }
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function HomeEventBox() {
  return (
    <section className='landing--event-box'>
      <EventBox />
    </section>
  );
}

// function AboutVoteForward() {
//   const [locale] = useState('en');
//   return (
//     <section className='landing--about bg-light'>
//       <div className='container'>
//         <h2 className='mb-5 text-center'>About Us</h2>
//         <div className='row'>
//           <div className='col-lg-8 mx-auto'>
//             <p className='lead'>
//               { i18n[locale]['home.about.p1'] }
//             </p>
//             <p className='lead mb-4'>
//               { i18n[locale]['home.about.p2.1'] }
//               <a
//                 href='https://twitter.com/sjforman'
//                 rel='noopener noreferrer'
//                 target='_blank'
//               >
//                 <i className='fa fa-twitter' />
//               </a>){ ' ' }
//               { i18n[locale]['home.about.p2.2'] }{ ' ' }
//               <a
//                 href='https://en.wikipedia.org/wiki/Opower'
//                 rel='noopener noreferrer'
//                 target='_blank'
//               >
//                 Opower
//               </a>
//               { ' ' }
//               { i18n[locale]['home.about.p3'] }{ ' ' }
//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

function HomeMailingList() {
  const [locale] = useState('en');
  return (
    <section className='landing--call-to-action text-white text-center'>
      <div className='overlay' />
      <div className='container'>
        <div className='w-75 mx-auto'>
          <h2 className='mb-4'>
            { i18n[locale]['home.mailinglist.prompt'] }
          </h2>
          <p className='mb-5'>
            { i18n[locale]['home.mailinglist.description'] }
          </p>
          <div className='hero-form-wrapper'>
            <MailingList />
          </div>
        </div>
      </div>
    </section>
  );
}

function Home() {
  const auth = useAuth();
  useEffect(() => {
    if (window.Beacon) {
      initBeacon({
        auth,
      });
    }
    return () => destroyBeacon();
  }, [auth]);

  return (
    <Fragment>
      <Masthead />
      <HomeCampaigns />
      <HomeStats />
      <Callouts />
      <HomeEventBox />
      <HomeMailingList />
    </Fragment>
  );
}

export default Home;
