import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import Box from '@mui/material/Box';

import BundleListItem from './BundleListItem';
import EmptyColumn from './EmptyColumn';

// Base height values
const MINIMIZED_BUNDLE_CARD_HEIGHT = 224;
const CELL_LABEL_HEIGHT = 32;
const LETTER_INFO_HEIGHT = 44;
const LETTER_ROW_HEIGHT = 56;
const NUM_CELLS_TO_SHOW = 2.75;

const columnHeaders = {
  UNPREPPED: 'Unprepared',
  PREPPED: 'Prepared',
  SENT: 'Sent',
};

function shouldShowLetterInfo(bundle) {
  if (bundle.social_token) {
    return bundle.social_assignee_email && (bundle.bundle_status === 'UNPREPPED');
  }
  return true;
}

function ManageLettersColumn({ bundleData, letterFilterPresent, modifyAndDownloadState, canAdoptSocial }) {
  const [expandedCardState, setExpandedCardState] = useState({});
  const listRef = useRef();
  const bundleLength = bundleData.bundles?.length || 0;

  // If letters are added or removed, reset all of their heights.
  useEffect(() => {
    if (listRef.current && bundleLength && bundleData.letter_count) {
      listRef.current.resetAfterIndex(0);
    }
  }, [bundleLength, bundleData.letter_count, listRef]);

  const baseHeight = MINIMIZED_BUNDLE_CARD_HEIGHT + (canAdoptSocial ? CELL_LABEL_HEIGHT : 0);
  const estimatedHeight = baseHeight + LETTER_INFO_HEIGHT;

  return (
    <div className='col-sm-6 col-md-4 mb-4 mb-md-0 letters-col'>
      <div className='pb-3'>
        <span className='pt-2 mb-4 h5'>{ columnHeaders[bundleData.bundle_status] } </span>
        <span className='fs-6 fw-normal'>{ `(${ bundleData.letter_count } letters in ${ bundleData.bundle_count } ${ (bundleData.bundle_count) === 1 ? 'bundle' : 'bundles' })` }</span>
      </div>
      { bundleData.letter_count
        ? (
          <Box
            height={ estimatedHeight * NUM_CELLS_TO_SHOW }
            marginBottom='1rem'
          >
            <AutoSizer>{ ({ height, width }) => (
              <List
                estimatedItemSize={ estimatedHeight }
                height={ height }
                itemCount={ bundleData.bundle_count }
                itemSize={ (index) => {
                  const bundle = bundleData.bundles[index];
                  const isCardExpanded = !!expandedCardState[bundle.hashid];
                  let height = baseHeight;
                  if (shouldShowLetterInfo(bundle)) {
                    height += LETTER_INFO_HEIGHT;
                  }
                  if (isCardExpanded) {
                    height += (LETTER_ROW_HEIGHT * bundle.letter_count);
                  }
                  return height;
                } }
                ref={ listRef }
                width={ width }
              >
                { ({ index, style }) => {
                  const hashId = bundleData.bundles[index].hashid;
                  const isCardExpanded = !!expandedCardState[hashId];
                  return (
                    <Box sx={ style }>
                      <BundleListItem
                        bundle={ bundleData.bundles[index] }
                        canAdoptSocial={ canAdoptSocial }
                        isCardExpanded={ isCardExpanded }
                        key={ hashId }
                        modifyAndDownloadState={ modifyAndDownloadState }
                        shouldShowLetterInfo={ shouldShowLetterInfo }
                        testLabel={ `bundle-${ index }-${ bundleData.bundle_status }` }
                        toggleCardExpansion={ () => {
                          setExpandedCardState({
                            ...expandedCardState,
                            [hashId]: !expandedCardState[hashId],
                          });
                          if (listRef.current) {
                            listRef.current.resetAfterIndex(index);
                          }
                        } }
                      />
                    </Box>
                  );
                } }
              </List>
            ) }
            </AutoSizer>
          </Box>
          )
        : (
          <EmptyColumn
            letterFilterPresent={ letterFilterPresent }
            status={ bundleData.bundle_status }
          />
          ) }
    </div>
  );
}

ManageLettersColumn.propTypes = {
  bundleData: PropTypes.object.isRequired,
  canAdoptSocial: PropTypes.bool.isRequired,
  letterFilterPresent: PropTypes.bool.isRequired,
  modifyAndDownloadState: PropTypes.object.isRequired,
};

export default ManageLettersColumn;
