import axios from 'axios';
import { getGrants } from './user';

function getNextSignablePartner() {
  return {
    slug: localStorage.getItem('next_signable_partner_slug'),
    id: localStorage.getItem('next_signable_partner_id'),
    name: localStorage.getItem('next_signable_partner_name'),
  };
}

async function signNextPartner(auth) {
  const accessToken = await auth.getAccessToken();
  await axios.put(`${ process.env.REACT_APP_API_URL }/v2/users/${ auth.getUserId() }/signedPartnerAgreements`, getNextSignablePartner(), {
    headers: {
      Authorization: 'Bearer '.concat(accessToken),
    },
  });

  await updateNextSignablePartner(auth);
}

async function updateNextSignablePartner(auth) {
  const accessToken = await auth.getAccessToken();

  const adminPartnerResponses = getGrants().filter((grant) => (
    grant.permission === 'partner.sign' && !(grant.resource.includes('*'))
  )).map((grant) => (
    axios.get(`${ process.env.REACT_APP_API_URL }/v1/partners/${ grant.resource.split(':')[1] }`, {
      headers: {
        Authorization: 'Bearer '.concat(accessToken),
      },
    })
  ));

  const nextSignablePartner = (await Promise.all(adminPartnerResponses)).find((response) => (
    !(response.data.signedAt || (response.data.agreementOverride && response.data.agreementOverride.length > 0))
  ));

  localStorage.setItem('next_signable_partner_slug', nextSignablePartner ? nextSignablePartner.data.slug : '');
  localStorage.setItem('next_signable_partner_id', nextSignablePartner ? nextSignablePartner.data.id : '');
  localStorage.setItem('next_signable_partner_name', nextSignablePartner ? nextSignablePartner.data.name : '');
}

export {
  getNextSignablePartner,
  signNextPartner,
  updateNextSignablePartner,
};
