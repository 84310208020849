import classNames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';

function StyledErrorMessage({ className, errors, name }) {
  return (
    <ErrorMessage
      errors={ errors }
      name={ name }
      render={ ({ message }) => (
        <div className={ classNames('invalid-feedback', className) }>
          { message }
        </div>
      ) }
    />
  );
}

StyledErrorMessage.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default StyledErrorMessage;
