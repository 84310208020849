import axios from 'axios';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  FunctionField,
  Filter,
  TextInput,
  BooleanInput,
  ImageInput,
  ImageField,
  DateInput,
  Button,
  BulkExportButton,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Create,
  SimpleForm,
  required,
  regex,
  minLength,
  Toolbar,
  SaveButton,
  Edit,
  EditButton,
  useNotify,
} from 'react-admin';
import LaunchIcon from '@material-ui/icons/Launch';
import PeopleIcon from '@material-ui/icons/People';
import propTypes from 'prop-types';
import queryString from 'query-string';
import React, { Fragment, Component } from 'react';

import { __setupAuth } from '../contexts/AuthContext';
import Users from './Users';

// eslint-disable-next-line no-empty-function
const auth = __setupAuth({ setPictureUrl: () => {} });
const PartnerEditToolbar = (props) => (
//removes default delete option from toolbar
  <Toolbar { ...props } >
    <SaveButton />
  </Toolbar>
);
const urlRegex = /^https?:\/\/.*$/;
const urlMessage = ' should be a url starting with https://';

const errorHandler = (error, notify) => {
  if (error.status === 'Conflict') {
    notify(`Error: Name and Slug must be Unique`);
  } else {
    notify(`Error: ${ error.body || error.message }`);
  }
};

const OptionalInputs = () => (
  <Fragment>
    <TextInput
      source='partnerUrl'
      validate={ [regex(urlRegex, `The partner url ${ urlMessage }`)] }
    />
    <br />
    <BooleanInput helperText="Turn public 'on' when the partner should become visible on the Internet." source='public' />
    <BooleanInput helperText='Show or hide on Big Send' source='showForBigsend' />
    <BooleanInput helperText='Show or hide on Big Send At Work' source='showForBigsendAtWork' />
    <BooleanInput helperText='Show or hide on Big Send On Campus' source='showForBigsendOnCampus' />
    <BooleanInput helperText='Show or hide landing page' source='hideLandingPage' />
    <BooleanInput source='hideSwingLeftOptIn' />
    <BooleanInput source='nonPartisan' />
    <ReferenceArrayInput
      allowEmpty
      filter={{ 'electionDate:greaterThanOrEqualTo': (new Date()).toISOString() }}
      helperText='Districts to show on the partner landing page.'
      label='districts'
      perPage={ 100 }
      reference='Districts'
      source='districtIds'
    >
      <AutocompleteArrayInput optionText='districtId' />
    </ReferenceArrayInput>
  </Fragment>
);


export const PartnerEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };

  return (
    <Edit onFailure={ onFailure } undoable={ false } { ...props }>
      <SimpleForm redirect='edit' toolbar={ <PartnerEditToolbar /> }>
        <TextInput disabled source='id' />
        <TextInput disabled source='slug' />
        <TextInput
          disabled
          label='Agreement Signed By'
          source='signedBy'
        />
        <DateInput
          disabled
          label='Agreement Signed At'
          source='signedAt'
        />
        <TextInput
          label='Partner Agreement Override'
          source='agreementOverride'
        />
        <TextInput
          source='name'
          validate={ [required(), minLength(3)] }
        />
        <FunctionField
          label='Existing Logo'
          render={ (record) => (
            record.logo
              ? (
                <Fragment>
                  <img alt='' className='img-fluid mt-4 mt-sm-0' label='Existing Logo' src={ record.logo } />
                  <br />
                  <Button
                    color='primary'
                    href={ `/${ record.slug }?preview=1` }
                    label='Preview Partner Landing Page'
                    target='_blank'
                  />
                </Fragment>
                )
              : '(None)'
          ) }
        />
        <ImageInput accept='image/*' label='Update Logo' source='logo'>
          <ImageField source='src' />
        </ImageInput>
        <FunctionField
          label='Existing Metatag Image'
          render={ (record) => (
            record.metaTagImage
              ? (
                <img
                  alt=''
                  className='img-fluid mt-4 mt-sm-0'
                  label='Existing Metatag Image'
                  src={ record.metaTagImage }
                />
                )
              : '(None)'
          ) }
        />
        <ImageInput accept='image/*' label='Update Metatag Image' source='metaTagImage'>
          <ImageField source='src' />
        </ImageInput>
        <OptionalInputs />
      </SimpleForm>
    </Edit>
  );
};

const PartnerCreateToolbar = (props) => (
  <Toolbar { ...props } >
    <SaveButton
      label='create'
    />
  </Toolbar>
);

export const PartnerCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };
  return (
    <Create onFailure={ onFailure } { ...props }>
      <SimpleForm toolbar={ <PartnerCreateToolbar /> }>
        <TextInput
          label='Partner name'
          source='name'
          validate={ [required(), minLength(3)] }
        />
        <TextInput
          helperText='This cannot be changed or deleted.'
          label='Partner slug'
          source='slug'
          validate={ [
            required(),
            regex(/^[a-z\d]*$/, 'Slug must be alphanumeric with lowercase letters'),
            regex(/[a-z]/, "Slug can't be all numbers"),
            minLength(3),
          ] }
        />
        <OptionalInputs />
      </SimpleForm>
    </Create>
  );
};

const PartnerListBulkActionButtons = (props) => (
  <BulkExportButton { ...props } />
);

const PartnerFilter = (props) => (
  <Filter { ...props }>
    <TextInput alwaysOn label='Name' source='name:contains' />
    <TextInput alwaysOn label='Slug' source='slug:contains' />
    <BooleanInput defaultValue source='public' />
    <BooleanInput defaultValue={ false } source='showForBigsend' />
    <BooleanInput defaultValue={ false } source='hideSwingLeftOptIn' />
    <BooleanInput defaultValue={ false } source='nonPartisan' />
    <BooleanInput label='Unsigned' source='signedAt' />
  </Filter>
);

class PartnerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: null,
      partner: props.location && props.location.search ? queryString.parse(this.props.location.search).partner : null,
    };
  }

  async componentDidMount() {
    const queryParams = this.state.partner
      ? {
          filter: JSON.stringify({ partner: this.state.partner }),
        }
      : {};

    const url = `${ process.env.REACT_APP_API_URL }/v1/partners?${ queryString.stringify(queryParams) }`;
    const params = { partnerLandingPage: true };
    const accessToken = await auth.getAccessToken();
    const result = await axios({
      method: 'GET',
      url,
      headers: { Authorization: `Bearer ${ accessToken }` },
      params,
    });
    this.setState({
      partners: result.data,
      partner: result.data.length === 1 ? result.data[0].slug : null,
    });
  }

  render() {
    if (!this.state.partners) {
      return null;
    }
    // If this user only has access to a single partner, then show the partner stats dashboard for that one partner
    return this.state.partner
      ? <Users auth={ auth } { ...this.props } partner={ this.state.partner } showHeader={ false } />
      : (
        <List { ...this.props } bulkActionButtons={ <PartnerListBulkActionButtons /> } filters={ <PartnerFilter /> } sort={{ field: 'id', order: 'DESC' }}>
          <Datagrid>
            <TextField source='id' />
            { /*<ImageField source="logo" />*/ }
            <TextField source='slug' />
            <FunctionField
              label='Name'
              render={ (record) => (
                <span>
                  { record.name }
                  <a
                    className={ record.partnerUrl && record.partnerUrl !== '' ? '' : 'hidden' }
                    href={ record.partnerUrl }
                    rel='noreferrer'
                    target='_blank'
                  >
                    <LaunchIcon fontSize='small' />
                  </a>
                </span>
              ) }
              sortBy='name'
            />
            <BooleanField source='public' />
            <DateField showTime source='createdAt' />
            <DateField showTime source='updatedAt' />
            <DateField showTime source='signedAt' />
            <FunctionField
              label='Users and Stats'
              render={ (record) => (
                <Button color='primary' href={ `/admin#/partner-users/${ record.slug }` } icon={ PeopleIcon } label='Stats' >
                  <PeopleIcon />
                </Button>
              ) }
            />
            { this.props.hasEdit ? <EditButton { ...this.props } /> : null }
          </Datagrid>
        </List>
        );
  }

  static get propTypes() {
    return {
      partner: propTypes.string,
      hasEdit: propTypes.bool,
      location: propTypes.object,
    };
  }
}

export default PartnerList;
