import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  AutocompleteInput,
  FunctionField,
  Filter,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  required,
  useNotify,
} from 'react-admin';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function TableRows(rows) {
  return (
    <TableBody>
      { rows.map((row) => (
        <TableRow key={ row.name }>
          <TableCell component='th' scope='row'>
            { row.name }
          </TableCell>
          <TableCell align='right'>{ row.count }</TableCell>
        </TableRow>
      )) }
    </TableBody>
  );
}
function BasicStatsTable(rows) {
  const statsExist = rows.filter((r) => Number.isInteger(r.count)).length > 0;
  return statsExist
    ? (
      <TableContainer component={ Paper }>
        <Table aria-label='stats'>
          { TableRows(rows) }
        </Table>
      </TableContainer>
      )
    : undefined;
}

const DistrictImportEditToolbar = (props) => (
  <Toolbar { ...props }>
    <SaveButton />
  </Toolbar>
);

export const DistrictImportEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => notify(`Error: ${ error.body || error.message }`);

  return (
    <Edit onFailure={ onFailure } undoable={ false } { ...props }>
      <SimpleForm redirect='edit' toolbar={ <DistrictImportEditToolbar /> }>
        <TextInput disabled source='id' />
        <TextInput disabled source='districtId' />
        <TextInput source='fileLocation' />
        <TextInput disabled source='createdAt' />
        <TextInput disabled source='lockedUntil' />
        <SelectInput
          choices={ [
            { id: 'CREATE', name: 'Add Voters' },
            { id: 'DELETE', name: 'Delete Voters' },
          ] }
          disabled
          helperText='Whether to add or delete voters'
          source='action'
        />
        <TextInput disabled source='processedAt' />
        <FunctionField
          label='Stats'
          render={ (record) => {
            record.action === 'DELETE'
              ? BasicStatsTable([
                {
                  name: 'Deleted Records',
                  count: record.deletedCount,
                },
                {
                  name: 'Not Deleted (because already adopted)',
                  count: record.notDeletedCount,
                },
              ])
              : BasicStatsTable([
                {
                  name: 'Added Records',
                  count: record.addedCount,
                },
                {
                  name: 'Updated Records',
                  count: record.updatedCount,
                },
                {
                  name: 'Ignored Records (Duplicate)',
                  count: record.duplicateCount,
                },
                {
                  name: 'Ignored Records (Opt-Out)',
                  count: record.optedOutCount,
                },
              ]);
          } }
        />
        <NumberInput disabled source='controlPercentage' />
        <NumberInput helperText='set to 3 to disable processing' source='processCount' />
        <TextField source='errorText' />
      </SimpleForm>
    </Edit>
  );
};

const DistrictImportCreateToolbar = (props) => (
  <Toolbar { ...props }>
    <SaveButton label='create' />
  </Toolbar>
);

export const DistrictImportCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => notify(`Error: ${ error.body || error.message }`);

  return (
    <Create { ...props } onFailure={ onFailure }>
      <SimpleForm toolbar={ <DistrictImportCreateToolbar /> }>
        <ReferenceInput
          filterToQuery={ (districtId) => ({ 'districtId:contains': districtId }) }
          label='District ID'
          reference='Districts'
          sort={{ field: 'districtId', order: 'ASC' }}
          source='numericDistrictId'
          validate={ [required()] }
        >
          <AutocompleteInput allowEmpty optionText='districtId' />
        </ReferenceInput>
        <SelectInput
          choices={ [{ id: 'ntl', name: 'ntl' }] }
          source='fileFormat'
          validate={ [required()] }
        />
        <SelectInput
          choices={ [
            { id: 'CREATE', name: 'Add Voters' },
            { id: 'DELETE', name: 'Delete Voters' },
          ] }
          helperText='Whether to add or delete voters'
          source='action'
          validate={ [required()] }
        />
        <TextInput helperText='e.g. AZGOTV2020A/queue/AZGOTV2020A.csv or https://secure/path/to/file.csv' label='Location of File in bucket or url' source='fileLocation' />
      </SimpleForm>
    </Create>
  );
};

const DistrictImportFilter = (props) => (
  <Filter { ...props }>
    <SelectInput
      alwaysOn
      choices={ [
        { id: null, name: 'All Rows' },
        { id: true, name: 'Unprocessed Rows' },
      ] }
      label='Processed?'
      source='processedAt:isNull'
    />
    <TextInput label='District ID' source='districtId:contains' />
    <NumberInput label='Process Count <= to' source='processCount:lessThanOrEqualTo' />
    <NumberInput alwaysOn label='Created After Days Ago' source='createdAt:afterDaysAgo' />
    <SelectInput
      choices={ [
        { id: null, name: 'All Rows' },
        { id: true, name: 'Only Parent Rows' },
      ] }
      label='Parents Only?'
      source='parentId:isNull'
    />

  </Filter>
);
const rowStyle = (record) => ({
  backgroundColor: record.processCount >= 3 ? '#ffc107' : '',
});
const DistrictImportList = (props) => (
  <List
    { ...props }
    bulkActionButtons={ false }
    filterDefaultValues={{ 'processedAt:isNull': true, 'createdAt:afterDaysAgo': 3 }}
    filters={ <DistrictImportFilter /> }
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowStyle={ rowStyle }>
      <NumberField source='id' />
      <TextField source='districtId' />
      <TextField source='action' />
      <DateField showTime source='createdAt' />
      <DateField showTime source='processedAt' />
      <FunctionField
        label='Stats'
        render={
          (record) => (record.action === 'DELETE'
            ? BasicStatsTable([
              { name: 'Deleted', count: record.deletedCount },
              { name: 'Not Deleted', count: record.notDeletedCount },
            ])
            : BasicStatsTable([
              { name: 'Added', count: record.addedCount },
              { name: 'Updated', count: record.updatedCount },
              { name: 'Ignored (Duplicate)', count: record.duplicateCount },
              { name: 'Ignored (Opt-Out)', count: record.optedOutCount },
            ], false))
        }
      />

      <NumberField source='parentId' />
      <EditButton { ...props } />
    </Datagrid>
  </List>
);

export default DistrictImportList;
