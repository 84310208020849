import React, { Component } from 'react';
import axios from 'axios';
import deprecatedConfig from '../lib/deprecatedConfig';
import logger from '../lib/logger';
import propTypes from 'prop-types';


class AdminImportRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale || 'en',
      endpoint: props.endpoint || 'file-import',
      title: props.title || 'Import Voter file',
      fileStatus: '',
      formError: '',
      errorMessage: '',
      disableButton: false,
    };
  }

  static get propTypes() {
    return {
      auth: propTypes.object,
      locale: propTypes.string,
      endpoint: propTypes.string,
      title: propTypes.string,
    };
  }

  verifyForm = () => {
    if (!this.state.file || this.state.file === '') {
      this.setState({ formError: 'the filename is required' });
      return false;
    }
    return true;
  };

  async componentDidMount() {
    if (this.state.endpoint === 'file-import') {
      const accessToken = await this.props.auth.getAccessToken();
      const result = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer '.concat(accessToken),
        },
        url: `${ process.env.REACT_APP_API_URL }/v1/imports/configs`,
      });
      this.setState({
        configs: result.data,
      });
    }
  }

  verifyFormReset = () => {
    this.setState({ formError: '' });
  };

  submitAdminImportRequestForm = async () => {
    let apiUrl;
    if (this.verifyForm()) {
      this.setState({ disableButton: true });
      try {
        const url = await deprecatedConfig.get('fileImportApiUrl');
        apiUrl = url;
        const accessToken = await this.props.auth.getAccessToken();
        this.setState({ fileStatus: 'submitted' });
        const data = { file: this.state.file };
        if (this.state.config) {
          data.config = this.state.config;
        }
        const res = await axios({
          method: 'POST',
          headers: {
            Authorization: 'Bearer '.concat(accessToken),
          },
          url: `${ apiUrl }/${ this.state.endpoint }`,
          data,
        });
        this.setState({ disableButton: false });
        if (res.code >= 400) {
          logger.log(res);
          this.setState({ errorMessage: res.data, fileStatus: 'error' });
        } else {
          this.setState({ fileStatus: 'completed' });
        }
      } catch (err) {
        if (err.response && err.response.data) {
          this.setState({ errorMessage: err.response.data });
        }
        this.setState({ disableButton: false, fileStatus: 'error' });
      }
    }
  };

  handleChange = (event) => {
    this.setState({ file: event.target.value });
  };

  _handleKeyDown = (e) => {
    this.verifyFormReset();
    if (e.key === 'Enter' && !this.state.disableButton) {
      this.submitAdminImportRequestForm();
    }
  };

  render() {
    let configSelect;
    if (this.state.configs) {
      configSelect = (
        <div>
          <label>Import Config</label>
          <select
            className='form-control'
            onChange={ (event) => this.setState({ config: event.target.value }) }
            value={ this.state.config }
          >
            <option value='' />
            { this.state.configs.map((config) => (<option key={ config } value={ config }>{ config }</option>)) }
          </select>
          <br />
        </div>
      );
    }
    const fileImportRequestForm = (
      <div>
        <div className='mb-3'>
          <p className='text-center'>Please enter the full path of the file in the file-imports bucket</p>
          <div>
            <label>File Name</label>
            <input
              className={ ['form-control', this.state.formError === '' ? '' : 'is-invalid'].join(' ') }
              name='file'
              onChange={ this.handleChange }
              onKeyDown={ this._handleKeyDown }
              placeholder='FLGOTV2020A/queue/file-1.csv'
              type='text'
            />
            <br />
            { configSelect }
            <button className={ ['btn', 'btn-primary', 'w-100', 'justify-content-center', this.state.disableButton ? 'disabled' : ''].join(' ') } disabled={ this.state.disableButton } onClick={ this.submitAdminImportRequestForm } style={{ color: 'white' }}>
              Import File
            </button>
          </div>
        </div>
      </div>
    );
    const fileImportRequestFormSubmitted = (
      <p>{ (() => {
        if (this.state.fileStatus === 'submitted') {
          return 'file processing...';
        } else if (this.state.fileStatus === 'completed') {
          if (this.state.endpoint === 'file-import') {
            return 'file import queued! To process the queue, POST /api/file-import/queue.  In production, this should already be scheduled to run automatically.';
          } else {
            return 'file import complete!';
          }
        } else if (this.state.fileStatus === 'error') {
          return this.state.errorMessage || 'an error occurred';
        }
      })() }
      </p>
    );
    return (
      <div>
        <div className='row col d-flex justify-content-center'>
          <div className='card' style={{ width: '24rem' }}>
            <article className='card-body' style={{ display: 'block' }}>
              <h4 className='card-title mb-4 mt-1 text-center'>{ this.state.title }</h4>
              <hr />
              { this.state.fileStatus !== '' ? fileImportRequestFormSubmitted : fileImportRequestForm }
            </article>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminImportRequest;
