import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import Loading from '../../../components/Loading';

DelayedRenderColumn.propTypes = {
  currentIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  render: PropTypes.func.isRequired,
  testLabel: PropTypes.string.isRequired,
};

export default function DelayedRenderColumn({
  items,
  render,
  testLabel,
  currentIndex = 0,
}) {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsReady(true), 100);
  }, []);
  // base case
  if (!items || currentIndex >= items.length) {
    return null;
  }

  if (!isReady) {
    return (<Loading />);
  }

  return (
    <Fragment>
      { render(items[currentIndex], `delayed-${ testLabel }-${ currentIndex }`) }
      <DelayedRenderColumn currentIndex={ currentIndex + 1 } items={ items } render={ render } testLabel={ testLabel } />
    </Fragment>
  );
}
