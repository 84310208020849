import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import '../scss/campaigns/_campaign-labels.scss';
import '../scss/lib/_typography.scss';

const campaignTypes = [
  'labs',
  'political',
  'priority',
  'social',
  'voter-registration',
];

function CampaignLabel({
  isVisible,
  type,
  isBundleDisplay,
}) {
  const { t } = useTranslation('campaigns', { keyPrefix: 'labels' });

  return isVisible
    ? (
      <div
        className={ classNames(
          'campaign-label fs-8 fw-bold me-2 px-2 text-uppercase rounded-pill',
          type,
          isBundleDisplay ? 'campaign-bubble-label' : '',
        ) }
      >
        { t(type) }
      </div>
      )
    : null;
}

CampaignLabel.propTypes = {
  isBundleDisplay: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default memo(CampaignLabel);
export { campaignTypes };
