import PropTypes from 'prop-types';

const messageByStatus = {
  UNPREPPED: 'You have no unprepared letters.',
  PREPPED: 'You have no prepared letters.',
  SENT: "You haven't sent any letters yet.",
};

function EmptyColumn({ letterFilterPresent, status }) {
  return (
    <div className='empty-letter-column mb-4 h5 text-gray'>
      { letterFilterPresent ? 'No results' : messageByStatus[status] }
    </div>
  );
}

EmptyColumn.propTypes = {
  letterFilterPresent: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default EmptyColumn;
