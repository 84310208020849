import { useMutation, useQuery, useQueryClient } from 'react-query';
import { segmentTrack } from '../segment';

import logger from '../logger';
import { getUserId } from '../user';
import { getWithOptionalToken, getWithToken, patchWithToken, postWithToken } from '../apiClient';
import { letterKitType } from '../../letter-kits/letterKitTypes';
import { useAuth } from '../../contexts/AuthContext';
import { useState, useRef } from 'react';
import downloadLetters from '../downloadLetters';
import downloadLetterBundles from '../downloadLetterBundles';

const getLettersQueryFields = [
  'id',
  'election_date',
  'toPrep',
  'toSend',
  'alreadySent',
  'letter_bundle_id',
  'letter_bundle_type',
  'letter_bundle_status',
  'mail_date_from',
  'mail_date',
  'first_name',
  'last_name',
  'city',
  'state',
].join(',');

const queryKeys = {
  campaigns: {
    get: ['campaigns', 'get'],
  },
  socialBatchesByStatus: {
    get: ['letters', 'batches', 'status', 'get'],
  },
  letterBundlesByStatus: {
    get: ['letters', 'status', 'get'],
  },
  letterKits: {
    get: (params = {}) => ['letterKits', 'get', Object.keys(params).length > 0 ? params : null].filter(Boolean),
  },
  letters: {
    get: ['letters', 'get'],
  },
  users: {
    self: {
      get: ['users', 'get', { userId: 'self' }],
    },
  },
};

const selectHelpers = {
  compareVotersByName: (a, b) => `${ a.first_name } ${ a.last_name }`.localeCompare(`${ b.first_name } ${ b.last_name }`),
};

function useCampaigns(params = {}, options = {}) {
  const auth = useAuth();
  return useQuery(
    [...queryKeys.campaigns.get, Object.keys(params).length > 0 ? params : null].filter(Boolean),
    async () => await getWithOptionalToken(
      '/get-districts',
      Object.prototype.hasOwnProperty.call(params, 'auth0Id') ? null : auth,
      { params },
    ),
    options,
  );
}

function useDownloadLetterState({ letterId, modifyAndDownloadState }) {
  const auth = useAuth();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });

  return useDownloadState({
    downloadId: `letter-${ letterId }`,
    downloadFunction: () => downloadLetters({
      auth,
      userId: user.auth0_id,
      letterId,
      excludePrepped: false,
    }),
    modifyAndDownloadState,
  });
}

function useDownloadBundleState({ letterBundleId, modifyAndDownloadState }) {
  const auth = useAuth();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });

  return useDownloadState({
    downloadId: `bundle-${ letterBundleId }`,
    downloadFunction: () => downloadLetterBundles({
      filters: { letterBundleIds: [letterBundleId] },
      auth,
      userId: user.auth0_id,
      excludePrepped: true,
    }),
    modifyAndDownloadState,
  });
}

function useModifyAndDownloadState() {
  const [isModifyInProgress, setIsModifyInProgress] = useState(false);
  const [isAnyDownloadInProgress, setIsAnyDownloadInProgress] = useState(false);
  const totalDownloadsInProgress = useRef(0);
  return {
    isModifyDisabled: isModifyInProgress || isAnyDownloadInProgress,
    isDownloadDisabled: isModifyInProgress,
    lockModify: () => setIsModifyInProgress(true),
    lockDownload: () => {
      totalDownloadsInProgress.current += 1;
      return setIsAnyDownloadInProgress(true);
    },
    unlockModify: () => setIsModifyInProgress(false),
    unlockDownload: () => {
      totalDownloadsInProgress.current -= 1;
      if (totalDownloadsInProgress.current === 0) {
        return setIsAnyDownloadInProgress(false);
      }
    },
  };
}

function useDownloadState({ downloadId, downloadFunction, modifyAndDownloadState }) {
  const [downloading, setDownloading] = useState({});
  const [downloadSuccess, setDownloadSuccess] = useState({});
  const [downloadError, setDownloadError] = useState({});

  return {
    downloading: downloading[downloadId],
    downloadSuccess: downloadSuccess[downloadId],
    downloadError: downloadError[downloadId],
    startDownload: async () => {
      await Promise.all([
        modifyAndDownloadState.lockDownload(),
        setDownloading({ ...downloading, [downloadId]: true }),
        setDownloadSuccess({ ...downloadSuccess, [downloadId]: false }),
        setDownloadError({ ...downloadError, [downloadId]: false }),
      ]);
      try {
        await downloadFunction();
        await Promise.all([
          modifyAndDownloadState.unlockDownload(),
          setDownloading({ ...downloading, [downloadId]: false }),
          setDownloadSuccess({ ...downloadSuccess, [downloadId]: true }),
        ]);
        segmentTrack('LettersDownload:success', { downloadId });
      } catch {
        await Promise.all([
          modifyAndDownloadState.unlockDownload(),
          setDownloading({ ...downloading, [downloadId]: false }),
          setDownloadError({ ...downloadError, [downloadId]: true }),
        ]);
      }
    },
  };
}

function useSendSocialReminderMutation({ socialToken }) {
  const auth = useAuth();
  return useMutation(
    async () => await postWithToken(
      `/v1/socialBatches/bundles/${ socialToken }/reminder`,
      {},
      auth,
    ),
  );
}

function useSocialBatchMutation({ onSuccess }) {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useMutation(
    async (formData) => await postWithToken(
      `/v1/users/${ user.auth0_id }/socialBatches`,
      formData,
      auth,
    ),
    {
      onSuccess,
      onSettled: async () => {
        await queryClient.invalidateQueries(queryKeys.letters.get[0]);
      },
    },
  );
}

function useLetterBundleMutation({ onSuccess }) {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useMutation(
    async (formData) => await postWithToken(
      `/v1/users/${ user.auth0_id }/letterBundles`,
      formData,
      auth,
    ),
    {
      onSuccess,
      onSettled: async () => {
        await queryClient.invalidateQueries(queryKeys.letters.get[0]);
      },
    },
  );
}

function useLetterKitMutation(options = {}) {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useMutation(
    async (formData) => await postWithToken(
      `/v1/users/${ user.auth0_id }/letterBundles`,
      formData,
      auth,
    ),
    {
      onSuccess: (result) => {
        queryClient.setQueryData(
          queryKeys.letterKits.get(),
          (oldLetterKits) => [result, ...(oldLetterKits || [])],
        );
      },
      ...options,
    },
  );
}

function useSocialBatchesByStatus() {
  const auth = useAuth();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useQuery(
    queryKeys.socialBatchesByStatus.get,
    async () => await getWithToken(
      `/v1/users/${ user.auth0_id }/socialBatches`,
      auth,
    ),
  );
}

function useLetterBundlesByStatus() {
  const auth = useAuth();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useQuery(
    queryKeys.letterBundlesByStatus.get,
    async () => await getWithToken(
      `/v2/users/${ user.auth0_id }/letterBundlesByStatus`,
      auth,
    ),
  );
}

function useLetterKits(params = {}, options = {}) {
  const auth = useAuth();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useQuery(
    queryKeys.letterKits.get(params),
    async () => await getWithToken(
      `/v1/users/${ user.auth0_id }/letterBundles`,
      auth,
      {
        params: {
          ...params,
          filter: {
            'createdAt:afterDaysAgo': 30,
            type: letterKitType,
            ...params.filter,
          },
        },
      },
    ),
    {
      enabled: !!user?.auth0_id,
      placeholderData: [],
      select: (kits) => [...kits]
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
      ...options,
    },
  );
}

function useLetterMutation() {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useMutation(
    async ({ filters, update, voterId }) => await patchWithToken(
      `/v1/users/${ user.auth0_id }/letters`,
      {
        filters: {
          ...filters,
          voterId,
        },
        update,
      },
      auth,
    ),
    {
      onError: (error, _, { unmutatedVoters }) => {
        logger.error(error);
        queryClient.setQueryData(queryKeys.letters.get, unmutatedVoters);
      },
      onMutate: async ({ optimisticUpdate, voterId }) => {
        await queryClient.cancelQueries(queryKeys.letters.get);
        const unmutatedVoters = queryClient.getQueryData(queryKeys.letters.get);
        const voterToMutateIndex = unmutatedVoters?.findIndex((voter) => voter.id === voterId);
        if (voterToMutateIndex >= 0) {
          const voterToMutate = unmutatedVoters[voterToMutateIndex];
          queryClient.setQueryData(
            queryKeys.letters.get,
            [
              ...unmutatedVoters.slice(0, voterToMutateIndex),
              {
                ...voterToMutate,
                ...optimisticUpdate,
              },
              ...unmutatedVoters.slice(voterToMutateIndex + 1),
            ],
          );
        }
        return { unmutatedVoters };
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(queryKeys.letters.get[0]);
      },
    },
  );
}

function useLetters({ letterIds }, options = {}) {
  const auth = useAuth();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  return useQuery(
    queryKeys.letters.get,
    async () => await getWithToken(
      `/v1/users/${ user.auth0_id }/letters`,
      auth,
      {
        params: {
          campaignTimeframe: 'active',
          fields: getLettersQueryFields,
          letterIds: letterIds.join(','),
        },
      },
    ),
    {
      enabled: letterIds?.length >= 1,
      placeholderData: [],
      select: (allLetters) => [...allLetters].sort(selectHelpers.compareVotersByName),
      ...options,
    },
  );
}

function useLettersMutation(modifyAndDownloadState, filterOptions = { letters: [] }, update = {}, optimisticUpdate = {}) {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { data: user } = useUser({
    placeholderData: { auth0_id: getUserId() }, // FIXME: This is transitional while we move to a more-query based system
  });
  const { letters: voters, ...otherFilterOptions } = filterOptions;
  return useMutation(
    async () => {
      const voterIds = voters.map((voter) => voter.id);

      return await patchWithToken(
        `/v1/users/${ user.auth0_id }/letters`,
        {
          filters: { ...otherFilterOptions, voterIds },
          update,
        },
        auth,
      );
    },
    {
      onError: (error, _, { unmutatedVoters }) => {
        logger.error(error);
        queryClient.setQueryData(queryKeys.letters.get[0], unmutatedVoters);
      },
      onMutate: async () => {
        await Promise.all([
          modifyAndDownloadState.lockModify(),
          queryClient.cancelQueries([queryKeys.letters.get[0]]),
        ]);
        const unmutatedVoters = queryClient.getQueryData(queryKeys.letters.get[0]);
        queryClient.setQueryData(
          queryKeys.letters.get[0],
          () => (unmutatedVoters || []).map((unmutatedVoter) => ({
            ...unmutatedVoter,
            ...optimisticUpdate,
          })),
        );
        return { unmutatedVoters };
      },
      onSettled: async () => {
        await modifyAndDownloadState.unlockModify();
        await queryClient.invalidateQueries([queryKeys.letters.get[0]]);
      },
    },
  );
}

function useUser(options = {}) {
  const auth = useAuth();
  return useQuery(
    queryKeys.users.self.get,
    async () => await getWithToken('/v1/users/self', auth),
    { ...options },
  );
}

export {
  queryKeys,
  selectHelpers,
  useCampaigns,
  useDownloadBundleState,
  useDownloadLetterState,
  useLetterBundlesByStatus,
  useLetterBundleMutation,
  useLetterKitMutation,
  useLetterKits,
  useLetterMutation,
  useLetters,
  useLettersMutation,
  useModifyAndDownloadState,
  useSendSocialReminderMutation,
  useSocialBatchesByStatus,
  useSocialBatchMutation,
  useUser,
};
