export function segmentTrackWrapper(wrappedFunction, event, properties) {
  return (e) => {
    if (window?.analytics?.track) {
      window.analytics.track(event, properties);
    }
    wrappedFunction(e);
  };
}

export function segmentTrack(eventId, params) {
  if (window?.analytics?.track) {
    window.analytics.track(eventId, params);
  }
}

export function segmentIdentify(userId) {
  if (window?.analytics && window?.analytics?.user) {
    const segmentId = window.analytics.user().id();
    if (!segmentId) {
      window.analytics.identify(userId);
    }
  }
}

export function segmentIdentifyAndTrackPage(userId) {
  if (userId) {
    segmentIdentify(userId);
  }
  if (window?.analytics) {
    window.analytics.page();
  }
}
