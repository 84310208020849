import propTypes from 'prop-types';

import '../scss/components/_photo-box.scss';

function PhotoBox({ imageCol, contentCol, photoOnRight = false }) {
  return (
    <div className='photo-box'>
      <div className='row'>
        <div className={ `col-12 col-lg-5 image-col ${ photoOnRight ? 'order-lg-2' : '' }` }>
          { imageCol }
        </div>
        <div className='col-12 col-lg-7 content-col'>
          { contentCol }
        </div>
      </div>
    </div>
  );
}

PhotoBox.propTypes = {
  contentCol: propTypes.node.isRequired,
  imageCol: propTypes.node.isRequired,
  photoOnRight: propTypes.bool,
};

export default PhotoBox;
