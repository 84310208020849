import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import identity from '../../lib/functions/identity';
import logger from '../../lib/logger';
import { get } from '../../lib/apiClient';
import { sortCampaigns } from '../../campaigns/lib/sorters';
import { useSortedSelector } from '../../lib/selectors';

const apiParams = { partnerLandingPage: true };

function usePartner({ noPartnerPath = '/', partnerSlug }) {
  const history = useHistory();

  const getPartnerApiPath = useMemo(
    () => `/v1/partners/${ partnerSlug }`,
    [partnerSlug],
  );
  const getPartner = useCallback(
    async () => await get(getPartnerApiPath, { apiParams }),
    [getPartnerApiPath],
  );
  const getPartnerQueryKey = useMemo(
    () => [getPartnerApiPath, 'get'],
    [getPartnerApiPath],
  );
  const handleError = useCallback(
    (error) => {
      logger.error(error);
      sessionStorage.removeItem('partner');
      history.replace(noPartnerPath);
    },
    [history, noPartnerPath],
  );

  return useQuery(
    getPartnerQueryKey,
    getPartner,
    {
      enabled: !!partnerSlug,
      onError: handleError,
      retry: 0,
    },
  );
}

function usePartnerCampaigns({ partnerSlug, selector = identity }) {
  const apiPath = useMemo(
    () => `/v2/partners/${ partnerSlug }/campaigns`,
    [partnerSlug],
  );
  const query = useCallback(
    async () => await get(apiPath),
    [apiPath],
  );
  const queryKey = useMemo(
    () => [apiPath, 'get'],
    [apiPath],
  );
  const sortedSelector = useSortedSelector({ selector, sorter: sortCampaigns });
  return useQuery(
    queryKey,
    query,
    {
      placeholderData: [],
      select: sortedSelector,
    },
  );
}

export { usePartner, usePartnerCampaigns };
