import { memo } from 'react';

import Logo from '../components/Logo';

function MinimalHeader() {
  return (
    <header>
      <nav className='navbar navbar-expand-lg' style={{ zIndex: 1000 }}>
        <div className='logo navbar-brand' id='votefwdLogo'>
          <Logo />
        </div>
      </nav>
    </header>
  );
}

export default memo(MinimalHeader);
